/**
 * Extends ImagesLoaded plugin to prevent forced synchronous layouts.
 *
 * The imagesloaded plugin causes forced synchronous layouts. requestAnimationFrame is
 * used to prevent this behaviour by grouping reads and writes.
 *
 * This is accomplished by extending the original imagesloaded plugin, initiating
 * the constructor on read and executing the callback on write.
 */
import 'imagesloaded.pkgd';

const ImagesLoadedrAF = function (images, callback) {
  // Create separate scope if method is used without new keyword
  if (this instanceof ImagesLoadedrAF === false) {
    return new ImagesLoadedrAF(images, callback);
  }

  const _this = this;

  requestAnimationFrame(function () {
    // calls imagesLoaded parent constructor
    window.imagesLoaded.call(_this, images, function () {
      // save callback scope and arguments and execute on
      // dom write loop
      const callbackArguments = Array.from(arguments);

      requestAnimationFrame(function () {
        callback.apply(this, callbackArguments);
      });
    });
  });
};

/**
 * Clones original ImagesLoaded prototype so requestAnimationFrame implementation
 * effectively extends prototype object.
 */
const CloneFn = function () {};
CloneFn.prototype = window.imagesLoaded.prototype;
ImagesLoadedrAF.prototype = new CloneFn();

export default ImagesLoadedrAF;
