/**
 * Event type constants used for analytics
 */

export default {
  ARTIFICIAL_PAGE_VIEW: 'artificialPageView',
  CART_UPDATED: 'cartUpdated',
  CONTACT_FORM_SUBMIT: 'contactFormSubmit',
  COOKIE_CLICK: 'cookieClick',
  EXTERNAL_LINK_CLICK: 'externalLinkClick',
  FAQ_ITEM_OPEN: 'faqItemOpen',
  FEEDBACK_BUTTON_CLICK: 'feedbackButtonClick',
  FILTER_APPLIED: 'filterApplied',
  INTERNAL_SEARCH: 'internalSearch',
  PDP_THUMBNAIL_CLICK: 'pdpThumbnailClick',
  PRODUCT_CLICK: 'productClick',
  PRODUCT_DETAIL_IMPRESSION: 'productDetailImpression',
  PRODUCT_IMPRESSION: 'productImpression',
  USER_INFO: 'userInfo',
  SHARE_QR_CART_MODAL_OPEN: 'qrCartModalOpen',
  SIZE_GUIDE_OPEN: 'sizeGuideOpen',
  SIZE_GUIDE_CLOSE: 'sizeGuideClose',

  SORTING_OPTION_CLICK: 'sortingOptionClick',
  STOCK_LOCATOR: 'stockLocator',
  STORE_FINDER_SEARCH: 'storeFinderSearch',
  VIEW_AS_CLICK: 'viewAsClick',
  WHERE_IS_IT_MADE_OPEN: 'whereIsItMadeOpen',
  CATEGORY_IMPRESSION: 'categoryImpression',
  NEWSLETTER_SUBSCRIBE: 'newsletterSubscribe',
  QUBIT_EMIT_PAGELOAD_BASKET_EVENTS: 'emitPageloadBasketEvents',
  QUBIT_EMIT_CONFIRMATION_PAGE_EVENT: 'emitConfirmationPageEvent',
  QUBIT_EMIT_PRODUCT_PAGE_EVENT: 'emitProductPageEvent',
  ALGOLIA_EMIT_CONFIRMATION_PAGE_EVENT: 'emitAlgoliaConfirmationPageEvent',
  ALGOLIA_EMIT_SUGGESTION_CLICK: 'emitAlgoliaSuggestionClick',
  STOCK_NOTIFICATION_OPEN: 'stockNotificationOpen',
  STOCK_NOTIFICATION_SUBMIT: 'stockNotificationSubmit',
  COMING_SOON_NOTIFICATION_OPEN: 'comingSoonNotificationOpen',
  COMING_SOON_NOTIFICATION_SUBMIT: 'comingSoonNotificationSubmit',
  QUICK_SHOP_CLOSE: 'quickShopClose',
  WISHLIST_MODAL_OPEN: 'wishlistModalOpen',
  SIZE_SELECTION: 'size selection',
  COMING_SOON: 'coming soon',
  RETURN_FEE_PDP_MESSAGE_VIEW_EVENT: 'returnFeePdpMessageViewEvent',
};
