/**
 * Make use of requestIdleCallback or fall back to window.setTimeout
 *
 * https://github.com/facebook/react/blob/9198a5cec0936a21a5ba194a22fcbac03eba5d1d/packages/shared/enqueueTask.js
 * https://github.com/facebook/react/pulls?q=is%3Apr+is%3Aclosed+MessageChannel+
 * @param {function} idleCallback
 */
export default function requestIdleCallback(idleCallback) {
  if ('requestIdleCallback' in window) {
    return window.requestIdleCallback(idleCallback);
  }

  if ('MessageChannel' in window) {
    const channel = new MessageChannel();
    channel.port1.onmessage = idleCallback;
    return channel.port2.postMessage(undefined);
  }

  return window.setTimeout(idleCallback, 1);
}
