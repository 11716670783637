/* global AppSettings */
/**
 * SideNavigation submenu handling.
 *
 * Handles:
 * - Closes elements on same level when you click on an element
 * - Makes sure that the title of sub sub lists is always visible (tops/bottoms etc.) when you open it
 * - Scroll active menu element into view first time you open the navigation
 *
 */
import $ from 'jquery';
import createLogger from '../logger/Logger';
import keyboardInput from '../utils/keyboardInput';
import requestIdleCallback from '../utils/requestIdleCallback';
import '../domutils/Element.jQuery';

const Logger = createLogger('SubMenu');
const _this = this;
const animationDuration = 500;
const bottomHeight = AppSettings.topNavigationHeight;
const containerClassName = 'js-sideNav-subMenu';
let initialClick = true;
let scrollContainer;
const scrollContainerClassName = 'js-sideNav-scrollContainer';
const scrollMargin = 10;
const scrollMarginActiveItem = -4;
let scrollParentItemIntoViewId;
let scrollParentItemIntoViewTimeoutId;
let sideNavigation;
const submenuClassName = 'js-sideNav-subMenu';
const submenuSelector = `.${submenuClassName}`;
const subtitleClassName = 'js-sideNav-subtitle';
const subtitleSelector = `.${subtitleClassName}`;
const titleClassName = 'js-sideNav-title';
const titleSelector = `.${titleClassName}`;
const toggleClassName = 'js-sideNav-menuToggle';
const toggleSelector = `.${toggleClassName}`;
const { topNavigationHeight } = AppSettings;

const Module = {};

/* ** PUBLIC METHODS ** */

Module.initialize = function () {
  Logger.info('Initialized');
  Logger.time('initialize', this);

  sideNavigation = document.querySelector('.js-sideNav') || document.createElement('div');

  bindEvents();

  requestIdleCallback(scrollParentOfActiveMenuItemIntoView);

  Logger.timeEnd('initialize', this);
};

/* ** PRIVATE METHODS ** */

function bindEvents() {
  $(toggleSelector).on('vclick', onToggleClick.bind(_this));
  sideNavigation.jq.on(
    'keydown',
    keyboardInput.handleKeys.bind(this, ['enter', ' '], onToggleClick)
  );
}

function onToggleClick(event) {
  if (event.target.nodeName === 'A') {
    return true;
  }

  event.stopPropagation();
  event.preventDefault();

  const idleCallback = () => {
    Logger.time('onToggleClick', this);
    if (initialClick) {
      const activeItems = [...document.getElementsByClassName('.is-active-initial')];

      activeItems.forEach(item => {
        item.style.display = 'block';
        item.classList.remove('is-active-initial');
      });
      initialClick = false;
    }

    const parent = event.target.closest(submenuSelector);
    Logger.debug('onToggleClick', parent);

    if (parent) {
      updateMenu(parent);
    }
    Logger.timeEnd('onToggleClick', this);
  };

  requestIdleCallback(idleCallback);

  /**
   * Toggle ARIA attributes
   */
  if (event.target.hasAttribute('aria-expanded')) {
    const { target } = event;
    const targetControl = document.querySelector(`#${target.getAttribute('aria-controls')}`);
    const isExpanded = target.getAttribute('aria-expanded');

    if (isExpanded === 'true') {
      target.setAttribute('aria-expanded', false);
      targetControl && targetControl.setAttribute('aria-hidden', true);
    } else {
      document.querySelectorAll(subtitleSelector).forEach(subtitle => {
        subtitle.setAttribute('aria-expanded', false);
        subtitle.nextElementSibling.setAttribute('aria-hidden', true);
      });
      if (target.classList.contains(titleClassName)) {
        document.querySelectorAll(titleSelector).forEach(title => {
          title.setAttribute('aria-expanded', false);
          title.nextElementSibling.setAttribute('aria-hidden', true);
        });
      }

      target.setAttribute('aria-expanded', true);
      targetControl && targetControl.setAttribute('aria-hidden', false);
    }
  }
}

/**
 * Make sure that the title of the parent element (if available)
 * is visible.
 *
 * Basically scroll it into view.
 * @param {jquery object} $element
 * @param {boolean} updateOnce If true position is updated once and $element is also checked to see if it's below the fold
 */
function scrollParentItemIntoView(element, updateOnce, checkBelowTheFold) {
  Logger.time('scrollParentItemIntoView', this);
  if (!scrollContainer) {
    scrollContainer = document.querySelector(`.${scrollContainerClassName}`);
  }

  let parent;
  let menuHeight = 0;
  let updateActive = true;

  cancelAnimationFrame(scrollParentItemIntoViewId);
  clearTimeout(scrollParentItemIntoViewTimeoutId);

  if (updateOnce) {
    updateActive = false;
  } else {
    // Update scroll position while the folding animation is running
    // when folding animation is done cancel updating the scroll position
    scrollParentItemIntoViewTimeoutId = setTimeout(function () {
      updateActive = false;
    }, animationDuration);
  }

  const updateScrollPosition = function () {
    let offsetTop;
    let elementOffsetBottom;

    requestAnimationFrame(function updateScrollPositionRead() {
      const scrollFixPx = 1;
      offsetTop =
        parent.offsetTop -
        (parent.offsetParent || parent).scrollTop -
        topNavigationHeight +
        scrollFixPx;
      elementOffsetBottom = checkBelowTheFold
        ? element.offsetTop - (parent.offsetParent || parent).scrollTop - menuHeight
        : 0;
      if (offsetTop < 0 || elementOffsetBottom > 0) {
        requestAnimationFrame(function updateScrollPositionWrite() {
          let scrollOffset = -(offsetTop - scrollMargin);

          if (elementOffsetBottom > 0) {
            scrollOffset -= scrollMarginActiveItem;
          }

          scrollContainer.scrollTop -= scrollOffset;
        });
      }

      if (updateActive) {
        scrollParentItemIntoViewId = requestAnimationFrame(updateScrollPosition);
      }
    });
  };

  requestAnimationFrame(function () {
    if (element.classList.contains(submenuClassName)) {
      parent = element;
    } else {
      parent = element.closest(submenuSelector);
    }

    if (checkBelowTheFold) {
      menuHeight = scrollContainer.clientHeight - bottomHeight;
    }

    if (parent) {
      scrollParentItemIntoViewId = requestAnimationFrame(updateScrollPosition);
    }
  });

  Logger.timeEnd('scrollParentItemIntoView', this);
}

/**
 * Called on initialization, scrolls the label of the active submenu element into view
 * if it's not visible yet.
 */
function scrollParentOfActiveMenuItemIntoView() {
  const activeItem = sideNavigation.querySelector(`${submenuSelector} .is-active`);
  if (activeItem) {
    scrollParentItemIntoView(activeItem, true, true);
  }
}

/**
 * Toggle `is-active` class and animate menu up or down based on state
 * @param  {Element}  element  DOM element containing sub menu
 * @param  {Boolean}  isActive Optional, if omited state is reversed based on `is-active` class
 * @return {Boolean}           Active state
 */
function toggleMenu(element, isActive) {
  if (typeof isActive !== 'boolean') {
    isActive = !element.classList.contains('is-active');
  }

  element.classList.toggle('is-active', isActive);
  const container = element.querySelector(`.${containerClassName}`);
  container &&
    container.jq
      .slideToggle(250)
      .attr('aria-hidden', !isActive)
      .prev(toggleSelector)
      .attr('aria-expanded', isActive);

  return isActive;
}

/**
 * Toggle menu state
 * @param  {Element} element DOM element
 * @return {Boolean}         Is menu open or closed
 */
function updateMenu(element) {
  const wasActive = element.classList.contains('is-active');

  if (!wasActive) {
    [...element.parentElement.getElementsByClassName(submenuClassName)].forEach(function (element) {
      if (element.classList.contains('is-active')) {
        toggleMenu(element);
      }
    });
  }

  const isActive = toggleMenu(element, !wasActive);
  if (isActive) {
    scrollParentItemIntoView(element, true);
  }

  return isActive;
}

export default Module;
