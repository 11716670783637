/* global AppSettings */
import Mustache from 'mustache';

export default {
  initializeStateful,
  initialize({ ampliencePreviewTime, amplienceVSE, amplienceTSP }) {
    const domTemplate = document.getElementById('stopAmpliencePreviewElement');
    if (!domTemplate) {
      return;
    }

    const href = `${domTemplate.dataset.basehref}`;

    let shareUrl = window.location.href;
    shareUrl +=  window.location.search !== "" ? `&vse=${amplienceVSE}` : `?vse=${amplienceVSE}`;
    if(amplienceTSP !== undefined && amplienceTSP !== null) {
      shareUrl += `&tsp=${amplienceTSP}`
    }

    domTemplate.insertAdjacentHTML('afterend', Mustache.render(domTemplate.innerHTML, {
      ampliencePreviewTime,
      shareUrl,
      exitLink: href,
    }))

    bindCopyAmpliencePreviewUrl();
  },
};

function bindCopyAmpliencePreviewUrl() {
  const copyButtonElm = document.querySelector('.js-copyAmpliencePreviewUrl');
  if(copyButtonElm) {

    copyButtonElm.addEventListener('click', event => {
      event.preventDefault();
      copyButtonElm.classList.remove('has-copied');
      copyButtonElm.classList.remove('not-copied');
      if(navigator && navigator.clipboard) {
        navigator.clipboard.writeText(copyButtonElm.getAttribute('href')).then( function() {
          copyButtonElm.classList.add('has-copied');
        }, function(err) {
          copyButtonElm.classList.add('not-copied');
        });
      }
    });
  }
}

function initializeStateful() {
  if (!AppSettings.statelessPage) {
    bindCopyAmpliencePreviewUrl();
  }
}
