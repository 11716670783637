import Toggle from './Toggle';
import ScrollFix from './ScrollFix';
import SubMenu from './SubMenu';
import AjaxActivateLinks from './AjaxActivateLinks';

export default {
  initialize() {
    SubMenu.initialize();
    ScrollFix.initialize();
    Toggle.initialize();
    AjaxActivateLinks.initialize();
  },
};
