import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import getConfirmationPageData from 'components/analytics/OrderConfirmationUtils';

const { enableBazaarvoice, currencySettings } = AppSettings;

class BazaarVoice {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    if (!enableBazaarvoice) {
      return;
    }

    const observer = new MutationObserver(mutations => {
      for (let mutation of mutations) {
        for (let node of mutation.addedNodes) {
          if (!(node instanceof HTMLElement) || node.id !== 'bv-mboxzone-lightbox') continue;

          node.dataset.csCapture = '';
          document.jq.trigger(AnalyticsEventTypes.ARTIFICIAL_PAGE_VIEW);
        }
      }
    });

    observer.observe(document.body, { childList: true });

    document.jq.on(
      AnalyticsEventTypes.QUBIT_EMIT_CONFIRMATION_PAGE_EVENT,
      BazaarVoice.emitConfirmationPageEvents
    );
  }

  /**
   * Emits BV transaction event,
   *
   * Price should be only strings with 2 decimals
   * For example: "2.00" is valid, "2" is invalid
   *
   * Docs: https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Collect/bvpixel.html
   */
  static emitConfirmationPageEvents() {
    const { transaction, basket, items } = getConfirmationPageData();
    const transactionEvent = {
      currency: currencySettings?.currencyCode?.toUpperCase(),
      orderId: transaction?.id,
      total: basket?.totalPriceNoTaxes?.value?.toFixed(2),
      items: items.map(({ quantity, product: { price, productId } }) => ({
        price: price?.value?.toFixed(2),
        quantity: quantity?.toString(),
        productId,
      })),
    };

    window.bvCallback = function (BV) {
      BV?.pixel?.trackTransaction(transactionEvent);
    };
  }
}

export default BazaarVoice;
