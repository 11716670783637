/**
 * Generic dismissable alert message container
 *
 * example:
 * document.jq.trigger(EventTypes.ALERT_RENDER_REQUEST, ['info', 'Account details modified']);
 * document.jq.trigger(EventTypes.ALERT_RENDER_REQUEST, ['danger', 'Invalid address supplied']);
 *
 * @see hybris/bin/custom/gstar/gstarstorefront/web/webroot/WEB-INF/tags/responsive/common/globalMessages.tag
 *
 * @author Erik Slagter
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import Singleton from 'components/utils/Singleton';
import Mustache from 'mustache';
import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';
import 'jquery.extendPrototype';

const Logger = createLogger('Alert');

  const alertTemplate =
    '<div class="alert alert-{{type}} alert-dismissable js-alert-dismissable" data-cs-capture><p>{{{message}}}&nbsp;</p></div>';

  const messageTypes = {
    INFO: 'info',
    WARNING: 'warning',
    DANGER: 'danger',
  };

  function Alert() {
    this.$container = $('.js-mainContainer');
    this.$uspBar = this.$container.find('.js-uspBar').last();
    this.bindEvents();
  }

  $.extendPrototype(Alert, {
    $container: undefined,
    bindEvents() {
      this.$container.on('vclick', '.js-alert-dismissable', this.hideAlert);
      document.jq.on(EventTypes.ALERT_RENDER_REQUEST, this.createAlert.bind(this));
    },
    hideAlert(event) {
      $(this).hide();
      return false;
    },
    createAlert(event, type, message) {
      if (this.validType(type)) {
        $('.js-alert-dismissable').trigger('vclick');
        const _message = Mustache.render(alertTemplate, { type, message });

        if (this.$uspBar.length) {
          this.$uspBar.after(_message);
        } else {
          this.$container.prepend(_message);
        }
      } else {
        Logger.warn(`Invalid alert type: ${type}`);
      }
    },
    validType(type) {
      return (
        messageTypes.INFO === type || messageTypes.WARNING === type || messageTypes.DANGER === type
      );
    },
  });

export default Singleton.create(Alert, 'Alert');
