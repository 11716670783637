/**
 * Markup of this chat button is provided by third party code (Salesforce livechat agent)
 * so this component serves only for triggering events for analytics vendors
 */

import createLogger from './../logger/Logger';
import EventTypes from './../EventTypes';
import onWindowLoaded from './../utils/onWindowLoaded';

const Logger = createLogger('invitationStartChatButton');

const InvitationStartChatButton = {
  invitationStartChatButton: null,

  /**
   * Initialize module after page load
   */
  initialize() {
    onWindowLoaded(this.onLoad, this, 10);
  },

  /**
   * Bind events
   */
  bindEvents() {
    this.invitationStartChatButton.addEventListener('click', this.onClick.bind(this));
  },

  /**
   * Executes on button click
   * @param  {Event} event Native Event object
   */
  onClick() {
    document.jq.trigger(EventTypes.LIVECHAT_BUTTONS_CLICKED);
  },

  /**
   * Executed on page load
   */
  onLoad() {
    Logger.debug('initialize');
    this.invitationStartChatButton = document.getElementById('gs-chat-accept-invite');

    if (this.invitationStartChatButton) {
      this.bindEvents();
    }
  },
};

export default InvitationStartChatButton;
