/* global AppSettings, AppState */
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import EventTypes from 'components/EventTypes';
import { getBasketItem, getBasketSummary, getProductData } from 'components/analytics/QubitUtils';
import getConfirmationPageData from 'components/analytics/OrderConfirmationUtils';
import createLogger from 'components/logger/Logger';

const Logger = createLogger('Qubit');

const {
  pageType = 'other',
  languageIso,
  country,
  currencySettings,
  statelessPage,
  qubitEventsEnabled,
} = AppSettings;

const { pageSubtype, userMetaData } = AppState;

const pageNameMap = new Map([
  ['cart', 'basket'],
  ['searchresults', 'search'],
  ['purchase', 'confirmation'],
  ['feature', 'other'],
  ['error404', 'other'],
]);

class Qubit {
  constructor() {
    if (
      /checkout-v2/.test(document.documentElement.className) &&
      !document.getElementById('order-confirmation-page')
    ) {
      return;
    }

    this.initialize();
    this.bindEvents();
  }

  bindEvents() {
    if (statelessPage) {
      document.jq.on(AnalyticsEventTypes.USER_INFO, () => Qubit.ecUserEvent());
    }

    document.jq
      .on(AnalyticsEventTypes.QUBIT_EMIT_CONFIRMATION_PAGE_EVENT, () =>
        Qubit.emitConfirmationPageEvents()
      )
      .on(AnalyticsEventTypes.QUBIT_EMIT_PRODUCT_PAGE_EVENT, (event, eventData) =>
        Qubit.emitProductPageEvents(eventData)
      )
      .one(EventTypes.READY_FOR_SECONDARY_ASSETS, Qubit.trackCheckoutV1.bind(Qubit));
  }

  initialize() {
    Qubit.ecViewEvent();

    if (!statelessPage) {
      Qubit.ecUserEvent();
    }
  }

  static emitEcEvent(eventName, payload) {
    if (qubitEventsEnabled) {
      try {
        window.uv.emit(eventName, payload);
      } catch (e) {
        Logger.error(e.message);
      }
    }
  }

  /**
   * Emits ecView event for Qubit.
   */
  static ecViewEvent() {
    const pageName = pageType?.toLowerCase() || '';
    const type = pageNameMap.get(pageName) || pageName;
    const eventObj = {
      type,
      language: languageIso?.toLowerCase()?.replace('_', '-'),
      country: country?.toUpperCase(),
      currency: currencySettings?.currencyCode?.toUpperCase(),
    };

    if (pageSubtype) {
      Object.assign(eventObj, {
        subtypes: [pageSubtype],
      });
    }

    const isSuccessConfirmationPage = type === 'confirmation' && !pageSubtype;

    if (isSuccessConfirmationPage) {
      Object.assign(eventObj, {
        subtypes: ['payment success'],
      });
    }

    Qubit.emitEcEvent('gstar.ecView', eventObj);
  }

  /**
   * Poll for Exponea segments (window.exponea.dimensionsHashed.segments)
   */
  static pollExponeaSegments() {
    const POLLING_INTERVAL = 100;
    const POLL_MAX_ATTEMPTS = 30;
    let attempts = 0;

    const executePoll = async (resolve, reject) => {
      const exponeaSegments = window?.exponea?.dimensionsHashed?.segments || [];
      attempts++;

      if (exponeaSegments.length) {
        return resolve(exponeaSegments);
      }

      if (attempts >= POLL_MAX_ATTEMPTS) {
        return resolve([]);
      }

      setTimeout(executePoll, POLLING_INTERVAL, resolve, reject);
    };

    return new Promise(executePoll);
  }

  /**
   * Emits ecUser event for Qubit.
   */
  static ecUserEvent() {
    Qubit.pollExponeaSegments()
      .then(exponeaSegments => {
        const user = {
          exponeaHashedSegments: exponeaSegments,
        };

        if (userMetaData?.customerId) {
          user.id = userMetaData.customerId;
        } else {
          user.isGuest = true;
        }

        return Qubit.emitEcEvent('gstar.ecUser', { user });
      })
      .catch(reason => {
        console.error(reason);
        window?.newrelic?.noticeError(reason);
      });
  }

  /**
   * Emits ecBasketItem events and ecBasketSummary event consecutively
   */
  static emitBasketEvents(eventData) {
    const basketSummaryData = getBasketSummary(eventData?.basket, false);
    const basketSummaryPerItemData = getBasketSummary(eventData?.basket, true);
    const basketItemsData = eventData?.items || [];

    for (const basketItem of basketItemsData) {
      Qubit.ecBasketItem(getBasketItem(basketItem, basketSummaryPerItemData));
    }

    Qubit.ecBasketSummary(basketSummaryData);
  }

  /**
   * Emits ecBasketItem event
   */
  static ecBasketItem(basketItem) {
    Qubit.emitEcEvent('gstar.ecBasketItem', basketItem);
  }

  /**
   * Emits ecBasketSummary event
   */
  static ecBasketSummary(basketSummary) {
    Qubit.emitEcEvent('gstar.ecBasketSummary', { basket: basketSummary });
  }

  /**
   * Trigger basket events for Qubit
   */
  static triggerBasketEvents() {
    const qubitBasketDataSelector = document.querySelector('[data-qubit-basket]');
    const qubitBasketData = qubitBasketDataSelector?.dataset?.qubitBasket;

    if (qubitBasketData) {
      try {
        const parsedBasketData = JSON.parse(qubitBasketData);
        document.jq.trigger(
          AnalyticsEventTypes.QUBIT_EMIT_PAGELOAD_BASKET_EVENTS,
          parsedBasketData
        );
      } catch {
        return undefined;
      }
    }
  }

  /**
   * Emits ecBasketItemTransaction event
   * @param {Object} basketData
   * @param {Object} itemData
   * @param {Object} transactionData
   */
  static ecBasketItemTransactionEvent(basketData, itemData, transactionData) {
    Qubit.emitEcEvent('gstar.ecBasketItemTransaction', {
      basket: getBasketSummary(basketData, true),
      ...itemData,
      transaction: transactionData,
    });
  }

  /**
   * Emits ecBasketTransactionSummary event
   * @param {Object} basketData
   * @param {Object} transactionData
   */
  static ecBasketTransactionSummaryEvent(basketData, transactionData) {
    Qubit.emitEcEvent('gstar.ecBasketTransactionSummary', {
      basket: getBasketSummary(basketData, false),
      transaction: transactionData,
    });
  }

  static ecProductEvent(eventData) {
    Qubit.emitEcEvent('gstar.ecProduct', {
      eventType: 'detail',
      product: {
        ...eventData,
      },
    });
  }

  /**
   * Emits ecBasketItem events and ecBasketSummary event consecutively
   */
  static emitConfirmationPageEvents() {
    const qubitConfirmationData = getConfirmationPageData();

    const basketData = qubitConfirmationData?.basket;
    const transactionData = qubitConfirmationData?.transaction;
    const itemsData = qubitConfirmationData?.items || [];

    if (basketData && itemsData.length && transactionData) {
      for (const itemData of itemsData) {
        // remove product images data as it is used on counting experience only
        if (itemData.product?.primaryImage) {
          delete itemData.product.primaryImage;
        }

        if (itemData.product?.hoverImage) {
          delete itemData.product.hoverImage;
        }

        Qubit.ecBasketItemTransactionEvent(basketData, itemData, transactionData);
      }

      Qubit.ecBasketTransactionSummaryEvent(basketData, transactionData);
    }
  }

  static emitProductPageEvents(productData) {
    productData = { ...productData, currency: currencySettings?.currencyCode?.toUpperCase() || '' };
    const qubitProductData = getProductData(productData);

    Qubit.ecProductEvent(qubitProductData);
  }

  /**
   * Emit view category event
   * @param eventData
   */
  static emitViewCategoryEvent(eventData) {
    const { category_id: categoryId, page_number: pageNumber } = eventData;
    Qubit.emitEcEvent('gstar.ecInteraction', {
      type: 'lazyLoad',
      name: `${categoryId} | ${pageNumber}`,
    });
  }

  /**
   * Emit ecInteraction event with type 'quickViews'
   * @param {Object} eventData
   */
  static emitProductDetailViewEvent(eventData) {
    Qubit.emitEcEvent('gstar.ecInteraction', {
      type: 'quickView',
      name: eventData?.materialNumber,
    });
  }

  /**
   * Return event type name for Add/Remove to Cart
   * @param {object} eventData event data as received from trigger
   * @returns {string}
   */
  static getEventType(eventData) {
    if (eventData.event === 'eecAddToCart') {
      return 'add';
    }
    if (eventData.event === 'eecRemoveFromCart') {
      return 'remove';
    }
    return '';
  }

  /**
   * Emit ecBasketItemAction event
   * @param eventData
   */
  static ecBasketItemActionEvent(eventData) {
    if (/checkout-v2/.test(document.documentElement.className)) {
      return;
    }
    const action = Qubit.getEventType(eventData);
    if (!action) {
      return;
    }
    const productData = eventData?.ecommerce[action]?.products[0];

    Qubit.emitEcEvent('gstar.ecBasketItemAction', {
      action,
      product: {
        productId: productData?.materialNumber,
      },
      quantity: productData?.quantity,
    });
  }

  /**
   * ecBasketItemAction event handler for checkout v1
   */
  static trackCheckoutV1() {
    const cartModificationElement = document.querySelector('.js-cartModification');
    const eventRegExp = /eec(AddTo|RemoveFrom)Cart/g;
    const matches = cartModificationElement?.textContent.match(eventRegExp).length || 0;

    if (matches) {
      const eventDatas = window.dataLayer
        .filter(entry => entry.event && eventRegExp.test(entry.event))
        .slice(0 - matches / 2);

      eventDatas.map(Qubit.ecBasketItemActionEvent);
    }
  }
}

export default Qubit;
