/**
 * Flyout and Account mini flyout interaction logic • MARS-280 • UD-23
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import EventTypes from 'components/EventTypes';
import keyboardInput from '../components/utils/keyboardInput';
import 'jquery.resizeEvents';

const FLYOUT_OPEN = 'flyout--open';
const FLYOUT_ACTIVE = 'flyout__main--active';
const FLYOUT_MAIN_HOOK = '.js-flyout__main';
const FLYOUT_ITEM_HOOK = '.js-topNavigation-mainLinks-item';
const FLYOUT_HOOK = '.js-flyout';
const MAIN_NAV_HEIGHT = 54;

class Flyout {
  /**
   * Constructs Flyout class
   * and set some initial state
   */
  constructor() {
    if (!document.querySelector('.has-flyout')) {
      return;
    }
    this.hasBreakpointFlyout = window.innerWidth > 1024;
    this.flyoutTimeIds = {};
    this.accountTimerId = undefined;
    this.isRunning = false;
    this.flyoutBody = document.querySelector('.js-flyout__body');

    this.calculateWidth();

    const flyout = document.querySelector(FLYOUT_HOOK);

    if (flyout) {
      const mainLinks = flyout.querySelectorAll(FLYOUT_MAIN_HOOK);
      if (mainLinks.length) {
        mainLinks.forEach(mainLink => this.copyAttributes(mainLink));
      }
    }

    this.bindEvents();
  }

  /**
   * Calculate the width of the flyout container
   */
  calculateWidth() {
    if (!this.flyoutBody) {
      return;
    }

    const wrapperContainer = this.flyoutBody.querySelector('.js-flyout__wrapperContainer');
    if (!wrapperContainer) {
      return;
    }

    const flyoutWrappers = wrapperContainer.querySelectorAll('.js-flyout__wrapper');
    if (flyoutWrappers.length) {
      wrapperContainer.style.width = `${flyoutWrappers.length * 100}%`;
    }
  }

  /**
   * Bind events for Flyout to DOM
   */
  bindEvents() {
    const container = document.querySelector(FLYOUT_HOOK);
    container &&
      container.jq
        .on('keyup', keyboardInput.handleKeys.bind(this, 'tab', this.onkeyupHandler))
        .on('focusout', this.focusoutHandler);

    const flyoutElements = document.querySelectorAll(`.js-flyout__main ${FLYOUT_ITEM_HOOK}`);
    flyoutElements.forEach(element => {
      element.jq
        .on('mouseenter', this.mouseenterHandler.bind(this))
        .on('mouseleave', event => this.clearTimeouts());
    });

    const flyoutContextElements = document.querySelectorAll(`.has-flyout ${FLYOUT_HOOK}`);
    flyoutContextElements.forEach(element => {
      element.jq.on('click', this.closeHandler.bind(this));
    });
    window.jq.on('resizeEnd', '.has-flyout', this.resizeHandler.bind(this));

    const flyoutWrapperElements = document.querySelectorAll(
      '.flyout__body, .flyout__wrapperContainer'
    );
    flyoutWrapperElements.forEach(element => {
      element.jq.on('transitionend', () => {
        this.isRunning = false;
      });
    });

    const closeEvents = [
      EventTypes.LOCALE_SELECTOR_SHOW,
      EventTypes.CART_VIEW_SHOW_START,
      EventTypes.WISHLIST_MODAL_SHOW_START,
      EventTypes.NOTIFICATION_VIEW_SHOW,
    ].join(' ');

    document.jq.on(closeEvents, () => this.closeFlyout());

    const accountMenu = document.querySelector('.js-topNavigation-account__menu');
    const accountNav = document.querySelector('.js-topNavigation-account');
    if (!accountMenu || !accountNav) {
      return;
    }

    accountNav.jq
      .on('mouseover', () => this.mouseoverHandler())
      .on('mouseleave', () => {
        this.accountTimerId = window.setTimeout(
          () => accountMenu.classList.remove('is-active'),
          2000
        );
      })
      .on('keyup', keyboardInput.handleKeys.bind(this, 'tab', this.mouseoverHandler));

    accountMenu.jq
      .on('mousemove mouseover', () => {
        this.hasBreakpointFlyout && window.clearTimeout(this.accountTimerId);
      })
      .on('mouseleave', () => accountMenu.classList.remove('is-active'));
  }

  /**
   * Handle keyup within flyout, accessibility addition
   */
  onkeyupHandler(event) {
    if (event.target.classList.contains('sideNav-link')) {
      const parentId = event.target.closest('.js-flyout__wrapper').id;
      if (
        !document.body.classList.contains('flyout--open') ||
        !document
          .querySelector(`[data-entry-name="${parentId}"]`)
          .classList.contains('flyout__main--active')
      ) {
        // force scrollLeft on container to 0, browser will scroll element in position at focus
        // i.e. browser will set scrollLeft of container, even though overflow: hidden.
        this.flyoutBody.scrollLeft = 0;
        document.querySelector(`[data-entry-name="${parentId}"] ${FLYOUT_ITEM_HOOK}`).click();
      }
    }
  }

  focusoutHandler(event) {
    const from = event.originalEvent.target;
    const to = event.originalEvent.relatedTarget;

    if (
      from &&
      from.closest(FLYOUT_HOOK) &&
      to &&
      to.closest(FLYOUT_HOOK) === null &&
      document.body.classList.contains('flyout--open')
    ) {
      const activeItem = document.querySelector('.js-flyout__main.flyout__main--active a');
      activeItem && activeItem.jq.trigger('mouseenter');
    }
  }

  /**
   * handler for when mouseovering the myaccount main nav element, accessibility addition
   */
  mouseoverHandler() {
    const accountMenu = document.querySelector('.js-topNavigation-account__menu');
    this.hasBreakpointFlyout && accountMenu.classList.add('is-active'), this.closeFlyout();
  }

  /**
   * Changes global state of flyout according to screen width
   */
  resizeHandler() {
    this.hasBreakpointFlyout = false;
    if (window.innerWidth > 1024) {
      this.hasBreakpointFlyout = true;
    }

    // reset any nav to closed state
    this.closeFlyout();
    document.documentElement.classList.toggle('has-open-sideNavigation', false);
  }

  /**
   * Click handler for top navigation main links.
   * Dependent on breakpoint the link should be followed or the flyout should be shown
   * @param {jQuery.Event} event jQuery event object
   */
  toggleMenu(event) {
    this.clearTimeouts();
    if (this.isRunning) {
      return false;
    }
    this.isRunning = true;

    if (!this.hasBreakpointFlyout) {
      return true;
    }
    event.preventDefault();

    const target = event.currentTarget;
    const flyoutMain = target.closest('.js-flyout__main');
    const isFlyoutActive = flyoutMain.classList.contains(FLYOUT_ACTIVE);

    this.closeOthers();

    if (isFlyoutActive) {
      flyoutMain.classList.remove(FLYOUT_ACTIVE);
      target.setAttribute('aria-expanded', false);
      this.flyoutBody.jq.one('transitionend', () => {
        this.isRunning = false;
        document.body.classList.toggle(FLYOUT_OPEN, false);
      });
      return;
    }

    document.querySelectorAll('.js-flyout__main').forEach((element, idx) => {
      element.classList.remove(FLYOUT_ACTIVE);
      const mainLink = element.querySelector(FLYOUT_ITEM_HOOK);
      mainLink.setAttribute('aria-expanded', false);
      document.querySelector('.flyout__wrapperContainer').classList.remove(`is-active-${idx}`);
    });

    flyoutMain.classList.add(FLYOUT_ACTIVE);
    target.setAttribute('aria-expanded', true);
    document
      .querySelector('.flyout__wrapperContainer')
      .classList.add(`is-active-${flyoutMain.dataset.idx}`);
    document.body.classList.add(FLYOUT_OPEN);
  }

  /**
   * Handle mouseenter event: clear timeouts and set new timeout for opening flyout in 500ms
   * @param  {jQuery.Event} event jQuery event object
   */
  mouseenterHandler(event) {
    const isFlyoutActive = event.currentTarget
      .closest('.flyout__main')
      .classList.contains(FLYOUT_ACTIVE);
    if (isFlyoutActive || !this.hasBreakpointFlyout) {
      return;
    }
    this.clearTimeouts();
    this.flyoutTimeIds[event.currentTarget.id] = window.setTimeout(() => {
      event.currentTarget.focus();
      this.toggleMenu(event);
    }, 500);
  }

  /**
   * Trigger close events for notifications and locale selector
   */
  closeOthers() {
    document.jq.trigger(EventTypes.NOTIFICATION_VIEW_HIDE);
    const languageSelectorClose = document.querySelector('.js-localeSelector-close');
    languageSelectorClose && languageSelectorClose.click();
  }

  /**
   * Close any opened flyout
   */
  closeFlyout() {
    const flyoutActiveElements = document.querySelectorAll(`.js-flyout__main.${FLYOUT_ACTIVE}`);

    flyoutActiveElements.forEach(element => {
      element.classList.remove(FLYOUT_ACTIVE);
      const mainLink = element.querySelector(FLYOUT_ITEM_HOOK);
      mainLink.setAttribute('aria-expanded', false);
    });
    document.body.classList.remove(FLYOUT_OPEN);
  }

  /**
   * Copy attributes from title to anchor
   */
  copyAttributes(element) {
    const link = element.querySelector(FLYOUT_ITEM_HOOK);
    if (!link) {
      return;
    }

    if (element.hasAttribute('aria-controls')) {
      link.setAttribute('aria-controls', element.getAttribute('aria-controls'));
      element.removeAttribute('aria-controls');
    }

    if (element.hasAttribute('aria-haspopup')) {
      link.setAttribute('aria-haspopup', element.getAttribute('aria-haspopup'));
      element.removeAttribute('aria-haspopup');
    }

    if (element.hasAttribute('aria-expanded')) {
      link.setAttribute('aria-expanded', element.getAttribute('aria-expanded'));
      element.removeAttribute('aria-expanded');
    }
  }

  /**
   * Handler that calculates wether the click was on the ::after and closes flyouts if so.
   * @param {jQuery.Event} event jQuery event object
   */
  closeHandler(event) {
    if (!this.hasBreakpointFlyout || !event.originalEvent) {
      return true;
    }
    event.stopPropagation();
    const activeFlyout = document.querySelector(`.${FLYOUT_ACTIVE} ~ .js-flyout__body`);
    const { clientY } = event.originalEvent;
    if (!event.currentTarget.classList.contains('topNavigation-mainLinks') || !activeFlyout) {
      return false;
    }

    if (clientY > MAIN_NAV_HEIGHT) {
      this.closeFlyout();
    }
  }

  /**
   * Clear hover mouseenter timeouts
   */
  clearTimeouts() {
    Object.keys(this.flyoutTimeIds).forEach(key => window.clearTimeout(this.flyoutTimeIds[key]));
    this.flyoutTimeIds = {};
  }
}

export default Flyout;
