/**
 * Accessibility helper
 *
 * This module only decorates focused HTML elements when they
 * have gotten focus after keyboard interation.
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import keyboardInput from 'components/utils/keyboardInput';
import 'jquery.extendPrototype';

const Logger = createLogger('Accessibility');

const dataFocusAttribute = 'data-focused-by';
const focusType = {
  MOUSE: 'mouse',
  KEY: 'key',
};

function Accessibility() {}

$.extendPrototype(Accessibility, {
  initialize() {
    document.jq.on('focusout', this.handleFocusOut);
    document.jq.on('keyup', keyboardInput.handleKeys.bind(this, 'tab', this.handleKeyUp));
    document.jq.on('click', this.handleClick);
  },

  /**
   * Adds the attribute data-focused-by with a value of 'mouse'
   * to the element that got focus
   */
  handleClick(event) {
    event.stopPropagation();
    const element = event.target;
    const focusedElements = document.querySelectorAll(':focus');

    if (
      focusedElements.length &&
      (!element.hasAttribute(dataFocusAttribute) ||
        element.getAttribute(dataFocusAttribute) !== focusType.MOUSE)
    ) {
      element.setAttribute(dataFocusAttribute, focusType.MOUSE);
    }
  },

  /**
   * Adds the attribute data-focused-by with a value of 'key'
   * to the element that got focus
   */
  handleKeyUp(event) {
    const focusedElement = document.querySelectorAll(':focus');

    if (focusedElement.length) {
      // workaround for the focusout event which is not triggered when a select did not fire a change event
      if (focusedElement[0].nodeName === 'SELECT') {
        document.jq.one('keydown.Select', this.handleFocusOut);
      }
      focusedElement[0].setAttribute(dataFocusAttribute, focusType.KEY);
    }
  },

  /**
   * Always remove data-focused-by from elements
   * when the loose focus
   */
  handleFocusOut(event) {
    const element = event.target;
    if (element && element.removeAttribute) {
      element.removeAttribute(dataFocusAttribute);
    }
  },
});

export default new Accessibility();
