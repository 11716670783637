/**
 * Get name of function
 * @param  {Function} func      Function to get name of
 * @param  {String}   fallback  Optional fallback value
 * @return {String}             Name of function
 */
export default function getFunctionName(func, fallback) {
  if (!func) {
    console.trace();
    console.log(func, fallback);
    return `Bogus-${Date.now()}`;
  }
  return func.name || fallback;
}
