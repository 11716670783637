/* global exponea, AppSettings, AppState, dataLayer */
/**
 * Exponea dataLayer object hydration
 * @see https://docs.exponea.com/docs/datalayer-helper
 * @see https://g-star.atlassian.net/browse/CDP-42
 * @see raw/src/checkout/analytics/exponea/productHydrator.js
 */
import EventTypes from 'components/EventTypes';
import createLogger from 'components/logger/Logger';
import '../domutils/Element.jQuery';
import ExponeaPushNotifications from './ExponeaPushNotifications';

const Logger = createLogger('Exponea');

const baseData = {
  namespace: 'exponea',
  event_name: '',
  event_properties: {},
};

const exponeaEvents = {
  VIEW_ITEM: 'view_item',
  VIEW_CATEGORY: 'view_category',
  CART_UPDATE: 'cart_update',
  SEARCH: 'search',
  WISHLIST: 'wishlist',
};

const cartUpdateType = {
  SYSTEM: 'system',
  USER: 'user',
};

class Exponea {
  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    document.jq
      .on(EventTypes.UPDATE_USER_INFO_DONE, event => {
        const identificationData = {};
        if (AppState.userMetaData && window.exponea) {
          const { hashedUserId, customerId } = AppState.userMetaData;
          if (hashedUserId) {
            identificationData.registered = hashedUserId;
          }
          if (customerId) {
            identificationData.subscriber_key = customerId;
          }
          if (Object.keys(identificationData).length) {
            exponea.identify(identificationData);
          }
        }
      })
      .one(EventTypes.READY_FOR_SECONDARY_ASSETS, () => {
        Exponea.trackCheckoutV1.bind(Exponea);
        const exponeaPushNotifications = new ExponeaPushNotifications();
        exponeaPushNotifications.initialize();
      });
  }

  /**
   * In checkoutV1, dataLayer data for Google Analytics is pushed directly from a script element
   * in the page's source.
   * This function checks if the script element is in the pages and then uses data from dataLayer
   * to prepare the push for Exponea
   */
  static trackCheckoutV1() {
    const cartModificationElement = document.querySelector('.js-cartModification');
    const eventRegExp = /eec(AddTo|RemoveFrom)Cart/g;
    const matches =
      (cartModificationElement && cartModificationElement.textContent.match(eventRegExp).length) ||
      0;

    if (matches) {
      const eventDatas = dataLayer
        .filter(entry => entry.event && eventRegExp.test(entry.event))
        .slice(0 - matches / 2);

      if (eventDatas.length) {
        eventDatas.map(eventData => {
          const exponeaEventData = this.getCartUpdateData(eventData);
          window.dataLayer.push(exponeaEventData);
        });
      }
    }
  }

  /**
   * Create an object with properties as configured for Exponea
   * @param {object} eventData Data taken from json from PDP
   * @returns {object}
   */
  static getProductDetailImpressionData(eventData) {
    const {
      ean,
      size,
      name,
      materialNumber = '',
      subTargetAudience = '',
      materialGroup = '',
      availability = 'unknown',
      ecomm_prodid = '',
      staticCategoryPathIds = '',
      staticCategoryPath = '',
      fromPrice,
      finalPrice,
      basePrice,
    } = eventData;

    const sale_discount_percentage = Math.abs(
      Math.round(((Number(finalPrice) - Number(fromPrice)) / Number(fromPrice)) * -100)
    );

    const unixTimestamp = Math.round(Date.now() / 1e3);

    const impressionData = Object.assign({}, baseData);
    impressionData.event = 'exponea_view_item';
    impressionData.event_name = exponeaEvents.VIEW_ITEM;
    impressionData.event_properties = {
      product_id: materialNumber.toLowerCase(),
      variant_id: ecomm_prodid.toLowerCase(),
      variant_name: name,
      categories_path: staticCategoryPath.replace('/', ' > '),
      category_id: staticCategoryPathIds,
      categories_ids: [staticCategoryPathIds.split('/').pop()],
      from_price_local_currency: Number(fromPrice || basePrice),
      base_price_local_currency: Number(finalPrice),
      local_currency: AppSettings.currencySettings.currencyCode,
      domain: window.location.hostname,
      timestamp: unixTimestamp,
      style_family_en: eventData.styleFamily,
      main_color_en: eventData.mainColor,
      ean,
      size,
      fit_en: eventData.fit,
      availability_en: availability,
      sub_target_audience: subTargetAudience,
      material_group: materialGroup,
      parent_id: materialNumber.split('-').slice(0, 2).join('-').toLowerCase(),
      event_uid: `web_view_item_${window.dataLayerCache?.pageInfo?.page?.correlationId}_${unixTimestamp}`,
    };

    if (fromPrice) {
      impressionData.event_properties.sale_discount_percentage = sale_discount_percentage;
    }

    return impressionData;
  }

  /**
   * Get event data for Exponea view_category or search event
   * @param {object} eventData Data as sent along by the event
   * @returns {object}
   */
  static getViewCategoryEventData(eventData) {
    if (eventData.search_query) {
      return this.getSearchData(eventData);
    }
    return this.getViewCategoryData(eventData);
  }

  /**
   * Create an object with properties as configured for Exponea
   * @param {object} eventData event data as received from trigger
   * @return {object}
   */
  static getViewCategoryData(eventData) {
    const impressionData = Object.assign({}, baseData);

    impressionData.event_name = exponeaEvents.VIEW_CATEGORY;
    impressionData.event_properties = {
      ...eventData,
      domain: window.location.hostname,
      timestamp: Math.round(Date.now() / 1e3),
    };

    return impressionData;
  }

  /**
   *
   * @param {object} eventData event data as received from trigger
   * @return {object}
   */
  static getSearchData(eventData) {
    const impressionData = Object.assign({}, baseData);
    const { category_listed_products, search_query, page_number } = eventData;

    impressionData.event_name = exponeaEvents.SEARCH;
    impressionData.event_properties = {
      category_listed_products,
      search_query,
      page_number,
      category_name: 'search',
      domain: window.location.hostname,
      timestamp: Math.round(Date.now() / 1e3),
    };

    return impressionData;
  }

  /**
   * Generate event type name for Exponea
   * @param {object} eventData event data as received from trigger
   * @returns {string}
   */
  static getEventType(eventData) {
    return eventData.event === 'eecAddToCart'
      ? 'add'
      : eventData.event === 'eecRemoveFromCart'
      ? 'remove'
      : '';
  }

  /**
   * Create an object with properties as configured for Exponea
   * @param {object} eventData
   * @returns {object|undefined}
   */
  static getCartUpdateData(eventData) {
    if (/checkout-v2/.test(document.documentElement.className)) {
      return;
    }
    try {
      if (typeof eventData === 'string') {
        eventData = JSON.parse(eventData);
      }
    } catch (e) {
      Logger.warn('Unable to parse cart data');
      return;
    }
    const action = this.getEventType(eventData);
    if (!action) {
      return;
    }
    const impressionData = Object.assign({}, baseData);

    const cartData = Exponea.getCartData();
    const cartTotalsElement = document.querySelector('.js-cartTotals');
    const total_price = cartTotalsElement && cartTotalsElement.dataset.totalPrice;

    const productData = eventData.ecommerce[action].products[0];
    const {
      materialNumber = '',
      ecomm_prodid,
      name,
      basePrice,
      finalPrice,
      styleFamily,
      mainColor,
      ean,
      size,
      fit,
      availability,
      fromPrice,
    } = productData;

    const { pageType = '' } = AppSettings;

    if (!Number(cartData.total_quantity)) {
      cartData.total_quantity = 0;
    }

    const unixTimestamp = Math.round(Date.now() / 1e3);

    impressionData.event = 'exponea_cart_update';
    impressionData.event_name = exponeaEvents.CART_UPDATE;
    impressionData.event_properties = {
      action,
      product_id: materialNumber.toLowerCase(),
      variant_id: ecomm_prodid.toLowerCase(),
      variant_name: name,
      from_price_local_currency: Number(fromPrice || basePrice),
      base_price_local_currency: finalPrice,
      ...cartData,
      total_price_local_currency: Number(total_price),
      page_type: pageType.toLowerCase(),
      local_currency: eventData.ecommerce.currencyCode,
      domain: window.location.hostname,
      timestamp: unixTimestamp,
      style_family_en: styleFamily,
      main_color_en: mainColor,
      ean,
      size,
      fit_en: fit,
      availability_en: availability,
      cart_update_type: cartUpdateType.USER,
      material_group: productData.materialGroup,
      parent_id: materialNumber.split('-').slice(0, 2).join('-').toLowerCase(),
      event_uid: `web_cart_update_${window.dataLayerCache?.pageInfo?.page?.correlationId}_${unixTimestamp}`,
    };
    return impressionData;
  }

  /**
   * Read mini cart datasets of order lines and populate some lists
   * @returns {object}
   */
  static getCartData() {
    const orderLineSelector = [
      '.js-cartViewHeader-inner [data-product-data]',
      '.js-checkoutShoppingCart-productListItem[data-entry-sku]',
    ].join(',');
    const orderLines = Array.from(document.querySelectorAll(orderLineSelector));

    if (!orderLines.length) {
      return {};
    }

    const variantData = orderLines.map(orderLine => {
      try {
        const quantity = Number(orderLine.dataset?.quantity);
        const variant_id = orderLine.dataset?.entrySku?.toLowerCase();
        const product_id = orderLine.dataset?.baseProductCode?.toLowerCase();

        return {
          product_id,
          quantity,
          variant_id,
        };
      } catch (e) {
        Logger.error('Unable to read order line data');
        return;
      }
    });

    let productData = variantData.reduce((acc, variant) => {
      acc[variant.product_id]
        ? (acc[variant.product_id] += variant.quantity)
        : (acc[variant.product_id] = variant.quantity);
      return acc;
    }, []);

    productData = Object.keys(productData).map(entry => {
      return { product_id: entry, quantity: productData[entry] };
    });

    return {
      product_list: productData,
      product_ids: productData.map(product => product.product_id),
      variant_list: variantData.map(variant => ({
        variant_id: variant.variant_id,
        quantity: variant.quantity,
      })),
      variant_ids: variantData.map(variant => variant.variant_id),
      total_quantity: variantData.reduce((acc, entry) => acc + entry.quantity, 0),
    };
  }

  static getWishlistUpdateData(eventData) {
    const {
      actionType,
      trackingData: { currentProduct, wishlistEntries },
    } = eventData;

    const { [actionType]: { action } = {} } = {
      wishlistAddItem: {
        action: 'add',
      },
      wishlistRemoveItem: {
        action: 'remove',
      },
    };

    if (!action || !currentProduct) {
      return;
    }

    const {
      code,
      mainColor,
      materialGroup,
      subTargetAudience = '',
      styleFamilyEN = '',
      fromPrice,
      price,
      fitEN = '',
      staticCategoryPath = '',
      staticCategoryPathIds = '',
    } = currentProduct;

    const totalPrice = Number(
      wishlistEntries.reduce((acc, product) => product.price.value + acc, 0).toFixed(2)
    );

    const wishlistUpdateData = { ...baseData };

    wishlistUpdateData.event_name = exponeaEvents.WISHLIST;
    wishlistUpdateData.event_properties = {
      action,
      product_id: code.toLowerCase(),
      product_ids: wishlistEntries
        .map(product => product.code)
        .toString()
        .toLowerCase(),
      categories_path: staticCategoryPath.replaceAll('/', '>'),
      category_id: staticCategoryPathIds,
      categories_ids: [staticCategoryPathIds.split('/').pop()],
      from_price: fromPrice.value,
      from_price_local_currency: fromPrice.value,
      base_price: price.value,
      base_price_local_currency: price.value,
      total_quantity: wishlistEntries.length,
      total_price: totalPrice,
      total_price_local_currency: totalPrice,
      page_type: (AppSettings.pageType || 'other').toLowerCase(),
      local_currency: AppSettings.currencySettings.currencyCode,
      language: AppSettings.language,
      location: window.location.href,
      domain: window.location.hostname,
      timestamp: Math.round(Date.now() / 1e3),
      style_family_en: styleFamilyEN,
      main_color_en: mainColor?.descriptionEN,
      fit_en: fitEN,
      material_group: materialGroup,
      parent_id: code.split('-').slice(0, 2).join('-').toLowerCase(),
      sub_target_audience: subTargetAudience,
    };

    return wishlistUpdateData;
  }
}

new Exponea();

export default Exponea;
