/**
 * Make sure a module does not miss the `pageshow` event by adding a `pageshowSticky` event.
 *
 * Also adds the `pageshowFromCache` event for when user uses back button and page is served
 * from BFCache.
 *
 * Can be used like this:
 * ```
 * $(window).on(EventTypes.PAGESHOW, function(event) {
 *   // Will execute when 'pageshow' event has already executed or when it's executed
 * });
 *
 * $(window).on(EventTypes.PAGESHOW_FROM_CACHE, function(event) {
 *   // Will execute when page is served from BFCache
 * });
 * ```
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
import EventTypes from 'components/EventTypes';
import createLogger from 'components/logger/Logger';
import SessionPageViews from 'components/sessionPageViews/SessionPageViews';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('pageshowEvents');

let cachedEvent;
let hasListeners = false;

/**
 * Capture `pageshow` event and keep event object to be dispatched to later listeners
 * @param  {Event} evt Native event object
 */
function onEvent(event) {
  SessionPageViews.pageShow();
  Logger.debug('onPageShow', event.persisted ? 'persisted' : 'fresh');
  cachedEvent = event;
  if (hasListeners) {
    if (event.persisted) {
      requestAnimationFrame(() => window.jq.trigger(EventTypes.PAGESHOW, event));

      // Wrapped in requestAnimationFrame because otherwise it won't work properly on iOS devices
      requestAnimationFrame(() => window.jq.trigger(EventTypes.PAGESHOW_FROM_CACHE, event));
    } else {
      window.jq.trigger(EventTypes.PAGESHOW, event);
    }
  }
}

const specialEvent = {
  /**
   * Called when a listener for event is bound
   * @param {Object} handleObj Object containing properties needed for adding listener:
   *                           http://benalman.com/news/2010/03/jquery-special-events/#api-add
   */
  add(handleObj) {
    // Call event listener when event has already occured
    if (cachedEvent && (handleObj.type === EventTypes.PAGESHOW || cachedEvent.persisted)) {
      handleObj.handler.call(this, cachedEvent);
    }
  },

  /**
   * Called when any event listener is bound
   */
  setup() {
    hasListeners = true;
  },
};

$.event.special[EventTypes.PAGESHOW] = specialEvent;
$.event.special[EventTypes.PAGESHOW_FROM_CACHE] = specialEvent;
window.addEventListener('pageshow', onEvent, false);
