/* global AppSettings, AppState */

/**
 * Creates a cookie based on user's VTA (Visitor Target Audience)
 * - If user is logged in, set cookie based on present VTA set by user
 * - Else, set cookie based on amount of visits of a product from a category
 *
 * @author Edson Domingos Junior <edson-junior@g-star.com>
 * @author Andy Lemaire
 */
import createLogger from './logger/Logger';
import $ from 'jquery';
import Storage from './utils/storage/Storage';
import StorageKeys from './utils/storage/StorageKeys';
import 'cookie';

const Logger = createLogger('starterCookie');

const initialViewValues = {
  boys: 0,
  girls: 0,
  men: 0,
  women: 0,
};

export default class StarterCookie {
  constructor() {
    Logger.info('Created');

    const loggedIn = AppState.userMetaData.customerId;
    const pageType = AppSettings.pageType.toLowerCase();
    const productAudience = document.querySelector('[data-product-audience]');

    if (loggedIn || pageType !== 'product' || !productAudience) {
      return;
    }

    this.incrementView(productAudience.dataset.productAudience);
    this.checkViews();
  }

  /**
   * Check if views are not equal or more than 3, if so reset to initial value
   */
  checkViews() {
    const views = Storage.getItem(StorageKeys.PDP_VIEWS);

    for (const key in views) {
      if (views[key] && views[key] >= 3) {
        if (AppSettings.shopType === 'b2c') {
          this.setCookie(key);
        }
        Storage.setItem(StorageKeys.PDP_VIEWS, initialViewValues);
      }
    }
  }

  /**
   * Increment view
   */
  incrementView(vta) {
    const pdpViews = Storage.getItem(StorageKeys.PDP_VIEWS);
    const views = pdpViews || initialViewValues;

    for (let key in views) {
      if (views[key] && key === vta) {
        key = views[key]++;
        Storage.setItem(StorageKeys.PDP_VIEWS, views);
      }
    }
  }

  /**
   * Set cookie
   */
  setCookie(vta) {
    $.cookie(StorageKeys.VTA, vta, { expires: 365, path: '/' });
  }
}
