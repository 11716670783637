/* eslint no-console: ["error", { allow: ["warn", "error", "log", "table"] }] */

/* global AppSettings,AppState */
/**
 * Global logger, outputs on console. Should be used by adding `logger` dependency in modules.
 * Do not use this object directly.
 *
 * Usage:
 * ```
 * Logger.trace('scrollUpdate');
 * Logger.debug('Events bound');
 * Logger.error('Could not load data:', url);
 * Logger.fatal('Component cannot be initialized, DOM element is missing.');
 * Logger.info('Instance created');
 * Logger.warn('This method is deprecated, please use xxx instead.');
 * Logger.assert(condition, resultMessage);
 * ```
 *
 * Logging performance:
 * ```
 * // if you want to measure performance of complete methods:
 * function SomeModule() {
 *   Logger.measure(this, ['initialize', 'doSomething']);
 *   this.initialize();
 * }
 *
 * // If you want to measure part of a method, or conditional logging:
 * function doSomething() {
 *   if(this.active) {
 *     Logger.time('doSomething', this);
 *     // Do some stuff that you want measured
 *     Logger.timeEnd('doSomething', this);
 *   }
 * }
 *```
 *
 * Enable logging on DEBUG level for a component (overrides global log level)
 * ```Logger.setLogLevel(module, Logger.DEBUG);```
 *
 * To see recorded timings (with `logger.time` and `logger.timeEnd`) run this on the `console`:
 * ```require('components/logger/Logger').logTimings()```
 *
 * @author Meinaart van Straalen
 */
import LogLevels from './LogLevels';
import getFunctionName from '../utils/getFunctionName';
import device from '../utils/device';
import Storage from '../utils/storage/Storage';
import StorageKeys from '../utils/storage/StorageKeys';
import EventTypes from '../EventTypes';
import onWindowLoaded from '../utils/onWindowLoaded';

// Get logLevel from localStorage.
//
// Set via console like this:
// `localStorage.setItem('logLevel', 'ALL');`
// `ALL` can be replaced by: `TRACE`, `INFO`, `ASSERT`, `DEBUG`, `WARN`, `ERROR`, `FATAL`, NONE`
const storedLogLevel = parseInt(Storage.getItem(StorageKeys.LOG_LEVEL), 10);

// eslint-disable-next-line no-restricted-globals
const globalLogLevel = isNaN(storedLogLevel) ? LogLevels.NONE : storedLogLevel;

function Logger() {
  Object.defineProperties(this, {
    timing: {
      value: [],
    },
    __timers: {
      value: {},
    },
    __timersLookup: {
      value: {},
    },
    __timingEvents: {
      value: [],
    },
    __invalidMethods: {
      value: {},
    },
  });
}

Logger.prototype = {
  // Keep reference to console in case some external component overwrites it
  console: window.console,

  // Does `console` support colors in messages
  // @type Boolean
  consoleSupportsColors: device.isWebkitBrowser || device.browserName === device.FIREFOX,

  // Styles to apply to instance name
  // @type String
  consoleInstanceStyles: 'color: #339933; font-weight: bold;',

  // Default name of instance if it can't be found
  // @type String
  defaultInstanceName: 'Unknown',

  // Current global log level
  // @type int
  logLevel: globalLogLevel,

  // Object with logLevels per instance, key is instanceName (string)
  // @type object
  logLevels: {},

  /**
   * Log on level DEBUG, ie: Logger.error('onResize called');
   */
  debug() {
    logOnLevel(this, LogLevels.DEBUG, arguments);
  },

  /**
   * Log on level ERROR, ie: Logger.error('Could not load data');
   */
  error() {
    logOnLevel(this, LogLevels.ERROR, arguments);
  },

  /**
   * Executes a method an measures performance.
   *
   * @param  {Object}   instance      Instance
   * @param  {Function} method        Method to call
   * @param  {Object=}  scope         Scope to run method on, can be omitted, if it's a string it's used as label.
   * @param  {String=}  label         Label to log measure on, by default uses method name.
   * @param  {Boolean=} [log=false]   If true the label is logged when called
   * @return {*}                      Result of method call
   */
  execute: function execute(instance, method, scope, label, log) {
    let numArgs = 5;
    if (typeof label === 'boolean') {
      log = label;
      label = undefined;
      numArgs--;
    }

    if (typeof scope === 'string') {
      label = scope;
      scope = null;
      numArgs--;
    }

    const args = Array.from(arguments);
    args.splice(0, numArgs);

    label = label || getFunctionName(method);

    if (label) {
      label = label.replace(/^bound /, '');
      this.time(instance, label, scope);
      if (log === true) {
        this.debug(instance, label);
      }
    } else {
      this.warn(instance, 'Cannot find method name:', method);
    }

    const result = scope || args.length ? method.apply(scope || null, args) : method();

    if (label) {
      this.timeEnd(instance, label, scope);
    }

    return result;
  },

  /**
   * Log on level FATAL, ie: Logger.fatal('Unrecoverable error found');
   */
  fatal() {
    logOnLevel(this, LogLevels.FATAL, arguments);
  },

  /**
   * Return name of instance
   *
   * @param  {object} instance      Instance (or string)
   * @param  {boolean} returnDefault Return default instancename?
   * @return {string}               Name for instance
   */
  getInstanceName(instance, returnDefault) {
    if (typeof instance === 'string') {
      return instance;
    }
    if (typeof instance === 'object' && instance.id !== undefined) {
      return instance.id;
    }
    return getFunctionName(
      instance.constructor,
      returnDefault ? this.defaultInstanceName : undefined
    );
  },

  table(module, tableData) {
    if (!AppState.loggingEnabled || this.logLevel > LogLevels.INFO) {
      return;
    }
    console.table(tableData);
  },

  /**
   * Log on level INFO, ie: Logger.info('Created');
   */
  info() {
    logOnLevel(this, LogLevels.INFO, arguments);
  },

  /**
   * Log on level ASSERT, ie: Logger.assert(condition, msg);
   */
  assert() {
    logOnLevel(this, LogLevels.ASSERT, arguments);
  },

  /**
   * Log message, additional arguments are supplied to `console.log`.
   *
   * @param {int} level Log level
   * @param  {object} instance Scope on which event should be ran
   */
  log(logLevel /*, instance */) {
    if (this.logLevel === LogLevels.NONE || logLevel >= LogLevels.NONE) {
      return;
    }
    let result;
    const args = arguments;
    const _this = this;
    const logCallback = function logCallback() {
      result = _this.__log(...args);
    };

    // Logging should never break anything
    try {
      logCallback();
    } catch (e) {}

    return result;
  },

  /**
   * Log timings to console, sorted on average time
   */
  logTimings: function logTimings(checkLogLevel) {
    if ((!checkLogLevel || this.logLevel <= LogLevels.DEBUG) && this.timing.length) {
      this.timing.sort(function (a, b) {
        if (a.average === b.average) {
          return 0;
        }
        return a.average > b.average ? -1 : 1;
      });

      const logTimingsTable = () => {
        console.table(this.timing);
      };

      const logTimingsJSON = () => {
        console.log(JSON.stringify(this.timing, null, 2));
      };

      try {
        logTimingsTable();
      } catch (e) {
        try {
          logTimingsJSON();
        } catch (e2) {}
      }
    }
  },

  /**
   * Method called by `this.log`
   *
   * @param {int} level Log level
   * @param {object} instance Scope on which event should be ran
   */
  __log(logLevel, instance) {
    // Add name of instance to list of messages to trace
    const instanceName = this.getInstanceName(instance, true);

    // Check if message should be logged
    if (this.shouldLog(logLevel)) {
      let method = 'log';
      switch (logLevel) {
        case LogLevels.ASSERT:
        case LogLevels.TRACE:
        case LogLevels.DEBUG:
        default:
          method = 'debug';
          break;
        case LogLevels.INFO:
          method = 'info';
          break;
        case LogLevels.WARN:
          method = 'warn';
          break;
        case LogLevels.ERROR:
        case LogLevels.FATAL:
          method = 'error';
          break;
      }

      const messages = Array.prototype.slice.call(arguments, 2);

      // Apply some colors to instance name
      if (this.consoleSupportsColors) {
        messages.splice(0, 0, `%c[${instanceName}]`, this.consoleInstanceStyles);
      } else {
        messages.unshift(`[${instanceName}]`);
      }

      // custom assert functionality
      if (LogLevels.ASSERT === logLevel) {
        let assertStyles;
        if (arguments.length <= 3) {
          method = 'error';
          messages.push('- Assert takes 2 parameters!');
        } else {
          if (messages[2] === true) {
            messages[0] += ' PASS:';
            assertStyles = ';color: green';
          } else {
            messages[0] += ' FAIL:';
            assertStyles = ';color: red';
          }
          if (this.consoleSupportsColors) {
            messages[1] += assertStyles;
            messages[2] = messages.pop();
          } else {
            messages[1] = messages.pop();
          }
        }
      }

      if (method) {
        let useConsoleLog = method === 'log' || !!this.__invalidMethods[method];

        if (!useConsoleLog) {
          try {
            callConsoleMethod(this.console, method, messages);
          } catch (e) {
            this.__invalidMethods[method] = true;
            useConsoleLog = true;
          }
        }

        if (useConsoleLog) {
          Function.prototype.apply.call(this.console.log, this.console, messages);
        }
      }
    }
  },

  /**
   * Add logging to method(s).
   * Used like:
   * ```
   * Logger.measure(this, ['initialize', 'onClick', 'heavyOperation']);
   * ```
   *
   * @param  {Object}        instance          Object used for logging
   * @param  {Object}        scope             Scope to run methods in
   * @param  {Array}         methodNames       Name of method(s)
   * @param  {Boolean}       [logCalls=false]  If true a `Logger.debug` call is done when method is executed
   */
  measure: function measure(instance, scope, methodNames, logCalls) {
    logCalls = logCalls === true;

    if (scope.prototype) {
      throw new Error(
        `Logger.measure should be called on an instance, not on prototype: ${this.getInstanceName(
          instance
        )}`
      );
    }

    if (AppState.logTimingEnabled) {
      methodNames.forEach(
        function (methodName) {
          scope[methodName] = this.execute.bind(
            this,
            instance,
            scope[methodName],
            scope,
            methodName,
            logCalls
          );

          const setName = function setName() {
            Object.defineProperty(scope[methodName], 'name', { value: methodName });
          };

          try {
            setName();
          } catch (e) {}
        }.bind(this)
      );
    }
  },

  /**
   * Check if logLevel is sufficient for logging
   * @param  {Int} logLevel Loglevel
   * @return {Boolean}          Should log?
   */
  shouldLog(logLevel) {
    return logLevel >= this.logLevel;
  },

  /**
   * Set log level, if instance is not supplied the global log level is set.
   *
   * @param {int} level    Level from LogLevels
   * @param {object} instance Object for which logLevel should be set (optional)
   */
  setLogLevel(instanceOrLevel, level) {
    let instance = instanceOrLevel;
    if (level === undefined) {
      level = instance;
      instance = undefined;
    }

    if (instance) {
      const instanceName = this.getInstanceName(instance);
      if (instanceName) {
        this.logLevels[instanceName] = level;
      }
    } else {
      this.logLevel = level;
    }
  },

  /**
   * Start logging of timing
   * @param  {Object}   module    Object to log on, usually `module`
   * @param  {String}   label     Label to log on
   * @param  {Object=}  instance  Instance of module
   */
  time: function time(module, label, instance) {
    if (!AppState.logTimingEnabled) {
      return;
    }

    const instanceName = this.getInstanceName(module, true);
    const logName = getLogName(instanceName, instance, label);
    if (!this.__timers[instanceName]) {
      this.__timers[instanceName] = {};
    }

    if (!this.__timers[instanceName][logName]) {
      this.__timers[instanceName][logName] = [];
    }

    this.__timers[instanceName][logName].push(now());
  },

  /**
   * End logging of timing
   * @param  {Object}   module      Object to log on
   * @param  {String}   label       Label used for logging
   * @param  {Object=}  instance    Instance
   * @return {Int}                  Time spend in milliseconds
   */
  timeEnd: function timeEnd(module, label, instance) {
    if (!AppState.logTimingEnabled) {
      return;
    }

    const instanceName = this.getInstanceName(module, true);
    const logName = getLogName(instanceName, instance, label);
    if (!this.__timers[instanceName]) {
      this.__timers[instanceName] = {};
    }

    if (!this.__timers[instanceName][logName]) {
      this.__timers[instanceName][logName] = [now()];
    }

    const times = this.__timers[instanceName][logName];
    const startTime = times[times.length - 1];
    let timePassed = now() - startTime;
    delete times[times.length - 1];

    let item;
    if (this.__timersLookup[instanceName + label] === undefined) {
      timePassed = roundDecimals(timePassed);

      item = {
        name: getLogName(instanceName),
        label,
        count: 1,
        min: timePassed,
        max: timePassed,
        average: timePassed,
        total: timePassed,
        timestamp: startTime,
      };
      this.timing.push(item);
      this.__timersLookup[instanceName + label] = this.timing.length - 1;
    } else {
      item = this.timing[this.__timersLookup[instanceName + label]];

      item.count++;
      if (timePassed < item.min) {
        item.min = roundDecimals(timePassed);
      } else if (timePassed > item.max) {
        item.max = roundDecimals(timePassed);
      }
      item.total = roundDecimals(item.total + timePassed);
      item.average = roundDecimals(item.total / item.count);
    }

    if (item) {
      // eslint-disable-next-line no-unused-expressions
      performance &&
        performance.mark &&
        performance.mark(getMark(instanceName, item.label, instance));

      if (
        !item.waitingForTrigger &&
        item.average >= AppSettings.logTimingThreshold &&
        document.readyState === 'complete'
      ) {
        item.waitingForTrigger = true;
        this.__timingEvents.push(item);
        if (!this.__timingEventsWaiting) {
          setTimeout(this.triggerTimingEvents.bind(this), 100);
          this.__timingEventsWaiting = true;
        }
      }
    }

    return timePassed;
  },

  /**
   * Log on level TRACE, ie: Logger.trace('Data processed', data);
   */
  trace() {
    logOnLevel(this, LogLevels.TRACE, arguments);
  },

  /**
   * Trigger an event for every timing object that has not been
   * dispatched yet.
   */
  triggerTimingEvents() {
    while (this.__timingEvents.length !== 0) {
      const item = this.__timingEvents.pop();
      delete item.waitingForTrigger;
      document.jq.trigger(EventTypes.LOGGER_TIMING, item);
    }
    this.__timingEventsWaiting = false;
  },

  /**
   * Log on level WARN, ie: Logger.warn('Value is not supplied, using default value.');
   */
  warn() {
    logOnLevel(this, LogLevels.WARN, arguments);
  },
};

Logger.prototype = Object.assign(Logger.prototype, LogLevels);

/**
 * Call method on supplied `console`
 * @param  {Object} console  Usually `window.console`
 * @param  {String} method   Method to call on `console`
 * @param  {Array}  messages Array with strings to call method with
 */
function callConsoleMethod(console, method, messages) {
  return Function.prototype.apply.call(console[method], console, messages);
}

/**
 * Prefix instanceName with `desktop/` when it's not in `components/` directory
 *
 * @param  {String} instanceName Path to instance
 * @param  {Object=} instance    If supplied the number of instance is added to the result
 * @param  {String=} label       If supplied label is added to result
 * @return {String}              Prefixed instanceName
 */
function getLogName(instanceName, instance, label) {
  let localInstanceName = instanceName;
  if (instanceName.indexOf('components/') === -1) {
    localInstanceName = `d/${instanceName}`;
  } else {
    localInstanceName = instanceName.replace(/^components/, 'c');
  }

  // Adds '#$instanceNumber' to result if available
  if (instance && instance.settings && instance.settings.numInstance !== undefined) {
    localInstanceName += `#${instance.settings.numInstance}`;
  }

  if (label) {
    localInstanceName += `:${label}`;
  }

  return localInstanceName;
}

/**
 * Format label for marking (either via `performance.mark` or `console.time`)
 * @param  {String} instanceName Name of instance
 * @param  {String} label        Label of action
 * @param  {Object=} instance
 * @return {String}              Name used for marking
 */
function getMark(instanceName, label, instance) {
  return getLogName(instanceName, instance, label);
}

/**
 * Round number to specified number of decimals
 * @param  {Number} num      Floating number
 * @return {Number}          Number rounded to specified number of decimals
 */
function roundDecimals(num) {
  return ((num * 100) >> 0) / 100;
}

/**
 * Internal function to log on specific level.
 *
 * @param  {Logger} logger Instance of logger
 * @param  {int} level Level from LogLevels
 * @param  {arguments} args  Arguments object
 */
function logOnLevel(logger, level, args) {
  if (!AppState.loggingEnabled) {
    return;
  }
  args = Array.from(args);
  args.unshift(level);
  logger.log(...args);
}

/**
 * Return now as a relative number. Only meant for comparing between another number returned by this method.
 * @return {Number} Number representing "now"
 */
function now() {
  let result;
  const getNow = function () {
    result = window.performance.now();
  };

  try {
    getNow();
  } catch (e) {}

  if (result === undefined) {
    result = Date.now() - (window.__startTime || 0);
  }

  return result;
}

const globalLogger = new Logger();

window.logTimings = globalLogger.logTimings.bind(globalLogger, false);

// Log timings to console 1500 ms after page load (but at most 4500 ms after document ready)
if (device.browserName === device.CHROME || device.browserName === device.FIREFOX) {
  onWindowLoaded(globalLogger.logTimings.bind(globalLogger, true), window, 3000);
}

export default function createLogger(moduleName) {
  const logContext = {};
  logContext.module = moduleName;

  function log(method /** , args * */) {
    const args = Array.prototype.slice.call(arguments, 1);
    args.unshift(moduleName);

    const callback = function (Logger) {
      Logger[method](...args);
      logContext.logger = Logger;
      logContext[method] = Logger[method].bind(Logger, logContext.module);
      try {
        Object.defineProperty(logContext[method], 'name', { value: method });
      } catch (e) {}
    };

    if (!logContext.logger) {
      logContext.logger = globalLogger;
    }

    if (logContext.logger) {
      callback(logContext.logger);
    }
  }

  [
    'debug',
    'error',
    'execute',
    'fatal',
    'info',
    'measure',
    'shouldLog',
    'time',
    'timeEnd',
    'trace',
    'warn',
    'assert',
    'table',
  ].forEach(method => {
    logContext[method] = log.bind(logContext, method);
  });

  return logContext;
}
