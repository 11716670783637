/**
 * Handles the cookie notices:
 * - Cookies not enabled
 * - Cookies are used are you aware of that
 *
 * @author Tim van Oostrom - timvanoostrom@gmail.com
 * @author Meinaart van Straalen - meinaart@kayenta.nl
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import storage from 'components/utils/storage/Storage';
import StorageKeys from 'components/utils/storage/StorageKeys';
import EventTypes from 'components/EventTypes';
import device from 'components/utils/device';
import 'cookie';
import './domutils/Element.jQuery';

const Logger = createLogger('cookieNotice');

const CookieNotice = {
  cookiesNoticed: false,
  cookiesEnabled: false,
  cookieNotice: null,
  initialize() {
    this.cookieNotice = document.getElementsByClassName('js-cookieNotice')[0];
    const hasNoSmartBanner = !document.querySelector('.highstreet-banner');

    if (this.cookieNotice) {
      this.cookiesNoticed = this.getValue(StorageKeys.COOKIES_NOTICED);
      this.cookiesEnabled = this.getValue(StorageKeys.COOKIES_ENABLED);

      if (hasNoSmartBanner && typeof window.loadSmartBanner === 'function') {
        window.loadSmartBanner();
      }

      if (this.cookiesNoticed && this.cookiesEnabled) {
        // do nothing if a user is already presented with a cookie
        return false;
      }

      if (!this.cookiesEnabled) {
        $.cookie(StorageKeys.COOKIES_ENABLED, 'yes', {
          expires: 365,
          path: '/',
        });

        this.cookiesEnabled = $.cookie(StorageKeys.COOKIES_ENABLED) !== undefined;

        if (this.cookiesEnabled) {
          // We know cookies are working, please remove cookie and store BOOLEAN in LocalStorage
          $.removeCookie(StorageKeys.COOKIES_ENABLED, {
            path: '/',
          });
          this.setValue(StorageKeys.COOKIES_ENABLED, true);
        }
      }

      this.cookieNotice.classList.toggle('has-cookiesEnabled', this.cookiesEnabled);
      requestAnimationFrame(this.toggleCookieNotice.bind(this, true));
    }
  },
  bindEvents() {
    if (device.osName === device.IOS && device.osVersion < 9) {
      // GSTAR-8493 iOS 8 display fixed fix
      document.jq.on(EventTypes.SIDENAV_ANIMATION_COMPLETE, () => {
        if (
          document.documentElement.classList.contains('has-open-sideNavigation') ||
          (this.cookiesNoticed && this.cookiesEnabled)
        ) {
          return;
        }

        this.$cookieNotice.removeClass('is-visible').css('transitionDuration', '0s');

        requestAnimationFrame(
          this.cookieNotice.classList.addClass.bind(this.cookieNotice.classList, 'is-visible')
        );
      });
    }
    $('.js-cookieNotice-closeButton', this.cookieNotice).one(
      'vclick',
      this.toggleCookieNotice.bind(this, false)
    );
  },
  toggleCookieNotice(showNotice, event) {
    const triggerEvent = showNotice ? EventTypes.COOKIE_NOTICE_SHOW : EventTypes.COOKIE_NOTICE_HIDE;

    this.cookieNotice.classList.toggle('is-visible', showNotice);
    this.cookieNotice.jq.trigger(triggerEvent);

    if (showNotice) {
      this.bindEvents();
    } else {
      if (event) {
        event.stopPropagation();
        event.preventDefault();
      }
      this.setCookieNoticeIsPresented();
    }
  },
  getValue(name) {
    return storage.getItem(name);
  },
  setValue(name, value) {
    storage.setItem(name, value);
  },
  setCookieNoticeIsPresented() {
    this.cookiesNoticed = true;
    this.setValue(StorageKeys.COOKIES_NOTICED, true);
  },
};

export default CookieNotice;
