/* global AppSettings */

import createLogger from './logger/Logger';
import $ from 'jquery';
import StorageKeys from './utils/storage/StorageKeys';
import 'cookie';

const Logger = createLogger('cheUrlCookie');

const CheCookie = {
  initialize() {
    Logger.info('Created');
    this.getChe() && this.setCookie();
  },
  setCookie() {
    $.cookie.raw = true;
    $.cookie(StorageKeys.CHE, this.getChe(), {
      expires: Math.floor(parseInt(AppSettings.bdgExpirationDate, 10) / (3600 * 24)),
      path: '/',
    });
  },
  getChe() {
    const url = document.location;

    try {
      return new URL(url.href).searchParams.get('che') || undefined; // eslint-disable-line
    } catch (e) {
      Logger.info('Using fallback for window.URL', e);

      const result = /che=([^&]+)/.exec(url.search);
      return result !== null ? result[1] : undefined;
    }
  },
};

export default CheCookie;
