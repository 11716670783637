/**
 * Add "lazy" properties to an object.
 *
 * That means that the first time a property is accessed it will call specified function to get the value. Next time it's called
 * this value is returned without calling callback again.
 *
 * This prevents calculating values before they are used. This is especially useful for heavier properties.
 *
 * Usage:
 * ```
 * defineLazyProperties(this, {
 *   name: function() {
 *     return 'Meinaart';
 *   },
 *   occupation: function() {
 *     return 'Frontend Developer'
 *   }
 * });
 * ```
 *
 * @author  Meinaart van Straalen
 */
import defineLazyProperty from './defineLazyProperty';

export default function defineLazyProperties(scope, properties) {
    for (const key in properties) {
      if (typeof properties[key] === 'function') {
        defineLazyProperty(scope, key, properties[key]);
      } else {
        Object.defineProperty(scope, key, {
          value: properties[key],
        });
      }
    }

    return scope;
}
