/**
 * Keyboard events mixin
 *
 * example:
 * document.jq.on('keyup', keyboardInput.handleKeys.bind(this, 'tab', this.handleKeyUp));
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import createLogger from '../logger/Logger';
const Logger = createLogger('keyboardInput');
Logger.info('Loading keyboardInput');

const keyboardInput = {
  handleKeys: function handleKeys(keys, callback, event) {
    if (typeof keys === 'string') {
      keys = [keys];
    }

    const { originalEvent } = event;

    if (originalEvent.key) {
      const keyName = originalEvent.key.toLowerCase();
      if (keys.indexOf(keyName) > -1 && typeof callback === 'function') {
        return callback.call(this, event);
      }
    }
  },
};

export default keyboardInput;
