/* global AppSettings */

/**
 * Object with keys to be used in storage module.
 *
 * They are stored here because we want to keep the keys short so we can also store the data
 * as compact as possible inside a cookie when LocalStorage is not available.
 *
 * @author Meinaart van Straalen
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */

export default {
  AB_TEST_SUBSCRIPTION_DIALOG: 'ab_test_msd',
  BAG_COUNT: 'bc',
  BASKET: 'remoteHtmlCache_basket',
  BASKET_LOCALE: 'remoteHtmlCache_basket_locale',
  REFRESH_BASKET: 'refresh_basket',
  COOKIES_ENABLED: 'ce',
  COOKIES_NOTICED: 'cn',
  COOKIE_CONSENT_WALL: 'coco',
  COOKIE_ABDET: 'abdet',
  COOKIE_ABDCT: 'abdct',
  COUNTRY_SELECTOR_FORCED_SHOWN: 'lss',
  COUNTRY_SELECTOR_TOGGLE: 'lst',
  LOG_LEVEL: 'logLevel',
  MEMBERSHIP_DIALOG_TIME_OUT: 'msd_to',
  MEMBERSHIP_DIALOG_PRESENTED: 'msd_p',
  NEWSLETTER_DIALOG_PRESENTED: 'nsd_p',
  NEWSLETTER_DIALOG_TIMEOUT: 'nsd_to',
  RECENTLY_VIEWED_PRODUCTS: 'rvp',
  RECENTLY_VIEWED_PRODUCTS_TIMESTAMP: 'rvpt',
  PDC: '__pdc',
  PRODUCT_LIST: 'uapl', // universal analytics product list
  PRODUCT_LIST_POSITION: 'uaplp', // universal analytics product list position
  PRODUCT_SIZE_TRANSFER_LENGTH: 'sizeTransferlength',
  PRODUCT_SIZE_TRANSFER_SIZE: 'sizeTransfersize',
  PRODUCT_SIZE_TRANSFER_WAISTLENGTHSELECTION: 'sizeRangeIsWaistLength',
  PRODUCT_TILE_TYPE: 'productTileType',
  BDG: 'bdg',
  CHE: 'che',
  PDP_VIEWS: 'pdpViews',
  VTA: 'vta',
  PRIVACY_POLICY_POPUP: 'showPrivacyPolicyPopup',
  STOCKLOCATOR: 'stockLocator',
  WISHLIST_COOKIE: `WISHLIST_COOKIE_${AppSettings.baseSite}`,
  WISHLIST_ENTRIES: `wishlistEntries_${AppSettings.locale}`,
  PAGE_VIEWS: 'pageviews',
  CART_MUTATED: 'cartMutated',
  USER_LOGGED_IN_STATUS: 'loggedInStatus',

  // Checkout V2 (React) related storage keys.
  // Prefixed with __REACT__ just to make it clear that these
  // are used only in the React part of the application.
  __REACT__HOME_DELIVERY_FORM: `form_${AppSettings.country}_homeDeliveryForm`,
  __REACT__PICKUP_DELIVERY_FORM: `form_${AppSettings.country}_pickUpDeliveryForm`,
  __REACT__LOGIN_FORM: `form_${AppSettings.country}_loginform`,
  __REACT__GUEST_FORM: `form_${AppSettings.country}_guestform`,
  __REACT__PAYMENT_FORM: `form_${AppSettings.country}_paymentform`,
};
