/**
 * Initialize Footer accordion, based on BasicAccordion component
 *
 * @author  Tamara Bazko
 */
import BasicAccordion from 'components/basicAccordion/BasicAccordion';

export default {
  initialize() {
    BasicAccordion.attachTo('.footer-navBlock', {
      maxBreakPoint: 'xsmall',
      contentSelector: '.js-footer-list',
      toggleSelector: '.js-footer-title',
    });
  },
};
