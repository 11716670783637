/* global AppSettings */
/**
 * All link clicks
 *
 * @author Andy Lemaire
 */
import createLogger from 'components/logger/Logger';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('Links');

export default class Links {
  constructor() {
    Logger.info('Initialized');
    this.bindEvents();
  }

  /**
   * Bind events
   */
  bindEvents() {
    const onClick = event => this.onClick(event);
    document.jq.on(
      'click',
      `a[href]:not([href^='/']), a[href][rel*='nofollow'], a[href][rel*='noreferrer'], a[href][rel*='noopener']`,
      onClick
    );
  }

  /**
   * On click event
   * @param {Object} event On click event
   */
  onClick(event) {
    const target = event.currentTarget;
    const targetDomain = target.href.substring(target.href.indexOf('.'));
    const hostnameDomain = window.location.hostname.substring(
      window.location.hostname.indexOf('.')
    );
    const imageDomain = AppSettings.imageBaseUrl.substring(AppSettings.imageBaseUrl.indexOf('.'));

    if (targetDomain.includes(hostnameDomain) || targetDomain.includes(imageDomain)) {
      return;
    }

    event.preventDefault();

      // bugs/ACE-3375 ensure that the callback gets executed max once
      let callbackExecuted = false;
      const eventCallback = () => {
        if (callbackExecuted) {
          return;
        }
        callbackExecuted = true;

        clearTimeout(timeoutId);

      if (target.target === '_blank') {
        return window.open(target.href, target.target);
      }

      window.location = target.href;
    };

    document.jq.trigger(AnalyticsEventTypes.EXTERNAL_LINK_CLICK, {
      eventCallback,
      label: target.href
    });

    const timeoutId = setTimeout(eventCallback, 750);
  }
}
