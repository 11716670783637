/**
 * Toggle a set of elements
 *
 * Mostly used in conjunction with genericMessage.js
 *
 * @author Erik Slagter
 * @author Vincent Bruijn
 */
import $ from 'jquery';
import Factory from 'components/utils/Factory';
  function ElementToggle($elements) {
    this.$elements = $elements;
    this.initialize();
  }

  $.extendPrototype(ElementToggle, {
    initialize() {
      this.$elements.on('vclick', function (event) {
        event.stopPropagation();
        const toggleSelector = event.currentTarget.jq.data('toggle');

        const $elementToToggle = $(toggleSelector);
        $elementToToggle.toggle();

        return false;
      });
    },
  });

  export default Factory.create(ElementToggle);

