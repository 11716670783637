/* global AppSettings */
import $ from 'jquery';
import createLogger from '../logger/Logger';
import device from '../utils/device';
import Mustache from 'mustache';
import Factory from '../utils/Factory';
import AnalyticsEventTypes from '../analytics/AnalyticsEventTypes';
import EventTypes from '../EventTypes';
import defaultTemplate from './templates/itemContainer.html';
import productTemplate from './templates/product.html';
import contentTemplate from './templates/content.html';
import dividerTemplate from './templates/divider.html';
import defineLazyProperty from '../utils/defineLazyProperty';
import matchBreakpoint from '../utils/matchBreakpoint';
import 'maxZindex';
import 'jquery.extendPrototype';
import '../domutils/Element.jQuery';
import Algolia from '../analytics/Algolia';

const Logger = createLogger('SuggestedSearch');

const endpoint =
  (AppSettings.endpoints && AppSettings.endpoints.suggestedSearch) ||
  `/api/v1/${AppSettings.locale}/suggest`;

const keywordRedirectName = 'keywordRedirect';
const suggestionsName = 'suggestions';
const productName = 'products';
const contentPageName = 'contentPages';
const contentSuggestionsName = 'contentSuggestions';

const itemTemplates = {};
itemTemplates[keywordRedirectName] = contentTemplate;
itemTemplates[suggestionsName] = defaultTemplate;
itemTemplates[contentSuggestionsName] = defaultTemplate;
itemTemplates[productName] = productTemplate;
itemTemplates[contentPageName] = contentTemplate;

itemTemplates.dividerContainer = dividerTemplate;

function SuggestedSearch($element) {
  Logger.time('initialize', this);
  this.$searchContainer = $element;
  this.searchContainer = $element.get(0);

  if (this.searchContainer) {
    this.searchInput = this.searchContainer.getElementsByTagName('input').item(0);

    if (this.searchInput) {
      defineLazyProperty(
        this,
        'searchResultsList',
        function () {
          return this.searchContainer.getElementsByClassName('js-suggestedSearch-list').item(0);
        }.bind(this)
      );

      defineLazyProperty(
        this,
        '$searchResultsList',
        function () {
          return this.searchResultsList.jq;
        }.bind(this)
      );

      this.bindEvents();
    }
  }
  Logger.timeEnd('initialize', this);
}

$.extendPrototype(SuggestedSearch, {
  $searchContainer: undefined,
  $searchResultsList: undefined,
  $searchResultItems: undefined,
  searchInput: undefined,
  suggestedSearchEndpoint: endpoint,
  currentSearchRequest: undefined,
  suggestThrottle: undefined,
  suggestThrottleTimeout: 200,
  maxSuggestionContentLength: 200,
  suggestionContentLength: 50,
  hideSuggestionsTimeout: undefined,

  bindEvents() {
    this.searchInput.jq.on('keydown', this.handleInputEvents.bind(this));

    if (!(device.isMobile && !matchBreakpoint('xsmall', device.screenWidth))) {
      this.searchInput.addEventListener('blur', this.hideSuggestions.bind(this));
    }

    this.searchInput.addEventListener('focus', this.restoreSuggestions.bind(this));

    this.$searchContainer
      .on('mouseover', '.js-suggestedSearchItem', event => {
        const target = event.currentTarget;
        [...target.parentNode.children].forEach(element => {
          element.classList.remove('is-active');
          element.setAttribute('aria-selected', false);
        });
        target.classList.add('is-active');
        target.setAttribute('aria-selected', true);
      })
      .on('click', '.js-suggestedItemLink', event => {
        event.preventDefault();
        const target = event.currentTarget;

        const eventCallback = () => {
          clearTimeout(timeoutId);
          window.location = target.href;
        };

        // When both values of screenX and screenY are 0, Enter has been clicked
        const eventType = event.screenX === 0 && event.screenY === 0 ? 'enter' : 'click';

        if (target.dataset.algoliaObjectId) {
          document.jq.trigger(AnalyticsEventTypes.ALGOLIA_EMIT_SUGGESTION_CLICK, {
            queryID: target.dataset.algoliaQueryId,
            click: {
              products: [
                {
                  objectID: target.dataset.algoliaObjectId,
                  position: Number(target.dataset.position),
                  queryID: target.dataset.algoliaQueryId,
                },
              ],
            },
          });
          Algolia.setLocalStorageItems({ objectID: target.dataset.algoliaObjectId })
        }

        document.jq.trigger(AnalyticsEventTypes.INTERNAL_SEARCH, {
          action: eventType,
          eventCallback,
          label: target.dataset.name,
          searchTerm: this.searchInput.value,
        });

        const timeoutId = setTimeout(eventCallback, 750);
      });

    const form = this.searchInput.closest('form');
    if (form) {
      form.addEventListener('submit', event => {
        if (this.searchInput.value.length === 0) {
          event.preventDefault();
          return false;
        }
      });
    } else {
      throw new Error('Cannot find form');
    }
  },
  handleInputEvents(event) {
    const code = parseInt(event.keyCode || event.which, 10);

    if (code === 27) {
      event.preventDefault();
      this.searchInput.jq.trigger('blur');
    } else if (code === 13) {
      // check first for input restrictions
      this.searchInput.jq.triggerHandler('blur.textRestriction');

      const activeSuggestion = (this.searchResultItems || []).filter(function (element) {
        return element.classList.contains('is-active');
      })[0];

      if (activeSuggestion) {
        event.preventDefault();
        event.stopPropagation();
        activeSuggestion.jq.find('a').click();
      } else if (this.searchInput.value.length > 0) {
        this.searchInput.closest('form').submit();
      }
    } else if (code === 38 || code === 40) {
      event.preventDefault();
      this.highlightSuggestions.call(this, event);
    } else if (!this.suggestThrottle && code !== 9) {
      this.suggestThrottle = true;
      setTimeout(() => {
        this.getSuggestionsAsync.call(this);
        this.suggestThrottle = false;
      }, this.suggestThrottleTimeout);
    }
  },
  getSuggestionsAsync() {
    const prevSearchTerms = this.previousValue;
    const searchTerms = this.searchInput.value.trim();

    if (searchTerms !== prevSearchTerms && searchTerms !== '') {
      if (this.currentSearchRequest) {
        this.currentSearchRequest.abort();
      }

      this.previousValue = searchTerms;

      this.currentSearchRequest = $.ajax({
        url: this.suggestedSearchEndpoint,
        data: {
          search: searchTerms,
        },
        dataType: 'json',
        method: 'get',
      });

      this.currentSearchRequest.done(this.renderSearchSuggestions.bind(this));
    } else if (searchTerms === '' && prevSearchTerms) {
      this.hideSuggestions();
    }

    return this.currentSearchRequest;
  },
  positionSuggestionsList() {
    const leftPos = this.searchResultsList.offsetParent
      ? this.searchResultsList.offsetParent.offsetLeft
      : this.$searchResultsList.offset().left;
    if (leftPos + this.searchResultsList.clientWidth > window.innerWidth) {
      this.searchResultsList.style.right = '0';
    }

    this.searchResultsList.classList.remove('is-active');
    this.$searchResultsList.maxZIndex();
  },
  renderSearchSuggestions(responseData) {
    let prevIndexName;
    let html = '';

    // Temporary switching off 'content terms grouping' feature
    // (until design provided)
    if (responseData.contentSuggestions) {
      delete responseData.contentSuggestions;
    }

    const suggestedSearchKeyOrder = ['keywordRedirect', 'contentPages', 'suggestions', 'products'];
    const algoliaQueryId = responseData?.algoliaQueryId;
    const algoliaIndexName = responseData?.algoliaIndexName;
    const showStrikethroughPrices = responseData?.showStrikethroughPrices;
    const originalPriceMessage = responseData?.originalPriceMessage;
    const lowestPriceMessage = responseData?.lowestPriceMessage;

    $.each(suggestedSearchKeyOrder, (index, sIndex) => {
      let indexName = sIndex;
      let template = itemTemplates[indexName];
      if (!template) {
        indexName = suggestionsName;
        template = itemTemplates[indexName];
      }

      const suggestionGroup = responseData[sIndex];

      if (template) {
        $.each(suggestionGroup, (index, rawSuggestionData) => {
          const isFirstOfClass = indexName !== prevIndexName && prevIndexName;
          const suggestionData = this.getFormattedResultData(
            indexName,
            { algoliaQueryId, algoliaIndexName, showStrikethroughPrices, originalPriceMessage, lowestPriceMessage, ...rawSuggestionData },
            index
          );

          html += this.renderSuggestion(template, suggestionData, isFirstOfClass);
          prevIndexName = indexName;
        });
      }
    });

    this.searchResultsList.innerHTML = html;
    this.lastSearchedTerm = this.searchInput.value;
    this.searchResultItems = [
      ...this.searchResultsList.getElementsByClassName('js-suggestedSearchItem'),
    ];

    if (this.searchResultItems.length) {
      this.showSuggestions();
    } else {
      this.hideSuggestions();
    }

    this.$searchContainer.trigger(EventTypes.SUGGESTED_SEARCH_RESULTS_RENDERED, {
      term: this.searchInput.value,
      queryID: responseData?.algoliaQueryId,
    });
  },
  renderSuggestion(template, data, isFirstOfClass) {
    let html = '';

    if (isFirstOfClass) {
      html += itemTemplates.dividerContainer;
    }

    html += Mustache.render(template, data);

    return html;
  },
  getFormattedResultData(indexName, data, index) {
    const suggestionData = {};
    data.searchterm = '';
    const inputValue = this.searchInput.value;
    let titleSyllable;
    let titleMatch;

    switch (indexName) {
      case keywordRedirectName:
        data.searchterm = data.label;
        suggestionData.resultUrl = `/${AppSettings.locale}${data.url}`;
        suggestionData.itemTitle = data.searchterm.replace(
          data.label,
          `<span class="suggestedSearch-itemTitle-highlight">${data.label}</span>`
        );
        break;
      case suggestionsName:
        data.searchterm = data.term;
        suggestionData.resultUrl = `/${AppSettings.locale}/search?q=${encodeURI(data.searchterm)}`;
        suggestionData.itemTitle = data.searchterm.replace(
          inputValue,
          `<span class="suggestedSearch-itemTitle-highlight">${inputValue}</span>`
        );
        break;
      case contentSuggestionsName:
        data.searchterm = data.term;
        suggestionData.resultUrl = `/${AppSettings.locale}/search?q=${encodeURI(data.searchterm)}`;
        suggestionData.itemTitle = data.searchterm.replace(
          inputValue,
          `<span class="suggestedSearch-itemTitle-highlight">${inputValue}</span>`
        );
        suggestionData.resultCount = data.numberOfResults;
        break;
      case productName:
        suggestionData.resultUrl = data.url;
        suggestionData.itemTitle = data.name;

        if (AppSettings.enableDarkBgImages && data.hasRequiredDarkBackgroundImgs) {
          suggestionData.imageUrl = data.simplifiedImageData.E01.url;
        } else {
          suggestionData.imageUrl = data.simplifiedImageData?.F01
            ? data.simplifiedImageData.F01.url
            : '';
        }

        if (data.showStrikethroughPrices) {
          suggestionData.itemColor = (data.color && data.color.description) || '';
        }

        // TODO price decoration
        suggestionData.itemPrice = (data.price && data.price.formattedValue) || '';
        if (data.showStrikethroughPrices && data.price && data.fromPrice && data.price.value !== data.fromPrice.value) {
          suggestionData.itemOldPrice = data.fromPrice && data.fromPrice.formattedValue;
        }

        suggestionData.originalPriceMessage = data?.originalPriceMessage;
        suggestionData.formattedOriginalPrice = data.fromPrice && data.fromPrice.formattedValue;
        suggestionData.formattedOriginalPriceDiscountPercentage = data?.formattedOriginalPriceDiscountPercentage;

        if (AppSettings?.showLowestPriceText === true) {
          suggestionData.lowestPriceMessage = data?.lowestPriceMessage;
          suggestionData.formattedLowestPrice = data?.formattedLowestPrice;
          suggestionData.formattedLowestPriceDiscountPercentage = data?.formattedLowestPriceDiscountPercentage;
        }

        suggestionData.algoliaQueryId = data?.algoliaQueryId;
        suggestionData.algoliaObjectId = data?.algoliaObjectId;
        suggestionData.algoliaIndexName = data?.algoliaIndexName;

        suggestionData.position = index + 1;
        suggestionData.analytics = JSON.stringify({
          id: data.baseProduct,
          list: 'Suggested search',
          name: suggestionData.itemTitle,
          price: suggestionData.itemPrice,
          variant: suggestionData.itemColor,
          position: index + 1,
        });
        break;
      case contentPageName:
        titleSyllable = new RegExp(inputValue, 'im'); //Find inputValue matching any part of the word
        titleMatch = data.title.match(titleSyllable);

        suggestionData.resultUrl = `/${AppSettings.locale}${data.url}`;
        if (titleMatch) {
          suggestionData.itemTitle = data.title.replace(
            titleMatch,
            `<span class="suggestedSearch-itemTitle-highlight">${titleMatch}</span>`
          );
        } else {
          suggestionData.itemTitle = data.title;
        }

        break;
    }

    return suggestionData;
  },
  restoreSuggestions() {
    this.searchResultItems = [
      ...this.searchResultsList.getElementsByClassName('js-suggestedSearchItem'),
    ];
    if (this.searchResultItems.length && this.lastSearchedTerm && this.searchInput.value !== '') {
      this.showSuggestions();
    }
  },
  showSuggestions() {
    this.positionSuggestionsList();

    this.searchResultsList.classList.add('is-active');
    this.searchResultsList.jq.trigger(EventTypes.SUGGESTED_SEARCH_RESULTS_VISIBLE, [
      this.searchContainer.getAttribute('data-location'),
    ]);
  },
  hideSuggestions() {
    this.hideSuggestionsTimeout = setTimeout(
      function () {
        this.searchResultsList.classList.remove('is-active');
      }.bind(this),
      200
    );
    this.searchResultsList.jq.trigger(EventTypes.SUGGESTED_SEARCH_RESULTS_HIDDEN);
  },
  highlightSuggestions(e) {
    const code = parseInt(e.keyCode || e.which, 10);

    if (code === 38 || code === 40) {
      e.preventDefault();

      if (!this.searchResultItems.length) {
        return false;
      }

      const activeSuggestion = this.searchResultItems
        .filter(function (element) {
          return element.classList.contains('is-active');
        })
        .pop();
      const currentIndex = this.searchResultItems.indexOf(activeSuggestion);
      const l = this.searchResultItems.length;
      let newIndex;

      switch (code) {
        case 38:
          //up
          if (currentIndex > 0) {
            newIndex = currentIndex - 1;
          } else if (currentIndex === 0) {
            newIndex = l - 1;
          }
          break;
        case 40:
          //down
          if (currentIndex < l - 1) {
            newIndex = currentIndex + 1;
          } else {
            newIndex = 0;
          }
          break;
      }

      this.highlightSuggestion(newIndex);
    }
  },
  highlightSuggestion(index) {
    if (this.searchResultItems.length && this.searchResultItems[index]) {
      this.searchResultItems[index].classList.add('is-active');
      this.searchResultItems[index].setAttribute('aria-selected', true);
      $(this.searchResultItems[index])
        .siblings()
        .each(function (idx, element) {
          element.classList.remove('is-active');
          element.setAttribute('aria-selected', false);
        });
    }
  },
});

export default Factory.create(SuggestedSearch, {
  defaultSelector: '.js-suggestedSearch',
});
