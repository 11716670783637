/* eslint no-console: ["error", { allow: ["error"] }] */
/**
 * Global error handler
 * @param  {string} message    Error message
 * @param  {string} scriptUrl  URL on which the error occured
 * @param  {int} lineNumber    Line number
 * @param  {int} column        Column number (not always supplied)
 * @param  {Error} error       Error object (not always supplied)
 * @return {Boolean}           Boolean indiciating default browser error dialog should be shown
 */
window.onerror = function (message, scriptUrl, lineNumber, columnNumber, error) {
  let errorMessage = '[LEGACY] ';

  try {
    errorMessage += `${message} on ${scriptUrl}:${lineNumber}`;
    if (columnNumber !== undefined) {
      errorMessage += `:${columnNumber}`;
    }

    // eslint-disable-next-line no-unused-expressions
    window.dataLayer &&
      window.dataLayer.push({
        event: 'js-error',
        events: {
          category: 'Javascript Error',
          action: errorMessage,
          label: error.name || '',
        },
      });
  } catch (e) {
    // If something fails above just show the default error dialog
    return false;
  }

  console && console.error && console.error(error);

  // Surpress error dialog
  return true;
};
