/**
 * Custom require js/css implementation, Promise based. Only to be used
 * for full URLs like
 *  - "//some.tld/js/script.js"
 *  - "https://cdn.some.tld/js/script.js"
 *  - "//campaign.g-star.com/some/asse.css"
 *
 * This is a shallow implementation to add a script tag to the DOM with
 * a src attribute containing the URL and is async by default.
 *
 * Usage:
 * import loadAsset from './loadAsset';
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */

// Initialize G-Star context object
window.GSRD = window.GSRD || {};

// local script loading cache
const assetCache = {};

/**
 * Custom require implementation that appends script element to the DOM
 *
 * @param {string} urlLike A string supposedly being a URL
 * @returns {Promise}
 */
function loadAsset(urlLike) {
  const error = new URIError();

  if (!assetCache[urlLike]) {
    assetCache[urlLike] = 0;
  }

  return new Promise((resolve, reject) => {
    // check if urlLike starts with // or with https
    if (/^\/\//.test(urlLike) || /^https/.test(urlLike)) {
      // check agains .css because like google api code does not end with .js
      const js = !/\.css$/.test(urlLike);
      // do not append script/link more than once
      if (
        assetCache[urlLike] === 1 ||
        document.querySelector(`script[src*="${urlLike}"],link[href*="${urlLike}"]`)
      ) {
        return resolve(true);
      }
      const assetElement = document.createElement(js ? 'script' : 'link');
      if (js) {
        assetElement.async = true;
      } else {
        assetElement.rel = 'stylesheet';
      }

      /**
       * Create onload, onerror, setTimeout handler
       * @param {function} callback Either resolve or reject
       * @param {function} errorMessage Message to supply to Error
       */
      // eslint-disable-next-line no-inner-declarations
      function handlerFunction(callback, errorMessage) {
        return () => {
          // eslint-disable-next-line no-multi-assign
          assetElement.onload = assetElement.onerror = null;
          clearTimeout(timeoutId);

          if (errorMessage) {
            try {
              document.head.removeChild(assetElement);
            } catch (e) {}
            error.message = `${errorMessage} ${urlLike}`;
            callback(error);
          } else {
            assetCache[urlLike] = 1;
            callback(true);
          }
        };
      }

      // timeout after 120 seconds of waiting
      const timeoutId = window.setTimeout(
        handlerFunction(reject, 'Load timeout exceeded for external asset'),
        120 * 1e3
      );

      try {
        assetElement.onload = handlerFunction(resolve);
        assetElement.onerror = handlerFunction(reject, 'Error loading external asset');

        const sourceAttribute = js ? 'src' : 'href';
        assetElement[sourceAttribute] = urlLike;

        requestAnimationFrame(() => {
          document.head.appendChild(assetElement);
        });
      } catch (exception) {
        reject(exception);
      }
    } else {
      error.message = `Parameter is not URL like.`;
      reject(error);
    }
  }).catch(reason => {
    console.error(reason);
    window?.newrelic?.noticeError(reason);
  });
}

window.GSRD.loadAsset = loadAsset;

export default loadAsset;
