/**
 * Utility function to match screen size to a named breakpoint.
 *
 * Breakpoints are aligned with the CSS breakpoints.
 */
import getCssBreakpointConfig from '../utils/getCssBreakpointConfig';
import '../domutils/Element.jQuery';

// Cache matchBreakpoint results && breakpointConfig
let activeBreakpoints;
let allBreakpoints;
let breakpointConfig;
let breakpointSizes;
let cache = {};
let listeningForResize = false;

/**
 * Reset cached values on resize
 */
function onResize() {
  activeBreakpoints = undefined;
  allBreakpoints = undefined;
  breakpointConfig = undefined;
  breakpointSizes = undefined;
  cache = {};
  listeningForResize = false;
}

/**
 * Checks if viewport size is equal or larger than named breakpoint.
 * @param  {string}   breakpointName Name of the breakpoint
 * @param   {int}     testWidth      Test against this width (default: current window size)
 * @return {boolean}               True if current window size is larger or equal than specified breakpoint size.
 */
export default function matchBreakpoint(breakpointName, testWidth) {
  let result;
  let cacheKey = breakpointName;
  if (isNaN(testWidth)) {
    testWidth = undefined;
  } else {
    cacheKey += `-${testWidth}`;
  }

  if (!listeningForResize) {
    window.jq.one('resize', onResize);
    listeningForResize = true;
  }

  if (cache[cacheKey] !== undefined) {
    result = cache[cacheKey];
  } else {
    if (activeBreakpoints === undefined) {
      breakpointConfig = breakpointConfig || getCssBreakpointConfig();

      if (breakpointConfig && breakpointConfig !== 'none') {
        const parts = breakpointConfig.split('|');
        activeBreakpoints = parts[0].split(',');
        allBreakpoints = parts[1].split(',');
      } else {
        activeBreakpoints = false;
        allBreakpoints = false;
        breakpointConfig = false;
        breakpointSizes = false;
      }
    }

    // Breakpoint is only valid if it's mentioned in the config
    // because config should contain all breakpoints
    if (breakpointConfig && breakpointConfig.indexOf(breakpointName) === -1) {
      throw `matchBreakpoint: breakpoint not found: ${breakpointName}`;
    }

    if (activeBreakpoints) {
      if (testWidth !== undefined) {
        if (!breakpointSizes) {
          breakpointSizes = {};

          for (let i = 0; i < allBreakpoints.length; i++) {
            if (!allBreakpoints[i].length) {
              continue;
            }
            const bp = allBreakpoints[i].split(':');
            breakpointSizes[bp[0]] = parseInt(bp[1], 10);
          }
        }

        result = breakpointName && testWidth >= breakpointSizes[breakpointName];
      } else {
        result = breakpointName && activeBreakpoints.indexOf(breakpointName) !== -1;
      }
    } else {
      // Missing, assume you are on mobile
      result = breakpointName === 'xxsmall';
    }
  }
  return result;
}
