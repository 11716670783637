/**
 * Retrieves confirmation page data from datalayer for analytics
 * @returns {Object}
 */
export default function getConfirmationPageData(tracker = 'qubit') {
  const confirmationDataSelector = document.querySelector(`[data-${tracker}-confirmation]`);
  let confirmationData;

  try {
    const trackerData = { qubit: 'qubitConfirmation', algolia: 'algoliaConfirmation' }[tracker];
    confirmationData = JSON.parse(confirmationDataSelector.dataset[trackerData]);
  } catch {
    return {};
  }

  return confirmationData;
}
