import $ from 'jquery';
import StorageKeys from './utils/storage/StorageKeys';

const AbdetCookie = {
  initialize() {
    const { cltAnalyticsEnabled, analyticsBlockDetectionEnabled, consentCookieValue } = AppSettings;
    const cookieWallEnabled = Boolean(AppSettings?.cookieDialog?.enableWall);
    let hasCookieConsent = false;

    if (consentCookieValue.length === 5 && consentCookieValue.charAt(2) === '1' || !cookieWallEnabled) {
      hasCookieConsent = true;
    }

    if (cltAnalyticsEnabled && analyticsBlockDetectionEnabled && $.cookie(StorageKeys.COOKIE_ABDET) === undefined && hasCookieConsent) {
      AbdetCookie.checkAnalyticsEndpoint();
    }
  },

  checkAnalyticsEndpoint() {
    const url = 'https://clt.g-star.com';

    fetch(url, { method: 'HEAD', mode: 'no-cors' })
      .then(() => { $.cookie(StorageKeys.COOKIE_ABDET, false, { path: '/' }); })
      .catch(() => { $.cookie(StorageKeys.COOKIE_ABDET, true, { path: '/' }); });
  },
};

export default AbdetCookie;
