/* global AppSettings */
/**
 * This module handles the communication with the Cart endpoint of de webapp (Server). Adding, retrieving and deleting products.
 * Communicatiues with events.
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';
import 'jquery.deparam';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('BasketData');

const cartEndpoint =
  (AppSettings.endpoints && AppSettings.endpoints.miniBasket) || `/${AppSettings.locale}/basket`;
const $document = document.jq;

const BasketData = {
  addItemEndPoint: cartEndpoint,
  removeItemEndPoint: `${cartEndpoint}/remove/`,

  /**
   * Add item to basket via Ajax
   * @param {Object} productData Object containing data needed for adding product to basket
   */
  addItem({ triggerType, ...productData }) {
    if (typeof productData === 'string') {
      productData = $.deparam(productData);
    }

    const $addItemDeferred = $.Deferred();
    let errorMessageString = '500 INTERNAL SERVER ERROR';
    let errorMessageStringHtml = `<strong>${errorMessageString}</strong><p>${
      window.labels?.genericError || ''
    }</p>`;

    $document.trigger(EventTypes.CART_ADD_ITEM_START, productData);

    function triggerErrorEvent(cartHtml, errorMessageString, errorMessageStringHtml) {
      $document.trigger(EventTypes.CART_ADD_ITEM_FAIL, {
        message: errorMessageString,
        error: errorMessageStringHtml,
        sku: productData.sku,
      });
    }

    $.ajax({
      url: this.addItemEndPoint,
      type: 'post',
      data: productData,
      dataType: 'html',
      success(cartHtml, status, xhr) {
        const $miniCart = $(cartHtml);
        const errorMsg = $miniCart.find('.errorMessage').text() || '';
        errorMessageStringHtml = `<p>${errorMsg}</p>`;

        if (errorMsg.length) {
          $addItemDeferred.then(triggerErrorEvent);
        }

        $addItemDeferred.resolve(cartHtml, errorMsg, errorMessageStringHtml);
      },
      error(xhr, testStatus, message) {
        let eventData;
        if (`${xhr.status}` === '403' && xhr.responseText) {
          eventData = xhr.responseText;
          return document.jq.trigger(EventTypes.CSRF_TOKEN_ERROR, eventData);
        }
        errorMessageString = `${testStatus} ${message}`;
        errorMessageStringHtml = `<strong>ERROR</strong><p>${errorMessageString}</p>`;

        $addItemDeferred.reject('', errorMessageString, errorMessageStringHtml);
      },
    });

    $addItemDeferred.done(function (cartHtml) {
      $document.trigger(EventTypes.CART_ADD_ITEM_SUCCESS, {
        html: cartHtml,
        sku: productData.sku,
        triggerType,
      });
    });

    $addItemDeferred.fail(triggerErrorEvent);

    $addItemDeferred.always(function () {
      $document.trigger(EventTypes.CART_ADD_ITEM_COMPLETE);
    });

    return $addItemDeferred;
  },

  /**
   * Bind event listeners
   */
  bindEvents() {
    document.jq
      .on(EventTypes.CART_ADD_ITEM_REQUEST, (event, productData) => this.addItem(productData))
      .on(EventTypes.CART_REMOVE_ITEM_REQUEST, (event, sku, entryNumber) => {
        this.removeItem(sku, entryNumber);
      })
      .on(EventTypes.CART_FETCH_REQUEST, () => {
        Logger.debug('On cart fetch request');
        this.fetchFromServer();
      })
      .on(EventTypes.SESSION_UPDATE_REQUIRED, () => this.fetchFromServer());
  },

  /**
   * Initialize component
   */
  initialize() {
    Logger.info('initialized');
    this.bindEvents();
  },

  /**
   * Retrieve foldout basket from server
   */
  fetchFromServer() {
    $document.trigger(EventTypes.CART_FETCH_START);

    const $fetchDeferred = $.ajax({
      url: cartEndpoint,
      success(cartHtml) {
        $document.trigger(EventTypes.CART_FETCH_SUCCESS, [cartHtml]);
      },
      error() {
        $document.trigger(EventTypes.CART_FETCH_FAIL);
      },
    });

    $fetchDeferred.always(function (cartHtml) {
      $document.trigger(EventTypes.CART_FETCH_COMPLETE, [cartHtml]);
    });

    return $fetchDeferred;
  },

  /**
   * Remove item from basket
   * @param  {String} sku SKU of product to remove
   */
  removeItem(sku, entryNumber) {
    $document.trigger(EventTypes.CART_REMOVE_ITEM_START, {
      sku,
    });

    const $removeDeferred = $.ajax({
      url: this.removeItemEndPoint + entryNumber,
      success(cartHtml) {
        $document.trigger(EventTypes.CART_REMOVE_ITEM_SUCCESS, [cartHtml]);
      },
      error() {
        $document.trigger(EventTypes.CART_REMOVE_ITEM_FAIL, [sku]);
      },
    });

    $removeDeferred.always(function (cartHtml) {
      $document.trigger(EventTypes.CART_ADD_ITEM_COMPLETE, [cartHtml, sku]);
    });
    return $removeDeferred;
  },
};

export default BasketData;
