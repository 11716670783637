/* eslint-disable no-param-reassign */
/* global AppSettings */
/**
 * Wrapper arround localStorage. Also offers the oppurtunity to store data in localStorage with a timeout.
 *
 * Example:
 * Storage.setItem('name','value', true, 5);
 *
 * This item will only be availabel for 5 seconds, so when this is called
 * within 5 seconds 'value' is returned, otherwise undefined is returned.
 * Storage.getItem('name');
 *
 * By default data is stored without a timeout.
 * When instead of a timeout true is specified the default timeout of 8 hours is used.
 *
 * @author Meinaart van Straalen
 */
import $ from 'jquery';
import StorageKeys from './StorageKeys';
import '../../../../vendor/jquery/cookie';
import '../../../../g-star/jquery/deparam';

const COOKIE_MAX_VALUE_SIZE = 32; // max bytes per value to allow storage in cookie
const COOKIE_EXPIRE = 365; // days
const COOKIE_NAME = '_ls';
const TIMEOUT = '_TO';
const COOKIE = 'cookie';
const LOCAL_STORAGE = 'localStorage';
const defaultTimeout = 28800; // 8 hours
const countrySpecificKeys = [
  StorageKeys.BASKET,
  StorageKeys.BAG_COUNT,
  StorageKeys.RECENTLY_VIEWED_PRODUCTS,
];
const prefix = `${AppSettings.country.toLowerCase()}_`;
const countrySpecificKeysRegExp = new RegExp(`[a-z]{2}_.*(${countrySpecificKeys.join('|')})$`);

class Storage {
  constructor() {
    this.storageMethod = LOCAL_STORAGE;

    /** * PRIVATE METHODS ** */
    this.data = [];
    this.cookieDataStore = undefined;

    this.updateStorageMethod();
    this.updateStorage();
  }

  /**
   * Store item with a key
   * @param {string} key       Name of the value being stored
   * @param {mixed} value      Can contain any object, if serialize is true it's stored serialized to JSON
   * @param {boolean} serialize Serialize value to JSON, defaults to true
   * @param {int/boolean/date} timeout Number of seconds after which this item should be invalidated,
   *                              if set to true default timeout is used, if omited value is not stored with timeout
   *                              if date is supplied then this is used as end date
   */
  setItem(key, value, serialize, timeout) {
    if (!key) {
      return false;
    }

    if (serialize === undefined) {
      serialize = true;
    }

    let processTimeout = true;
    if (timeout === true) {
      timeout = defaultTimeout;
    } else if (timeout instanceof Date) {
      processTimeout = false;
      timeout = this.getTime(timeout);

      // Time out has passed, remove item from storage
      if (timeout < this.getTime()) {
        timeout = -1;
      }
      // eslint-disable-next-line no-restricted-globals
    } else if (isNaN(timeout)) {
      timeout = undefined;
    }

    if (timeout && processTimeout) {
      timeout = this.getTime() + timeout;
    }

    // Supplied timeout has passed, remove item from storage
    if (timeout && timeout < 0) {
      this.removeItem(key);
    } else {
      let result;
      const storageKey = this.formatKey(key);
      let valueSaved = true;

      if (serialize && typeof value !== 'string') {
        switch (typeof value) {
          case 'object':
            value = JSON.stringify(value);
            break;
          default:
            value = value.toString();
        }
      }

      if (this.storageMethod === LOCAL_STORAGE) {
        try {
          result = localStorage.setItem(storageKey, value);
        } catch (e) {
          this.data[storageKey] = value;
        }
      } else if (this.storageMethod === COOKIE) {
        if (value.length < COOKIE_MAX_VALUE_SIZE) {
          // Value is not too large to store in cookie
          const cookieData = this.getCookieData();
          cookieData[storageKey] = value;
        } else {
          // Value is too large to store in cookie
          // Do not save timeout value to cookie when value is not saved to cookie
          valueSaved = false;
          if (timeout) {
            this.data[this.formatKey(key + TIMEOUT)] = timeout;
          }
        }
        this.data[storageKey] = value;
      } else {
        this.data[storageKey] = value;
      }

      // Save timeout to storage
      if (timeout && valueSaved) {
        this.setItem(key + TIMEOUT, timeout, true, false);
      }

      // Dispatch event for regular saves
      if (key.indexOf(TIMEOUT) === -1) {
        // Save changed values to cookie
        if (this.storageMethod === COOKIE) {
          this.saveDataToCookie();
        }
      }

      return result;
    }
  }

  /**
   * Retrieve item from storage
   * @param  {string} key         Name of the value being retrieved
   * @param  {boolean} deserialize Deserialize result from storage, defaults to true
   * @param  {boolean} checkTimeout Check if data has timed out (defaults to true)
   * @return {mixed}             Stored object
   */
  getItem(key, deserialize, checkTimeout) {
    if (deserialize === undefined) {
      deserialize = true;
    }
    if (checkTimeout === undefined) {
      checkTimeout = true;
    }

    const storageKey = this.formatKey(key);

    let value;
    if (this.storageMethod === LOCAL_STORAGE) {
      try {
        value = localStorage.getItem(storageKey);
      } catch (e) {
        value = this.data[storageKey];
      }
    } else if (this.storageMethod === COOKIE) {
      const cookieData = this.getCookieData();
      value = cookieData[storageKey];
    } else {
      value = this.data[storageKey];
    }

    // Check if item has a timeout, if item has expired nothing is returned
    if (value !== undefined && checkTimeout) {
      let timeout = this.getItem(key + TIMEOUT, false, false);
      if (timeout) {
        timeout = parseInt(timeout, 10);
        if (this.getTime() > timeout) {
          // Value has expired
          return;
        }
      }
    }

    if (value && deserialize) {
      let jsonValue;
      try {
        jsonValue = JSON.parse(value);
      } catch (e) {
        jsonValue = value;
      }
      value = jsonValue;
    }

    return value;
  }

  /**
   * Removes a key/value pair from localstoreage
   * @param  {string} key
   * @param {boolean} isFormattedKey If set to true key is not processed via formatKey function
   */
  removeItem(key, isFormattedKey) {
    const storageKey = isFormattedKey ? key : this.formatKey(key);
    const storageTimeoutKey = key.indexOf(TIMEOUT) === -1 ? storageKey + TIMEOUT : false;

    if (this.storageMethod === LOCAL_STORAGE) {
      try {
        localStorage.removeItem(storageKey);

        if (storageTimeoutKey) {
          localStorage.removeItem(storageTimeoutKey);
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } else if (this.storageMethod === COOKIE) {
      const cookieData = this.getCookieData();
      delete cookieData[storageKey];

      if (storageTimeoutKey) {
        delete cookieData[storageTimeoutKey];
      }

      this.saveDataToCookie();
    }
    delete this.data[storageKey];
    if (storageTimeoutKey) {
      delete this.data[storageTimeoutKey];
    }
  }

  /** * EVENT LISTENERS ** */

  /**
   * Prepend country code to key so storage is country specific
   * @param  {string} key 'raw' storage key
   * @param {boolean} forceCountryPrefix Force country prefix (defaults to false)
   * @return {string}     [description]
   */
  formatKey(key, forceCountryPrefix) {
    const regex = new RegExp(TIMEOUT, 'gi');
    const bareKey = key && key.match(regex) ? key.replace(regex, '') : key;
    return forceCountryPrefix || $.inArray(bareKey, countrySpecificKeys) !== -1
      ? `${AppSettings.country.toLowerCase()}_${key}`
      : key;
  }

  /**
   * Returns data stored in cookie in an object
   * @return {object} Plain object which contains storage data
   */
  getCookieData() {
    if (!this.cookieDataStore) {
      const cookieData = $.cookie(COOKIE_NAME);
      $.cookie.raw = true;
      $.cookie.json = true;
      this.cookieDataStore = cookieData ? $.deparam(cookieData) : {};
    }
    return this.cookieDataStore;
  }

  /**
   * Get current timestamp (seconds from january 1, 1970)
   * @param {date} date (optional) Date object, if omited current date is used
   * @return {int} Unix timestamp
   */
  getTime(date) {
    if (date === undefined) {
      date = new Date();
    }
    return Math.round(date.getTime() / 1000);
  }

  /**
   * Save data stored in cookie object back to the cookie.
   */
  saveDataToCookie() {
    const cookieData = this.getCookieData();

    // Clean up expired variables and variables not set for current country
    const currentTime = this.getTime();
    for (const key in cookieData) {
      if (key.indexOf(TIMEOUT) === -1) {
        const timeout = cookieData[key + TIMEOUT];

        if (timeout !== undefined && currentTime > timeout) {
          delete cookieData[key];
          delete cookieData[key + TIMEOUT];
          delete this.data[key];
          delete this.data[key + TIMEOUT];
        }
      }
    }

    // Save value to cookie
    const value = $.param(cookieData);
    $.cookie.json = true;
    $.cookie(COOKIE_NAME, value, {
      expires: COOKIE_EXPIRE,
      path: '/',
    });
    $.cookie.json = false;
  }

  /**
   * Check if localStorage is available and actually works.
   * Otherwise switch to cookie saving.
   */
  updateStorageMethod() {
    if (this.storageMethod === LOCAL_STORAGE) {
      let canSave = true;
      try {
        const x = 'canSave';
        localStorage.setItem(x, x);
        localStorage.removeItem(x);
      } catch (e) {
        canSave = false;
      }

      if (!canSave) {
        this.storageMethod = COOKIE;
      }
    } else {
      this.storageMethod = COOKIE;
    }

    if (this.storageMethod === LOCAL_STORAGE && document.cookie.indexOf(`${COOKIE_NAME}=`) > -1) {
      $.removeCookie(COOKIE_NAME, {
        path: '/',
      });
    }
  }

  /**
   * Migrates data from old storage key to new storage key.
   * Also moves data previously stored in cookie to local storage.
   *
   * Also removes country specific data from other locales.
   */
  updateStorage() {
    // Remove country specific data from other countries
    let storage;
    if (this.storageMethod === LOCAL_STORAGE) {
      storage = localStorage;
    } else if (this.storageMethod === COOKIE) {
      storage = this.getCookieData();
    }

    // Remove country specific items from storage when
    // they do not match the current country
    const cleanupItems = Object.keys(storage)
      .filter(el => countrySpecificKeysRegExp.test(el))
      .filter(el => el.substr(0, 3) !== prefix);

    cleanupItems.length && cleanupItems.forEach(el => this.removeItem(el));
  }
}

/**
 * Checks if localstorage is available
 *
 * @returns {boolean}
 */
export const hasLocalStorage = () => {
  try {
    const test = '__storage_test__';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * Checks if sessionStorage is available
 *
 * @returns {boolean}
 */
export const hasSessionStorage = () => {
  try {
    const test = '__storage_test__';
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export default new Storage();
