/* global AppSettings, AppState */
/**
 * This module handles the Nasty Dialog pop-up that is presented to you
 * when first arriving at G-star.com
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import Dialog from 'components/dialogs/dialog';
import storage from 'components/utils/storage/Storage';
import device from 'components/utils/device';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import EventTypes from 'components/EventTypes';
import DialogTypes from 'components/dialogs/Types';
import onWindowLoaded from 'components/utils/onWindowLoaded';
import StorageKeys from 'components/utils/storage/StorageKeys';
import { getCSRFToken } from '../utils/CsrfUtils';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('dialog--subscriptionFormCTA');

let dialogsCreated = 0;

const AB_TEST_PDP_VISITS = 'msdpdpv';
const PRESENTED = '_p';
const TIMEOUT = '_to';

function SubscriptionDialog(options) {
  return {
    dialog: false,
    $form: null,
    options: {
      className: 'dialog--subscriptionFormCTA',
      cssDependencies: [`${AppSettings.assetPath}css/dialog--subscriptionFormCTA`],
      id: null,
      jsDependencies: () => {
        return import(
          /* webpackChunkName: "dialog--subscriptionFormCTA" */ 'components/dialogs/dialog--subscriptionFormCTA'
        );
      },
      storageExpires: 365 * 24 * 3600,
      timeOut: 5000,
      type: DialogTypes.NEWSLETTER,
      url: null,
      vAlignMiddleOnSmallScreen: true,
      isFullScreenOnMobile: false,
      cache: false,
    },
    afterSuccesfulSubmitDisappearTimeout: 3500,

    initialize() {
      Logger.time('initialize', this);

      if (!options.id) {
        options.id = `dialog-${dialogsCreated++}`;
      }

      if (!options.storageId) {
        options.storageId = options.id;
      }

      $.extend(this.options, options);

      if (this.hasVisitedFromNewsletter()) {
        this.setCookie();
      } else {
        this.setTimeOutCookie();
        onWindowLoaded(this.autoShowDialog, this);
      }

      Logger.timeEnd('initialize', this);
    },

    dateToTimestamp(date) {
      return Math.round(date.getTime() / 1000);
    },

    timestampToDate(ts) {
      const d = new Date();
      d.setTime(ts * 1000);
      return d;
    },

    setTimeOutCookie() {
      const time = new Date();
      const name = this.options.storageId + TIMEOUT;
      const timeSet = storage.getItem(name);

      if (!timeSet) {
        time.setSeconds(time.getSeconds() + this.options.timeOut / 1000);
        const expires = new Date();
        const minutes = 30;
        expires.setTime(expires.getTime() + minutes * 60 * 1000);
        storage.setItem(name, this.dateToTimestamp(time), true, expires);
      }
    },

    autoShowDialog() {
      const timeSet = this.timestampToDate(storage.getItem(this.options.storageId + TIMEOUT));
      const now = new Date();
      let diff = timeSet - now;
      diff = !isNaN(diff) && diff > 0 ? diff : 0;

      window.setTimeout(() => {
        // don't show the dialog when the side navigation is open on mobile devices
        if (
          device.isMobile &&
          !matchBreakpoint('xsmall', device.screenWidth) &&
          document.documentElement.classList.contains('has-open-sideNavigation')
        ) {
          return;
        }

        this.showDialog();
      }, diff);
    },

    showDialog() {
      Dialog.show(this.options).done(dialog => {
        this.dialog = dialog;
        this.initSubscriptionForm();
        this.bindDialogFixEvents();
      });
    },

    closeDialog() {
      this.setCookie();

      this.unbindDialogFixEvents();
    },

    initSubscriptionForm() {
      this.form = this.dialog.$dialogContent.get(0).querySelector('form');

      // be sure to bind the form-controls after the submit event. Form controls prevents propagation if errors are found.
      if (this.form) {
        import(
          /* webpackChunkName: "import--SubscriptionForm" */ 'components/footer/SubscriptionForm'
        ).then(dependency => {
          const SubscriptionForm = dependency.default;
          SubscriptionForm.attachTo(this.form.id);
        });
        import(/* webpackChunkName: "import--FormControls" */ 'components/FormControls').then(
          dependency => {
            const FormControls = dependency.default;
            this.form.jq.data('scrollToErrorsDisabled', true);
            FormControls.initialize(this.form);
            this.bindEvents();
          }
        );
      }
    },

    focusFirstInput() {
      const firstInput = this.form.querySelector('input:not([type=hidden])');
      firstInput.focus();
    },

    unbindDialogFixEvents() {
      window.jq.off('.SubscriptionDialog');
    },

    bindDialogFixEvents() {
      this.dialog.$dialog.on(EventTypes.DIALOG_CLOSE, this.closeDialog.bind(this));
    },

    bindEvents() {
      document.jq.on(EventTypes.DEFERRED_INITIALIZE_COMPLETE, this.focusFirstInput.bind(this));
      this.dialog.$dialogContent.off('submit').on('submit', 'form', this.submitForm.bind(this));

      this.hasMouseDown = false;
      this.$emailInput = this.form.jq.find('.formInputGroup--subscriptionFormCTA input');
      this.$optinCheckbox = this.form.jq.find('.subscriptionFormCTA-optin input[type=checkbox]');
      this.$emailInput.on('focus', this.onEmailInputFocus.bind(this));
      this.$emailInput.on('blur', this.onEmailInputBlur.bind(this));

      if (device.isMobile) {
        this.dialog.$dialogContent.on('mousedown', this.onDialogMouseDown.bind(this));
        this.dialog.$dialogContent.on('mouseup', this.onDialogMouseUp.bind(this));
      }
    },

    /**
     * Handle form input focus event
     */
    onEmailInputFocus() {
      this.toggleOptin(true);
    },

    /**
     * Handle form input blur event
     */
    onEmailInputBlur() {
      // hide optin on iOS virtual keyboard "done" press
      if (!this.hasMouseDown && this.canHideOptin()) {
        this.toggleOptin(false);
      }
    },

    /**
     * Handle dialog mouse down event
     * @param {Event} event
     */
    onDialogMouseDown(event) {
      const isTargetWithinForm = !!$(event.target).closest('.js-formOptIn').length;
      this.hasMouseDown = isTargetWithinForm;

      if (!isTargetWithinForm && this.canHideOptin()) {
        this.toggleOptin(false);
      }
    },

    /**
     * Handle dialog mouse up event
     * @param {Event} event
     */
    onDialogMouseUp() {
      this.hasMouseDown = false;
    },

    /**
     * Returns if we're allowed to hide the optin checkbox
     * @return {Boolean}
     */
    canHideOptin() {
      const isEmailInputEmpty = this.$emailInput.val() === '';
      const isCheckboxChecked = this.$optinCheckbox.is(':checked');

      return isEmailInputEmpty && !isCheckboxChecked;
    },

    /**
     * Toggle optin's visibility
     * @param {Boolean} toggle
     */
    toggleOptin(toggle) {
      this.form.classList.toggle('is-extended', toggle);
    },

    submitForm(e) {
      e.preventDefault();

      if (this.form.jq.data('valid')) {
        const $form = $(e.target);
        const formData = $form.serializeObject();

        getCSRFToken()
          .then(CSRFToken => {
            formData.CSRFToken = CSRFToken;

            $.post($form.attr('action'), formData)
              .done(this.afterSubmit.bind(this, formData))
              .fail(this.afterSubmit.bind(this, formData));
          })
          .catch(message => {
            Logger.error(message);
          });
      }
    },

    afterSubmit(formData, response) {
      const $content = $(response.responseText ? response.responseText : response);
      const gender = formData.gender || 'not specified';

      if ($content.find('.invalid,.error').length) {
        this.dialog.replaceContent($content);
        this.initSubscriptionForm();
      } else {
        this.dialog.$dialogContent.find('.js-subscriptionFormCTA-content').html($content);

        document.jq.trigger(EventTypes.UPDATE_USER_INFO);

        setTimeout(this.dialog.close.bind(this), this.afterSuccesfulSubmitDisappearTimeout);

        document.jq.trigger(AnalyticsEventTypes.NEWSLETTER_SUBSCRIBE, formData);
      }
    },

    setCookie() {
      storage.setItem(this.options.storageId + PRESENTED, true, true, this.options.storageExpires);
      window.sessionStorage.removeItem(AB_TEST_PDP_VISITS);
    },

    hasVisitedFromNewsletter() {
      return window.location.href.indexOf('utm_medium=email') !== -1;
    },
  };
}

export default {
  /**
   * Should subscription dialog be showed at all
   * @type {boolean}
   */
  shouldShowDialog: false,

  /**
   * Define readiness based on if initialize is done or
   * common data endpoint has succesfully been called
   * @type {boolean}
   */
  ready: false,

  /**
   * Prevent retriggering of subscription dialog
   * @type {boolean}
   */
  triggered: false,

  initialize() {
    if (!AppSettings?.subscriptionDialog?.length) {
      return;
    }
    if (storage.getItem(AppSettings.subscriptionDialog[0].storageId + PRESENTED)) {
      return;
    }

    this.shouldShowDialog = true;

    if (AppSettings.statelessPage) {
      document.jq.one(EventTypes.COMMON_DATA_READY, () => {
        if (!AppSettings.subscriptionDialog.length) {
          this.shouldShowDialog = false;
          return;
        }
        this.ready = true;
        document.jq.trigger(EventTypes.SUBSCRIPTION_DIALOG_READY);
      });
    } else {
      this.ready = true;
      document.jq.trigger(EventTypes.SUBSCRIPTION_DIALOG_READY);
    }
  },

  trigger() {
    if (this.triggered || !AppSettings.subscriptionDialog) {
      return;
    }

    // Note: subscriptionDialog is an array because of legacy reasons.
    // Keep in mind the dependency of ClickValue's A/B testing script
    const subscriptionDialog = AppSettings.subscriptionDialog[0];

    // subscriptionDialog is disabled for this page
    if (subscriptionDialog.active === false) {
      return;
    }

    if (AppState.msdAbTestingEnabled) {
      const abCookieValue = $.cookie(StorageKeys.AB_TEST_SUBSCRIPTION_DIALOG);

      if (!abCookieValue) {
        // If the ab_test cookie has no value yet it should wait for the
        // `cookieHasBeenSet` event from optimize and retry
        document.jq.one(EventTypes.AB_TEST_OPTIMIZE_EVENT, (event, eventData) => {
          this.trigger();
        });
        return;
      }

      if (abCookieValue === 'B') {
        // B value only considers PDPs
        if (AppSettings.pageType !== 'PRODUCT') {
          this.triggered = true; // prevent retriggering for this page
          return;
        }
        const pdpVisits = parseInt(window.sessionStorage.getItem(AB_TEST_PDP_VISITS) || 0);
        if (pdpVisits < 1) {
          window.sessionStorage.setItem(AB_TEST_PDP_VISITS, pdpVisits + 1);
          this.triggered = true; // prevent retriggering for this page
          return;
        }
      }
    }

    new SubscriptionDialog(subscriptionDialog).initialize();
    this.triggered = true;
  },

  triggerWhenReady() {
    if (!this.shouldShowDialog) {
      return;
    }

    if (this.ready) {
      this.trigger();
      return;
    }

    document.jq.one(EventTypes.SUBSCRIPTION_DIALOG_READY, () => {
      this.trigger();
    });
  },
};
