/* globals AppState */
/**
 * Registers sticky events for the application.
 *
 * Registered event handlers are executed when the event occures or
 * directly if the event already occured.
 *
 * G-star uses this mechanism to determine when assets should be
 * loaded during the page load sequence.
 *
 * Events:
 * EventTypes.READY_FOR_SECONDARY_ASSETS - assets needed after primary assets can be added to the queue
 */
import $ from 'jquery';
import 'jquery.stickyTrigger';
import EventTypes from 'components/EventTypes';
import 'components/domutils/Element.jQuery';

let loadHandlerId;

const Module = {
  initialized: false,
  loaded: false,
  initialize() {
    if (!this.initialized) {
      /**
       * Creates sticky event to determine when READY_FOR_SECONDARY_ASSETS event should fire. This
       * event should fire when the window load event has been fired or when certain time
       * has passed since the domContentLoaded event.
       *
       * Event handlers can be registered via `document.jq.on(EventTypes.READY_FOR_SECONDARY_ASSETS, callbackFn )`
       */

      if (document.readyState === 'complete') {
        this.loadHandler();
      } else {
        const timeOut = 2000;
        const _this = this;
        this.loadListener = this.loadHandler.bind(this, true);

        document.jq.ready(function () {
          loadHandlerId = setTimeout(_this.loadListener, timeOut);
        });
        window.jq.on('load.DelayedLoading', this.loadListener);
      }
      this.initialzed = true;
    }
  },
  loadHandler(viaEventOrTimeout) {
    if (this.loaded) {
      return;
    }

    if (viaEventOrTimeout) {
      clearTimeout(loadHandlerId);
      window.jq.off('load.DelayedLoading');
    }

    document.jq.stickyTrigger(EventTypes.READY_FOR_SECONDARY_ASSETS);
    this.loaded = true;
  },
};

// Self initializing
Module.initialize();
export default Module;
