/* global AppSettings */
/**
 * Handles the cookie notices:
 * - Cookies not enabled
 * - Cookies are used are you aware of that
 *
 * @author Edson Junior
 */
import createLogger from './logger/Logger';
import $ from 'jquery';
import StorageKeys from './utils/storage/StorageKeys';
import 'cookie';

const Logger = createLogger('uciUrlCookie');

const BdgCookie = {
  initialize() {
    Logger.info('Created');
    this.getUuid() && this.setCookie();
  },
  setCookie() {
    $.cookie.raw = true;
    $.cookie(StorageKeys.BDG, this.getUuid(), {
      expires: Math.floor(parseInt(AppSettings.bdgExpirationDate, 10) / (3600 * 24)),
      path: '/',
    });
  },
  getUuid() {
    const url = document.location;

    return new URL(url.href).searchParams.get('bdg') || undefined; // eslint-disable-line
  },
};

export default BdgCookie;
