/* global AppSettings */
const events = {
  EECCONTACTFORMSUBMIT: 'contactform-submit',
  EECEXTERNALLINKCLICK: 'externallink-click',
  EECFAQITEMOPEN: 'faqitem-open',
  EECFILTER: 'filter-on',
  EECSEARCHCLICK: 'search-click',
  EECSEARCHENTER: 'search-enter',
  EECSEARCHPAGELOAD: 'searchpageload',
  EECSIZEGUIDEOPEN: 'sizeguide-open',
  EECSORTINGOPTIONCLICK: 'sortingoption-click',
  EECSTOREFINDERSEARCH: 'storefinder-search',
  EECTHUMBNAILCLICK: 'thumbnail-click',
  EECVIEWASCLICK: 'viewas-click',
  EECWHEREISITMADE: 'whereisitmade-open',
  NEWSLETTERSUBSCRIBE: 'newsletter-subscribe',
  STOCKNOTIFICATIONOPEN: 'stocknotification-open',
  STOCKNOTIFICATIONSUBMIT: 'stocknotification-submit',
  COMINGSOONNOTIFICATIONOPEN: 'comingsoonnotification-open',
  COMINGSOONNOTIFICATIONSUBMIT: 'comingsoonnotification-submit',
  WISHLISTADD: 'wishlist-add',
  WISHLISTREMOVE: 'wishlist-remove',
  MINIWISHLISTOPEN: 'mini-wishlist-open',
};

const interactionsData = {
  event: undefined,
  eventCallback: undefined,
  eventTimeout: undefined,
  events: {
    action: undefined,
    category: undefined,
    label: undefined,
  },
  product: undefined,
};

/**
 * "Deep" clone interactionsData
 * @returns {obejct}
 */
function getInteractionsData() {
  const intermediate = { ...interactionsData };
  intermediate.events = { ...interactionsData.events };
  return intermediate;
}

class Interactions {
  /**
   * https://g-star.atlassian.net/browse/DAN-111
   * @param {Object} eventData
   */
  static getAppliedFilterData(eventData) {
    const data = getInteractionsData();

    data.events = {
      action: 'on',
      category: 'filters',
      label: eventData.label,
    };

    data.page = {
      filter: eventData.filter,
    };

    data.event = events.EECFILTER;

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-107
   * @param {*} eventData
   */
  static getContactFormData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECCONTACTFORMSUBMIT;

    data.events = {
      action: 'submit',
      category: 'contact form',
      label: eventData.label,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-97
   * @param {Object} eventData
   */
  static getCookieData(eventData) {
    const data = getInteractionsData();
    const consentLevel =
      document.cookie.replace(/(?:(?:^|.*;\s*)coco\s*=\s*([^;]*).*$)|^.*$/, '$1') || '00000';

    data.events = {
      action: eventData.action,
      category: 'cookies',
      label: consentLevel,
    };

    data.page = {
      countryCode: (AppSettings.country || '').toUpperCase(),
      pageType: (AppSettings.pageType || 'other').toLowerCase(),
    };

    data.user = {
      consentLevel,
    };

    data.event = `cookie-${eventData.action}`;

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-103
   * @param {Object} eventData
   */
  static getExternalLinkData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECEXTERNALLINKCLICK;

    if (eventData.eventCallback) {
      data.eventCallback = eventData.eventCallback;
      data.eventTimeout = 500;
    }

    data.events = {
      action: 'click',
      category: 'external link',
      label: eventData.label,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-110
   * @param {Object} eventData
   */
  static getInternalSearchData(eventData) {
    const data = getInteractionsData();

    if (eventData.action === 'page loaded') {
      data.event = events.EECSEARCHPAGELOAD;
    } else {
      data.event = eventData.action === 'click' ? events.EECSEARCHCLICK : events.EECSEARCHENTER;
    }

    data.events = {
      action: eventData.action,
      category: 'search',
      label: eventData.label,
    };

    data.page = {
      searchTerm: eventData.searchTerm,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-109
   * @param {Object} eventData
   */
  static getSizeGuideData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECSIZEGUIDEOPEN;
    data.events.action = 'open';
    data.events.category = 'size guide';
    data.product = {
      materialNumber: eventData.materialNumber.toLowerCase(),
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-104
   * @param {Object} eventData
   */
  static getSortingOptionData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECSORTINGOPTIONCLICK;

    data.events = {
      action: 'click',
      category: 'sorting options',
      label: eventData.label,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-101
   * @param {Object} eventData
   */
  static getStockLocatorData(eventData) {
    const { action, label, searchTerm } = eventData;
    const data = getInteractionsData();

    data.event = `stocklocator-${action}`;

    data.events = {
      action,
      category: 'stock locator',
    };

    if (label) data.events.label = label;

    data.page = {
      searchTerm,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-100
   * @param {Object} eventData
   */
  static getStoreFinderData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECSTOREFINDERSEARCH;

    data.events = {
      action: 'search',
      category: 'store finder',
      label: eventData.label,
    };

    data.page = {
      searchTerm: eventData.searchTerm,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-99
   * @param {Object} eventData
   */
  static getThumbnailData(eventData) {
    const { page, user, ...thumbnailData } = getInteractionsData();
    const data = {
      ...(page && { page }),
      ...(user && { user }),
      ...thumbnailData,
    };

    data.event = events.EECTHUMBNAILCLICK;
    data.events.action = eventData.action;
    data.events.category = 'thumbnails';
    data.events.label = eventData.label;
    data.product = {
      materialNumber: eventData.materialNumber.toLowerCase(),
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-105
   * @param {Object} eventData
   */
  static getViewAsData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECVIEWASCLICK;

    data.events = {
      action: 'click',
      category: 'view as',
      label: eventData.label,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-98
   * @param {Object} eventData
   */
  static getWhereIsItMadeData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECWHEREISITMADE;

    data.events = {
      action: 'open',
      category: 'where is it made',
      label: undefined,
    };

    return data;
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-108
   * @param {Object} eventData
   */
  static getFaqData(eventData) {
    const data = getInteractionsData();

    data.event = events.EECFAQITEMOPEN;

    data.events = {
      action: 'open',
      category: 'faq',
      label: eventData.label,
    };

    return data;
  }

  static getNewsletterData(eventData) {
    const { entryPoint, subscriptionPageTopicId = '', newsletterSubscription } = eventData;

    if (!entryPoint) return;

    switch (newsletterSubscription || newsletterSubscription?.[0]) {
      case 'false':
      case 'off':
        return;
      default:
        const data = getInteractionsData();

        data.event = events.NEWSLETTERSUBSCRIBE;

        data.page = {
          countryCode: (AppSettings.country || '').toUpperCase(),
        };

        data.events = {
          category: 'newsletter',
          action: 'subscribe',
          label: `${entryPoint.toLowerCase()}~${subscriptionPageTopicId}`,
        };

        return data;
    }
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-770
   * @param {Object} eventData
   */
  static getStockNotificationOpenData(eventData) {
    return {
      ...getInteractionsData(),
      event: events.STOCKNOTIFICATIONOPEN,
      events: {
        action: 'open',
        category: 'stock notification',
        label: undefined,
      },
      product: {
        materialNumber: eventData.materialNumber.toLowerCase(),
      },
    };
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-770
   * @param {Object} eventData
   */
  static getStockNotificationSubmitData(eventData) {
    return {
      ...getInteractionsData(),
      event: events.STOCKNOTIFICATIONSUBMIT,
      events: {
        action: 'submit',
        category: 'stock notification',
        label: undefined,
      },
      product: {
        materialNumber: eventData.materialNumber.toLowerCase(),
      },
    };
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-771
   * @param {Object} eventData
   */
  static getComingSoonNotificationOpenData(eventData) {
    return {
      ...getInteractionsData(),
      event: events.COMINGSOONNOTIFICATIONOPEN,
      events: {
        action: 'open',
        category: 'coming soon notification',
        label: undefined,
      },
      product: {
        materialNumber: eventData.materialNumber.toLowerCase(),
      },
    };
  }

  /**
   * https://g-star.atlassian.net/browse/DAN-771
   * @param {Object} eventData
   */
  static getComingSoonNotificationSubmitData(eventData) {
    return {
      ...getInteractionsData(),
      event: events.COMINGSOONNOTIFICATIONSUBMIT,
      events: {
        action: 'submit',
        category: 'coming soon notification',
        label: undefined,
      },
      product: {
        materialNumber: eventData.materialNumber.toLowerCase(),
      },
    };
  }

  /**
   * https://g-star.atlassian.net/browse/TK-414
   * @param {Object} eventData
   */
  static getWishlistUpdateData(eventData) {
    const { actionType, skus: [code] = '' } = eventData;

    const { [actionType]: { event, action } = {} } = {
      wishlistAddItem: {
        event: events.WISHLISTADD,
        action: 'add',
      },
      wishlistRemoveItem: {
        event: events.WISHLISTREMOVE,
        action: 'remove',
      },
    };

    return (
      event && {
        ...getInteractionsData(),
        event,
        events: {
          action,
          category: 'wishlist',
          label: code,
        },
      }
    );
  }

  /**
   * https://g-star.atlassian.net/browse/TK-757
   * @param {Object} eventData
   */
  static getWishlistModalOpenData(eventData) {
    return {
      ...getInteractionsData(),
      event: events.MINIWISHLISTOPEN,
      events: {
        action: 'hover',
        category: 'wishlist',
        label: 'open mini-wishlist',
      },
    };
  }

  /**
   * https://g-star.atlassian.net/browse/TK-1965
   * @param {Object} eventData
   */
  static getReturnFeePdpMessageViewEventData(eventData) {
    const { returnType } = eventData;

    switch (returnType) {
      case 'freeReturns':
        return {
          ...getInteractionsData(),
          event: 'free-returns-pdpmessage-view',
          events: {
            category: 'Return_fee',
            action: 'View free returns message on PDP',
            label: 'free-returns-PDPmessage-view',
          },
        };
      case 'chargedReturns':
        return {
          ...getInteractionsData(),
          event: 'no-free-returns-pdpmessage-view',
          events: {
            category: 'Return_fee',
            action: 'View no free returns message on PDP',
            label: 'no-free-returns-PDPmessage-view',
          },
        };
      default:
    }
  }

  /**
   * https://g-star.atlassian.net/browse/TK-2665
   * @param {Object} eventData
   * @returns {Object}
   */
  static getUserLoggedInData(eventData) {
    return {
      event: 'login',
      events: {
        category: 'login',
        action: 'account',
        label: 'successful',
      },
    };
  }
}

export default Interactions;
