/**
 * New session navigation handling.
 *
 **/
import $ from 'jquery';
import Storage from 'components/utils/storage/Storage';
import StorageKeys from 'components/utils/storage/StorageKeys';
  const _this = this;
  const newSessionSelector = '.js-account--newsession';

  const NewSession = {};

  /*** PUBLIC METHODS ***/

  NewSession.initialize = function () {
    bindEvents();
  };

  /*** PRIVATE METHODS ***/

  function bindEvents() {
    $(newSessionSelector).on('click', onNewSessionClick.bind(_this));
  }

  function onNewSessionClick(event) {
    Storage.setItem(StorageKeys.REFRESH_BASKET, true);
  }

  export default NewSession;

