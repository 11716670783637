/**
 * Levels for Logger component
 *
 * @author  Meinaart van Straalen
 */

  export default {
    // Log everything
    ALL: 0,

    // Show the results of all asserts
    ASSERT: 1,

    // Most detailed information, probably only used when debugging
    TRACE: 2,

    // Detailed information, for tracking flow of a module
    DEBUG: 3,

    // Interesting runtime events (initialization etc.).
    INFO: 4,

    // When something goes wrong but it can be resolved by the module
    WARN: 5,

    // When an error occurs (for example in loading external data)
    ERROR: 6,

    // When an unrecoverable error occurs
    FATAL: 7,

    // No logging
    NONE: 99,
  };

