import $ from 'jquery';
import createLogger from '../logger/Logger';
import defineLazyProperty from './defineLazyProperty';
import '../domutils/Element.jQuery';

const Logger = createLogger('defineLazyJquerySelector');

/**
 * Make a child jQuery object lazy, which means it's looked up when it's needed (only once).
 * @param {Object} scope      Object to add lazy listener to
 * @param {String} name       Name, for example "$submitButton"
 * @param {String} selector   Selector to find child
 * @param {jQuery|Element=} container jQuery object or DOM element in which should be searched,
 *                             if ommitted the selector is searched in whole document
 * @param {Boolean=} overwrite When true property will be overwritten even if it exists (default: false)
 */
export default function defineLazyJquerySelector(scope, name, selector, container, overwrite) {
  if (overwrite || !Object.getOwnPropertyDescriptor(scope, name)) {
    defineLazyProperty(scope, name, $.bind($, selector, container));
  } else {
    Logger.warn(`Property "${name}" already exists on`, scope);
  }
  return scope;
}
