/**
 * Cache jQuery object on DOM element. Useful for objects that are reused in multiple modules.
 *
 * Use `document.jq` instead of `$(document)`.
 *
 * You can also use it to quickly transform a collection to a jQuery object:
 * ```
 * document.getElementsByClassName('js-sideNav-link').jq.on('vclick', clickListener);
 * ```
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
/**
 * Define `$` getter on `Target` this means that `$` is only created when
 * somebody tries to access `Target.$`.
 */
function mixin(Target) {
  Object.defineProperty(Target, 'jq', {
    get() {
      if (typeof this !== 'object') {
        throw new ReferenceError(`Element.jQuery: \`this\` is not an object: ${this}`);
      }

      if (!this.__jq) {
        Object.defineProperty(this, '__jq', {
          value: $(this),
        });
      }

      return this.__jq;
    },
    set(value) {
      if (!this.__jq) {
        Object.defineProperty(this, '__jq', {
          value: $(this),
        });
      }
    },
    configurable: true,
    writeable: false,
  });
}

mixin(NodeList.prototype);
mixin(HTMLCollection.prototype);
mixin(Element.prototype);
mixin(Document.prototype);
mixin(Window.prototype);
