/**
 * This file imports any dependency that is used in campaign pages.
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import createLogger from '../components/logger/Logger';
import loadAsset from '../../g-star/loadAsset';
import requestIdleCallback from '../components/utils/requestIdleCallback';

const Logger = createLogger('campaignSupport');

const campaignSupport = {
  'components/EventTypes': () => {
    return import(
      /* webpackChunkName: 'import--components-EventTypes' */ '../components/EventTypes'
    );
  },
  'components/FormControls': () => {
    return import(
      /* webpackChunkName: 'import--components-FormControls' */ '../components/FormControls'
    );
  },
  /**
   * @deprecated Do not use anymore. Most functions in this module have been removed due to dataLayer v2
   * @returns Promise<any>
   */
  'components/analytics/Analytics': () => {
    return import(
      /* webpackChunkName: 'import--components-analytics-Analytics' */ '../components/analytics/Analytics'
    );
  },
  'components/carousels/GridCarousel': () => {
    return import(
      /* webpackChunkName: 'import--components-carousels-GridCarousel' */ '../components/carousels/GridCarousel'
    );
  },
  'components/carousels/transitions/Slide': () => {
    return import(
      /* webpackChunkName: 'import--components-carousels-transitions-Slide' */ '../components/carousels/transitions/Slide'
    );
  },
  'components/dialogs/dialog': () => {
    return import(
      /* webpackChunkName: 'import--components-dialogs-dialog' */ '../components/dialogs/dialog'
    );
  },
  'components/domutils/Element.jQuery': () => {
    return import(
      /* webpackChunkName: 'import--components-domutils-Element.jQuery' */ '../components/domutils/Element.jQuery'
    );
  },
  'components/lazyLoading/LazyLoadImage': () => {
    return import(
      /* webpackChunkName: 'import--components-lazyLoading-LazyLoadImage' */ '../components/lazyLoading/LazyLoadImage'
    );
  },
  'components/productDetail/quickShopProduct': () => {
    return import(
      /* webpackChunkName: 'import--components-productDetail-quickShopProduct' */ '../components/productDetail/quickShopProduct'
    );
  },
  'components/utils/CurrentBreakpoint': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-CurrentBreakpoint' */ '../components/utils/CurrentBreakpoint'
    );
  },
  'components/utils/device': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-device' */ '../components/utils/device'
    );
  },
  'components/utils/getQueryParameter': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-getQueryParameter' */ '../components/utils/getQueryParameter'
    );
  },
  'components/utils/matchBreakpoint': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-matchBreakpoint' */ '../components/utils/matchBreakpoint'
    );
  },
  'components/utils/onWindowLoaded': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-onWindowLoaded' */ '../components/utils/onWindowLoaded'
    );
  },
  'components/utils/scaleElementInContainer': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-scaleElementInContainer' */ '../components/utils/scaleElementInContainer'
    );
  },
  'components/utils/scrollTo': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-scrollTo' */ '../components/utils/scrollTo'
    );
  },
  'components/videoPlayer/videoPlayer': () => {
    return import(
      /* webpackChunkName: 'import--components-videoPlayer-videoPlayer' */ '../components/videoPlayer/videoPlayer'
    );
  },
  'components/productDetail/ProductApi': () => {
    return import(
      /* webpackChunkName: 'import--components-productDetail-ProductApi' */ '../components/productDetail/ProductApi'
    );
  },
  'components/basicAccordion/BasicAccordion': () => {
    return import(
      /* webpackChunkName: 'import--components-basicAccordion-BasicAccordion' */ '../components/basicAccordion/BasicAccordion'
    );
  },
  'components/StickyRow': () => {
    return import(/* webpackChunkName: 'import--components-StickyRow' */ '../components/StickyRow');
  },
  countdown: () => {
    return import(/* webpackChunkName: 'import--countdown' */ 'countdown');
  },
  imagesloaded: () => {
    return import(/* webpackChunkName: 'import--imagesloaded' */ 'imagesloaded');
  },
  jquery: () => {
    return import(/* webpackChunkName: 'import--jquery' */ 'jquery');
  },
  velocity: () => {
    return import(
      /* webpackChunkName: 'import--velocity' */ '../../vendor/jquery/velocity-1.1.0.min.js'
    );
  },
  'jquery.extendPrototype': () => {
    return import(
      /* webpackChunkName: 'import--jquery.extendPrototype' */ 'jquery.extendPrototype'
    );
  },
  'jquery.resizeEvents': () => {
    return import(/* webpackChunkName: 'import--jquery.resizeEvents' */ 'jquery.resizeEvents');
  },
  'jquery.scrollEvents': () => {
    return import(/* webpackChunkName: 'import--jquery.scrollEvents' */ 'jquery.scrollEvents');
  },
  'jquery.foldDetection': () => {
    return import(/* webpackChunkName: 'import--jquery.foldDetection' */ 'jquery.foldDetection');
  },
  mustache: () => {
    return import(/* webpackChunkName: 'import--mustache' */ 'mustache');
  },
  'components/hotspots': () => {
    return import(/* webpackChunkName: 'import--components-hotspots' */ 'components/hotspots');
  },
  'components/utils/positionImage': () => {
    return import(
      /* webpackChunkName: 'import--components-utils-positionImage' */ 'components/utils/positionImage'
    );
  },
  'components/contentBlocks/ScrollHint': () => {
    return import(
      /* webpackChunkName: 'import--components-contentBlocks-ScrollHint' */ 'components/contentBlocks/ScrollHint'
    );
  },
  'components/ContextMenu': () => {
    return import(
      /* webpackChunkName: 'import--components-ContextMenu' */ 'components/ContextMenu'
    );
  },
  'components/Parallax': () => {
    return import(/* webpackChunkName: 'import--components-Parallax' */ 'components/Parallax');
  },
  'components/CanvasSequence': () => {
    return import(
      /* webpackChunkName: 'import--components-CanvasSequence' */ 'components/CanvasSequence'
    );
  },
  'components/Canvas360': () => {
    return import(/* webpackChunkName: 'import--components-Canvas360' */ 'components/Canvas360');
  },
  'components/productDetail/ExplodedView': () => {
    return import(
      /* webpackChunkName: 'import--components-productDetail-ExplodedView' */ 'components/productDetail/ExplodedView'
    );
  },
  'components/ChapterNavigation': () => {
    return import(
      /* webpackChunkName: 'import--components-ChapterNavigation' */ 'components/ChapterNavigation'
    );
  },
  'createjs.preload': () => {
    return import(
      /* webpackChunkName: 'import--createjs-preload' */ 'vendor/createjs/preloadjs-0.6.2.min'
    );
  },
  'createjs.easel': () => {
    return import(
      /* webpackChunkName: 'import--createjs-easel' */ 'vendor/createjs/easeljs-0.8.1.min'
    );
  },
};

/**
 * Require/load a dependency
 * @param {strins|string[]} deps
 * @param {function} callback
 * @returns {Promise}
 */
function requireFn(deps, callback) {
  // check if single dependency. If so, look up in campaignSupport and return
  if (typeof deps === 'string' && !callback) {
    if (!campaignSupport[deps]) {
      Logger.warn(`Unknown dependency: ${deps}`);
      return undefined;
    }
    return new Promise((resolve, reject) => {
      campaignSupport[deps]
        .call()
        .then(_ => {
          if (_ && _.default) {
            resolve(_.default);
          } else if (_) {
            resolve(_);
          } else {
            resolve(true);
          }
        })
        .catch(reject);
    }).catch(reason => {
      Logger.error(reason);
      window?.newrelic?.noticeError(reason);
    });
  }

  // if deps is array, map over it
  const depz = deps.map(dep => {
    // lookup in campaignSupport
    if (campaignSupport[dep]) {
      return campaignSupport[dep].call();
    }

    // if it start with two slashes we assume it's a URL
    if (/^\/\//.test(dep)) {
      return loadAsset(dep);
    }
  });

  // load all deps and then
  return Promise.all(depz).then(packages => {
    const defaults = packages.map(_ => _ && _.default);
    if (typeof callback === 'function') {
      Logger.debug('Applying callback...');
      callback.apply(callback, defaults);
    } else {
      Logger.debug('No callback supplied after requiring array of dependencies');
    }
  });
}

window.GSRD = window.GSRD || {};
window.GSRD.load = requireFn;

function processQueue() {
  if (window.GSRD?.queue?.length) {
    let entry;
    while (((entry = window.GSRD.queue.shift()), entry)) {
      let deps = entry,
        callback;
      if (Array.isArray(entry) && typeof entry[1] === 'function') {
        deps = entry[0];
        callback = entry[1];
      }
      requestIdleCallback(() => {
        requireFn.apply(window, [deps, callback]);
      });
    }
  }
}

window.GSRD.queue.push = function (element) {
  if (arguments.length) {
    this[this.length] = element;
    processQueue();
  }
  return this.length;
};

processQueue();
