/* global AppSettings */
import createLogger from 'components/logger/Logger';
import Storage from 'components/utils/storage/Storage';
import StorageKeys from 'components/utils/storage/StorageKeys';

const Logger = createLogger('Ecommerce');

  const events = {
    EECIMPRESSIONS: 'eecImpressions',
    EECPRODUCTCLICK: 'eecProductClick',
    EECPRODUCTDETAILVIEW: 'eecProductDetailView',
  };

  const ecommerceData = {
    ecommerce: {
      currencyCode: AppSettings.currencySettings.currencyCode,
    },
    event: undefined,
    eventCallback: undefined,
    eventTimeout: undefined,
    events: {
      category: 'ecommerce',
      action: undefined,
      label: undefined,
    },
  };

  /**
   * "Deep" clone ecommerceData
   * @returns {object}
   */
  function getEcommerceData() {
  const intermediate = { ...ecommerceData };
  intermediate.events = { ...ecommerceData.events };
    return intermediate;
  }

  class Ecommerce {
    /**
     * https://g-star.atlassian.net/browse/DAN-87
     * @param {Object} eventData
     */
    static getProductImpressionData(eventData) {
      const data = getEcommerceData();
      let productsData;
      try {
        productsData = eventData.products.map(JSON.parse);
      } catch (e) {
        Logger.warn(`Unable to JSON.parse string of productData: ${e.message}`);
        return;
      }

      data.ecommerce = {
        currencyCode: AppSettings.currencySettings.currencyCode,
        impressions: {
          products: productsData,
        },
        productTileType: eventData.productTileType,
      };

      if (eventData.plpCategoryPath && eventData.plpCategoryPath !== '') {
        data.ecommerce.plpCategoryPath = eventData.plpCategoryPath;
      }

      if (eventData.plpCategoryPathIds && eventData.plpCategoryPathIds !== '') {
        data.ecommerce.plpCategoryPathIds = eventData.plpCategoryPathIds;
      }

      data.event = events.EECIMPRESSIONS;
      data.events.action = events.EECIMPRESSIONS;

      return data;
    }

    /**
     * https://g-star.atlassian.net/browse/DAN-88
     * @param {Object} eventData
     */
    static getProductClickData(eventData) {
      const data = getEcommerceData();

      data.ecommerce = {
        click: eventData.click,
        currencyCode: AppSettings.currencySettings.currencyCode,
        productTileType: eventData.productTileType,
      };

      data.event = events.EECPRODUCTCLICK;
      data.events.action = events.EECPRODUCTCLICK;
      data.eventCallback = eventData.eventCallback;

      if (eventData.eventCallback) {
        data.eventTimeout = 2000;
      }

      return data;
    }

    /**
     * https://g-star.atlassian.net/browse/DAN-89
     * @param {Object} eventData
     */
    static getProductDetailImpressionData(eventData) {
      const data = getEcommerceData();

      data.ecommerce.detail = {
        products: [eventData],
      };
      data.ecommerce.productTileType = (
        Storage.getItem(`${StorageKeys.PRODUCT_TILE_TYPE}-${eventData.materialNumber}`) || ''
      ).toLowerCase();

      data.event = events.EECPRODUCTDETAILVIEW;
      data.events.action = events.EECPRODUCTDETAILVIEW;

      Storage.removeItem(`${StorageKeys.PRODUCT_TILE_TYPE}-${eventData.materialNumber}`);

      return data;
    }

    /**
     * https://g-star.atlassian.net/browse/DAN-90
     * https://g-star.atlassian.net/browse/DAN-91
     * @param {Object} eventData
     */
    static getCartData(eventData) {
      try {
        return JSON.parse(eventData);
      } catch (response) {
        return '';
      }
    }
  }

export default Ecommerce;
