import $ from 'jquery';
  $.fn.activate = function() {
    $(this).addClass('active is-active');
    return this;
  };

  $.fn.deActivate = function() {
    $(this).removeClass('active is-active');
    return this;
  };

  $.fn.isActive = function() {
    return $(this).is('.active, .is-active');
  };

