/* global AppSettings */
/**
 * Initializes the (new) top navigation
 */
import Scroll from './Scroll';
import Search from './Search';

export default {
  initialize() {
    Scroll.initialize();
    Search.initialize();
  },
};
