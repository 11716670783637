(function($) {
  $.maxZIndex = $.fn.maxZIndex = function(opt) {
    var def = {
      inc: 10,
      group: '*'
    };

    var zmax = 0;

    $.extend(def, opt);

    document.querySelectorAll(def.group).forEach(function(el) {
      var style = window.getComputedStyle(el);
      var cur = parseInt(style.getPropertyValue('z-index'));
      if (isNaN(cur)) {
        return;
      }
      zmax = cur > zmax ? cur : zmax;
    });

    if (!this.jquery) {
      return zmax;
    }

    return this.each(function() {
      zmax += def.inc;
      $(this).css('z-index', zmax);
    });
  };
})(jQuery);
