import createLogger from './logger/Logger';
import { createFocusTrap } from 'focus-trap';

import EventTypes from 'components/EventTypes';

const Logger = createLogger('FocusTrap');

const FocusTrap = {
  initialize() {
    Logger.info('Created');

    const observer = new MutationObserver(this.checkNewNodes);
    const observerAnchorNode = document.querySelector('body');

    observer.observe(observerAnchorNode, {
      childList: true,
      subtree: true,
      characterDataOldValue: true,
      attributeFilter: ['role'],
    });
  },

  onNodeRemove(element, callback) {
    new MutationObserver((mutations, observer) => {
      if (!document.body.contains(element)) {
        observer.disconnect();
        callback();
      }
    }).observe(document.body, { childList: true, subtree: true });
  },

  activateFocusTrap(node) {
    const focusTrapOptions = {
      checkCanFocusTrap: trapContainers => {
        const results = trapContainers.map(trapContainer => {
          return new Promise(resolve => {
            const firstTabbableElement = trapContainer.querySelector('[tabindex="0"]');
            const interval = setInterval(() => {
              if (
                firstTabbableElement &&
                getComputedStyle(firstTabbableElement).visibility !== 'hidden'
              ) {
                resolve();
                clearInterval(interval);
              }
            }, 5);
          });
        });
        return Promise.all(results);
      },
    };
    const focusTrap = createFocusTrap(node, focusTrapOptions);
    focusTrap.activate();
    FocusTrap.onNodeRemove(node, focusTrap.deactivate);
    document.jq.one(EventTypes.DIALOG_SIZE_GUIDE_CLOSE, () => {
      focusTrap.deactivate();
    });
    document.jq.one(EventTypes.DIALOG_SIZE_GUIDE_SHOW, () => {
      FocusTrap.activateFocusTrap(node);
    });
  },

  checkNewNodes(mutations) {
    for (const mutation of mutations) {
      for (const node of mutation.addedNodes) {
        if (!(node instanceof HTMLElement)) continue;

        if (node.matches('[role="dialog"]')) {
          FocusTrap.activateFocusTrap(node);
          continue;
        }

        const innerNode = node.querySelector('[role="dialog"]');
        if (innerNode) {
          FocusTrap.activateFocusTrap(innerNode);
        }
      }
    }
  },
};

export default FocusTrap;
