import createLogger from './logger/Logger';
import scrollTo from './utils/scrollTo';
import EventTypes from './EventTypes';
import onWindowLoaded from './utils/onWindowLoaded';
import device from './utils/device';
import matchBreakpoint from './utils/matchBreakpoint';
import 'jquery.scrollEvents';
import 'jquery.resizeEvents';
import './domutils/Element.jQuery';

const Logger = createLogger('backToTopButton');

const BackToTopButton = {
  backToTopButton: null,
  toTopScrollDistance: 0,
  fixToBottomThreshold: 0,
  isVisible: false,
  enabled: true,
  name: 'BackToTopButton',
  isMobile: false,

  /**
   * Initialize module after page load
   */
  initialize() {
    this.isMobile = device.isMobile && !matchBreakpoint('xsmall', device.screenWidth);
    onWindowLoaded(this.onLoad, this);
  },

  /**
   * Bind events
   */
  bindEvents() {
    this.backToTopButton.addEventListener('click', this.onClick.bind(this), true);

    document.jq
      .on(EventTypes.BACK_TO_TOP_BUTTON_HIDE_REQUEST, this.setEnabled.bind(this, false))
      .on(EventTypes.BACK_TO_TOP_BUTTON_SHOW_REQUEST, this.setEnabled.bind(this, true));

    window.jq.on('scrollUpdate resizeHeightEnd', this.update.bind(this));
  },

  /**
   * Executes on button click
   * @param  {Event} event Native Event object
   */
  onClick(event) {
    event.preventDefault();
    event.stopPropagation();
    scrollTo(0);
  },

  /**
   * Executed on page load
   */
  onLoad() {
    Logger.debug('initialize');
    this.backToTopButton = document.getElementsByClassName('js-backToTopButton').item(0);

    if (this.backToTopButton) {
      if (this.isMobile) {
        try {
          document.querySelector('.js-mainContainer').removeChild(this.backToTopButton);
        } catch {}
      }
      this.update();
      this.bindEvents();
    }
  },

  /**
   * Set enabled state, if false button is hidden.
   * if true scroll position is checked.
   * @param {Boolean} enabled Enabled state of button
   */
  setEnabled(enabled) {
    this.enabled = enabled;
    this.update();
  },

  /**
   * Update visibility of back to top button
   */
  update() {
    const wasVisible = this.isVisible;
    this.isVisible = this.enabled && window.pageYOffset >= window.innerHeight / 2;

    if (wasVisible !== this.isVisible) {
      requestAnimationFrame(() => {
        this.backToTopButton.classList.toggle('is-visible', this.isVisible);
      });
    }
  },
};

export default BackToTopButton;
