/**
 * Event type constants used for base custom events
 */

export default {
  AB_TEST_OPTIMIZE_EVENT: 'msdOptimizeEvent',
  ACCORDION_TOGGLE: 'accordionToggle',
  AJAX_CONTENT_LOADED: 'ajaxContentLoaded',

  API_ERROR: 'apiError',
  API_FAIL: 'apiFail',

  ALERT_RENDER_REQUEST: 'alertRenderRequest',

  BACK_TO_TOP_BUTTON_HIDE_REQUEST: 'backToTopButtonHideRequest',
  BACK_TO_TOP_BUTTON_SHOW_REQUEST: 'backToTopButtonShowRequest',

  BREAKPOINT_CHANGED: 'breakpointChanged',

  CAROUSEL_LOADED: 'carouselLoaded',
  CAROUSEL_PAUSED: 'carouselPaused',
  CAROUSEL_READY: 'carouselReady',
  CAROUSEL_RESUMED: 'carouselResumed',
  CAROUSEL_SLIDE_CHANGE: 'carouselSlideChange',

  CART_ADD: 'addToCart',
  CART_ADD_ITEM_COMPLETE: 'addToBagResponseComplete',
  CART_ADD_ITEM_FAIL: 'addToBagResponseError',
  CART_ADD_ITEM_REQUEST: 'addToBag',
  CART_ADD_ITEM_START: 'addToBagStarted',
  CART_ADD_ITEM_SUCCESS: 'addToBagResponseSuccess',
  CART_ADD_VOUCHER_INVALID: 'addVoucherInValid',
  CART_ADD_VOUCHER_VALID: 'addVoucherValid',
  CART_EMPTY: 'emptyBag',
  CART_FETCH_COMPLETE: 'fetchCartFromServerCompleted',
  CART_FETCH_FAIL: 'fetchCartFromServerFailed',
  CART_FETCH_REQUEST: 'fetchCartFromServer',
  CART_FETCH_START: 'fetchCartFromServerStarted',
  CART_FETCH_SUCCESS: 'fetchCartFromServerSucceeded',
  CART_MODIFY_ITEM_START: 'basketItemUpdate',
  CART_REMOVE: 'removeFromCart',
  CART_REMOVE_ITEM_FAIL: 'removeFromBagFailed',
  CART_REMOVE_ITEM_REQUEST: 'pleaseRemoveProductFromBag',
  CART_REMOVE_ITEM_START: 'removeFromBag',
  CART_REMOVE_ITEM_SUCCESS: 'removedFromBag',
  CART_VIEW_HIDE_START: 'hideFoldOutShoppingCart',
  CART_VIEW_SHOW_START: 'showFoldOutShoppingCart',
  CART_VIEW_UPDATE_COMPLETE: 'basketUpdate',
  CART_INTERACTION_START: 'cartInteractionStart',

  COMMON_DATA_READY: 'commonDataReady',

  COOKIE_NOTICE_HIDE: 'cookieNoticeHide',
  COOKIE_NOTICE_SHOW: 'cookieNoticeShow',

  COMPLETE_THE_LOOK_DISPLAYED: 'completeTheLookDisplayed',
  COMPLETE_THE_LOOK_NO_PRODUCTS_FOUND: 'completeTheLookNoProductsFound',
  COMPLETE_THE_LOOK_START: 'completeTheLookStart',

  CSRF_RECEIVE: 'receiveCsrf',
  CSRF_REQUEST: 'requestCsrf',
  CSRF_TOKEN_ERROR: 'csrfToken.error',

  DEFERRED_INITIALIZE_COMPLETE: 'deferredInitializeCompleted',

  DESTROY: 'destroy',

  DIALOG_CLOSE: 'dialogClose',
  DIALOG_CLOSE_REQUEST: 'dialogCloseRequest',
  DIALOG_CLOSED: 'dialogClosed',
  DIALOG_LOADED: 'dialogLoaded',
  DIALOG_PAGE_LAYER_SHOW: 'showLayer',
  DIALOG_SET_FOCUS: 'dialogSetFocus',
  DIALOG_SHOW: 'dialogShow',
  DIALOG_PAGE_LAYER_INIT_TRIGGERS: 'initializeLayerTriggers',

  DIALOG_SIZE_GUIDE_SHOW: 'dialogSizeGuideShow',
  DIALOG_SIZE_GUIDE_CLOSE: 'dialogSizeGuideClose',

  DIRECTIONS_SHOW_OVERLAY: 'directionsShowOverlay',
  DIRECTIONS_HIDE_OVERLAY: 'directionsHideOverlay',
  DIRECTIONS_ROUTE_DISPLAYED: 'directionsRouteDisplayed',
  DIRECTIONS_ROUTE_NOT_FOUND: 'directionsRouteNotFound',
  DIRECTIONS_PLACES_CHANGED: 'directionsPlaceChanged',

  EMAIL_VALIDATION_SUGGESTION: 'emailValidationSuggestionTrigger',

  EXPLODED_VIEW_ANIMATION_COMPLETE: 'explodedViewAnimationComplete',
  EXPLODED_VIEW_START_ANIMATION_REQUEST: 'explodedViewStartAnimationRequest',
  EXPLODED_VIEW_RESET_ANIMATION_REQUEST: 'explodedViewResetAnimationRequest',

  FIT_IN_SCREEN_HEIGHT: 'fitInScreenHeight',

  FITGUIDE_INIT_REQUEST: 'fitGuideInitRequest',

  FORM_ELEMENT_ERROR_HIDE: 'formElementErrorHide',
  FORM_ELEMENT_ERROR_SHOW: 'formElementErrorShow',
  FORM_FIELD_ERROR: 'formFieldError',
  FORM_GENDER_VALIDATE: 'formGenderValidate',
  FORM_INPUT_ERROR_SHOWN: 'formInputErrorShown',
  FORM_VALIDATED: 'formValidated',

  HOTSPOTS_FORCE_ENABLE: 'hotspotsForceEnable',

  INITIALIZE_VISIBLE_CHECKBOX_ACCORDION: 'initializeVisibleCheckboxAccordion',
  JAVASCRIPT_ERROR: 'javascriptError',
  LAZY_LOADED: 'lazyLoaded',

  LIVECHAT_BUTTONS_CLICKED: 'livechatButtonsClicked',

  LOCALE_LANGUAGE_CHANGED: 'localeLanguageChanged',
  LOCALE_SELECTOR_HIDE: 'localeSelectorHide',
  LOCALE_SELECTOR_SHOW: 'localeSelectorShow',
  LOCALE_SELECTOR_RENDERED: 'localeSelectorRendered',

  LOGIN: 'login',
  LOGOUT: 'logout',

  LOGGER_TIMING: 'loggerTiming',
  LOOKBOOK_PAGE_RENDERED: 'lookbookPageRendered',

  NOTIFICATION_VIEW_HIDE: 'notificationHide',
  NOTIFICATION_VIEW_SHOW: 'notificationShow',

  PAGESHOW: 'pageshowSticky' /* Do not rename to `pageshow`, will interfere with native event */,
  PAGESHOW_FROM_CACHE: 'pageshowFromCache',
  PAGE_VIEWS_COMPLETE: 'pageViewsComplete',

  PDP_IMAGE_FAILED_LOADING: 'failedLoadingImage',
  PDP_THUMBNAIL_FAILED_LOADING: 'failedLoadingThumbnail',
  PDP_THUMBNAIL_SET_ACTIVE: 'setActiveThumbnail',

  PICTURE_SEQUENCE_PAUSE_REQUEST: 'pictureSequencePauseRequest',
  PICTURE_SEQUENCE_PLAY_REQUEST: 'pictureSequencePlayRequest',
  PICTURE_SEQUENCE_RESTART_REQUEST: 'pictureSequenceRestartRequest',
  PICTURE_SEQUENCE_SEEK_REQUEST: 'pictureSequenceSeekRequest',
  PICTURE_SEQUENCE_STOP_REQUEST: 'pictureSequenceStopRequest',
  PICTURE_SEQUENCE_UPDATE_REQUEST: 'pictureSequenceUpdateRequest',

  PLP_FILTER_BAR_APPENDED: 'filterBarIsAppended',
  PLP_FILTER_BAR_CREATED: 'stickyFilterCreated',
  PLP_FILTER_BAR_DETACHED: 'filterBarStartDetach',
  PLP_FILTER_BAR_SHOW: 'filterBarShow',
  PLP_FILTER_COLLAPSED: 'productListerFilterCollapsed',
  PLP_FILTER_DESELECTED: 'productListerFilterDeselected',
  PLP_FILTER_EXPANDED: 'productListerFilterExpanded',
  PLP_FILTER_OVERLAY_HIDE: 'productListerFiltersOverlayHide',
  PLP_FILTER_OVERLAY_SHOW: 'productListerFiltersOverlayShow',
  PLP_FILTER_SELECTED: 'productListerFilterSelected',
  PLP_FILTERS_RESET: 'productListerFiltersReset',
  PLP_FILTERS_TRIGGERED_SCROLL: 'productListerFiltersTriggeredScroll',
  PLP_FITGUIDE_INITIALISED: 'fitguide-initialised',

  PLP_FACET_RENDERED: 'productListerFacetRendered',

  PLP_LOAD_PAGE_BEFORE_RENDER: 'productListerLoadPageBeforeRender',
  PLP_LOAD_PAGE_FAIL: 'productListerLoadPageFailed',
  PLP_LOAD_PAGE_RENDER: 'productListerLoadPageRendered',
  PLP_LOAD_PAGE_REQUEST: 'productListerLoadPageRequest',
  PLP_LOAD_PAGE_RESULT_COUNT_UPDATE: 'productListerLoadPageResultCountUpdate',
  PLP_LOAD_PAGE_START: 'productListerLoadPageStart',
  PLP_LOAD_PAGE_SUCCESS: 'productListerLoadPageSuccess',
  PLP_LOAD_PAGE_RENDERED: 'productListerLoadPageRendered',

  PRE_SUBMIT_HOOKS_DONE: 'PreSubmitHooksDone',

  PARALLAX_REPOSITION: 'parallaxReposition',

  PRODUCT_FORM_ENABLED: 'productFormEnabled',
  PRODUCT_FORM_INVALID_SIZE: 'invalidProductSizeSelection',
  PRODUCT_FORM_SCARCITY_NOTIFICATION_REQUEST: 'showScarcityMessageNotificationRequest',
  PRODUCT_FORM_SIZE_AVAILABLE: 'productSizeAvailable',
  PRODUCT_FORM_SIZE_SELECTED: 'productSizeSelected',
  PRODUCT_FORM_SIZE_SELECT: 'productSizeSelect',
  PRODUCT_FORM_BROADCAST_SIZE_SELECTED: 'productBroadcastSizeSelected',
  PRODUCT_FORM_REQUEST_SIZE_SELECTED: 'productRequestSizeSelected',
  PRODUCT_FORM_SIZERANGE_TYPE_AVAILABLE: 'productSizeRangeAvailable',
  PRODUCT_FORM_REQUEST_INFO_UPDATE: 'productFormRequestInfoUpdate',
  PRODUCT_FORM_UPDATE_SIZE_SELECTION_COMPLETE: 'productFormUpdateSizeSelectionComplete',
  PRODUCT_DETAILS_REQUEST_INFO_UPDATE: 'productDetailsRequestInfoUpdate',
  PRODUCT_DETAILS_REQUEST_SELECTION_INFO_UPDATE: 'productDetailsRequestSelectionInfoUpdate',
  PRODUCT_PRICE_UPDATE_REQUEST: 'productPriceUpdateRequest',
  PRODUCT_STOCK_AVAILABILITY: 'productStockAvailability',
  PRODUCT_USPS: 'productUsps',
  PRODUCT_DETAILS_RECALCULATE_CAROUSEL_HEIGHT: 'pdpRecalcCarouselHeight',
  PRODUCT_DETAILS_COLOR_SELECTOR_CLICK: 'pdpColorSelectorClick',

  PROMOVIEW_SCROLL: 'promoviewScroll',

  QUICK_SHOP_BIND_TRIGGER: 'bind-gstar-quick-shop-triggers',
  QUICK_SHOP_SHOW_REQUEST: 'quickShopShowRequest',
  QUICK_SHOP_INITIALIZE_REQUEST: 'quickShopInitializeRequest',
  QUICK_SHOP_SHOW: 'quickShopShow',

  READY_FOR_SECONDARY_ASSETS: 'readyForSecondaryAssets',

  RECENTLY_VIEWED_PRODUCTS_ADD_REQUEST: 'addRecentlyViewedItem',
  RECENTLY_VIEWED_PRODUCTS_REMOVE_REQUEST: 'removeRecentlyViewedItem',
  RECENTLY_VIEWED_PRODUCTS_RENDER_REQUEST: 'renderRecentlyViewedItem',
  RECENTLY_VIEWED_PRODUCTS_RENDER_SUCCESS: 'recentlyViewedItemRendered',
  RECENTLY_VIEWED_PRODUCTS_NO_RENDER: 'recentlyViewedItemNoRender',
  RECENTLY_VIEWED_PRODUCTS_NO_PRODUCTS_FOUND: 'recentlyViewedItemNoProductsFound',
  RECENTLY_VIEWED_PRODUCTS_REMOVE_CLICK_EVENT: 'click.removeFromRecentlyViewedStore',
  RECENTLY_VIEWED_PRODUCTS_LIST_UPDATED: 'recentlyViewedProductsListUpdated',

  REATTACH_DISCLAIMER_LISTENER: 'reattachDisclaimerListener',
  RENDER_QUBIT_RECOMMENDATION_CAROUSEL: 'renderQubitRecommendationCarousel',
  RENDER_QUBIT_RECOMMENDATION_CAROUSEL_COMPLETE: 'completeRenderingQubitRecommendationCarousel',
  RENDER_QUBIT_RVP_CAROUSEL: 'renderQubitRVPCarousel',
  RENDER_RECOMMENDATION_CAROUSEL_COMPLETE: 'completeRenderingRecommendationCarousel',
  RVP_QUBIT_REMOVE_COMPONENT_REQUEST: 'removeRVPQubitComponent',
  RVP_QUBIT_REMOVE_ITEM_REQUEST: 'removeRVPQubitItem',

  SCALE_ELEMENT_IN_CONTAINER_COMPLETE: 'scaleElementInContainerComplete',
  SESSION_UPDATE_REQUIRED: 'sessionRefresh',

  SHIPPING_ESTIMATE_REQUEST: 'shippingEstimateRequest',
  PICK_UP_TODAY_REQUEST: 'pickUpTodayRequest',

  SIDENAV_ANIMATION_COMPLETE: 'sideNavAnimationComplete',
  SIDENAV_ANIMATION_PROGRESS: 'sideNavAnimationProgress',
  SIDENAV_ANIMATION_START: 'sideNavAnimationStart',
  SIDENAV_HIDE: 'sideNavHide',
  SIDENAV_HIDE_REQUEST: 'sideNavHideRequest',
  SIDENAV_SHOW: 'sideNavShow',
  SIZE_GUIDE_INITIALIZATION_COMPLETE: 'sizeGuideInitializationComplete',

  SUBSCRIPTION_DIALOG_READY: 'subscriptionDialogReady',

  SLIDE_CAROUSEL_INITIALIZE_REQUEST: 'slideCarouselInitializeRequest',
  SLIDE_CAROUSEL_RENDER_REQUEST: 'slideCarouselRenderRequest',

  STICKY_ATB_ON_PDP_RENDER_REQUEST: 'stickyAddToBagRenderRequest',

  STOCKLOCATOR_CACHE_SIZE_REQUEST: 'stocklocatorCacheSizeRequest',
  STOCKLOCATOR_TOGGLE_MESSAGING: 'stocklocatorToggleMessaging',
  STOCK_NOTIFICATION_LOADED: 'stockNotificationLoaded',

  STOREFINDER_DISTANCE_UPDATE: 'storeFinderDistanceUpdate',
  STOREFINDER_DATA_STORES_UPDATED: 'storeFinderDataStoresUpdated',
  STOREFINDER_GET_STORES: 'storeFinderGetStores',
  STOREFINDER_GET_STORES_REQUEST: 'storeFinderStoresRequest',
  STOREFINDER_GET_USER_LOCATION: 'storeFinderGetUserLocation',
  STOREFINDER_GET_USER_LOCATION_ERROR: 'storeFinderGetUserLocationError',
  STOREFINDER_GET_USER_LOCATION_REQUEST: 'storeFinderGetUserLocationRequest',
  STOREFINDER_GOT_STORES_DATA: 'storeFinderGetStoresData',
  STOREFINDER_HIDE_FILTERS: 'storeFinderHideFilters',
  STOREFINDER_HIDE_FILTERS_REQUEST: 'storeFinderShowHideRequest',
  STOREFINDER_HIDE_LIST_REQUEST: 'storeFinderHideListRequest',
  STOREFINDER_HIDE_MAP_REQUEST: 'storeHideMapRequest',
  STOREFINDER_INITIALIZED: 'storeFinderInitialized',
  STOREFINDER_LOAD_STORES: 'storeFinderLoadStores',
  STOREFINDER_LOAD_STORES_BY_COORDINATES: 'storeFinderLoadStoresByCoordinates',
  STOREFINDER_MAP_BOUNDS_CHANGED: 'storeFinderBoundsChanged',
  STOREFINDER_MAP_FAILED: 'storeFinderMapFailed',
  STOREFINDER_MAP_INITIALIZED: 'storeFinderMapInitialized',
  STOREFINDER_MAP_STORES_UPDATED: 'storeFinderMapStoresUpdated',
  STOREFINDER_MARKER_CLICK: 'storeFinderMarkerClick',
  STOREFINDER_MARKER_DBLCLICK: 'storeFinderMarkerDblclick',
  STOREFINDER_MARKER_MOUSEOUT: 'storeFinderMarkerMouseOut',
  STOREFINDER_MARKER_MOUSEOVER: 'storeFinderMarkerMouseOver',
  STOREFINDER_SEARCH_LOCATION_BUTTON_CLICKED: 'storeFinderSearchLocationButtonClicked',
  STOREFINDER_SEARCH_PLACES_CHANGED: 'storeFinderSearchPlacesChanged',
  STOREFINDER_SEARCH_SUBMIT: 'storeFinderSearchSubmit',
  STOREFINDER_SHOW_FILTERS: 'storeFinderShowFilters',
  STOREFINDER_SHOW_FILTERS_REQUEST: 'storeFinderShowFiltersRequest',
  STOREFINDER_SHOW_LIST: 'storeFinderShowList',
  STOREFINDER_SHOW_LIST_REQUEST: 'storeFinderShowListRequest',
  STOREFINDER_SHOW_MAP_REQUEST: 'storeShowMapRequest',
  STOREFINDER_STORE_DESELECTED: 'storeFinderStoreDeselected',
  STOREFINDER_STORE_DETAILS_CLOSE: 'storeFinderStoreDetailsHiide',
  STOREFINDER_STORE_DETAILS_CLOSE_REQUEST: 'storeFinderStoreDetailsHideRequest',
  STOREFINDER_STORE_DETAILS_OPEN: 'storeFinderStoreDetailsOpen',
  STOREFINDER_STORE_DETAILS_OPEN_REQUEST: 'storeFinderStoreDetailsOpenRequest',
  STOREFINDER_STORE_SELECTED: 'storeFinderStoreSelected',
  STOREFINDER_STORE_BUTTON_CLICKED: 'storeFinderStoreButtonClicked',
  STOREFINDER_STORES_FILTER_REQUEST: 'storeFinderStoresFilterRequest',
  STOREFINDER_STORES_FILTERED: 'storeFinderStoresFiltered',
  STOREFINDER_SUGGESTION_FOUND: 'storeFinderSuggestionFound',

  SUGGESTED_SEARCH_RESULTS_HIDDEN: 'suggestedSearchResultsHidden',
  SUGGESTED_SEARCH_RESULTS_RENDERED: 'suggestedSearchResultsRendered',
  SUGGESTED_SEARCH_RESULTS_VISIBLE: 'suggestedSearchResultsVisible',

  TIMELINE_INITIALIZED: 'timelineInitialized',
  TIMELINE_CLICK: 'timelineClick',
  TIMELINE_ENABLE_REQUEST: 'timelineEnableRequest',
  TIMELINE_DISABLE_REQUEST: 'timelineDisableRequest',

  TOPNAV_ANIMATION_COMPLETE: 'topNavAnimationComplete',
  TOPNAV_ANIMATION_START: 'topNavAnimationStart',
  TOPNAV_HIDE: 'topNavHide',
  TOPNAV_ITEM_CLICK: 'topNavItemClick',
  TOPNAV_SHOW_REQUEST: 'topNavShowRequest',
  TOPNAV_SCROLL_PAUSE_REQUEST: 'topNavPauseRequest',
  TOPNAV_SCROLL_RESUME_REQUEST: 'topNavResumeRequest',
  TOPNAV_SCROLL_ENABLE_REQUEST: 'topNavEnableRequest',
  TOPNAV_SHOW: 'topNavShow',
  TOPNAV_HIDE_REQUEST: 'topNavHideRequest',
  TOPNAV_HIDE_OFFSET_UPDATED: 'topNavHideOffsetUpdated',
  TOPNAV_HIDE_OFFSET_REQUEST: 'topNavHideOffsetRequest',

  UPDATE_USER_INFO: 'updateUserInfo',
  UPDATE_USER_INFO_DONE: 'updateUserInfoDone',
  UPDATE_FIBER_RANKING_PRODUCT_TILE_STICKER: 'updateFiberRankingProductTileSticker',

  VALIDATE_BIRTHDATE: 'validateBirthdate',

  VIDEO_ADJUST_SIZE: 'adjustSize',
  VIDEO_COMPONENT_INITIALIZED: 'videoInitialized',
  VIDEO_MUTE_REQUEST: 'videoMuteRequest',
  VIDEO_UNMUTE_REQUEST: 'videoUnmuteRequest',
  VIDEO_MUTED: 'videoMuted',
  VIDEO_UNMUTED: 'videoUnmuted',
  VIDEO_PAUSE: 'videoPause', // rename this to a request event
  VIDEO_PLAY: 'videoPlay', // rename this to a request event
  VIDEO_PLAYER_READY: 'videoPlayerReady',
  VIDEO_PLAYBACK_STARTED: 'videoPlaybackStarted',
  VIDEO_PLAYBACK_PAUSED: 'videoPlaybackPaused',
  VIDEO_PLAYBACK_STOPPED: 'videoPlaybackStopped',
  VIDEO_PLAYING: 'videoPlaying',
  VIDEO_REPLAY_REQUEST: 'videoReplayRequest',
  VIDEO_SEEK_REQUEST: 'videoSeekRequest',
  VIDEO_STOP_REQUEST: 'videoStopRequest',
  VIDEO_LOADED_DATA: 'videoLoadedData',
  VIDEO_TIME_UPDATE: 'videoOnProgress',

  WISHLIST_ADD_ITEM_REQUEST: 'wishlistAddItem',
  WISHLIST_REMOVE_ITEM_REQUEST: 'wishlistRemoveItem',
  WISHLIST_BIND_TRIGGER: 'wishlistBindTrigger',
  WISHLIST_MODAL_HIDE_START: 'showWishlistModal',
  WISHLIST_MODAL_SHOW_START: 'hideWishlistModal',
  WISHLIST_MODAL_RERENDER: 'rerenderWishlistModal',
  WISHLIST_UPDATE_ITEMS: 'wishlistUpdate',

  ZOOM_HIDE: 'zoomHide',
  ZOOM_SHOW: 'zoomShow',
};
