/**
 * Util for creating factories.
 *
 * Read comments for defaultSettings to see the options.
 *
 * @author Meinaart van Straalen
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import LogLevels from 'components/logger/LogLevels';
import getFunctionName from 'components/utils/getFunctionName';
import 'jquery.extendPrototype';
import '../domutils/Element.jQuery';

const Logger = createLogger('Factory');

let factoryCounter = 0;
const emptyFactory = {
  attachTo() {},
  initialize() {},
};

/**
 * Factory constructor
 * @param {function} Component  Component that should be initialized
 * @param {object}   settings   Settings for Factory
 */
function Factory(Component, settings) {
  this.Component = Component;
  this.settings = $.extend(true, {}, this.defaultSettings, settings || {});
  this.settings.componentName = getComponentName(this.Component);

  factoryCounter++;
  if (Logger.shouldLog(LogLevels.TRACE)) {
    Logger.trace('Created for', this.settings.componentName);
  }
}

$.extendPrototype(Factory, {
  _instantiateAfterEventBound: false,
  _instantiateAfterEventDispatched: false,
  _numInstance: 0,
  _initialized: false,
  defaultSettings: {
    // Add $elements containing matched elements to settings object for each instance
    // @type Boolean
    addElementsToSettings: false,

    // Add numInstance property to settings object of instance
    // @type Boolean
    addnumInstanceToSettings: true,

    // Add collection selector to settings object for each instance
    // @type Boolean
    addSelectorToSettings: false,

    // If set it's used as the default selector when factory.initialize is called.
    // @type String
    defaultSelector: undefined,

    // Returns an object for instance settings,
    // useful if it contains dynamic values that can change
    // over the course of instance creation
    // @type Function
    getInstanceSettings: undefined,

    // Do we need to create an instance per DOM element (true) or one instance for whole collection (false)?
    // @type Boolean
    instancePerDOMElement: true,

    // Create instances after receiving event
    // @type String
    instantiateAfterEvent: undefined,

    // Listen to instantiateAfterEvent on this element
    // @type Object
    instantiateAfterEventScope: document,

    // If set to true event is bound on factory itself and it is tracked when event has dispatched
    // @type Boolean
    instantiateAfterEventStateful: true,

    // If set it will be used for (performance) logging
    // @type components/logger/Logger
    logger: undefined,

    // Called after first instance is created
    // @type Function
    onFirstInstanceCreated: undefined,

    // Is called after the first `attachTo` call, even when there are no matching elements
    // @type Function
    onInitialize: undefined,

    // Object with as a key a selector and as value another Component
    // for when element matches selector
    // @type Object
    selectorComponents: undefined,

    // If true the element is always wrapped in a jQuery object
    // @type Boolean
    useJQuery: true,
  },

  /**
   * Create components for collection of dom elements.
   * @param  {mixed} selector   Can be string with selector or jQuery object
   * @param  {object} settings  Settings for component.
   * @return {Promise}  jQuery deferred object
   */
  attachTo(selector, settings) {
    return new Promise((resolve, reject) => {
      settings = $.extend(true, {}, settings);

      if (this.settings.addSelectorToSettings) {
        settings.selector = selector;
      }

      // Add listener to instantiateAfterEvent when instantiateAfterEventStateful is true
      if (
        this.settings.instantiateAfterEvent &&
        this.settings.instantiateAfterEventStateful &&
        !this._instantiateAfterEventDispatched &&
        !this._instantiateAfterEventBound
      ) {
        this.settings.instantiateAfterEventScope.jq.one(
          this.settings.instantiateAfterEvent,
          this.handleCreateAfterEvent.bind(this)
        );

        this._instantiateAfterEventBound = true;
      }

      if (!this._initialized) {
        this._initialized = true;
        if (this.settings.onInitialize) {
          this.settings.onInitialize.call(this, this);
        }
      }

      const frameCallback = this.createInstances.bind(this, selector, settings, resolve);
      requestAnimationFrame(frameCallback);
    }).catch(reason => {
      Logger.error(reason);
      window?.newrelic?.noticeError(reason);
    });
  },

  /**
   * Create new instance of Component for specified DOM element.
   * @param  {Element} element DOMElement
   * @param  {object} settings Object containing settings for component.
   */
  createInstance(element, settings) {
    if (this.instanceAllowed(element)) {
      const ElementComponent = this.getComponent(element);

      if (this.settings.addnumInstanceToSettings) {
        settings.numInstance = this._numInstance;
      }

      if (typeof this.settings.getInstanceSettings === 'function') {
        settings = $.extend(true, settings, this.settings.getInstanceSettings());
      }

      const instance = new ElementComponent(element, settings);

      if (element instanceof Element) {
        element.__factory[this.settings.componentName] = instance;
      } else {
        element.toArray().forEach(
          function (el) {
            el.__factory[this.settings.componentName] = instance;
          }.bind(this)
        );
      }

      if (this._numInstance === 0 && this.settings.onFirstInstanceCreated) {
        this.settings.onFirstInstanceCreated.call(this, this);
      }

      if (Logger.shouldLog(LogLevels.TRACE)) {
        Logger.trace('Created an instance of', this.settings.componentName, 'for', element);
      }
      this._numInstance++;
    }
  },

  createjQueryInstances(selector) {
    const elements = document.querySelectorAll(selector);
    return Array.from(elements).map(el => el.jq);
  },

  /**
   * Create instances
   * @param  {mixed} selector   Selector or jQuery Object or NodeList or Element or HTMLCollection
   * @param  {object} settings  Instance settings
   * @param  {function} resolve jQuery Deferred object that should be resolved with processed elements
   * @return {object}           Processed elements
   */
  createInstances(selector, settings, resolve) {
    if (this.settings.logger) {
      this.settings.logger.time('createInstances');
    }
    let elements;

    if (this.settings.useJQuery) {
      if (typeof selector === 'string') {
        elements = this.createjQueryInstances(selector);
      } else if (selector instanceof $) {
        elements = selector;
      } else {
        try {
          elements = selector.jq;
        } catch (exception) {
          Logger.warn(`Unknown selector type: ${selector.toString()}`);
          elements = $(selector);
        }
      }
    } else if (selector instanceof $) {
      elements = selector.toArray();
    } else {
      const matches = selector.match(/^.([a-z0-9-]+)/i);
      if (matches && matches[0] === selector) {
        elements = document.getElementsByClassName(matches[1]);
      } else {
        elements = document.querySelectorAll(selector);
      }
    }

    if (elements.length) {
      if (this.settings.addElementsToSettings) {
        if (this.settings.useJQuery) {
          settings.$elements = Array.from(elements).map(el => el.jq);
        } else {
          settings.elements = elements;
        }
      }

      if (this.settings.instancePerDOMElement) {
        if (elements instanceof Element) {
          elements = [elements];
        } else if (!Array.isArray(elements)) {
          elements = Array.from(elements);
        }

        elements.forEach(this.processElement.bind(this, settings));
      } else {
        this.processElement(settings, elements);
      }

      if (this.settings.logger) {
        this.settings.logger.timeEnd('createInstances');
      }
    }

    resolve(elements);
  },

  /**
   * Return Component for DOM element
   * @param  {Element} element DOMElement
   * @return {Function}        Component
   */
  getComponent(element) {
    if (!(element instanceof Element)) {
      element = element.toArray()[0];
    }

    if (this.settings.selectorComponents) {
      for (const componentSelector in this.settings.selectorComponents) {
        if (element.matches(componentSelector)) {
          return this.settings.selectorComponents[componentSelector];
        }
      }
    }

    return this.Component;
  },

  /**
   * Event listener for `instantiateAfterEvent`
   */
  handleCreateAfterEvent() {
    this._instantiateAfterEventDispatched = true;
  },

  /**
   * Initialize calls `factory.attachTo` with the `this.settings.defaultSelector`.
   * @param  {string} selector Selector (if ommitted, this.settings.defaultSelector is used)
   * @param  {object} settings Settings object
   * @return {mixed}           Result from `this.attachTo`
   */
  initialize(selector, settings) {
    return this.attachTo(selector || this.settings.defaultSelector, settings);
  },

  /**
   * Check if new instance is allowed for this element.
   *
   * @param  {Element} element DOM Element
   * @return {Boolean}         Is new instance allowed
   */
  instanceAllowed(element) {
    let elements;
    if (element instanceof Element) {
      elements = [element];
    } else {
      elements = element.toArray();
      element = elements[0];
    }

    elements.forEach(function (el) {
      if (!el.__factory) {
        Object.defineProperty(el, '__factory', {
          value: {},
        });
      }
    });

    if (!element) {
      throw new Error('element not defined');
    }

    return element.__factory[this.settings.componentName] === undefined;
  },

  /**
   * Create an instance per DOM element, callback method for `$.each`.
   *
   * @param  {object}       settings Instance settings
   * @param  {DOMElement}   element  Element
   */
  processElement(settings, element) {
    if (this.settings.useJQuery) {
      element = element instanceof $ ? element : $(element);
    }

    if (this.settings.instantiateAfterEvent && !this._instantiateAfterEventDispatched) {
      this.settings.instantiateAfterEventScope.jq.one(
        this.settings.instantiateAfterEvent,
        this.createInstance.bind(this, element, settings)
      );
    } else {
      this.createInstance(element, settings);
    }
  },
});

/**
 * Return name of Component
 * @param  {Object}   module          RequireJS module Object
 * @param  {Function} Component       Component for which to get the name
 * @param  {Boolean}  returnDefault   Return a default name (default: true)
 * @return {string}                   Name of component
 */
function getComponentName(Component, returnDefault) {
  if (returnDefault === undefined) {
    returnDefault = true;
  }
  const defaultName = `FactoryModule${factoryCounter}`;
  const name = getFunctionName(Component, returnDefault ? defaultName : undefined);
  if (!name || name === defaultName) {
    Logger.fatal(
      'getFunctionName: function has no name (please use "function name() {" instead of "var name = function() {")',
      Component
    );
  }

  return name;
}

export default {
  create(Component, settings) {
    if (!Component) {
      return emptyFactory;
    }
    return new Factory(Component, settings || {});
  },

  /**
   * Removes stored reference to component in data of DOMElement
   *
   * @param  {Object} instance    Instance which should be removed
   * @param  {Element} element DOMElement
   * @return {Boolean}            True if element is found
   */
  destroyInstance(instance, element) {
    const componentName = getComponentName(instance.constructor, false);
    if (componentName) {
      if (Logger.shouldLog(LogLevels.TRACE)) {
        Logger.trace(componentName, 'Instance destroyed for', element);
      }

      if (element.__factory) {
        delete element.__factory[componentName];
      }
    } else {
      Logger.warn('Component name cannot be found for:', instance);
    }

    return false;
  },
};
