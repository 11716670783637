/* eslint-disable no-new */
/* global AppSettings, AppState, newrelic */
/**
 * For each entrypoint that is the main entry point of a pageContext,
 * the function baseInitialize of this file should be imported and called.
 *
 * For any entry point that is a feature on its own but does not depend on
 * base.js, this is not needed. (e.g. fitguide.js)
 */
import $ from 'jquery';
import '../../g-star/loadAsset';
import '../../g-star/requireGsap';
import EventTypes from '../components/EventTypes';
import CommonData from '../components/common/CommonData';
import createLogger from '../components/logger/Logger';
import Navigation from '../components/navigation/Base';
import SideNavigation from '../components/sidenavigation/Base';
import LocaleSelector from '../components/localeSelector';
import BasketData from '../components/basket/BasketData';
import CookieNotice from '../components/cookieNotice';
import BdgCookie from '../components/bdgCookie';
import CheCookie from '../components/cheCookie';
import abdetCookie from '../components/abdetCookie';
import StarterCookie from '../components/starterCookie';
import SuggestedSearch from '../components/suggestedSearch/SuggestedSearch';
import ToggleSearch from '../components/toggleSearch/ToggleSearch';
import '../components/basket/FoldoutCache';
import FoldoutView from '../components/basket/FoldoutView';
import InputRestriction from '../components/inputRestriction/InputRestriction';
import FooterSubscriptionForm from '../components/footer/SubscriptionForm';
import FooterAccordion from '../components/footer/FooterAccordion';
import onWindowLoaded from '../components/utils/onWindowLoaded';
import elementToggle from '../components/elementToggle';
import Alert from '../components/Alert';
import CsrfUtils from '../components/utils/CsrfUtils';
import Accessibility from '../components/utils/Accessibility';
import NotificationMessages from '../components/NotificationMessages';
import NewSession from '../components/endlessAisle/session/NewSession';
import '../components/utils/storage/Storage';
import StorageKeys from '../components/utils/storage/StorageKeys';
import Flyout from '../components/Flyout';
import ContextData from '../components/analytics/ContextData';
import LoginForm from '../components/LoginForm';
import LivechatButtons from '../components/livechatButtons';
import PaymentMethods from '../components/footer/PaymentMethods';
import UspScroll from '../components/uspBanner/UspScroll';

import 'imagesloaded';
import 'jquery.utils';
import 'vmouse';
import 'swipe';
import Analytics from '../components/analytics/Analytics';
import '../components/analytics/Exponea';
import '../components/ErrorHandler';
import '../components/lazyLoading/DelayedLoading';
import '../components/utils/SessionRefresher';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'jquery.pageshowEvents';
import '../components/domutils/Element.jQuery';
import CookieDialog from '../components/dialogs/dialog--cookie';
import SubscriptionDialog from '../components/dialogs/dialog--subscriptionFormCTA';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cookie';
import './campaignSupport';
import FocusTrap from '../components/FocusTrap';

window.GSRD = window.GSRD || {};

const Logger = createLogger('base');
let isInitialized = false;

/**
 * Initialize components on first `requestIdleCallback` after page is loaded
 * (or after max timeout specified in `onWindowLoaded`).
 */
function initialize2() {
  CheCookie.initialize();
  CsrfUtils.initialize();
  new Flyout();

  if (AppState.headerShoppingBagEnabled !== false) {
    if (AppSettings.isEndlessAisleSession) {
      FoldoutView.initialize({
        hideDelay: 5000,
      });
    } else {
      FoldoutView.initialize();
    }
  }

  // eslint-disable-next-line no-unused-expressions
  !AppSettings.cookieDialog.enableWall && CookieNotice.initialize();
  BdgCookie.initialize();
  new StarterCookie();

  abdetCookie.initialize();

  const quickshopSelector = [
    '.js-quick-shop-link:not([data-quick-shop-listener])',
    '.js-quickShopProduct:not([data-quick-shop-listener])',
  ].join(', ');

  const initQuickShop = () => {
    import(
      /* webpackChunkName: "component--quickShopProductListener" */ 'components/productDetail/quickShopProductListener'
    ).then(module => {
      module.default.initialize();
    });
  };

  if (
    document.querySelector(quickshopSelector) ||
    document.querySelector('.js-recentlyViewedProducts')
  ) {
    initQuickShop();
  }

  document.jq.one(EventTypes.QUICK_SHOP_INITIALIZE_REQUEST, initQuickShop);

  // load when wishlist enabled on site level: AppSettings.wishlistEnabled and header component exists
  const loadWishlistHeader =
    AppSettings?.wishlistEnabled && document.querySelector('.js-wishlistViewHeaderTrigger');
  if (loadWishlistHeader) {
    import(
      /* webpackChunkName: "component--WishlistHeader" */ 'components/wishlist/WishlistHeader'
    );
  }

  // load on large devices when wishlist enabled on site level
  const loadWishlistModal =
    loadWishlistHeader && !AppSettings?.isMobile && document.getElementById('react-wishlist-modal');
  if (loadWishlistModal) {
    import(/* webpackChunkName: "component--WishlistModal" */ 'components/wishlist/WishlistModal');
  }

  const wishlistSelector = '.js-wishlist-cta';
  const carouselSelector = '.js-slideCarousel';
  const loadWishlist =
    document.querySelector(wishlistSelector) ||
    document.querySelector(quickshopSelector) ||
    document.querySelector(carouselSelector);
  if (loadWishlist) {
    import(
      /* webpackChunkName: "component--WishlistHandler" */ 'components/wishlist/WishlistHandler'
    );
  }

  document.jq.on(EventTypes.RENDER_QUBIT_RECOMMENDATION_CAROUSEL, (event, eventData) => {
    import(
      /* webpackChunkName: "component--WishlistHandler" */ 'components/wishlist/WishlistHandler'
    );
  });

  elementToggle.attachTo('[data-toggle]');

  if (document.querySelectorAll('.countdown-container').length) {
    import(
      /* webpackChunkName: "component--countdown-Countdown" */ 'components/countdown/Countdown'
    ).then(module => {
      const Countdown = module.default;
      Countdown.initialize();
    });
  }

  if (AppSettings.audioEyeEnabled) {
    import(/* webpackChunkName: "component--AudioEye" */ 'components/AudioEye');
  }

  const privacyPolicyPopupRegExp = new RegExp(StorageKeys.PRIVACY_POLICY_POPUP);
  if (
    /logged-in/.test(document.documentElement.className) &&
    /homepage|checkout/.test(document.body.className) &&
    privacyPolicyPopupRegExp.test(document.cookie)
  ) {
    // Load React code
    window.GSRD.loadAsset(
      `${window.location.origin}${AppSettings.assetPath}js/react/myAccount--v2.js`
    );
  }

  if (
    /page-myAddressAdd/.test(document.body.className) ||
    /page-myAddressEdit/.test(document.body.className)
  ) {
    window.GSRD.loadAsset(
      `${window.location.origin}${AppSettings.assetPath}js/react/myAddresses.js`
    );
  }
}

/**
 * Initialize components on second `requestAnimationFrame` after page is loaded
 * (or after max timeout specified in `onWindowLoaded`).
 */
function initialize3() {
  const searchV2Enabled = document.querySelector('[data-search-V2-enabled]');

  if (AppSettings.suggestedSearchEnabled) {
    if (searchV2Enabled) {
      window.GSRD.loadAsset(
        `${window.location.origin}${AppSettings.assetPath}js/react/suggestedSearch--v2.js`
      );
    } else {
      SuggestedSearch.initialize();
      new ToggleSearch({
        container: '.js-topNavigation',
        selector: '.js-toggleSearch',
      });
    }
  }

  new Analytics();
  FooterAccordion.initialize();
  FooterSubscriptionForm.attachTo('.js-newsletterForm');
  PaymentMethods.lazyLoad();
  new LocaleSelector('.js-localeSelector');
  Alert.initialize();
  InputRestriction.initialize();
  Accessibility.initialize();
  LivechatButtons.initialize();
  UspScroll.initialize();

  if (document.querySelector('.js-notificationMessagesTrigger')) {
    NotificationMessages.initialize();
  }

  const previewBar = document.querySelectorAll('.hac-preview-panel');
  if (previewBar) {
    import(
      /* webpackChunkName: "components--amplience-AmpliencePreviewBar" */ '../components/amplience/AmpliencePreviewBar'
    ).then(module => {
      module.default.initializeStateful();
    });
  }

  if (AppSettings.usabillaUrl) {
    import(/* webpackChunkName: "component--usabilla" */ 'components/vendor/usabilla');
  }

  const defaultSelector = `.js-mainContainer form[data-controlled], .js-footer form[data-controlled]`;

  if (document.querySelector(defaultSelector)) {
    import(/* webpackChunkName: "import--FormControls" */ '../components/FormControls').then(
      module => {
        module.default.initialize();
      }
    );
  }

  window.jq.on('resizeEnd', window.updateDocumentElement);

  if ('Performance' in window && typeof window.performance?.mark === 'function') {
    onWindowLoaded(() => {
      performance.mark('gs-js-end');

      const info = [
        ['html', 'gs-html-start', 'gs-html-end'],
        ['css', 'gs-css-start', 'gs-css-end'],
        ['js', 'gs-js-start', 'gs-js-end'],
        ['DOMContentLoaded', undefined, 'gs-domcontentloaded'],
        ['onload', undefined, 'gs-onload'],
      ];
      const aggregate = info.reduce((acc, entry) => {
        try {
          const entryData = performance.measure(...entry);
          acc[entryData.name] = entryData.duration;
        } catch {}
        return acc;
      }, {});

      Logger.table(aggregate);

      if (window.newrelic) {
        newrelic.finished();
        newrelic.addPageAction('gs-perf-data', aggregate);
      }
    });
  }
}

/**
 * Initialize components that should be initialized as quickly as possible
 */
function initialize() {
  Navigation.initialize();
  SideNavigation.initialize();
  BasketData.initialize();
  LoginForm.initialize();
  FocusTrap.initialize();

  if (shouldShowCookieWall()) {
    document.documentElement.classList.add('has-cookiewall');
    new CookieDialog(AppSettings.cookieDialog);
  } else {
    SubscriptionDialog.triggerWhenReady();
  }

  document.jq.on(EventTypes.RENDER_QUBIT_RECOMMENDATION_CAROUSEL, (event, eventData) => {
    import(
      /* webpackChunkName: "component--RecommendationsQubit" */ '../components/RecommendationsQubit'
    ).then(loadedModule => {
      const RecommendationsQubit = loadedModule.default;
      new RecommendationsQubit(eventData);
    });
  });

  document.jq.on(EventTypes.RENDER_QUBIT_RVP_CAROUSEL, (event, eventData) => {
    import(
      /* webpackChunkName: "component--RecentlyViewedProductsQubit" */ '../components/RecentlyViewedProductsQubit'
    ).then(loadedModule => {
      const RecentlyViewedProductsQubit = loadedModule.default;
      new RecentlyViewedProductsQubit(eventData);
    });
  });

  // Delay initialization of some components
  setTimeout(initialize2, 0);
  onWindowLoaded(initialize3, window, true);

  if (AppSettings.isEndlessAisleSession) {
    NewSession.initialize();
  }
}

export default function baseInitialize() {
  if (isInitialized) {
    return;
  }
  if ('Performance' in window && typeof performance?.mark === 'function') {
    window.addEventListener(
      'DOMContentLoaded',
      performance.mark.bind(performance, 'gs-domcontentloaded')
    );
    window.addEventListener('load', performance.mark.bind(performance, 'gs-onload'));
  }

  // noop fn against "Can't find variable: _AutofillCallbackHandler" errors
  window._AutofillCallbackHandler = () => {};

  isInitialized = true;

  $.ajaxSetup({
    cache: false,
  });

  new ContextData();
  CommonData.initialize();
  SubscriptionDialog.initialize();

  setTimeout(() => {
    initialize();
  }, 1000 / 60);
}

/**
 * Check criteria for if the cookie wall should be shown
 * @returns {boolean}
 */
function shouldShowCookieWall() {
  if (!AppSettings.cookieDialog.enableWall) {
    return false;
  }
  const hasProperConsentCookie = !/[^0-1]+/.test($.cookie(StorageKeys.COOKIE_CONSENT_WALL));
  if (hasProperConsentCookie) {
    return false;
  }
  const onCookieStatementPage = /cookie-statement/.test(window.location.pathname);
  const onPrivacyPolicyPage = /privacy-policy/.test(window.location.pathname);
  if (onCookieStatementPage || onPrivacyPolicyPage) {
    return false;
  }
  return true;
}
