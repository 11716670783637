/**
 * Will extract the queryParameters form the search part of an url
 * returns an object with the query params
 *
 * @param {string} searchUrl the URL queryparameters including the '?' at the
 * beginning if present (window.location.search)
 */
const extractFromSearchUrl = (searchUrl) => {
  const queryParams = {};

  if (!searchUrl || !searchUrl.length) {
    return queryParams;
  }

  const params = searchUrl.substring(1).split('&');

  params.forEach((param) => {
    const [key, value] = param.split('=');
    queryParams[key] = value;
  });

  return queryParams;
};

export default {
  extractFromSearchUrl,
};
