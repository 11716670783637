
  /**
   * Make a property of an object lazy, which means it's looked up when it's needed (only once).
   *
   * @param {Object}    scope      Object to add lazy listener to
   * @param {String}    name       Name, for example "$submitButton"
   * @param {Function}  getValue   Function that returns the value
   */
  export default function defineLazyProperty(scope, name, getValue) {
    Object.defineProperty(scope, name, {
      configurable: true,
      get() {
        const value = getValue.call(scope);
        Object.defineProperty(scope, name, {
          configurable: true,
          value,
        });
        return value;
      },
      writeable: false,
    });

    return scope;
  };

