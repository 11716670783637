import matchBreakpoint from '../utils/matchBreakpoint';

const ANIMATION_DURATION = 600;
const MESSAGE_DISPLAY_TIME = 4000;
const USP_BAR_LIST_SELECTOR = '.uspBar-list';
const USP_BANNER_SELECTOR = '.uspBar-item';

class UspScroll {
  constructor() {
    this.animationStartTime = 0;
    this.availableBanners = document.querySelectorAll(USP_BANNER_SELECTOR);
  }

  initialize() {
    const { uspScrollEnabled = false } = window.AppSettings;

    if (uspScrollEnabled) {
      this.handleAnimation();
    }
  }

  /**
   * Create styles for animation according to passed parameters
   */
  createAnimationStyles() {
    const fullCycleTime = ANIMATION_DURATION + MESSAGE_DISPLAY_TIME + ANIMATION_DURATION;
    const appearEndKeyframe = Math.floor((100 / fullCycleTime) * ANIMATION_DURATION);
    const disappearStartKeyframe = 100 - appearEndKeyframe;
    const animationStyles = document.createElement('style');
    document.head.appendChild(animationStyles);

    animationStyles.insertAdjacentHTML(
      'beforeend',
      `@keyframes activeAnimation {
        0% {
          opacity: 0;
          transform: translateX(-400px);
        }

        ${appearEndKeyframe}% {
          opacity: 1;
          transform: translateX(0px);
        }

        ${disappearStartKeyframe}% {
          opacity: 1;
          transform: translateX(0px);
        }

        100% {
          opacity: 0;
          transform: translateX(400px);
        }
      }

      .uspBar .active {
          display: inline-block;
          animation: activeAnimation ${fullCycleTime}ms ease-in-out;
      }`
    );
  }

  /**
   * Check the conditions for creating and running animation and perform these actions
   */
  handleAnimation() {
    const isMobile =
      Boolean(window?.AppSettings?.isMobile || window?.AppState?.isMobile) && !matchBreakpoint('xsmall');
    const uspBarList = document.querySelector(USP_BAR_LIST_SELECTOR);

    const uspBannerObserver = new MutationObserver(() => {
      this.availableBanners = document.querySelectorAll(USP_BANNER_SELECTOR);
      this.availableBanners.forEach((banner, index) => {
        if (index === 0) {
          banner.classList.add('active');
        } else {
          banner.classList.remove('active');
        }
      });
    });

    if (uspBarList) {
      uspBannerObserver.observe(uspBarList, { childList: true });
    }

    if (isMobile && uspBarList && this.availableBanners.length > 1) {
      uspBarList.classList.add('scrollable');
      this.availableBanners[0].classList.add('active');

      this.createAnimationStyles();

      requestAnimationFrame(timestamp => {
        this.animationStartTime = timestamp || Date.now();
        this.animateUspBar(timestamp);
      });
    }
  }

  /**
   * Run animation cycle of usp banners
   * @param timestamp
   * @param banners
   */
  animateUspBar(timestamp) {
    const currentTimeStamp = timestamp || Date().now();
    const runtime = currentTimeStamp - this.animationStartTime;

    if (runtime > MESSAGE_DISPLAY_TIME + ANIMATION_DURATION * 2) {
      const lastBannerIndex = this.availableBanners.length - 1;
      for (let i = 0; i <= lastBannerIndex; i++) {
        if (this.availableBanners[i].classList.contains('active')) {
          this.availableBanners[i].classList.toggle('active');
          i = i === lastBannerIndex ? 0 : ++i;
          this.availableBanners[i].classList.toggle('active');
          break;
        }
      }
      this.animationStartTime = currentTimeStamp;
    }

    requestAnimationFrame(timestamp => {
      this.animateUspBar(timestamp);
    });
  }
}

export default new UspScroll();
