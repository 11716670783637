/* global jQuery */
/**
 * Module that activates menu items for ajax loaded content.
 *
 * Listens to EventTypes.AJAX_CONTENT_LOADED event.
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
import EventTypes from '../EventTypes';
import '../domutils/Element.jQuery';

const $document = document.jq;

const AjaxActivateLinks = function () {};

/**
 * Find active link in supplied content and activate link with same href
 * in current document.
 * @param  {object} $content jQuery object containing loaded content
 * @param  {string} selector jQuery selector to find links
 * @param  {boolean} onIndex  When set to true the link with the same index is set to active.
 * @param {String} contentSelector Selector on content to find items
 */
AjaxActivateLinks.prototype.activateLink = function ($content, selector, onIndex, contentSelector) {
  if (contentSelector) {
    $content = $content.find(contentSelector);
  }
  const $activeLinks = $content.find(`${selector}.is-active`);
  const $links = (contentSelector ? $(contentSelector).find(selector) : $(selector)).removeClass(
    'is-active'
  );

  if ($activeLinks.length) {
    $activeLinks.each(function (index, element) {
      if (element.id) {
        const link = document.getElementById(element.id);
        if (link) {
          link.classList.add('is-active');
        }
      } else if (onIndex) {
        const $activeLink = $(element);
        $links.eq($activeLink.index()).addClass('is-active');
      } else {
        $links.filter(`[href="${element.href}"]`).addClass('is-active');
      }
    });
  }
};

/**
 * Bind events
 */
AjaxActivateLinks.prototype.bindEvents = function () {
  $document.on(EventTypes.AJAX_CONTENT_LOADED, this.onAjaxContentLoaded.bind(this));
};

/**
 * Initialize component
 */
AjaxActivateLinks.prototype.initialize = function () {
  this.bindEvents();
};

/**
 * Event listener for EventTypes.AJAX_CONTENT_LOADED event
 * @param  {object} e    jQuery event object
 * @param  {object} data Data object related to event
 */
AjaxActivateLinks.prototype.onAjaxContentLoaded = function (e, data) {
  if (data && data.response) {
    this.processContent(data.response);
  }
};

/**
 * Check supplied content for links and activate them in current document
 * @param  {string} content HTML content in string format (although jQuery object would also work)
 */
AjaxActivateLinks.prototype.processContent = function (content) {
  if (content) {
    // Make sure content contains html string
    content = content instanceof jQuery ? content.html().toString() : content.toString();

    // Check if content contains "topNavigation-mainLinks-item" to see if it content has navigation items
    if (content.indexOf('topNavigation-mainLinks-item') !== -1) {
      const $content = $(content);

      // Update top bar links
      requestAnimationFrame(
        this.activateLink.bind(this, $content, '.js-topNavigation-mainLinks-item', true)
      );

      // Activate side navigation links
      requestAnimationFrame(this.activateLink.bind(this, $content, '[id]', false, '.js-sideNav'));
    }
  }
};

export default new AjaxActivateLinks();
