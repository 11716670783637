/**
 * Call callback when load event of window is fired (all assets are fully loaded).
 *
 * This also works when document is already loaded when this function is called.
 * https://web.dev/rail/#idle:-maximize-idle-time
 */
import requestIdleCallback from './requestIdleCallback';

const DEFAULT_TIMEOUT = 2000;

/**
 * Call callback when window has loaded
 * @param  {Function} callback  Method to call
 * @param  {Object}   scope     Scope to call method in
 * @param  {Int=}     [timeout=2000]   If supplied the callback is executed after max specified amount of ms
 * @return {Boolean}            Indicating if window was already loaded
 */
export default function onWindowLoaded(callback, scope, timeout) {
  if (timeout === true) {
    timeout = DEFAULT_TIMEOUT;
  }

  if (timeout && isNaN(timeout)) {
    throw new Error('timeout is not a number');
  }

  const scopeType = scope ? typeof scope : undefined;
  if (scopeType && scopeType !== 'object' && scopeType !== 'function') {
    throw new Error(`scope has wrong type: ${scopeType}`);
  }

  const isLoaded = document.readyState === 'complete';
  let timeoutId;

  const listener = function onWindowLoadedListener() {
    requestIdleCallback(() => {
      if (scope) {
        callback.call(scope);
      } else {
        callback();
      }
    });

    if (document.readyState !== 'complete') {
      window.removeEventListener('load', listener);
    }

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
  };

  if (isLoaded) {
    listener();
  } else {
    window.addEventListener('load', listener);

    if (timeout) {
      timeoutId = setTimeout(listener, timeout);
    }
  }

  return isLoaded;
}
