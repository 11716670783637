/**
 * Dispatches `scrollUpdate` event when scroll position has changed.
 *
 * Events are called within a `requestAnimationFrame` callback.
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
import 'jquery.extendPrototype';

const ScrollUpdate = function () {
  this.initializeEvent('scrollStart');
  this.initializeEvent('scrollUpdate');
  this.initializeEvent('scrollEnd');
};

$.extendPrototype(ScrollUpdate, {
  /**
   * Initialize jQuery event
   * @param  {String} eventType jQuery event type
   */
  initializeEvent(eventType) {
    const _this = this;
    $.event.special[eventType] = {
      /**
       * Called by jQuery when the first listener for this event is bound
       */
      setup() {
        const $element = $(this);
        const data = {
          eventType,
          running: false,
          element: this,
          $element,
        };

        $element.data(eventType, data).on(`scroll.${eventType}`, _this.onScroll.bind(_this, data));
      },

      /**
       * Called by jQuery when the last listener for this event is unbound
       */
      teardown() {
        const $elem = $(this);
        const data = $elem.data(eventType);

        if (data) {
          $elem.off(`scroll.${eventType}`).removeData(eventType);
        }
      },
    };
  },

  /**
   * Called on `scroll` event
   * @param  {jQuery.Event} event jQuery event object
   */
  onScroll(event) {
    const scheduler = window.requestAnimationFrame;
    const { eventType } = event;
    const { $element } = event;
    const { scrollId } = event;

    if (event.running) {
      return;
    }

    if (!event.scrolling) {
      if (eventType === 'scrollStart') {
        scheduler($element.trigger.bind($element, 'scrollStart'));
      }
      event.scrolling = true;
    }

    if (eventType === 'scrollStart' || eventType === 'scrollEnd') {
      if (scrollId) {
        cancelAnimationFrame(scrollId);
      }

      event.scrollId = requestAnimationFrame(this.onScrollEnd.bind(this, event));
    }

    if (eventType === 'scrollUpdate') {
      event.running = true;
      scheduler(this.onUpdate.bind(this, event));
    }
  },

  /**
   * Triggered when user is done scrolling
   * @param  {Object} data Object containing scroll properties
   */
  onScrollEnd(data) {
    const { eventType } = data;
    delete data.scrollId;
    data.scrolling = false;
    data.running = false;

    if (eventType === 'scrollEnd') {
      data.$element.trigger(eventType);
    }
  },

  /**
   * Checks if scroll position has changed since last call and if so dispatches `scrollUpdate` event.
   *
   * @param  {Object} data Object containing `running`, `scrollX` and `scrollY` properties
   */
  onUpdate(data) {
    const elem = data.element;
    const scrollX = elem === window ? window.pageXOffset : elem.scrollLeft;
    const scrollY = elem === window ? window.pageYOffset : elem.scrollTop;

    if (data.scrollX !== scrollX || data.scrollY !== scrollY) {
      data.scrollX = scrollX;
      data.scrollY = scrollY;

      data.$element.trigger(data.eventType);
    }

    data.running = false;
  },
});

export default new ScrollUpdate();
