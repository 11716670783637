/**
 * Markup of this chat button is provided by third party code (Salesforce livechat agent)
 * so this component serves only for triggering events for analytics vendors
 */

import createLogger from './../logger/Logger';
import EventTypes from './../EventTypes';
import onWindowLoaded from './../utils/onWindowLoaded';

const Logger = createLogger('helpButton');

const HelpButton = {
  helpButton: null,

  /**
   * Initialize module after page load
   */
  initialize() {
    onWindowLoaded(this.onLoad, this, 10);
  },

  /**
   * Bind events
   */
  bindEvents() {
    this.helpButton.addEventListener('click', this.onClick.bind(this));
  },

  /**
   * Executes on button click
   * @param  {Event} event Native Event object
   */
  onClick() {
    document.jq.trigger(EventTypes.LIVECHAT_BUTTONS_CLICKED);
  },

  /**
   * Executed on page load
   */
  onLoad() {
    Logger.debug('initialize');
    this.helpButton = document.getElementsByClassName('gs-js-chat-init').item(0);

    if (this.helpButton) {
      this.bindEvents();
    }
  },
};

export default HelpButton;
