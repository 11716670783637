/**
 * Helper for singleton pattern.
 *
 * It's used for components that should be initialized by another component.
 *
 * Self initializing components should just return an object or an instance of itself.
 *
 * Usage:
 * ```
 * import Singleton from 'components/utils/Singleton';
 *
 * function Component(settings) {
 * }
 *
 * export default Singleton.create(Component, 'Component');
 * ```
 *
 * @author Meinaart van Straalen
 */
import createLogger from 'components/logger/Logger';
import getFunctionName from 'components/utils/getFunctionName';

const Logger = createLogger('Singleton');

/**
 * Singleton object
 * @param {function} Component
 */
function Singleton(Component, componentName) {
  this.componentName = componentName;
  this.Component = Component;

  Logger.trace('Created for', this.componentName);
}

Singleton.prototype.instance = undefined;
Singleton.prototype.Component = undefined;
Singleton.prototype.componentName = undefined;

/**
 * Initialize component within this singleton
 * @param  {object} settings Object with settings
 * @return {object}          Instance of component
 */
Singleton.prototype.initialize = function () {
  const args = Array.from(arguments);
  args.unshift(this.Component);

  if (!this.instance) {
    this.instance = new (Function.prototype.bind.apply(this.Component, args))();
    Logger.trace('Created instance of', this.componentName);
  }

  return this.instance;
};

// Making sure there is one singleton object per component
const singletonsSet = {};

export default {
  /**
   * Create a Singleton for supplied component. Ensuring that only one instance can ever be created.
   * @param  {function} Component Component that should only have one instance.
   * @return {object}             Singleton object with an `initialize` method to initialize the component.
   */
  create(Component, fallbackName) {
    const componentName = fallbackName || getFunctionName(Component, Component);
    let singleton = singletonsSet[componentName];

    if (!singleton) {
      singleton = singletonsSet[componentName] = new Singleton(Component, componentName);
    }
    return singleton;
  },
};
