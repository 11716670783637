/**
 * Utils for the CSRFToken.
 *
 * Refresh the page when an Ajax call receives a 403 due to invalid csrf-token
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import EventTypes from 'components/EventTypes';
import createLogger from 'components/logger/Logger';

const Logger = createLogger('CsrfUtils');
const csrfTokenMessage = 'Bad or missing CSRF Token';

Logger.debug('Initialize CsrfUtils');

/**
 * Bind events for this module
 */
function initialize() {
  document.jq.on(EventTypes.CSRF_TOKEN_ERROR, handleCsrfTokenError);
}

/**
 * Reload page when AJAX request receives a 403 Bad or missing CSRF Token response
 * from the server.
 */
function handleCsrfTokenError(event, eventData) {
  const jsonResponse = JSON.parse(eventData);

  if (`${jsonResponse.statusCode}` === '403' && jsonResponse.message === csrfTokenMessage) {
    window.location.reload();
  }
}

/**
 * Promise wrapper around 2 events for retrieving the csrf token
 * @returns {Promise<string>} a promise resolving to a string containing the csrf token
 */
export function getCSRFToken() {
  return new Promise((resolve, reject) => {
    document.jq.one(EventTypes.CSRF_RECEIVE, (event, csrfToken) => {
      if (!csrfToken) {
        return reject('No csrfToken received');
      }
      resolve(csrfToken);
    });
    document.jq.trigger(EventTypes.CSRF_REQUEST);
  }).catch(reason => {
    Logger.error(reason);
    window?.newrelic?.noticeError(reason);
  });
}

export default {
  initialize,
};
