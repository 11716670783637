/**
 * Shows a Dialog with overlay
 * @author Tim van Oostrom  <timvanoostrom@gmail.com>
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import device from 'components/utils/device';
import keyboardInput from 'components/utils/keyboardInput';
import 'jquery.extendPrototype';
import 'jquery.resizeEvents';
import 'components/domutils/Element.jQuery';
import requestIdleCallback from '../utils/requestIdleCallback';

const Logger = createLogger('dialog');

let instanceCounter = 0;
const dialogHtml = `<div role="dialog" aria-labelledby="dialog-title" aria-modal="true" class="dialog js-dialog js-dialogOverlay is-loading is-hidden is-showing"><div class="dialog-contentWrapper js-dialog-contentWrapper"><a class="dialog-closeButton js-closeDialog" tabindex="0" aria-label="${window.labels.quickshop.closeOverlay}"></a><div class="dialog-content js-dialog-content"></div></div></div>`;

/**
 * Toggle CSS classes for styling (dialog) backgrounds
 */
function handleOverlayBackgrounds(options) {
  // hides the overlay backgrounds of all dialogs but the one on top
  const $allDialogs = $('.js-dialogOverlay');
  $allDialogs.filter(':not(:first)').addClass('no-overlay');

  const $lastVisibleDialog = $allDialogs
    .filter('.is-showing,.is-positioned:not(.is-hidden)')
    .first();
  $lastVisibleDialog.removeClass('no-overlay');

  const hasVisibleDialogs = !!$allDialogs.filter('.is-showing,:not(.is-hidden)').length;

  document.body.classList.toggle('has-activeDialog', hasVisibleDialogs);

  if (options.preventBackgroundScroll === true) {
    document.documentElement.classList.toggle('has-preventBackgroundScroll', hasVisibleDialogs);
  }

  toggleActiveClass(options.isFullScreenOnMobile, hasVisibleDialogs);
}

function toggleActiveClass(isFullScreenOnMobile, add) {
  if (isFullScreenOnMobile && device.isMobile && !matchBreakpoint('xsmall', device.screenWidth)) {
    document.body.classList.toggle('has-activeFullScreenDialog', add);
  }
}

function Dialog(options) {
  this.options = $.extend({}, this.options, options);
  Logger.measure(this, ['show', 'closeDialog']);

  this.eventNamespace = `.dialog${instanceCounter}`;
  instanceCounter++;
}

$.extendPrototype(Dialog, {
  $closeButton: undefined,
  $dialog: undefined,
  $dialogContent: undefined,
  $dialogContentWrapper: undefined,
  dialogDeferred: undefined,
  hasTopNavigation: $('.js-topNavigation').length !== 0,
  pendingAsyncRequest: undefined,
  windowOffset: 0,
  options: {
    //required ID
    id: undefined,

    //the classNames of the dialog
    className: 'dialog--default',

    //close the dialog only when clicking the closeButton. Otherwise also close dialog when clicking on overlay.
    closeDialogButtonOnly: false,

    // If true close button is appended to content instead of it's default location
    // handy for autosizing dialogs.
    closeButtonInContent: true,

    //url for a remote html page. *not crossdomain compatible*
    url: undefined,

    // align the dialog content in the center of the page
    vAlignMiddle: true,

    // custom html contents, *cannot* be used in conjunction with url
    contents: '',

    // after ajax response is loaded, search for the given selector in the html fragment
    filterResponseSelector: undefined,

    // just hide the dialog, don't destroy it.
    hideOnClose: false,

    // tries to restore scroll position on closing
    scrollOnClose: true,

    //css dependencies for the the loaded content.
    cssDependencies: [],

    //requirejs modules
    jsDependencies: undefined,

    // disallow "natural" scrolling of the background.
    // false Will result in double scrollbars when the dialog content exceeds the height of the window
    preventBackgroundScroll: true,

    // open this dialog fullscreen on mobile devices
    isFullScreenOnMobile: true,

    // whether a transitionend event should be triggered
    hasTransition: false,
  },

  setFocus(container) {
    if (!container) {
      return;
    }

    const elements = container.querySelectorAll(
      'a[href], area[href], button, input:not([type="hidden"]), select, textarea, [tabindex="0"]:not(.js-detail-back)'
    );
    const visualElements = [...elements].filter(
      element =>
        window.getComputedStyle(element).display !== 'none' &&
        window.getComputedStyle(element).visibility !== 'hidden' &&
        element.disabled !== true
    );

    if (!visualElements.length) {
      return;
    }

    visualElements[0].focus();
  },

  show() {
    const dialogDeferred = $.Deferred();
    const dialogDependencies = [];
    let jsModules = [];

    this.focusedElementBeforeOpen = document.activeElement;
    this.windowOffset = window.pageYOffset;

    if (!this.$dialog) {
      this.$dialog = this.createHtml();
      this.dialog = this.$dialog.get(0);

      this.bindEvents();

      if (!matchBreakpoint('xsmall')) {
        this.windowOffset = window.pageYOffset;
      }

      let cssDepLength = this.options.cssDependencies.length;
      const jsDepLength = typeof this.options.jsDependencies === 'function';

      if (cssDepLength) {
        const cssRequireArguments = [];
        const cssDeferred = $.Deferred();
        let cssFile = '';

        while (cssDepLength--) {
          cssFile = this.options.cssDependencies[cssDepLength];
          cssRequireArguments.push(`${cssFile}`);
        }

        cssRequireArguments.forEach((linkPath, idx, arr) => {
          const isPresent = document.querySelector(`[href*="${linkPath}.css"]`);
          if (!isPresent) {
            const linkElement = document.createElement('link');
            linkElement.href = `${linkPath}.css`;
            linkElement.rel = 'stylesheet';
            document.head.appendChild(linkElement);
          }
          if (idx === arr.length - 1) {
            cssDeferred.resolve();
          }
        });

        dialogDependencies.push(cssDeferred);
      }

      if (jsDepLength) {
        const jsDeferred = $.Deferred();
        const jsDependencies = this.options.jsDependencies;

        jsDependencies().then(scripts => {
          const dependencies = Array.isArray(scripts) ? scripts : [scripts];

          dependencies.forEach(script => jsModules.push(script.default));
          jsDeferred.resolve();
        });

        dialogDependencies.push(jsDeferred);
      }

      if (this.options.url) {
        const htmlDeferred = this.loadUrlInDialogAsync(this.options.url);

        htmlDeferred.fail(errorResponse => {
          this.loadDomContents(errorResponse);
          this.$dialog &&
            this.$dialog
              .removeClass('is-showing')
              .removeClass('is-hidden')
              .trigger(EventTypes.DIALOG_SHOW, this.options);
        });

        dialogDependencies.push(htmlDeferred);
      }

      $.when.apply(this, dialogDependencies).then(() => {
        if (!this.options.url) {
          this.loadDomContents();
        }

        this.positionDialogContent(this.dialog);

        this.dialog.classList.remove('is-showing');
        this.dialog.classList.remove('is-hidden');
        this.dialog.jq.trigger(EventTypes.DIALOG_SHOW, this.options);

        window.setTimeout(() => {
          this.dialog.classList.remove('is-loading');
          this.setFocus(this.dialog);
        }, 100);

        dialogDeferred.resolve(this, jsModules);

        return dialogDeferred;
      });
    } else {
      this.positionDialogContent(this.dialog);
      this.$dialog.addClass('is-showing');
      window.setTimeout(() => {
        this.$dialog
          .removeClass('is-showing')
          .removeClass('is-hidden')
          .removeClass('is-loading')
          .trigger(EventTypes.DIALOG_SHOW, this.options);
      }, 0);
    }

    return dialogDeferred;
  },

  createHtml() {
    if (!this.$dialog) {
      const id = `dialog-${this.options.id}`;
      const dialogHtmlStructure = document.createElement('div');
      dialogHtmlStructure.innerHTML = dialogHtml;
      const dialog = dialogHtmlStructure.querySelector('.dialog');

      let dialogsContainerElement = document.getElementById('dialogsContainer');

      if (dialogsContainerElement === null) {
        dialogsContainerElement = $('<div/>').attr({ id: 'dialogsContainer' });
        dialogsContainerElement.appendTo(document.body);
      }

      dialog.id = id;
      dialog.jq.addClass(this.options.className);
      dialogHtmlStructure.jq.prependTo(dialogsContainerElement);

      if (dialog.classList.contains('js-dialog--cookie')) {
        dialog.style.zIndex = 1e8 + 1;
      } else {
        const zIndexConfig = {
          group: '*:not(.js-dialog--cookie)',
          inc: document.documentElement.classList.contains('has-cookiewall') ? 0 : 10,
        };
        dialog.jq.maxZIndex(zIndexConfig);
      }

      if (matchBreakpoint('xsmall') && dialog.classList.contains('has-absolutePosition')) {
        dialog.style.height = `${document.documentElement.scrollHeight}px`;
      }

      this.$dialog = dialog.jq;
      this.$dialogContentWrapper = dialog.jq.find('.js-dialog-contentWrapper');
      this.$dialogContent = this.$dialogContentWrapper.find('.js-dialog-content');
      this.$closeButton = dialog.jq.find('.js-closeDialog');
    }

    return this.$dialog;
  },

  bindEvents() {
    let timerId;

    document.jq
      .on(
        EventTypes.DIALOG_CLOSE_REQUEST + this.eventNamespace,
        this.onDialogCloseRequest.bind(this)
      )
      .on(
        EventTypes.DIALOG_SET_FOCUS,
        (event, eventData) => () => requestIdleCallback(() => this.setFocus(eventData))
      );

    this.onResize = () => this.positionDialogContent(this.dialog);
    window.jq.on('resizeEnd', this.onResize);

    this.$dialog
      .on(EventTypes.DIALOG_CLOSE_REQUEST + this.eventNamespace, this.close.bind(this))
      .on(
        EventTypes.CAROUSEL_READY + this.eventNamespace,
        this.positionDialogContent.bind(this, this.dialog)
      );

    // click anywhere outside the dialog to close it
    if (this.options.closeDialogButtonOnly) {
      // only close the dialog by clicking the x close button
      this.$dialog.on(
        `click${this.eventNamespace}`,
        '.js-closeDialog',
        this.closeDialogTrigger.bind(this)
      );
    } else {
      this.$dialog
        .on(`click${this.eventNamespace}`, this.closeDialogTrigger.bind(this))
        .on(
          'keyup',
          '.js-closeDialog',
          keyboardInput.handleKeys.bind(this, 'enter', this.closeDialogTrigger)
        );
    }

    if (!device.hasTouch) {
      window.jq.on(`resizeUpdate${this.eventNamespace}`, () => {
        if (this.$dialogContent) {
          if (timerId) {
            clearTimeout(timerId);
          }
          timerId = setTimeout(() => {
            this.positionDialogContent(this.dialog);
            timerId = undefined;
          }, 100);
        }
      });
    }

    // added this event due to iOS with their fancy browser chrome disappearing tricks
    window.jq.on(
      `orientationchange${this.eventNamespace}`,
      this.positionDialogContent.bind(this, this.dialog)
    );

    this.$dialog.on(EventTypes.DIALOG_SHOW + this.eventNamespace, () => {
      if (this.options.hasTransition) {
        this.$dialog.one('transitionend', () => {
          handleOverlayBackgrounds(this.options);
        });
      } else {
        handleOverlayBackgrounds(this.options);
      }
    });
  },

  /**
   * Handler for global event DIALOG_CLOSE_REQUEST
   * @param  {jQuery.Event} event
   * @param  {Object} eventData If supplied the dialog is only closed when `id` property matches dialog's id
   */
  onDialogCloseRequest(event, eventData) {
    if (eventData && eventData.id === this.options.id) {
      this.close();
    }
  },

  /**
   * Calls function on 'transitionend' event if dialog has enabled transition
   * @param {Function} action
   * @returns {void}
   */
  handleAnimationAwareAction(action) {
    if (this.options.hasTransition) {
      toggleActiveClass(this.options.isFullScreenOnMobile, false);
      this.$dialog.one('transitionend', action);
    } else {
      action();
    }
  },

  closeDialogTrigger(event) {
    const $target = event.target.jq;

    if (this.$dialog && $target?.is('.js-dialog-contentWrapper, .js-closeDialog, .js-dialog')) {
      //only react to the close event
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (this.options.hideOnClose) {
        this.hideDialog();
      } else {
        this.closeDialog();
      }
    }
  },

  close() {
    // alias for closeDialog
    this.closeDialog();
  },

  closeDialog() {
    if (this.$dialog) {
      this.$dialog.addClass('is-hiding');

      const closeDialogTrigger = () => {
        this.$dialog.addClass('is-hidden');
        this.$dialog.removeClass('is-hiding');
        this.$dialog.trigger(EventTypes.DIALOG_CLOSE, { id: this.options.id });

        if (this.options.type === 'quickshop') {
          this.$dialog.trigger(AnalyticsEventTypes.QUICK_SHOP_CLOSE);
        }

        if (this.options.id) {
          this.$dialog.trigger(`${EventTypes.DIALOG_CLOSE}-${this.options.id}`);
        }

        this.$dialogContent = undefined;
        this.$dialogContentWrapper = undefined;
        this.$dialog.off(this.eventNamespace);
        this.$dialog.parent().remove();
        this.$dialog = undefined;

        if (this.pendingAsyncRequest) {
          this.pendingAsyncRequest.abort();
        }

        DialogFactory.removeFromStore(this.options.id);
        this.restoreLayoutScrollPosition();
        handleOverlayBackgrounds(this.options);

        document.jq.trigger(EventTypes.DIALOG_CLOSED, this.options).off(this.eventNamespace);
        window.jq.off(this.eventNamespace);
      };

      this.handleAnimationAwareAction(closeDialogTrigger);

      this.focusedElementBeforeOpen.focus();
    }
  },

  /**
   * alias for hideDialog
   */
  hide() {
    this.hideDialog();
  },

  hideDialog() {
    if (this.$dialog) {
      this.$dialog.addClass('is-hiding');

      const hideDialogTrigger = () => {
        this.$dialog.trigger(EventTypes.DIALOG_CLOSE, this.options);
        this.$dialog.addClass('is-hidden');
        this.$dialog.removeClass('is-hiding');

        if (this.options.type === 'size-guide') {
          document.jq.trigger(EventTypes.DIALOG_SIZE_GUIDE_CLOSE);
          this.$dialog.trigger(AnalyticsEventTypes.SIZE_GUIDE_CLOSE);
        }

        this.restoreLayoutScrollPosition();
        handleOverlayBackgrounds(this.options);
      };

      this.handleAnimationAwareAction(hideDialogTrigger);
    }
  },

  restoreLayoutScrollPosition() {
    if (this.options.scrollOnClose && !matchBreakpoint('xsmall')) {
      window.jq.scrollTop(this.windowOffset);
    }
  },

  loadUrlInDialogAsync(url) {
    const deferred = $.Deferred();

    this.pendingAsyncRequest = $.ajax({
      url,
      success: response => {
        // a selector maybe given to extract a dom element from the response
        if (this.options.filterResponseSelector) {
          const $response = $(response).find(this.options.filterResponseSelector);
          if ($response.length) {
            response = $response;
          }
        }

        this.loadDomContents(response);
        deferred.resolve(this);
      },
      cache: this.options.cache || true,
    });

    this.pendingAsyncRequest.fail(jqResponse => {
      const errorResponse = `<div class="dialog-loadError">ERROR ${jqResponse.status}</div>`;
      deferred.reject(errorResponse);
    });

    return deferred;
  },

  loadDomContents(contents) {
    contents = contents || this.options.contents;

    this.replaceContent(contents);
  },

  replaceContent(contents) {
    contents = contents || '';

    if (this.$dialogContent && this.$dialogContent.length) {
      if (this.$closeButton.parents('.js-dialog-contentWrapper').length === 0) {
        this.$closeButton.appendTo(this.$dialogContentWrapper);
      }

      this.$dialogContent.html(contents);

      if (this.options.closeButtonInContent) {
        this.$closeButton.appendTo(this.$dialogContent);
      }

      return this.$dialogContent;
    }

    return false;
  },

  positionDialogContent(dialog) {
    if (!dialog) {
      return;
    }

    if (!this.options.isFullScreenOnMobile) {
      dialog.classList.toggle(
        'dialog--vAlign-bottom-smallScreen',
        !!this.options.vAlignBottomOnSmallScreen
      );
      dialog.classList.toggle(
        'dialog--vAlign-center-smallScreen',
        !this.options.vAlignBottomOnSmallScreen
      );
    }

    if (this.options.vAlignBottom) {
      dialog.classList.toggle('dialog--vAlign-bottom', !!this.options.vAlignBottom);
      dialog.classList.toggle('dialog--vAlign-center', !this.options.vAlignBottom);
    } else {
      dialog.classList.toggle('dialog--vAlign-center', this.options.vAlignMiddle);
    }

    window.setTimeout(() => {
      const dialogContentWrapper = dialog.querySelector('.js-dialog-contentWrapper');
      const containerTooSmall = dialog.offsetHeight <= dialogContentWrapper.offsetHeight;

      if (containerTooSmall) {
        dialog.classList.remove('dialog--vAlign-bottom');
        dialog.classList.remove('dialog--vAlign-bottom-smallScreen');
        dialog.classList.remove('dialog--vAlign-center');
        dialog.classList.remove('dialog--vAlign-center-smallScreen');
      }

      dialog.classList.toggle('dialog--vAlign-top', containerTooSmall);
      dialog.classList.toggle('dialog--vAlign-top-smallScreen', containerTooSmall);
    }, 0);

    dialog.classList.add('is-positioned');
  },
});

const DialogFactory = {
  store: {},

  initialize(options) {
    let dialog = false;

    if (!options.id) {
      throw 'You did not supply an ID for the Dialog';
    } else {
      dialog = this.store[options.id];

      if (!dialog || dialog.options.url !== options.url) {
        dialog = new Dialog(options);
        this.addToStore(options.id, dialog);
      }
    }

    // return the dialog object or a deferred
    dialog = dialog ? dialog.show() : false;

    return dialog;
  },

  show(options) {
    return this.initialize(options);
  },

  addToStore(id, dialogObject) {
    this.store[id] = dialogObject;
  },

  removeFromStore(id) {
    delete this.store[id];
  },
};

export default DialogFactory;
