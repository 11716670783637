export default {
  /**
   * Promise based IntersectionObserver
   * @param {NodeList} nodeList List of elements to observe
   * @param {Object} options IntersectionObserver options
   */
  observe(nodeList, options = {}) {
    const elements = [...nodeList];
    if (!elements.length) {
      return [Promise.reject('Empty NodeList provided')];
    }

    return elements.map(element => this.observeSingle(element, options));
  },

  observeSingle(element, options = {}) {
    if (!element) {
      Promise.reject('No element provided');
    }
    return new Promise((resolve, reject) => {
      const intersectionObserver = new IntersectionObserver((entries, observer) => {
        const intersectingElements = entries
          .filter(entry => entry.isIntersecting)
          .map(entry => entry.target);

        if (intersectingElements.length) {
          resolve({ intersectingElements, observer });
        }
      }, options);

      intersectionObserver.observe(element);
    }).catch(reason => {
      window?.newrelic?.noticeError(reason);
    });
  },
};
