/* global  AppSettings */
/**
 * Handles hidden/open state of terms checkbox on all devices
 *
 * @author Tamara Bazko
 */
import $ from 'jquery';
import { gsap, CSSPlugin } from 'gsap';
import createLogger from 'components/logger/Logger';
import device from 'components/utils/device';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import EventTypes from 'components/EventTypes';
import defineProperties from 'components/utils/defineProperties';
import Factory from 'components/utils/Factory';
import requestIdleCallback from '../utils/requestIdleCallback';
import { getCSRFToken } from '../utils/CsrfUtils';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('SubscriptionForm');
gsap.registerPlugin(CSSPlugin);

function FooterSubscriptionForm($element, settings) {
  Logger.measure(this, ['initialize']);
  this.form = $element.get(0);
  this.settings = $.extend({}, this.settings, settings);

  this.initialize();
}

$.extendPrototype(FooterSubscriptionForm, {
  settings: {
    checkboxSelector: '[type=checkbox]',
    emailSelector: '[type=email]',
    buttonSelector: '.js-formButton',

    checkboxBlockSelector: '.js-formInputGroup--checkbox',
    fieldsContainerSelector: '.js-newsletterForm__fields',
    responseSelector: '.js-newsletterForm__response',
    emailBlockSelector: '.js-formInputGroup--emailFooter',
  },
  initialize() {
    this.$checkbox = this.form.jq.find(this.settings.checkboxSelector);
    this.$emailInput = this.form.jq.find(this.settings.emailSelector);
    this.$submitButton = this.form.jq.find(this.settings.buttonSelector);

    defineProperties(this, {
      $checkboxBlock: { selector: this.settings.checkboxBlockSelector, container: this.form.jq },
      $stateElements: {
        lazy: () => {
          return this.$emailInput
            .add(this.$emailInput.parents(this.settings.emailBlockSelector))
            .add(this.$checkboxBlock)
            .add(this.$checkbox)
            .add(this.$submitButton);
        },
      },
    });

    requestIdleCallback(this.preselectOption);

    this.bindEvents();

    // scoped to cater for campaign pages with multiple forms
    if (this.form.closest('.js-subscription-form')) {
      this.getUserPrefilledInformation();
    }

    this.editEmail();

    if (this.$checkbox.prop('checked') || this.$emailInput.val() !== '') {
      this.$emailInput.trigger('showCheckboxBlock');
    }
  },

  bindEvents() {
    this.$emailInput.on('focus showCheckboxBlock', this.showCheckboxBlock.bind(this));
    this.form.jq.on(EventTypes.FORM_VALIDATED, this.submitForm.bind(this));
  },

  showCheckboxBlock(event) {
    this.form.classList.add('is-extended');

    if (event && event.type === 'focus') {
      this.$emailInput.on('vmousedown', this.stopBubbling.bind(this));
      this.$checkboxBlock.on('vmousedown', this.stopBubbling.bind(this));
      // eslint-disable-next-line no-shadow
      this.$submitButton.on('vmousedown', event => {
        this.stopBubbling(event);
        if (!this.form.jq.is('is-extended')) {
          this.showCheckboxBlock();
        }
      });

      this.setClickAndBlurListener();
    }
  },

  setLoading(isLoading) {
    this.$stateElements
      .toggleClass('is-loading', isLoading)
      .toggleClass('disabled', isLoading)
      .prop('disabled', isLoading);

    if (!isLoading) {
      this.form.jq.data('api').enableSubmitButton();
    }
  },

  setClickAndBlurListener() {
    this.$clickTarget = null;

    document.jq.on('vmousedown.SubscriptionForm', event => {
      this.$clickTarget = $(event.target) || false;
    });

    this.$emailInput.blur(() => {
      const isFormEmpty = this.$emailInput.val() === '' && !this.$checkbox.is(':checked');
      const hidingKeyboard =
        this.$clickTarget === null ||
        (this.$clickTarget && this.$clickTarget.closest('.js-formInputGroup--emailFooter').length);

      if (
        (this.$clickTarget && isFormEmpty) ||
        (device.hasTouch && hidingKeyboard && isFormEmpty)
      ) {
        this.form.classList.remove('is-extended');
      }
      document.jq.off('vmousedown.SubscriptionForm');
    });
  },

  stopBubbling(event) {
    event.stopPropagation();
    this.$clickTarget = false;
  },

  getUserPrefilledInformation() {
    const extractParamFromUrl = parameter => {
      const url = new URL(window.location.href);
      return url.searchParams.get(parameter);
    };

    const che = extractParamFromUrl('che');
    const bodySubtitle = document.querySelector('.js-contentBlock-body-subTitle');
    const bodyText = document.querySelector('.js-contentBlock-body-text');
    const isInviteOnly = document.querySelector('.js-newsletterForm-error');

    const hidePageElements = isHidden => {
      if (this.form && bodySubtitle && bodyText) {
        this.form.hidden = isHidden;
        bodySubtitle.hidden = isHidden;
        bodyText.hidden = isHidden;
      }
    };

    if (!che && isInviteOnly !== null) {
      hidePageElements(true);
      return;
    }

    const url = `${AppSettings.endpoints.prefillEmail}${che}`;

    return $.ajax({
      url,
      cache: false,
      dataType: 'json',
      headers: {
        'X-Original-Referer': document.referrer,
        'X-Accept-Language': AppSettings.languageIso,
      },
      success: response => {
        const data = response;
        const isUserInvited = data.userInvited;

        if (isInviteOnly && !isUserInvited) {
          hidePageElements(true);
        } else {
          const emailInput = this.form.querySelector('.js-mailcheck');

          if (this.form && emailInput && data.hasOwnProperty('anonymisedEmail')) {
            emailInput.disabled = true;
            const userAnonymisedEmail = data.anonymisedEmail;

            this.form.classList.add('newsletterForm--show-checkboxes', 'is-extended');
            emailInput.setAttribute('placeholder', userAnonymisedEmail);
            emailInput.setAttribute('value', userAnonymisedEmail);
            emailInput.setAttribute('data-anonymised-email', true);

            if (isInviteOnly) {
              isInviteOnly.hidden = true;
            } else {
              const editInput = this.form.querySelector('.js-edit-button');
              editInput.style.display = 'inline-block';
            }
          }
        }
      },
    });
  },

  editEmail() {
    const emailEditButton = this.form.querySelector('.js-edit-button');

    if (!emailEditButton) return;

    const emailInput = this.form.querySelector('.js-mailcheck');

    emailEditButton.addEventListener('click', () => {
      emailInput.removeAttribute('data-anonymised-email');
      emailInput.disabled = false;
      emailInput.value = '';
    });
  },

  preselectOption() {
    const categoryCodeRegexp = /(girls|boys|kids)|((wo|)men)/i;
    const categoryCode = document.body.dataset.categoryCode;
    let selector;
    const matches = categoryCode?.match(categoryCodeRegexp);

    if (!matches) {
      return;
    }

    switch (true) {
      case matches[3] === 'wo':
        selector = 'footer-optin-women';
        break;
      case typeof matches[3] === 'undefined':
        selector = 'footer-preferredCategoryKids';
        break;
      case matches[3].length === 0:
        selector = 'footer-optin-men';
        break;
      default:
        return;
    }

    const element = document.getElementById(selector);
    element && element.click();
  },

  submitForm(event, formIsValid) {
    if (formIsValid) {
      const formData = this.form.jq.serializeObject();
      this.setLoading(true);

      getCSRFToken()
        .then(CSRFToken => {
          formData.CSRFToken = CSRFToken;

          const genderChecked = this.form.querySelector('[name$=preferredCategory]:checked');
          formData.gender = (genderChecked && genderChecked.value) || '';

          const emailInput = this.form.querySelector('[name$=email]');
          if (emailInput && emailInput.disabled) {
            formData.email = emailInput.value;
          }

          $.post(this.form.action, formData)
            .done(response => this.submitDone(response, formData))
            .fail(response => this.submitFail(response))
            .always(this.setLoading(false));
        })
        .catch(message => {
          Logger.error(message);
        });
    } else {
      this.showCheckboxBlock();
    }
  },

  submitDone(response, formData) {
    const fieldsContainer = this.form.querySelector(this.settings.fieldsContainerSelector);
    const responseContainer = this.form.querySelector(this.settings.responseSelector);
    responseContainer.innerHTML = response.successMessage;
    gsap.to(fieldsContainer, { duration: 0.25, autoAlpha: 0 });
    gsap.to(responseContainer, {
      duration: 0.25,
      autoAlpha: 1,
      onComplete: () => {
        fieldsContainer.parentNode.removeChild(fieldsContainer);
      },
    });

    document.jq.trigger(AnalyticsEventTypes.NEWSLETTER_SUBSCRIBE, formData);
    document.jq.trigger(AnalyticsEventTypes.ARTIFICIAL_PAGE_VIEW);
  },

  submitFail(response) {
    const { errorList } = response.responseJSON;
    errorList.forEach(error => {
      const element = this.form.querySelector(`[name=${error.fieldName}]`);
      document.jq.trigger(EventTypes.FORM_ELEMENT_ERROR_SHOW, [
        this.form,
        element,
        error.errorMessage,
      ]);
    });
  },
});

export default Factory.create(FooterSubscriptionForm);
