/* global AppSettings, AppState */
/**
 * Handles the hiding of the topbar on small screen sizes when scrolling.
 */
import $ from 'jquery';
import createLogger from '../logger/Logger';
import device from '../utils/device';
import matchBreakpoint from '../utils/matchBreakpoint';
import EventTypes from '../EventTypes';
import defineLazyProperty from '../utils/defineLazyProperty';
import 'jquery.pageshowEvents';
import 'jquery.resizeEvents';
import 'jquery.bindTransitionEnd';
import 'jquery.scrollEvents';
import '../domutils/Element.jQuery';

const Logger = createLogger('Scroll');

function TopBarScroll() {
  this.animationDuration = 275; // ms
  this.enableTillBreakpoint = 'xsmall';

  this.lastScrollY = undefined;
  this.updateVisbilityId = undefined;
  this.enabled = false;
  this.enabledExternally = false;
  this.paused = false;
  this.visible = true;
  this.firstToggleScrollHide = true;
  this.$topNavigation = null;
  this.hideOffsetCache = undefined;

  Logger.measure(this, ['initialize', 'setVisible'], true);
}

Object.defineProperty(TopBarScroll.prototype, 'hideOffset', {
  get() {
    return this.hideOffsetCache
      ? this.hideOffsetCache
      : AppState.topNavigationHideOffset || window.innerHeight * 0.5;
  },
  set(value) {
    this.hideOffsetCache = value;
  },
  configurable: true,
  enumerable: true,
});

/**
 * Initializes the object, start scroll handling behavior
 */
TopBarScroll.prototype.initialize = function () {
  defineLazyProperty(this, '$topNavigation', function () {
    const topNavigation = document.querySelector('.js-topNavigation');
    return (topNavigation && topNavigation.jq) || $();
  });

  this.bindEvents();
};

/**
 * Bind jQuery events.
 */
TopBarScroll.prototype.bindEvents = function () {
  window.jq.on(EventTypes.PAGESHOW_FROM_CACHE, () => {
    this.lastScrollY = window.pageYOffset;
    this.setVisible(true);
  });

  // Check if screen size is larger than minimum breakpoint
  if (matchBreakpoint(this.enableTillBreakpoint, device.screenSizeMax)) {
    window.jq.on('resizeWidthEnd', this.checkEnabled.bind(this));
  }

  document.jq
    .on(EventTypes.TOPNAV_SCROLL_PAUSE_REQUEST, this.onPauseRequest.bind(this))
    .on(EventTypes.TOPNAV_SCROLL_RESUME_REQUEST, this.onResumeRequest.bind(this))
    .on(EventTypes.TOPNAV_HIDE_OFFSET_UPDATED, this.handleHideOffsetUpdated.bind(this))
    .on(EventTypes.TOPNAV_SHOW_REQUEST, this.onShowRequest.bind(this))
    .on(EventTypes.TOPNAV_HIDE_REQUEST, this.onHideRequest.bind(this))
    .on(EventTypes.TOPNAV_HIDE_OFFSET_REQUEST, this.onOffsetRequest.bind(this))
    .one(EventTypes.TOPNAV_SCROLL_ENABLE_REQUEST, this.handleEnableRequest.bind(this));

  this.checkEnabled();
};

/**
 * Check if hiding of topbar should be enabled (based on if sideNavigation is open and screen
 * resolution. It can also be enabled via `TOPNAV_SCROLL_ENABLE_REQUEST` event)
 */
TopBarScroll.prototype.checkEnabled = function () {
  const inCheckoutV2 = document.documentElement.classList.contains('in-checkout-v2');

  // The scroll functionality should not be enabled on the mobile
  // checkout v2 pages because this causes "jumping" behaviour.
  if (AppState.isMobile && inCheckoutV2) {
    return false;
  }

  const hasOpenSideNav =
    document.documentElement.classList.contains('has-open-sideNavigation') ||
    (document.documentElement.classList.contains('has-open-sideNavigationByDefault') &&
      matchBreakpoint(AppSettings.sideNavigationBreakpointNextToContent));

  this.enabled =
    (!matchBreakpoint(this.enableTillBreakpoint) || this.enabledExternally) && !hasOpenSideNav;

  if (this.enabled && !this.paused) {
    this.resume();
  } else {
    this.toggleScrollHide(this.enabled);
  }
};

/**
 * Update the hide offset value
 */
TopBarScroll.prototype.handleHideOffsetUpdated = function () {
  this.hideOffset = AppState.topNavigationHideOffset;
};

/**
 * Handle external enable request, this enables the hiding of top navigation independent of
 * screen resolution.
 */
TopBarScroll.prototype.handleEnableRequest = function () {
  Logger.info('handleEnableRequest');
  this.enabledExternally = true;
  this.checkEnabled();
  document.jq.on(EventTypes.SIDENAV_ANIMATION_COMPLETE, this.checkEnabled.bind(this));
};

TopBarScroll.prototype.clearDelayedUpdates = function () {
  cancelAnimationFrame(this.updateVisbilityId);
};

/**
 * Return scroll position
 * @return {Number} Current Y scroll position
 */
TopBarScroll.prototype.getScrollY = function () {
  return Math.max(
    0,
    Math.min(document.documentElement.scrollHeight - window.innerHeight, window.pageYOffset)
  );
};

/**
 * Triggered when offset is requested
 *
 * @returns {number|undefined}
 */
TopBarScroll.prototype.onOffsetRequest = function () {
  return this.enabled ? this.hideOffset : undefined;
};

/**
 *  Triggered when external component asks for scroll behavior to be paused
 * @param  {jQuery.Event} event
 * @param  {Object=} eventData Optional object containing `maintainState` boolean that indicates bar visibility
 *                             should not be changed initially.
 */
TopBarScroll.prototype.onPauseRequest = function (event, eventData) {
  if (this.enabled) {
    if (eventData && eventData.maintainState) {
      this.paused = true;
      this.toggleScrollHide(false, false);
    } else {
      this.pause();
    }
  } else {
    this.paused = true;
  }
};

/**
 * Triggered when external component asks for scroll behavior to be resumed
 * @param  {jQuery.Event} event
 * @param  {Object=} eventData Optional object containing `maintainState` boolean that indicates bar visibility
 *                             should not be changed initially. `forceVisible` property will set visibility of topNav
 *                             to true.
 */
TopBarScroll.prototype.onResumeRequest = function (event, eventData) {
  if (this.enabled) {
    if (eventData && eventData.maintainState) {
      this.paused = false;
      this.toggleScrollHide(true, false);
    } else {
      this.resume();
    }

    if (eventData && eventData.forceVisible) {
      this.setVisible(true);
    }
  } else {
    this.paused = false;
  }
};

/**
 * Listener for show event
 */
TopBarScroll.prototype.onShowRequest = function () {
  this.setVisible(true);
};

/**
 * Listener for hide event
 */
TopBarScroll.prototype.onHideRequest = function () {
  this.setVisible(false);
};

/**
 * Pauses scroll behavior, makes navigation visible again.
 */
TopBarScroll.prototype.pause = function () {
  this.paused = true;
  this.toggleScrollHide(false);
};

/**
 * Resumes scroll behavior, also makes navigation visible
 */
TopBarScroll.prototype.resume = function () {
  this.paused = false;
  this.toggleScrollHide(true);
};

/**
 * Toggle scroll behavior for top navigation
 *
 * @param  {Boolean} toggle      Should scroll behavior be enabled or disabled
 * @param  {Boolean=} [updateState=true] Should visibility be updated
 */
TopBarScroll.prototype.toggleScrollHide = function (toggle, updateState) {
  updateState = updateState !== false;

  if (!this.firstToggleScrollHide) {
    this.clearDelayedUpdates();
    window.jq.off('scrollUpdate.topNavigation');
  }

  if (this.toggle && this.enabled) {
    this.lastScrollY = this.getScrollY();
    window.jq.on('scrollUpdate.topNavigation', this.updateScroll.bind(this));

    if (updateState) {
      this.updateScroll();
    }
  } else if (updateState) {
    this.setVisible(true);
  }

  this.firstToggleScrollHide = false;
};

/**
 * Update visibility.
 *
 * @param {bool} visibility
 */
TopBarScroll.prototype.setVisible = function (visibility) {
  if (this.paused || !this.enabled) {
    visibility = true;
  }

  if (visibility !== this.visible) {
    this.visible = visibility;

    cancelAnimationFrame(this.updateVisbilityId);

    this.updateVisbilityId = requestAnimationFrame(() => {
      const eventData = {
        visible: this.visible,
      };

      this.$topNavigation
        .trigger(EventTypes.TOPNAV_ANIMATION_START, eventData)
        .toggleClass('is-hidden', !this.visible)
        .trigger(this.visible ? EventTypes.TOPNAV_SHOW : EventTypes.TOPNAV_HIDE, eventData)
        .bindTransitionEnd(
          this.$topNavigation.trigger.bind(
            this.$topNavigation,
            EventTypes.TOPNAV_ANIMATION_COMPLETE,
            eventData
          ),
          this.animationDuration
        );
    });
  }
};

/**
 * Update scroll position.
 */
TopBarScroll.prototype.updateScroll = function () {
  if (this.paused) {
    return;
  }

  const scrollY = this.getScrollY();

  if (scrollY !== this.lastScrollY) {
    const scrollingUp = scrollY < this.lastScrollY;
    const pastOffset = scrollY > this.hideOffset;

    this.setVisible(scrollingUp || !pastOffset);
    this.lastScrollY = scrollY;
  }
};

export default new TopBarScroll();
