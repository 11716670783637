/* global AppSettings, AppState, exponea */
/**
 * Common data API
 *
 * @author  Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import createLogger from '../logger/Logger';
import backToTopButton from '../backToTopButton';
import AnalyticsEventTypes from '../analytics/AnalyticsEventTypes';
import EventTypes from '../EventTypes';
import stopAssistedSaleButton from '../assistedSale/stopAssistedSaleButton';
import ampliencePreviewBar from '../amplience/AmpliencePreviewBar';
import QueryParams from '../utils/queryParams';

const Logger = createLogger('CommonData');

/**
 * An object with stateful data
 * @typedef {Object} StatefulData
 * @property {string} accountType Type of account of the current consumer.
 * @property {string} agegroup Something similar to "35-44"
 * @property {string} asmInstoreLocale
 * @property {boolean} assistedSale Assisted sale for the consumer.
 * @property {string} assistedSaleUserID
 * @property {string} assistedSaleUserType
 * @property {string} correlationId Correlation ID used for tracking.
 * @property {string} csrfToken CSRFToken to be used in form submissions.
 * @property {string} customerId The customer id of the consumer.
 * @property {string} customerType One of new or returning
 * @property {string} firstName The first name of the consumer.
 * @property {string} gender The gender of the consumer.
 * @property {string} hashedIp The hashedIp address of the consumer.
 * @property {string} hashedUserId The hashed user id of the consumer.
 * @property {boolean} internal Is the consumer an internal G-Star employee.
 * @property {boolean} loggedInStatus Is the consumer logged in.
 * @property {string} isAmpliencePreview
 * @property {string} ampliencePreviewTime
 * @property {string} amplienceVSE
 * @property {number} amplienceTSP
 */

/**
 * @type {string} Name of USER_INFO analytics event
 */
const userInfoKey = AnalyticsEventTypes.USER_INFO;

/**
 * @type {string} Variable to cache the CSRFToken
 */
let csrfToken;

/**
 * @type {boolean} Variable flagging running state
 */
let isRunning = false;

/**
 * @type {bolean} Variable flagging awaiting state
 */
let isAwaiting = false;

function initialize() {
  csrfToken = getCsrfFromPage();

  bindEvents();

  if (!AppSettings.statelessPage && window.dataLayerCache) {
    pushData(window.dataLayerCache.userInfo);
  }

  if (!AppSettings.statelessPage || csrfToken) {
    return regularInitialize();
  }

  doApiCall()
    .then(processName)
    .then(processIsMobile)
    .then(updateUserMetaData)
    .then(processHashes)
    .then(processCsrf)
    .then(processAssistedSale)
    .then(processAmpliencePreview)
    .then(onCommonDataReady)
    .then(pushData)
    .catch(reason => {
      regularInitialize();
      Logger.error('Failed to load common data');
      window?.newrelic?.noticeError(reason);
    });
}

/**
 * Get the CSRF token from the HTML header
 */
function getCsrfFromPage() {
  const csrfMeta = document.head.querySelector('[name="_csrf"]');

  return csrfMeta ? csrfMeta.content : undefined;
}

/**
 * Bind events to `document` for this module
 */
function bindEvents() {
  document.jq.on(EventTypes.CSRF_REQUEST, csrfRequestHandler);

  // Handles logout event.
  document.jq.on(EventTypes.LOGOUT, processLogoutEvent.bind(this));

  // Handles login event.
  document.jq
    .on(EventTypes.LOGIN, processLoginEvent.bind(this))
    .on(EventTypes.UPDATE_USER_INFO, () => {
      doApiCall()
        .then(updateUserMetaData)
        .then(() => {
          document.jq.trigger(EventTypes.UPDATE_USER_INFO_DONE);
        })
        .catch(reason => Logger.error(reason));
    });
}

function csrfRequestHandler() {
  if (csrfToken) {
    crsfReceiveTrigger(csrfToken);
  } else if (!isRunning) {
    isRunning = true;
    doApiCall()
      .then(data => {
        if (data.csrfToken) {
          csrfToken = data.csrfToken;
          crsfReceiveTrigger(csrfToken);
        }
        isRunning = false;
        isAwaiting = false;
      })
      .catch(reason => Logger.error(reason));
  } else {
    isAwaiting = true;
  }
}

/**
 * Generic wrapper around jQuery trigger for the CSRF_RECEIVE event
 * @param {string} csrfTokenParam the CSRFToken
 */
function crsfReceiveTrigger(csrfTokenParam) {
  document.jq.trigger(EventTypes.CSRF_RECEIVE, csrfTokenParam);
}

/**
 * Call minimal modules for regular page load
 */
function regularInitialize() {
  backToTopButton.initialize();
}

/**
 * Do the API call to retrieve stateful data
 * @returns {object} jQuery Deferred from $.ajax()
 */
function doApiCall() {
  const url = AppSettings.endpoints.commonUserInfo;
  isRunning = true;

  // stateless pages served from cache will not receive and parse query params
  // therefore they need to be propagated to the server
  const data = QueryParams.extractFromSearchUrl(window.location.search);

  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      cache: false,
      data,
      dataType: 'json',
      headers: {
        'X-Original-Referer': document.referrer,
      },
    })
      .done(response => resolve(response))
      .fail(response => reject(response));
  });
}

/**
 * Process the csrf token when found in the response data
 * @param {StatefulData} data The stateful ajax response data
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function processCsrf(data) {
  csrfToken = data.csrfToken;
  if (!csrfToken.length) {
    throw new Error('CSRF error!');
  }

  const csrfMetaTag = document.createElement('meta');
  csrfMetaTag.setAttribute('name', '_csrf');
  csrfMetaTag.setAttribute('content', csrfToken);
  document.head.appendChild(csrfMetaTag);

  const csrfInput = document.createElement('input');
  csrfInput.type = 'hidden';
  csrfInput.name = 'CSRFToken';
  csrfInput.value = csrfToken;

  document.querySelectorAll('form[method="post"]').forEach(formElement => {
    const maybeCsrfInput = formElement.querySelector('[name="CSRFToken"]');
    if (maybeCsrfInput) {
      maybeCsrfInput.value = csrfToken;
    } else {
      formElement.insertAdjacentElement('afterbegin', csrfInput.cloneNode());
    }
  });

  return data;
}

/**
 * @param {StatefulData} data
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function updateUserMetaData(data) {
  const { hashedUserId, customerId, loggedInStatus } = data;

  AppState.userMetaData = AppState.userMetaData || {};

  AppState.userMetaData.hashedUserId = hashedUserId;
  AppState.userMetaData.loggedInStatus = loggedInStatus;

  if (customerId) {
    AppState.userMetaData.customerId = customerId;
  }
  return data;
}

/**
 * Push user related data to Universal Analytics
 * @param {StatefulData} data
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function processHashes(data) {
  const {
    hashedIP,
    hashedUserId,
    customerId,
    correlationId,
    assistedSale,
    newsletterUser,
    msdAbTestingEnabled,
    gsOrigin,
  } = data;

  AppState.assistedSale = assistedSale;
  AppState.msdAbTestingEnabled = msdAbTestingEnabled;
  AppState._rsu = correlationId;
  AppState.correlationId = correlationId;

  if (AppSettings.subscriptionDialog && (newsletterUser || gsOrigin === 'email')) {
    AppSettings.subscriptionDialog.length = 0;
  }

  if (AppState.trackingEnabled) {
    if (AppSettings.statelessPage) {
      if (window.exponea) {
        const exponeaData = {};
        if (hashedUserId) {
          exponeaData.registered = hashedUserId;
        }
        if (customerId) {
          exponeaData.subscriber_key = customerId;
        }
        if (Object.keys(exponeaData).length) {
          exponea.identify(exponeaData);
        }
      }
    }
  }

  return data;
}

/**
 * Process the login event if the user is logged in via reactjs.
 * @param {object} event
 * @param {object} data Event data as passed through .trigger()
 * @returns {object}
 */
function processLoginEvent(event, data) {
  return processName(data, true);
}

/**
 * Processes the logout event by updating the header and
 * hiding the my account section in the side navigation.
 */
function processLogoutEvent() {
  const logoutSelector = '.topNavigation-account--logout';
  const element = document.querySelector(logoutSelector);

  if (!element) {
    return;
  }

  const { loggedOutLabel } = element.dataset;
  const { loggedOutHref } = element.dataset;

  // Update the anchor tag in the menu.
  element.textContent = loggedOutLabel;
  element.href = loggedOutHref;

  // Hide the side navigation my account section.
  toggleMyAccountMenuVisibility(false);
  element.setAttribute('data-cs-capture', '');
}

/**
 * Add name to header when user is logged in
 * @param {StatefulData} data Stateful response data as JSON
 * @param {boolean} checkUpdated
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function processName(data, checkUpdated = false) {
  const updateHeader = data.loggedInStatus === true || checkUpdated === true;

  updateHtmlTag(data.loggedInStatus === true);

  if (!data.firstName || !updateHeader) {
    return data;
  }

  updateName(data.firstName, checkUpdated);
  toggleMyAccountMenuVisibility(true);

  return data;
}

/**
 * Process mobile specific initialization
 * @param {StatefulData} data
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function processIsMobile(data) {
  backToTopButton.initialize();

  return data;
}

/**
 * Show stop assisted sale button when current session is an assisted sale
 * @param {StatefulData} data
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function processAssistedSale(data) {
  if (data.assistedSale) {
    stopAssistedSaleButton.initialize(data);
  }
  return data;
}

/**
 * Show stop amplience preview button when current session is a preview session
 * @param {StatefulData} data
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function processAmpliencePreview(data) {
  if (data.isAmpliencePreview) {
    ampliencePreviewBar.initialize(data);
  }
  return data;
}

/**
 * Handles on common data ready events.
 *
 * @param {StatefulData} data - Common data.
 * @returns {StatefulData} Passes on stateful data to next `then`
 */
function onCommonDataReady(data) {
  document.jq.trigger(EventTypes.COMMON_DATA_READY, data);
  isRunning = false;

  if (isAwaiting) {
    crsfReceiveTrigger(csrfToken);
    isAwaiting = false;
  }

  AppState.commonDataReady = true;

  return data;
}

/**
 * https://g-star.atlassian.net/browse/DAN-86
 *
 * @param {StatefulData} eventData - Common data.
 * */
function pushData(eventData) {
  // Checkout v2 dispatches it's own userInfo event and thus we can return early here.
  if (!eventData || (AppSettings.checkoutV2Enabled && document.querySelector('.in-checkout-v2'))) {
    return;
  }

  const userInfo = { ...window.dataLayerCache.userInfo };
  const {
    hashedUserId,
    internal,
    loggedInStatus,
    gender,
    customerType,
    accountType,
    ageGroup,
    hashedIp,
    assistedSaleUserType,
    assistedSaleUserID,
    thresholdCustomerType,
  } = eventData;

  if (hashedUserId) {
    userInfo.user.userID = hashedUserId;
  }

  if (internal !== undefined) {
    userInfo.user.internal = internal;
  }

  if (loggedInStatus !== undefined) {
    userInfo.user.loggedInStatus = loggedInStatus;
  }

  if (gender) {
    userInfo.user.gender = gender && gender.toLowerCase();
  }

  if (customerType) {
    userInfo.user.customerType = customerType;
  }

  if (accountType) {
    userInfo.user.accountType = accountType && accountType.toLowerCase();
  }

  if (ageGroup) {
    userInfo.user.ageGroup = ageGroup;
  }

  if (hashedIp) {
    userInfo.user.hashedIP = hashedIp;
  }

  if (assistedSaleUserType) {
    userInfo.user.assistedSaleUserType = assistedSaleUserType;
  }

  if (assistedSaleUserID) {
    userInfo.user.assistedSaleUserID = assistedSaleUserID;
  }

  if (thresholdCustomerType) {
    userInfo.user.thresholdCustomerType = thresholdCustomerType;
  }

  document.jq.trigger(userInfoKey, userInfo);
}

/**
 * Adds stateful css class name to html tag
 * @param {boolean} isLoggedIn
 */
function updateHtmlTag(isLoggedIn) {
  const htmlCssClass = `logged-${isLoggedIn ? 'in' : 'out'}`;
  document.documentElement.classList.remove('logged-in');
  document.documentElement.classList.remove('logged-out');
  document.documentElement.classList.add(htmlCssClass);
}

/**
 * Update userName in header and sidenav
 * @param {string} firstName First name of the consumer
 * @param {boolean} checkUpdated
 */
function updateName(firstName, checkUpdated) {
  let selector = '.js-show-userName';

  if (!checkUpdated) {
    selector = `${selector}:not(.is-updated)`;
  }

  document.querySelectorAll(selector).forEach(element => {
    element.textContent = `\n${firstName}\n`.replace(/\+/g, ' ');
    element.classList.remove('invisible');
  });

  const element = document.querySelector('.js-topNavigation-account');

  if (!element) {
    return;
  }

  const logoutCssClass = element.dataset.loggedInClass;
  const { loggedInHref } = element.dataset;
  const { loggedInAnalyticsClick } = element.dataset;
  element.classList.remove('topNavigation-account--login');
  element.classList.add(logoutCssClass);
  element.href = loggedInHref;
  element.setAttribute('data-analytics-click', loggedInAnalyticsClick);
  element.removeAttribute('data-cs-capture');
}

/**
 * Toggle my account menu visibility.
 *
 * @param {boolean} visibile - Whether the menu section has to be hidden or not.
 */
function toggleMyAccountMenuVisibility(visible) {
  const myAccountMenuSelector = '.js-sideNav-scrollContainer .js-sideNav-subMenu[id*="myAccount"]';
  const myAccountMenu = document.querySelector(myAccountMenuSelector);

  if (!myAccountMenu) {
    return;
  }

  if (visible) {
    myAccountMenu.style.display = 'block';
  } else {
    myAccountMenu.style.display = 'none';
  }
}

export default {
  initialize,
};
