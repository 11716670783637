/**
 * This module handles the interaction, display and animation of the CartHeader view (fold out basket)
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import Storage from 'components/utils/storage/Storage';
import StorageKeys from '../utils/storage/StorageKeys';
import EventTypes from 'components/EventTypes';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import device from 'components/utils/device';
import defineProperties from 'components/utils/defineProperties';
import Singleton from 'components/utils/Singleton';
import onWindowLoaded from 'components/utils/onWindowLoaded';
import gsap from 'gsap';
import 'jquery.resizeEvents';
import 'jquery.scrollEvents';
import 'components/domutils/Element.jQuery';
import Qubit from 'components/analytics/Qubit';
import wait from 'components/utils/WaitUtil';

const Logger = createLogger('FoldoutView');

function FoldoutView() {
  Logger.measure(this, [
    'initialize',
    'toggleHideEvents',
    'updateViewFromCache',
    'setBagCount',
    'setViewCache',
  ]);

  this.initialize();
}

$.extend(FoldoutView.prototype, {
  cacheDuration: 900,
  cartHideDuration: 200,
  cartShowDuration: 200,
  defaultHideDelay: 200,
  destroyLineItemDuration: 0.2,
  hideDelay: 3000,
  hideEventsEnabled: false,
  hto: false,
  initialUpdatePerformed: false, //fetch the cart silently on the background
  qubitCrossSellEnabled: false,

  initialize() {
    defineProperties(this, {
      $cart: { selector: '.js-cartViewHeader' },
      $trigger: { selector: '.js-cartViewHeaderTrigger' },
    });

    if (!this.$cart.length && !this.$trigger.length) {
      return;
    }

    this.bindEvents();

    if (
      Storage.getItem(StorageKeys.BASKET) &&
      Storage.getItem(StorageKeys.BASKET_LOCALE) === AppSettings.locale
    ) {
      requestAnimationFrame(() => {
        this.setBagCount();
      });
      onWindowLoaded(this.updateViewFromCache, this);
    } else {
      Storage.removeItem(StorageKeys.BASKET_LOCALE);
      onWindowLoaded(this.requestBasketFromServer, this);
    }
  },

  autoShowBag() {
    Logger.debug('autoShowBag');
    if (this.qubitCrossSellEnabled) {
      const waitPromise = wait(5000);
      const crossUpsellDisplayedPromise = new Promise(resolve => {
        document.jq.on(EventTypes.COMPLETE_THE_LOOK_DISPLAYED, () => resolve());
      });

      return Promise.race([waitPromise, crossUpsellDisplayedPromise])
        .then(() => this.showBagDelayed(2000))
        .catch(reason => {
          Logger.error(reason);
          window?.newrelic?.noticeError(reason);
        });
    }
    this.showBag();
    this.hideDelayed(this.hideDelay);
  },

  bindEvents() {
    this.$cart.on('vclick', '.js-removeFromCart:not(.loading)', this.removeItem.bind(this));
    this.$trigger.on('vclick', e => this.preventMultipleRequests(e, this.$trigger));

    if (!device.hasTouch) {
      const hideDelayed = this.hideDelayed.bind(this);

      this.$cart
        .on('vclick', '.empty .js-hide-cart', this.hideBag.bind(this))
        .on('mouseleave', hideDelayed)
        .on('vmousemove mouseenter', this.stopDelayedHide.bind(this));

      this.$trigger.on('mouseleave', hideDelayed).on('vmouseover', this.showBagDelayed.bind(this));
    }

    document.jq
      .on(EventTypes.LOCALE_SELECTOR_SHOW, this.hideBag.bind(this))
      .on(EventTypes.CART_FETCH_FAIL, this.clearViewCache.bind(this))
      .on(
        `${EventTypes.CART_FETCH_SUCCESS} ${EventTypes.CART_ADD_ITEM_SUCCESS}`,
        this.updateView.bind(this)
      )
      .on(EventTypes.CART_ADD_ITEM_SUCCESS, this.setMutationState)
      .on(EventTypes.COMPLETE_THE_LOOK_START, this.enableQubitCrossSell.bind(this))
      .on(EventTypes.CART_ADD_ITEM_SUCCESS, this.autoShowBag.bind(this))
      .on(EventTypes.LOCALE_LANGUAGE_CHANGED, this.clearViewCache.bind(this));
  },

  preventMultipleRequests(event, $element) {
    if ($element.attr('disabled')) {
      return event.preventDefault();
    }

    $element.attr('disabled', true);

    setTimeout(() => {
      $element.removeAttr('disabled');
    }, 3000);
  },

  clearViewCache() {
    Storage.removeItem(StorageKeys.BASKET);
  },

  hideBag() {
    Logger.debug('hideBag');
    this.stopDelayShow();
    this.toggleHideEvents(false);

    document.jq.trigger(EventTypes.CART_VIEW_HIDE_START);
    const trigger = this.$trigger.get(0);
    if (trigger) {
      trigger.jq.deActivate();
      trigger.setAttribute('aria-expanded', false);
    }
    this.$cart.deActivate();
  },

  hideDelayed(duration) {
    this.stopDelayedHide();
    duration = isNaN(duration) ? this.defaultHideDelay : duration;
    this.hto = window.setTimeout(this.hideBag.bind(this), duration);
    const trigger = this.$trigger.get(0);
    trigger.setAttribute('aria-expanded', false);
  },

  /**
   * Handler for when user clicks on body and cart should be hidden
   * @param  {jQuery.Event} event
   */
  onBodyClick(event) {
    if (this.$cart.hasClass('is-active') && event.target) {
      event.stopPropagation();

      // Check if user did not click on something within the cart (or on cart icon)
      if (
        !event.target.classList.contains('js-cartViewHeaderTrigger') &&
        !event.target.closest('.js-cartViewHeader')
      ) {
        this.hideBag();
      }
    }
  },

  /**
   * Send request for retrieving basket from server
   */
  requestBasketFromServer() {
    document.jq.trigger(EventTypes.CART_FETCH_REQUEST);
  },

  setViewCache(html) {
    try {
      if (typeof html !== 'string') {
        const $element = $(html);
        if ($element.length) {
          html = $element.get(0).outerHTML;
        } else {
          html = '';
        }
      }
    } catch (e) {
      html = '';
    }

    if (html) {
      Storage.setItem(StorageKeys.BASKET, html, false, this.cacheDuration);
      Storage.setItem(StorageKeys.BASKET_LOCALE, AppSettings.locale, false);
    }
  },

  showBag() {
    Logger.debug('showBag');
    this.stopDelayShow();

    if (!this.$cart || !this.$trigger) {
      return;
    }

    document.jq.trigger(EventTypes.CART_VIEW_SHOW_START);

    if (!this.$checkoutBtn) {
      this.$checkoutBtn = $('.js-checkoutButton');
      this.$checkoutBtn.on('vclick', e => this.preventMultipleRequests(e, this.$checkoutBtn));
    }

    this.toggleHideEvents(true);
    this.$trigger.activate();
    this.$cart.activate();
  },

  showBagDelayed(timeout = 300) {
    Logger.debug('showBagDelayed');
    const trigger = this.$trigger.get(0);

    trigger.focus();
    trigger.setAttribute('aria-expanded', true);

    if (this.delayShowId === undefined) {
      this.delayShowId = setTimeout(this.showBag.bind(this), timeout);
    }
  },

  stopDelayedHide() {
    window.clearTimeout(this.hto);
  },

  stopDelayShow() {
    clearTimeout(this.delayShowId);
    delete this.delayShowId;
  },

  toggleHideEvents(toggle) {
    if (toggle !== this.hideEventsEnabled) {
      if (toggle) {
        const hideBagHandler = this.hideBag.bind(this);

        document.jq.one('scrollUpdate.hideBag', hideBagHandler);
        window.jq.one('resizeStart.hideBag', hideBagHandler);
        document.body.jq.one('vclick.hideBag touchstart.hideBag', this.onBodyClick.bind(this));
      } else {
        document.jq.off('.hideBag');
        window.jq.off('.hideBag');
        document.body.jq.off('.hideBag');
      }
    }

    this.hideEventsEnabled = toggle;
  },

  /**
   * Update view from localStorage
   */
  updateViewFromCache() {
    const cachedViewHtml = Storage.getItem(StorageKeys.BASKET, false, true);

    if (cachedViewHtml) {
      this.updateView(cachedViewHtml, false);
    } else {
      this.requestBasketFromServer();
    }
  },

  /**
   * Update foldout view
   * @param  {String} cartHtml    Foldout HTML
   * @param  {Boolean=} [updateCache=true] Update cached basket in localStorage
   * @param  {Object=} fromEvent   If set updateView is called from an event
   */
  updateView(cartHtml, updateCache, fromEvent) {
    if (updateCache === undefined) {
      updateCache = true;
    } else if (typeof updateCache !== 'boolean') {
      fromEvent = updateCache;
      updateCache = true;
    }

    if (fromEvent) {
      cartHtml = fromEvent;
    }

    Logger.time('updateView', this);
    cartHtml = typeof cartHtml === 'string' ? cartHtml : cartHtml.html;
    this.$cartInner = $(cartHtml).find('.js-cartViewHeader-inner');

    if (updateCache) {
      this.setViewCache(cartHtml);
    }

    this.$cartInner.attr('data-cs-capture', '');
    this.$cart.empty().append(this.$cartInner);
    this.setBagCount();

    this.initialUpdatePerformed = true;
    document.jq.trigger(EventTypes.CART_VIEW_UPDATE_COMPLETE);

    if (AppSettings.pageType !== 'CART' && AppSettings.pageType !== 'PURCHASE') {
      Qubit.triggerBasketEvents();
    }

    const cartDataSelector = document.querySelector('[data-cart-modification-data]');
    if (updateCache !== false && cartDataSelector) {
      const cartData = cartDataSelector.dataset.cartModificationData;
      document.jq.trigger(AnalyticsEventTypes.CART_UPDATED, cartData);
    }

    Logger.timeEnd('updateView', this);
  },

  setMutationState() {
    try {
      localStorage.setItem(
        `${AppSettings.country.toLowerCase()}_${StorageKeys.CART_MUTATED}`,
        'hy'
      );
    } catch {}
  },

  removeItem(event) {
    event.preventDefault();

    const $element = event.currentTarget.jq;
    const $li = $element.closest('li');

    if ($element.hasClass('is-loading')) {
      return false;
    }

    $element.addClass('is-loading');
    const analyticsData = $element.parent().data('analyticsProduct') || {};

    document.jq
      .trigger(EventTypes.CART_INTERACTION_START, [analyticsData, EventTypes.CART_REMOVE])
      .trigger(EventTypes.CART_REMOVE_ITEM_REQUEST, [
        $element.attr('data-entry-sku'),
        $element.attr('data-entryNumber'),
      ])
      .on(EventTypes.CART_REMOVE_ITEM_FAIL, $element.removeClass.bind($element, 'is-loading'))
      .on(EventTypes.CART_REMOVE_ITEM_SUCCESS, (event, cartHtml) => {
        this.setMutationState();
        gsap.to($li, {
          duration: this.destroyLineItemDuration,
          css: { opacity: 0 },
          onComplete: () => {
            $li.remove();
            this.updateView(cartHtml);

            const bagCount = this.getBagCount();
            if (!bagCount) {
              document.jq.trigger(EventTypes.CART_EMPTY);
            }
          },
        });
      });
  },

  getBagCount() {
    return Storage.getItem(StorageKeys.BAG_COUNT, false) || '';
  },

  enableQubitCrossSell() {
    this.qubitCrossSellEnabled = true;
  },

  setBagCount() {
    const bagCountElement = document.querySelector('.js-bagCount');

    if (!bagCountElement) {
      return;
    }

    let bagCount = this.getBagCount();
    const prevBagCount = bagCount;

    this.$cartInner =
      this.$cartInner || this.$cart.get(0)?.querySelector('.js-cartViewHeader-inner')?.jq;

    if (this.$cartInner && this.$cartInner.attr('data-basketcount')) {
      bagCount = this.$cartInner.attr('data-basketcount');
      if (bagCount === '0') {
        bagCount = '';
      }
    }

    if (!bagCount) {
      Storage.removeItem(StorageKeys.BAG_COUNT);
    } else if (bagCount !== prevBagCount) {
      Storage.setItem(StorageKeys.BAG_COUNT, bagCount, false, 3600);
    }
    bagCountElement.innerHTML = `<span data-cs-capture>${bagCount}</span>`;

    const ariaLabel = this.$trigger.attr('data-label') || '';
    if (ariaLabel) {
      this.$trigger
        .attr('aria-label', ariaLabel.replace('{0}', bagCount || 0))
        .toggleClass('has-count', bagCount !== '');
    }

    return bagCount;
  },
});

export default Singleton.create(FoldoutView, 'FoldoutView');
