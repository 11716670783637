/**
 * Contentsquare analytics
 * @see https://docs.contentsquare.com/uxa-en/#general-principles
 */
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import EventTypes from 'components/EventTypes';

class Contentsquare {
  constructor() {
    this.initialize();
    this.bindEvents();
  }

  /**
   * Initialize global _uxa variable to be able to send instructions even before
   * the library has finished loading
   */
  initialize() {
    window._uxa = window._uxa || [];
  }

  /**
   * Subscribes to events to handle Contentsquare analytics
   */
  bindEvents() {
    document.jq
      .on(AnalyticsEventTypes.STOCK_NOTIFICATION_SUBMIT, Contentsquare.pushStockNotificationSubmit)
      .on(
        AnalyticsEventTypes.COMING_SOON_NOTIFICATION_SUBMIT,
        Contentsquare.pushStockNotificationSubmit
      )
      .on(EventTypes.CART_ADD_ITEM_SUCCESS, Contentsquare.pushAddToCart)
      .on(AnalyticsEventTypes.FEEDBACK_BUTTON_CLICK, Contentsquare.pushFeedbackButtonClick)
      .on(EventTypes.LIVECHAT_BUTTONS_CLICKED, Contentsquare.pushLivechatButtonsClick)
      .on(AnalyticsEventTypes.NEWSLETTER_SUBSCRIBE, Contentsquare.pushNewsletterSubscribe)
      .on(AnalyticsEventTypes.SIZE_GUIDE_OPEN, Contentsquare.pushSizeGuideOpen)
      .on(AnalyticsEventTypes.SIZE_GUIDE_CLOSE, Contentsquare.pushSizeGuideClose)
      .on(EventTypes.QUICK_SHOP_SHOW, Contentsquare.pushQuickShopOpen)
      .on(AnalyticsEventTypes.QUICK_SHOP_CLOSE, Contentsquare.pushQuickShopClose)
      .on(AnalyticsEventTypes.SHARE_QR_CART_MODAL_OPEN, Contentsquare.pushShareQrCartModalOpen)
      .on(AnalyticsEventTypes.ARTIFICIAL_PAGE_VIEW, Contentsquare.pushArtificialPageView);
  }

  static getLocation() {
    return window.location.pathname + window.location.hash.replace('#', '?__');
  }

  /**
   * Emits stock notification submit event
   */
  static pushStockNotificationSubmit() {
    window._uxa.push(['trackPageEvent', 'Action | Subscribed to notify me when back in stock']);
  }

  /**
   * Emits add to cart event
   */
  static pushAddToCart() {
    window._uxa.push(['trackPageEvent', 'Action | Add to cart clicked']);
  }

  /**
   * Emits artificial page view event
   */
  static pushArtificialPageView() {
    window._uxa.push(['trackPageview', `${Contentsquare.getLocation()}`]);
  }

  /**
   * Emits size guide pop-in open event
   */
  static pushSizeGuideOpen() {
    window._uxa.push(['trackPageview', `${Contentsquare.getLocation()}?cs-popin-sizeguide`]);
  }

  /**
   * Emits quick shop pop-in open event
   */
  static pushQuickShopOpen() {
    window._uxa.push(['trackPageview', `${Contentsquare.getLocation()}?cs-popin-quickshop`]);
  }

  /**
   * Emits size guide pop-in close event
   */
  static pushSizeGuideClose() {
    window._uxa.push(['trackPageview', Contentsquare.getLocation()]);
  }

  /**
   * Emits quick shop pop-in close event
   */
  static pushQuickShopClose() {
    window._uxa.push(['trackPageview', Contentsquare.getLocation()]);
  }

  /**
   * Emits share qr cart modal open event
   */
  static pushShareQrCartModalOpen() {
    window._uxa.push(['trackPageEvent', 'Action | Share cart']);
  }

  /**
   * Emits newsletter subscribe event
   */
  static pushNewsletterSubscribe() {
    window._uxa.push(['trackPageEvent', 'Action | Subscribed to newsletter']);
  }

  /**
   * Emits 3rd party (Salesforce) livechat buttons click event
   */
  static pushLivechatButtonsClick() {
    window._uxa.push(['trackPageEvent', 'Action | Livechat clicked']);
  }

  /**
   * Emits 3rd party (Usabilla) feedback button click event
   */
  static pushFeedbackButtonClick() {
    window._uxa.push(['trackPageEvent', 'Action | Feedback button clicked']);
  }
}

export default Contentsquare;
