/**
 * Adds $.deparam method which converts a queryString into an object,
 * repeating keys are converted to array.
 */
import $ from 'jquery';
/**
 * Remove url encoding
 * @param  {String} str Input string
 * @return {String}     Output string
 */
function decode(str) {
  return !str || str.indexOf('%') === -1 ? str : unescape(str);
}

/**
 * Convert queryString into object
 * @param  {String} queryString Querystring, for example `foo=bar&bar=foo`
 * @return {Object}             Object with key value pairs
 */
$.deparam = function (queryString) {
  const result = {};
  let temp;
  let i;
  let key;
  let value;
  let index;

  do {
    i = queryString.indexOf('&');
    temp = i === -1 ? queryString : queryString.substr(0, i);
    index = temp.indexOf('=');
    if (index === -1) {
      index = temp.length;
    }
    key = decode(temp.substr(0, index));
    value = decode(temp.substr(index + 1));

    if (result[key] === undefined) {
      result[key] = value;
    } else {
      if (!(result[key] instanceof Array)) {
        result[key] = [result[key]];
      }
      result[key].push(value);
    }

    queryString = i === -1 ? '' : queryString.substr(i + 1);
  } while (i !== -1);

  return result;
};
