/**
 * Types of dialogs, meant for `type` property when a dialog is opened
 */

  export default {
    DETAIL_ZOOM: 'detail-zoom',
    NEWSLETTER: 'newsletter',
    QUICKSHOP: 'quickshop',
    SIZE_GUIDE: 'size-guide',
    PERSONAL_STYLIST: 'personal-stylist',
    RETAIL_ACQUISITION: 'retail-acquisition',
    STOCK_LOCATOR: 'stock-locator',
    STOCK_NOTIFICATION: 'stock-notification',
    VIDEO: 'video',
    SHIPPING_INFORMATION: 'shipping-information',
    FREQUENTLY_ASKED_QUESTIONS: 'frequently-asked-questions',
    QR_CODE: 'qr-code',
  };

