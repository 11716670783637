/* global AppSettings */
/**
 * Notification messages functionality
 *
 * @author Andy Lemaire <andy.lemaire@elision.eu>
 */
import $ from 'jquery';
import EventTypes from 'components/EventTypes';
import Singleton from 'components/utils/Singleton';
import LocalStorage from 'components/utils/storage/Storage';
import createLogger from 'components/logger/Logger';
import Mustache from 'mustache';

const Logger = createLogger('NotificationMessages');

const $document = document.jq;

const notificationsActiveClassSelector = 'has-activeNotificationMessages';
const notificationMessageTemplate =
  '<div class="notificationMessage js-notificationMessage" data-notification-code="{{{messageCode}}}">{{#messageTitle}}<h4 class="notificationMessage-heading">{{{messageTitle}}}</h4>{{/messageTitle}}{{#messageText}}<p>{{{messageText}}}</p>{{/messageText}}{{#messageLink.url}}<a class="notificationMessage-button{{#messageLink.styleModifiers}} {{{messageLink.styleModifiers}}}{{/messageLink.styleModifiers}}" href="{{{messageLink.url}}}">{{{messageLink.label}}}</a>{{/messageLink.url}}</div>';

function NotificationMessages() {
  this.$notificationTrigger = $('.js-notificationMessagesTrigger');
  this.$notificationTriggerClose = $('.js-notificationMessage-closeButton');
  this.$notificationCount = $('.js-notificationCount');
  this.$notificationMessageContainer = $('.js-notificationMessageContainer');
  this.$notificationMessageContent = $('.js-notificationMessageContent');

  this.bindEvents();
  this.getNotifications();
}

$.extend(NotificationMessages.prototype, {
  bindEvents() {
    $document
      .on(EventTypes.NOTIFICATION_VIEW_HIDE, this.hideNotifications.bind(this))
      .on(EventTypes.NOTIFICATION_VIEW_SHOW, this.showNotifications.bind(this));
    this.$notificationTrigger.on('vclick', this.toggleNotifications.bind(this));
    this.$notificationTriggerClose.on('vclick', this.closeNotifications.bind(this));
  },

  closeNotifications() {
    $document.trigger(EventTypes.NOTIFICATION_VIEW_HIDE);
  },

  getNotifications() {
    $.ajax({
      type: 'get',
      cache: true,
      url: AppSettings.endpoints.notificationMessages,
      dataType: 'json',
    })
      .done(
        function (notification) {
          let notificationCount = notification.length;

          if (notificationCount > 0) {
            let notificationContent = '';

            $.each(notification, function (i) {
              const notificationCode = notification[i].code;
              const notificationLink = notification[i].link;
              const notificationText = notification[i].text;
              const notificationTitle = notification[i].title;

              const notificationMessage = Mustache.render(notificationMessageTemplate, {
                messageCode: notificationCode,
                messageTitle: notificationTitle,
                messageText: notificationText,
                messageLink: notificationLink,
              });

              if (LocalStorage.getItem(notificationCode)) {
                notificationCount -= 1;
              }

              notificationContent += notificationMessage;
            });

            this.setNotificationsCount(notificationCount);
            this.$notificationMessageContent.html(notificationContent);
          }
        }.bind(this)
      )
      .fail(function (response) {
        Logger.warn(`Notification messages failed: ${response}`);
      });
  },

  hideNotifications() {
    document.body.classList.toggle(notificationsActiveClassSelector, false);
    this.$notificationTrigger.attr('aria-expanded', false);
    this.$notificationMessageContainer.deActivate().attr('aria-hidden', true);
  },

  setNotificationsCount(notificationCount) {
    const notificationTriggerLabel = this.$notificationTrigger.attr('data-label');
    this.$notificationTrigger.attr(
      'aria-label',
      notificationTriggerLabel.replace('{0}', notificationCount)
    );

    if (notificationCount > 0) {
      this.$notificationCount.html(notificationCount);
    } else {
      this.$notificationCount.empty();
    }
  },

  showNotifications() {
    document.body.classList.toggle(notificationsActiveClassSelector, true);
    this.$notificationTrigger.attr('aria-expanded', true);
    this.$notificationMessageContainer.activate().attr('aria-hidden', false);

    $('.js-localeSelector-close').click();

    const notificationMessageClass = [...document.getElementsByClassName('js-notificationMessage')];
    notificationMessageClass.forEach(function (element) {
      const $element = $(element);
      const notificationCode = $element.data('notification-code');

      LocalStorage.setItem(notificationCode, true);
    });

    this.setNotificationsCount(0);
  },

  toggleNotifications(event) {
    event.preventDefault();

    if (!this.$notificationMessageContainer.isActive()) {
      $document.trigger(EventTypes.LOCALE_SELECTOR_HIDE);
      $document.trigger(EventTypes.NOTIFICATION_VIEW_SHOW);
    } else {
      $document.trigger(EventTypes.NOTIFICATION_VIEW_HIDE);
    }
  },
});

export default Singleton.create(NotificationMessages, 'NotificationMessages');
