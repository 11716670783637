/**
 * Toggle the search bar in the header
 *
 * @author Andy Lemaire
 */

import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';

const showSearchClass = 'topNavigation--showSearch';
const Logger = createLogger('ToggleSearch');

export default class ToggleSearch {
  constructor(options) {
    this.searchToggleButton = document.querySelectorAll(options.selector);
    this.initialize();
  }

  /**
   * Initialize
   */
  initialize() {
    Logger.info('Initialized');
    this.bindEvents();
  }

  /**
   * Bind events
   */
  bindEvents() {
    const onClick = (event) => this.onClick(event);
    this.searchToggleButton.forEach((element) => element.addEventListener('click', onClick));
  }

  /**
   * Toggle class for visibility of the search bar
   */
  onClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const suggestedSearchSelector = document.querySelector('.topNavigation--checkout .js-suggestedSearch');
    const suggestedSearch = suggestedSearchSelector ? suggestedSearchSelector : document.querySelector('.topNavigation');

    if (suggestedSearch) {
      suggestedSearch.classList.toggle(showSearchClass);
      suggestedSearch
        .querySelector('.js-suggestedSearch--topNavigation .js-suggestedSearch-input')
        .focus();

      document.jq.trigger(EventTypes.SUGGESTED_SEARCH_RESULTS_HIDDEN);
    }
  }
}
