/**
 * Handles hidden states of the search bar in top navigation.
 *
 * @author Tamara Bazko
 */
import createLogger from '../logger/Logger';
import AnalyticsEventTypes from '../analytics/AnalyticsEventTypes';
import EventTypes from '../EventTypes';
import matchBreakpoint from '../utils/matchBreakpoint';
import defineLazyProperties from '../utils/defineLazyProperties';

import '../../../g-star/jquery/resizeEvents';
import '../domutils/Element.jQuery';
const Logger = createLogger('Search');
function qs(selector) {
  return document.querySelector(selector);
}

const TopBarSearch = {
  mainContainer: undefined,
  search: undefined,
  footer: undefined,
  topNav: undefined,
  sideNavActive: false,
  previousBreakpointXsmall: false,

  initialize() {
    Logger.measure(this, ['initialize']);
    const topNavSelector = '.js-topNavigation';
    const searchSelector = '.js-suggestedSearch--topNavigation';

    defineLazyProperties(this, {
      mainContainer: () => qs('.js-mainContainer'),
      footer: () => qs('.js-footer'),
      topNav: () => qs(topNavSelector),
      search: () => qs(`${topNavSelector} ${searchSelector}`),
      searchSubmit: () => qs(`${topNavSelector} ${searchSelector} [type=submit]`),
    });

    if (!matchBreakpoint('xsmall')) {
      this.previousBreakpointXsmall = true;
    }
    this.bindEvents();
    this.bindResolutionDependentEvents();
  },

  bindEvents() {
    document.jq.on(
      [EventTypes.SIDENAV_HIDE, EventTypes.SIDENAV_SHOW].join(' '),
      this.sideNavStateUpdate.bind(this)
    );
    window.jq.on('resizeEnd', this.bindResolutionDependentEvents.bind(this));

    if (!this.searchSubmit) {
      return;
    }

    const onClick = event => this.onClick(event);
    this.searchSubmit.addEventListener('click', onClick);
  },

  bindResolutionDependentEvents() {
    if (!matchBreakpoint('xsmall') && this.previousBreakpointXsmall) {
      document.jq
        .on(EventTypes.SUGGESTED_SEARCH_RESULTS_VISIBLE, this.showSearchListOnly.bind(this))
        .on(EventTypes.SUGGESTED_SEARCH_RESULTS_HIDDEN, this.showAll.bind(this));
    } else if (matchBreakpoint('xsmall') && !this.previousBreakpointXsmall) {
      document.jq
        .off(EventTypes.SUGGESTED_SEARCH_RESULTS_VISIBLE)
        .off(EventTypes.SUGGESTED_SEARCH_RESULTS_HIDDEN);
    }
    this.previousBreakpointXsmall = matchBreakpoint('xsmall');
  },

  onClick(event) {
    event.preventDefault();
    const form = event.currentTarget.closest('form');
    const input = form.querySelector('input[type="text"]');

    if (!input.value) {
      input.focus();
      return;
    }

    // When both values of screenX and screenY are 0, Enter has been clicked
    const eventType = event.screenX === 0 && event.screenY === 0 ? 'enter' : 'click';

    document.jq.trigger(AnalyticsEventTypes.INTERNAL_SEARCH, {
      action: eventType,
      searchTerm: input.value,
    });

    form.submit();
  },

  sideNavStateUpdate(event) {
    this.sideNavActive = event.type === EventTypes.SIDENAV_SHOW;
  },

  showSearchListOnly(event, location) {
    location = location || 'topNav';

    if (location === 'topNav') {
      this.mainContainer.classList.add('is-hidden');
      this.footer.classList.add('is-hidden');
      this.search.jq.trigger(EventTypes.TOPNAV_SCROLL_PAUSE_REQUEST);
    }
  },

  showAll() {
    this.mainContainer.classList.remove('is-hidden');
    this.footer.classList.remove('is-hidden');

    if (!this.sideNavActive) {
      this.search.jq.trigger(EventTypes.TOPNAV_SCROLL_RESUME_REQUEST);
    }
  },
};

export default TopBarSearch;
