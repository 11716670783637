/**
 * Adds special jQuery resize events:
 *
 * - `resizeEnd`
 *   Called 250ms after last `resize` event.
 *
 * - `resizeHeightEnd`
 *   Called 250ms after last `resize` event only when the height of browser has changed since `resizeStart`.
 *
 * - `resizeHeightUpdate`
 *   Called every `requestAnimationFrame` while user is resizing the browsers height.
 *
 * - `resizeWidthEnd`
 *   Called 250ms after last `resize` event only when the width of browser has changed since `resizeStart`.
 *
 * - `resizeWidthUpdate`
 *   Called every `requestAnimationFrame` while user is resizing the browsers width.
 *
 * - `resizeStart`
 *   Called when users starts resizing the screen. User needs to stop resizing for 250ms
 *   to get a new `resizeStart` event.
 *
 * - `resizeUpdate`
 *   Called every `requestAnimationFrame` while user is resizing the browser.
 *
 * Usage:
 * ```
 * $(window).on('resizeUpdate', function(event) {
 *   // update stuff
 * });
 * ```
 *
 * Events are called within a `requestAnimationFrame` call.
 *
 * @author  Meinaart van Straalen
 */

import $ from 'jquery';
import '../../app/components/domutils/Element.jQuery';

const ResizeEvents = {
  // The time (in milliseconds) after which we considered the user stopped resizing
  delay: 250, // ms

  // jQuery events this plugin makes available
  events: [
    'resizeEnd',
    'resizeHeightEnd',
    'resizeHeightUpdate',
    'resizeStart',
    'resizeUpdate',
    'resizeWidthEnd',
    'resizeWidthUpdate',
  ],

  /**
   * Create jQuery special event
   * @param  {String} eventType Event name
   */
  createSpecialEvent: function createSpecialEvent(eventType) {
    $.event.special[eventType] = {
      /**
       * Called by jQuery when the first listener for this event is bound
       */
      setup() {
        const data = {
          type: eventType,
          running: false,
        };

        // Set initial sizes, used for comparison later on to determine size has changed
        switch (eventType) {
          case 'resizeHeightEnd':
          case 'resizeHeightUpdate':
            data.height = ResizeEvents.getElementHeight(this);
            break;
          case 'resizeWidthEnd':
          case 'resizeWidthUpdate':
            data.width = ResizeEvents.getElementWidth(this);
        }

        this.jq.data(eventType, data).on(`resize.${eventType}`, data, ResizeEvents.onResize);
      },

      /**
       * Called by jQuery when the last listener for this event is unbound
       */
      teardown() {
        const $element = this.jq;
        const data = $element.data(eventType);

        if (data) {
          $element.off(`resize.${eventType}`).removeData(eventType);

          if (data.timeoutId) {
            window.clearTimeout(data.timeoutId);
            delete data.timeoutId;
          }
        }
      },
    };
  },

  /**
   * Initialize special resizeEvents
   */
  initialize: function initialize() {
    // Create events for every event in the `plugin.events` array
    this.events.forEach(this.createSpecialEvent, this);
  },

  /**
   * Get height of element
   * @param  {Element} element Element or `window`
   * @return {Number}         Height of element
   */
  getElementHeight(element) {
    return element === window ? element.innerHeight : element.offsetHeight;
  },

  /**
   * Get width of element
   * @param  {Element} element Element or `window`
   * @return {Number}         Width of element
   */
  getElementWidth(element) {
    return element === window ? element.innerWidth : element.offsetWidth;
  },

  /**
   * Called when event is considered done. For end events this will be after delay.
   * For other events it will be on next `requestAnimationFrame`.
   *
   * This function ends timeouts, set `data.running` to false and trigger events on element (except `resizeStart`).
   *
   * @param  {Object} data Object containing `type`, `running` and (optional) `timeoutId` properties.
   */
  onEventDone: function onEventDone(data, alreadyInRequestAnimationFrame) {
    data.running = false;

    // eslint-disable-next-line consistent-this
    const $element = this;
    let trigger = data.type !== 'resizeStart';
    let height;
    let width;

    switch (data.type) {
      case 'resizeEnd':
      case 'resizeStart':
        delete data.timeoutId;
        break;
      case 'resizeHeightEnd':
      case 'resizeHeightUpdate':
        height = ResizeEvents.getElementHeight($element.get(0));

        if (data.height === height) {
          trigger = false;
        } else {
          data.height = height;
        }
        break;
      case 'resizeWidthEnd':
      case 'resizeWidthUpdate':
        width = ResizeEvents.getElementWidth($element.get(0));

        if (data.width === width) {
          trigger = false;
        } else {
          data.width = width;
        }
        break;
    }

    if (trigger) {
      if (alreadyInRequestAnimationFrame) {
        $element.trigger(data.type);
      } else {
        requestAnimationFrame($element.trigger.bind($element, data.type));
      }
    }
  },

  /**
   * Poll function, called on `resize` event
   * @param  {Object} event Object containing `type` property
   */
  onResize: function onResize(event) {
    const data = event.data || {};
    const eventType = data.type;

    const $element = this.jq;

    switch (eventType) {
      case 'resizeEnd':
      case 'resizeHeightEnd':
      case 'resizeStart':
      case 'resizeWidthEnd':
        if (eventType === 'resizeStart' && !data.running) {
          requestAnimationFrame($element.trigger.bind($element, eventType));
        }

        data.running = true;

        // Clear the timer if we are still resizing
        // so that the delayed function is not exectued
        if (data.timeoutId) {
          window.clearTimeout(data.timeoutId);
        }

        data.timeoutId = window.setTimeout(
          ResizeEvents.onEventDone.bind($element, data, false),
          ResizeEvents.delay
        );
        break;
      case 'resizeHeightUpdate':
      case 'resizeUpdate':
      case 'resizeWidthUpdate':
        if (!data.running) {
          data.running = true;
          requestAnimationFrame(ResizeEvents.onEventDone.bind($element, data, true));
        }
        break;
    }
  },
};

ResizeEvents.initialize();
