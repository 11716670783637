/**
 * Unicode planes
 *
 * To restrict input for certain input fields, regular expressions are used
 * which are created based on character ranges as shown below.
 *
 * CJK stands for Chinese-Japanese-Korean
 * @see https://en.wikipedia.org/wiki/Plane_(Unicode)
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */

  const Utf8Blocks = {
    CJK_PUNCTUATION: '\u3000-\u303F',
    CJK_UNIFIED: '\u4E00-\u9FAF',
    CJK_UNIFIED_EXTENSION_A: '\u3400-\u4DBF',
    HIRAGANA: '\u3040-\u309F',
    KATAKANA: '\u30A0-\u30FF',
    KOREAN_HANGUL: '\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uD7AF\uD7B0-\uD7FF',
    HALFWIDTH_FULLWIDTH: '\uFF00-\uFFEF',
    HALFWIDTH_FULLWIDTH_NUMBERS: '\uFF10-\uFF19',
    HALFWIDTH_FULLWIDTH_LATIN: '\uFF21-\uFF3A\uFF41-\uFF5A',
    HALFWIDTH_FULLWIDTH_MISC: '\uFF00-\uFF0F\uFF1A-\uFF20\uFF3B-\uFF40',
    LATIN_NUMBERS: '0-9',
    LATIN:
      'a-zA-Z\u0000-\u007F\u0080-\u00FF\u0100-\u017F\u0180-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF',
    LATIN_PUNCTUATION: '-.,;+',

    // eslint-disable-next-line no-useless-escape
    WHITESPACE: 's ',
  };

  export default Utf8Blocks;

