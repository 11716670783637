/* global */
/**
 * Contains helper functions for Analytics.
 *
 * @author Meinaart van Straalen
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import createLogger from '../logger/Logger';
import './../domutils/Element.jQuery';

const Logger = createLogger('Analytics');

export default class Analytics {
  constructor() {
    Logger.time('initialize');
    this.bindEvents();
  }

  bindEvents() {
    $(() => {
      try {
        originalLocationToDataLayer();
      } catch (error) {
        console.error('Unable to set or get "originalLocation" from session storage: ', error);
      }
    });
    $(window).on('beforeunload', () => {
      try {
        window.sessionStorage.removeItem('originalLocation');
      } catch (error) {
        console.error('Unable to remove "originalLocation" from session storage: ', error);
      }
    });
    document.body.jq
      .on('vclick', '.js-OptOutLevelOne', optOutHandler.bind(this, 0))
      .on('vclick', '.js-OptOutLevelTwo', optOutHandler.bind(this, 1));
  }
}

/**
 * Handle click on opt-out button
 * LevelOne is opting out for UA
 * LevelTwo is opting out for all GTM
 *
 * Set cookie for one year to true
 */
function optOutHandler(level) {
  const levels = ['optOutLevelOne', 'optOutLevelTwo'];
  const cookieConfig = {
    expires: 365,
    path: '/',
  };

  if (level >= levels.length) {
    Logger.error('Invalid OptOut level.');
    return;
  }

  Logger.debug(`Setting optout in cookie to level ${level}`);
  $.cookie(levels[level], 'true', cookieConfig);
}

function originalLocationToDataLayer() {
  const pathFromStorage = window.sessionStorage.getItem('originalLocation');
  const originalLocation = pathFromStorage || document.location.href;

  if (!pathFromStorage) window.sessionStorage.setItem('originalLocation', originalLocation);

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'originalLocation',
    originalLocation,
  });
}
