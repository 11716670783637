/**
 * Handler to use transitionend events. If not supported the event will be dispatched on supplied timeout.
 *
 * Events will be bind for one time use (ie: $object.one).
 *
 * @author  Meinaart van Straalen
 */
import $ from 'jquery';
  const DEFAULT_TIMEOUT = 250; // ms

  const eventNamespace = '.bindTransitionEnd';
  const transitionEndEvents = [
    'webkitTransitionEnd',
    'otransitionend',
    'oTransitionEnd',
    'msTransitionEnd',
    'transitionend',
  ];
  const transitionEndEvent = transitionEndEvents.join(`${eventNamespace} `) + eventNamespace;
  const eventName = 'transitionend';
  const timerName = 'bindTransitionEndTimerId';

  /**
   * Clears timer
   * @param  {Number} timerId ID of timer, if not supplied it tries to retrieve it via `.data`
   */
  const clearTimer = function clearTimer(timerId) {
    const dataTimerId = this.data(timerName);
    timerId = timerId || dataTimerId;

    if (timerId !== undefined) {
      clearInterval(timerId);
    }

    if (dataTimerId === timerId) {
      this.removeData(timerName);
    }

    return this;
  };

  /**
   * Listener for when transitionend event occurs
   * @param  {Number} timerId ID of associated timer
   * @param  {jQuery.Event} event   jQuery event
   */
  const handleTransitionEnd = function handleTransitionEnd(timerId, event) {
    clearTimer.call(this, timerId);

    if (!event || event.type !== eventName) {
      this.trigger(eventName);
    }

    return this.off(eventNamespace);
  };

  /**
   * Listen to transitionend event. If it's not dispatched before timeout it's automatically triggered.
   * @param  {Function} callback Event callback function
   * @param  {int}      timeOut  Timeout in ms, the event will always be dispatched at this time.
   * @param  {boolean}  keepExistingTimer If true last timer is still considered valid, otherwise new timeout is started (previous one is cancelled)
   * @return {object}            Supplied $object
   */
  $.fn.bindTransitionEnd = function bindTransitionEnd(callback, timeOut, keepExistingTimer) {
    timeOut = timeOut || DEFAULT_TIMEOUT;

    let timerId = this.data(timerName);

    if (!keepExistingTimer) {
      if (timerId !== undefined) {
        clearTimer.call(this, timerId);
        timerId = undefined;
      }

      this.off(eventNamespace);
    }

    if (timerId === undefined) {
      timerId = setTimeout(this.trigger.bind(this, eventName), timeOut);
      this.data(timerName, timerId).one(
        transitionEndEvent,
        handleTransitionEnd.bind(this, timerId)
      );
    }

    return this.one(eventName + eventNamespace, callback);
  };

  /**
   * Remove listeners previously bound via `bindTransitionEnd`
   */
  $.fn.unbindTransitionEnd = function unbindTransitionEnd() {
    clearTimer.call(this);
    return this.off(eventNamespace);
  };

