/* global AppSettings */
/**
 * Locale selector
 *
 * @author Andy Lemaire
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import createLogger from './logger/Logger';
import EventTypes from './EventTypes';
import matchBreakpoint from './utils/matchBreakpoint';
import Storage from './utils/storage/Storage';
import StorageKeys from './utils/storage/StorageKeys';
import keyboardInput from './utils/keyboardInput';
import requestIdleCallback from './utils/requestIdleCallback';

const Logger = createLogger('localeSelector');

export default class LocaleSelector {
  localeSelectClass = '.js-localeSelector';

  countrySelectClass = `${this.localeSelectClass}-country`;

  languageSelectClass = `${this.localeSelectClass}-language`;

  languageHiddenInputClass = `${this.languageSelectClass}HiddenInput`;

  localeDialogTriggerClass = `${this.localeSelectClass}Trigger`;

  isVisibleSelector = 'is-visible';

  constructor(selector) {
    Logger.info('Initialized');
    this.localeSelectors = Array.from(document.querySelectorAll(selector));

    if (!this.localeSelectors.length) {
      return;
    }

    const localeDialogTriggers = document.querySelectorAll(this.localeDialogTriggerClass);
    let localeSelectorsToRender = [...this.localeSelectors];

    if (localeDialogTriggers.length) {
      localeDialogTriggers.forEach(localeDialogTrigger => {
        localeDialogTrigger.jq.on('vclick', this.toggleDialog.bind(this));
      });

      requestIdleCallback(() => {
        this.checkIpLocale(this.localeSelectors[0]);
      });

      localeSelectorsToRender = this.localeSelectors.slice(1);
    }

    localeSelectorsToRender.forEach(localeSelector => {
      this.renderSelectors(localeSelector)
        .then(this.bindEvents.bind(this))
        .catch(message => Logger.error(message));
    });
  }

  bindEvents() {
    this.localeSelectors.forEach(localeSelector => {
      const countrySelect = localeSelector.querySelector(this.countrySelectClass);
      const languageSelect = localeSelector.querySelector(this.languageSelectClass);

      countrySelect.jq.on('change', event => this.onCountryChange(event));
      languageSelect.jq.on('change', event => this.onLanguageChange(event));
    });
  }

  bindDialogEvents(localeDialogSelector) {
    const closeSelector = `${this.localeSelectClass}-close`;
    function unbindEvents() {
      localeDialogSelector.jq
        .off('vclick', closeSelector)
        .off('keyup', closeSelector)
        .off('vclick', `${this.localeSelectClass}-changeLocaleButton`);
      this.hideDialog(localeDialogSelector, undefined, true);
    }
    localeDialogSelector.jq
      .on('vclick', closeSelector, unbindEvents.bind(this))
      .on(
        'keyup',
        closeSelector,
        keyboardInput.handleKeys.bind(this, 'enter', () => {
          this.hideDialog(localeDialogSelector, undefined, true);
        })
      )
      .on('vclick', `${this.localeSelectClass}-changeLocaleButton`, event => {
        const currentLanguage = window?.AppSettings.locale;
        const selectedLanguage = document.querySelector(this.languageSelectClass);
        if(currentLanguage?.toLowerCase() !== selectedLanguage?.value?.toLowerCase()) {
          this.pushToDataLayer('submit');
        }
        event.preventDefault();
        document.jq.trigger(EventTypes.LOCALE_LANGUAGE_CHANGED);
        selectedLanguage && selectedLanguage.jq.trigger('change');
        const target = event.currentTarget;
        const localeForm = target.closest('form');
        localeForm.submit();
      });
  }

  checkIpLocale(localeSelector) {
    if (localeSelector.classList.contains('js-localeSelectorDialog')) {
      const isLocaleMatch = this.checkLocaleMatch(localeSelector);

      if (!isLocaleMatch) {
        const countryFromIpLocale = this.getCountryFromIpLocal();
        if (matchBreakpoint('xsmall')) {
          if (['en_ca', 'en_at'].indexOf(AppSettings.locale) > -1) {
            if (Storage.getItem(StorageKeys.COUNTRY_SELECTOR_FORCED_SHOWN, false) !== 'true') {
              this.showDialog(localeSelector, false, countryFromIpLocale);
              Storage.setItem(StorageKeys.COUNTRY_SELECTOR_FORCED_SHOWN, 'true', false);
            }
          } else {
            setTimeout(() => {
              this.showDialog(localeSelector, true, countryFromIpLocale);
            }, 1500);
          }
        }
      }
    }
  }

  checkLocaleMatch(localeSelector) {
    const autoToggle = Storage.getItem(StorageKeys.COUNTRY_SELECTOR_TOGGLE) !== true;

    if (!autoToggle || !matchBreakpoint('xsmall')) {
      return true;
    }

    const cookieLocale = this.getCookie('ipLocale').toLowerCase();

    if (!cookieLocale || cookieLocale === AppSettings.locale.toLowerCase()) {
      return true;
    }

    const countryFromIpLocale = cookieLocale.split('_').pop();
    const countryFromCurrent = AppSettings.country.toLowerCase();

    if (countryFromIpLocale === countryFromCurrent) {
      return true;
    }

    const languageOptionsTemplate = localeSelector.querySelector('.localeSelector-country-options')
      ?.textContent;
    const ipCountryAvailable =
      languageOptionsTemplate &&
      languageOptionsTemplate.match(new RegExp(`value="${countryFromIpLocale}"`));

    if (!ipCountryAvailable) {
      return true;
    }

    return false;
  }

  getCountryFromIpLocal(countrySelect, languageSelect) {
    const ipLocale = this.getCookie('ipLocale');
    return ipLocale.split('_').pop() || AppSettings.country;
  }

  getCookie(cookieName) {
    if (typeof cookieName !== 'string') {
      throw new Error('cookieName should be a string');
    }

    const regExp = new RegExp(`${cookieName}=(.*?);`);
    return document.cookie.match(regExp)?.[1] || '';
  }

  cloneOptions(select) {
    const clonedOptions = [];
    for (let i = 0; i < select.options.length; i++) {
      clonedOptions.push(select.options[i].cloneNode(true));
    }
    return clonedOptions;
  }

  hideDialog(localeSelector, autoToggle, sendToDataLayer) {
    if(sendToDataLayer) {
      this.pushToDataLayer('close');
    }
    const countrySelect = localeSelector.querySelector(this.countrySelectClass);
    const languageSelect = localeSelector.querySelector(this.languageSelectClass);

    if (autoToggle && autoToggle instanceof $.Event) {
      const event = autoToggle;
      event.preventDefault();
      event.stopPropagation();
    }

    document.body.classList.remove('has-activeLocaleSelector');
    countrySelect.blur();
    languageSelect.blur();
    localeSelector.setAttribute('aria-expanded', false);
    localeSelector.setAttribute('aria-hidden', true);

    if (autoToggle !== true) {
      Storage.setItem(StorageKeys.COUNTRY_SELECTOR_TOGGLE, true, true, true);
    }

    document.jq.trigger(EventTypes.LOCALE_SELECTOR_HIDE);
  }

  onCountryChange(event) {
    const select = event.currentTarget;
    const localeSelector = select.closest(this.localeSelectClass);
    const languageSelect = localeSelector.querySelector(this.languageSelectClass);
    const languages = select.options[select.selectedIndex].dataset.languages.split(',');

    this.setLanguages(languageSelect, languages);
    Storage.setItem(StorageKeys.COUNTRY_SELECTOR_TOGGLE, true, true, true);
  }

  onLanguageChange(event) {
    const select = event.currentTarget;
    const form = select.closest('form');
    const languageHiddenInput = form.querySelector(this.languageHiddenInputClass);
    languageHiddenInput.value = select.value;
  }

  render(countrySelect, languageSelect, countryFromIpLocale) {
    const countrySelectOptions = countrySelect?.nextElementSibling?.textContent;
    countrySelect.insertAdjacentHTML('beforeend', countrySelectOptions);

    if (countryFromIpLocale) {
      countrySelect.value = countryFromIpLocale;
    }

    const languageSelectOptions = languageSelect?.nextElementSibling?.textContent;
    languageSelect.insertAdjacentHTML('beforeend', languageSelectOptions);

    return new Promise((resolve, reject) => {
      this.allLanguageOptions = this.cloneOptions(languageSelect);
      const languages = countrySelect.options[countrySelect.selectedIndex].dataset.languages.split(
        ','
      );

      if (!languages) {
        reject('No languages.');
      }

      if (languageSelect) {
        this.setLanguages(languageSelect, languages);
      }

      resolve();
    });
  }

  renderSelectors(localeSelector, countryFromIpLocale) {
    return new Promise((resolve, reject) => {
      const countrySelect = localeSelector.querySelector(this.countrySelectClass);
      const languageSelect = localeSelector.querySelector(this.languageSelectClass);

      this.render(countrySelect, languageSelect, countryFromIpLocale).then(resolve, reject);
    }).catch(reason => Logger.debug(reason));
  }

  selectMatchingLanguage(select, languages) {
    const ipLocale = this.getCookie('ipLocale');
    // prefer selected option by backend (if present)
    for (const option of select.options) {
      if (option.selected) {
        this.toggleLanguageSelectState(select, languages);
        return;
      }
    }
    if (!ipLocale) {
      this.toggleLanguageSelectState(select, languages);
      return;
    }

    let languageValue = ipLocale.toUpperCase();

    if (this.getCookie('userLocale') || select.closest('.js-localeSelector-newsletterForm')) {
      const form = select.closest('form');
      const languageHiddenInput = form.querySelector(this.languageHiddenInputClass);

      languageValue = languageHiddenInput.value.substring(0, 3);
    }

    const matchingLanguages = languages.filter(language => language.includes(languageValue));

    if (matchingLanguages.length) {
      select.value = matchingLanguages[0];
    } else {
      select.value = select.options[0].value;
    }

    this.toggleLanguageSelectState(select, languages);
  }

  setLanguages(languageSelect, languages) {
    languageSelect.disabled = false;
    languageSelect.removeAttribute('aria-disabled');

    if (!languageSelect || !languages) {
      return;
    }

    // clear all options
    languageSelect.innerHTML = '';

    // append matching options
    this.allLanguageOptions.forEach(option => {
      if (languages.indexOf(option.value) > -1) {
        languageSelect.appendChild(option);
      }
    });

    this.selectMatchingLanguage(languageSelect, languages);
  }

  showDialog(localeSelector, autoToggle, countryFromIpLocale) {
    this.pushToDataLayer('open');
    if (localeSelector.dataset.isRendered !== 'true') {
      this.renderSelectors(localeSelector, countryFromIpLocale)
        .then(() => {
          document.jq.trigger(EventTypes.LOCALE_SELECTOR_RENDERED);
          localeSelector.dataset.isRendered = 'true';
        })
        .then(this.bindEvents.bind(this))
        .catch(message => Logger.error(message));
    }

    localeSelector.setAttribute('aria-expanded', true);
    localeSelector.setAttribute('aria-hidden', false);

    document.jq.one('vclick', event => {
      let filterSet = localeSelector;

      if (localeSelector.jq.index(this.localeDialogTriggerClass) === -1) {
        const localeDialogTrigger = document.querySelector(this.localeDialogTriggerClass);
        if (localeDialogTrigger) {
          filterSet = [localeSelector, localeDialogTrigger];
        }
      }

      const isActive = filterSet.filter(element =>
        element.classList.contains(this.isVisibleSelector)
      ).length;
      const isDescendant = filterSet.filter(element => element.contains(event.target)).length;

      if (isActive && !isDescendant) {
        this.hideDialog(localeSelector, autoToggle);
      }
    });

    document.body.classList.add('has-activeLocaleSelector');
    document.jq.trigger(EventTypes.NOTIFICATION_VIEW_HIDE);
    document.jq.trigger(EventTypes.LOCALE_SELECTOR_SHOW, {
      auto: autoToggle,
      autoDescription: autoToggle ? 'Automatic' : 'User initiated',
    });

    this.bindDialogEvents(localeSelector);
  }

  toggleDialog(event, autoToggle) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const target = event.currentTarget;
    const controlledTargetId = target.getAttribute('aria-controls');
    const controlledTarget = this.localeSelectors.filter(
      localeSelector => localeSelector.id === controlledTargetId
    )[0];
    const isHidden = controlledTarget.getAttribute('aria-hidden');

    if (isHidden === 'true') {
      this.showDialog(controlledTarget, autoToggle);

      if (target.hasAttribute('aria-haspopup')) {
        target.setAttribute('aria-expanded', true);
      }
    } else {
      this.hideDialog(controlledTarget, autoToggle);

      if (target.hasAttribute('aria-haspopup')) {
        target.setAttribute('aria-expanded', false);
      }
    }
  }

  toggleLanguageSelectState(select, languages) {
    const multipleLanguages = languages.length > 1;
    select.disabled = !multipleLanguages;
    select.setAttribute('aria-disabled', !multipleLanguages);
    select.jq.trigger('change');
  }

  pushToDataLayer(action, event = 'select_locale') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      events: {
        category: event,
        action: action,
      },
      event,
    });
  }
}
