/* global AppState */
/**
 * Util object that gives information about the user's browser.
 *
 * @see tags/responsive/template/assets/detection.tag
 */
import defineProperties from './defineProperties';
import defineLazyProperties from './defineLazyProperties';

const ua = navigator.userAgent;

function getAndroidVersion() {
  const match = ua.match(/Android\s([0-9.]*)/);
  return match ? match[1] : false;
}

function getIosVersion() {
  return (
    parseFloat(
      `${(/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(ua) || [0, ''])[1]}`
        .replace('undefined', '3_2')
        .replace('_', '.')
        .replace('_', '')
    ) || false
  );
}

/**
 * Return orientation angle
 */
function getOrientationAngle() {
  if (window.screen && window.screen.orientation === undefined) {
    window.screen.orientation = {
      angle: 0,
    };
  }

  return Math.abs(
    window.orientation !== undefined ? window.orientation : window.screen.orientation.angle
  );
}

const device = {
  ANDROID: 'Android',
  IOS: 'iOS',
  OSX: 'OSX',
  WINDOWS: 'Windows',

  ANDROID_BROWSER: 'Android browser',
  CHROME: 'Chrome',
  EDGE: 'Edge',
  FIREFOX: 'Firefox',
  IE: 'Internet Explorer',
  OPERA: 'Opera',
  OPERA_MINI: 'Opera Mini',
  SAFARI: 'Safari',
  SILK: 'Silk',
  CHROMIUM_BASED_BROWSER: 'Chromium Engine Based Browser',
  EDGE_CHROMIUM: 'Chromium EDGE',

  UNKNOWN: null,
  _hasMouse: undefined,

  initialize() {
    device.isTablet;
    device.isMobile;

    const NOT_ALL = 'not all';
    const isAndroid = this.browserName === this.ANDROID_BROWSER || this.osName === device.ANDROID;
    const mediaQueryList = window.matchMedia('(pointer: fine) and (hover: hover)');
    this._hasMouse =
      mediaQueryList && mediaQueryList.media === NOT_ALL ? !isAndroid : mediaQueryList.matches;
  },
};

Object.defineProperties(device, {
  /**
   * Return screen width, if device is rotated the height is returned instead
   * @return {Int}
   */
  screenWidth: {
    enumerable: true,
    get: () => {
      return getOrientationAngle() === 90 ? window.screen.availHeight : window.screen.availWidth;
    },
  },
  /**
   * Return window width, if device is rotated the height is returned instead
   * @return {Int}
   */
  windowWidth: {
    enumerable: true,
    get: () => {
      return getOrientationAngle() === 90 ? window.innerHeight : window.innerWidth;
    },
  },
  hasMouse: {
    enumerable: true,
    get: () => device._hasMouse,
  },
});

// Define lazy properties: those are only looked up the first time when accessed.
// Only use this for values that should not change during the lifetime of the current page.
//
// For example window size can change so nothing that has anything to do with window size should be defined here.
defineLazyProperties(device, {
  /**
   * Return name of browser (for example this.ANDROID)
   * @return {String}
   */
  browserName() {
    if (ua.indexOf('Opera') !== -1) {
      return ua.indexOf('Mini') !== -1 ? this.OPERA_MINI : this.OPERA;
    }
    if (ua.indexOf('Firefox') !== -1) {
      return this.FIREFOX;
    }
    if (ua.indexOf('Android') !== -1) {
      // Put this before Safari check because user agent of Android also contains Safari
      return ua.indexOf('Chrome') !== -1 ? this.CHROME : this.ANDROID_BROWSER;
    }
    if (ua.indexOf('Edge') !== -1) {
      return this.EDGE;
    }
    if (ua.indexOf('Edg') !== -1) {
      return this.EDGE_CHROMIUM;
    }
    if (this.isChromeBrowser === true) {
      return this.CHROME;
    }
    if (ua.match(/Chrome/i) || (ua.match(/CriOS/i) && this.isChromeBrowser !== true)) {
      return this.CHROMIUM_BASED_BROWSER;
    }
    if (ua.indexOf('Silk') !== -1) {
      return this.SILK;
    }
    if (ua.indexOf('Safari') !== -1) {
      return this.SAFARI;
    }
    if (ua.indexOf('MSIE') !== -1 || ua.match(/Trident/i)) {
      return this.IE;
    }

    return this.UNKNOWN;
  },

  /**
   * Return version of browser
   * @return {Number}
   */
  browserVersion() {
    let matches;
    if (this.browserName === this.SAFARI) {
      if (navigator.platform === 'MacIntel') {
        matches = navigator.appVersion.match(/Version\/([0-9.]+)/);
        return (matches && matches[1]) || this.UNKNOWN;
      }
      return this.osVersion;
    }
    if (ua.indexOf('Android') !== -1 || ua.match(/iPhone|iPad|iPod/i)) {
      return this.osVersion;
    }
    if (ua.indexOf('Edge/') !== -1) {
      matches = ua.match(/Edge\/([0-9.]+)/);
      return matches ? matches[1] : 12;
    }
    if (ua.indexOf('MSIE') !== -1 || ua.match(/Trident/i)) {
      matches = ua.match(/MSIE ([0-9]+)/);
      let minVersion = 9;

      if (!matches) {
        matches = ua.match(/rv:([0-9]+)/);
        minVersion = 10;
      }

      if (matches) {
        return parseInt(matches[1], 10) || minVersion;
      }
    }

    return this.UNKNOWN;
  },

  /**
   * Is DPI of the screen 2 or higher
   * @return {Boolean}
   */
  hasRetinaScreen() {
    return window.devicePixelRatio >= 2;
  },

  /**
   * Checks if device supports updating view while scrolling.
   * @return {Boolean}
   */
  hasScrollUpdate() {
    return !this.isMobile || (this.osName === this.IOS && this.osVersion >= 8);
  },

  /**
   * Does user have a touch enabled device
   * @return {Boolean}
   */
  hasTouch() {
    return !!(
      'ontouchstart' in window ||
      navigator.msMaxTouchPoints ||
      (navigator.maxTouchPoints && navigator.maxTouchPoints > 0) ||
      /touch/i.test(ua)
    );
  },

  /**
   * Is user on an ipad
   * @return {Boolean}
   */
  isIpad() {
    return ua.indexOf('iPad') !== -1;
  },

  /**
   * Return if user is using a mobile device
   * (meaning being iOS or Android, with touch)
   * @return {Boolean}
   */
  isMobile() {
    const os = this.osName;
    if (AppState.isMobile === undefined) {
      AppState.isMobile =
        (os === this.IOS || (os === this.ANDROID && /mobi/i.test(ua))) && this.hasTouch;
    }
    return AppState.isMobile;
  },

  /**
   * DO NOT USE device.isTablet() ! Only here for legacy purposes
   * @deprecated Do not use this function. It is not reliable. Use feature detection instead.
   *
   * Check if user is using a tablet; Conditions:
   * - Not on mobile
   * - Is using a device with iOS or Android,
   *   or if user agent contains 'tablet'
   *   or has a maximum screen size of 1024
   * - Has touch
   * @return {Boolean}
   */
  isTablet() {
    if (AppState.isTablet === undefined) {
      const os = this.osName;
      AppState.isTablet =
        !this.isMobile &&
        (os === this.IOS || os === this.ANDROID || (/tablet/i.test(ua) && this.hasTouch));
    }
    return AppState.isTablet;
  },

  /**
   * Has user agent webkit in its name
   * @return {Boolean}
   */
  isWebkitBrowser() {
    return ua.match(/WebKit/i);
  },

  /**
   * Name of operating system
   * @return {String}
   */
  osName() {
    if (ua.indexOf('Android') !== -1) {
      return this.ANDROID;
    }
    if (
      ua.match(/iPhone|iPad|iPod/i) &&
      // Microsoft injected the word iPhone in IE11's userAgent
      !window.MSStream
    ) {
      return this.IOS;
    }
    if (navigator.platform.match(/win/i)) {
      return this.WINDOWS;
    }
    if (navigator.platform.match(/mac/i) || ua.match(/mac/i) !== -1) {
      return this.OSX;
    }

    return this.UNKNOWN;
  },

  /**
   * Return version of Operating System (if it can be detected)
   * @return {Number}
   */
  osVersion() {
    if (ua.match(/iPhone|iPad|iPod/i)) {
      return getIosVersion();
    }
    if (ua.indexOf('Android') !== -1) {
      return getAndroidVersion();
    }
    if (navigator.platform.match(/win/i)) {
      if (ua.match(/NT 6\.1/)) {
        return 7;
      }
      if (ua.match(/NT 6\.2/)) {
        return 8;
      }
      if (ua.match(/NT 6\.3/)) {
        return 8.1;
      }
      if (ua.match(/NT 5/)) {
        // Vista or XP
        return 6;
      }

      // 10 or higher
      return 10;
    }

    return this.UNKNOWN;
  },

  /**
   * Returns the largest of screen width or height
   * @return {Int}
   */
  screenSizeMax() {
    return Math.max(window.screen.availHeight, window.screen.availWidth);
  },

  /**
   * Does browser support `pushState`
   * @return {Boolean}
   */
  supportsPushState() {
    return !!history.pushState;
  },

  isChromeBrowser() {
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edg') > -1;

    if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
      return true;
    }
  },
});

device.initialize();
export default device;
