/* global AppSettings, AppState */
/**
 * Algolia analytics tracking
 * Specifications: https://g-star.atlassian.net/l/cp/XjCA0GwA
 *
 * data-algolia-objectid
 * data-algolia-queryid
 * data-algolia-index-name
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import aa from 'search-insights';

import AnalyticsEventTypes from './AnalyticsEventTypes';
import EventTypes from '../EventTypes';
import getConfirmationPageData from './OrderConfirmationUtils';
import { hasLocalStorage } from 'components/utils/storage/Storage';

const productTileTypeMapping = {
  plp: 'PLP',
  searchresults: 'PRP',
  plp_stylevar: 'PLP_STYLEVAR',
  searchresults_stylevar: 'PRP_STYLEVAR',
};

let plpLoadPageRendered = false;
let initialized = false;

class Algolia {
  static get rsu() {
    return AppState?._rsu || $.cookie('_rsu') || '';
  }

  static get queryID() {
    return document.querySelector('[data-algolia-queryid]')?.dataset?.algoliaQueryid;
  }

  static get index() {
    return document.querySelector('[data-algolia-index-name]')?.dataset?.algoliaIndexName;
  }

  static get categoryName() {
    return document.querySelector('[data-category-name]')?.dataset?.categoryName;
  }

  /**
   * Check if event data object is properly populated
   * meaning it has truthy values for all properties
   */
  static isValid(obj) {
    return Object.values(obj)
      .map(e => (Array.isArray(e) ? e.every(Boolean) : e))
      .every(Boolean);
  }

  /**
   * Check if "Identify Me As Non-Real-User-Traffic" cookie is set
   * https://g-star.atlassian.net/wiki/spaces/ROC/pages/3227123725/Core+-+User+-+Identify+Non-Real-User-Traffic
   */
  static get imanrut() {
    return $.cookie('imanrut') || window.location.href.includes('?imanrut=true') || '';
  }

  /**
   * If Algolia index name is not empty but queryID is, this indicates that Algolia analytics has been disabled for the call.
   * As a consequence, no events should be sent Algolia as well
   */
  constructor() {
    if (Algolia.imanrut) return;
    if (Algolia.index && !Algolia.queryID) return;
    if (
      /checkout-v2/.test(document.documentElement.className) &&
      !document.getElementById('order-confirmation-page')
    ) {
      return;
    }

    this.initialize();
    this.bindEvents();
  }

  bindEvents() {
    if (!initialized) return;

    document.jq
      .on(AnalyticsEventTypes.ALGOLIA_EMIT_CONFIRMATION_PAGE_EVENT, () => {
        Algolia.emitConfirmationPageEvents();
      })
      .on(AnalyticsEventTypes.ALGOLIA_EMIT_SUGGESTION_CLICK, (_, eventData) => {
        const methodName = eventData.queryID ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs';
        Algolia[methodName](eventData, `SUGGESTION_${methodName}`);
      })
      .on(EventTypes.PLP_LOAD_PAGE_RENDERED, () => {
        plpLoadPageRendered = true;
      })
      .on(
        EventTypes.PRODUCT_DETAILS_COLOR_SELECTOR_CLICK,
        Algolia.handleProductDetailsColorSelectorClick
      )
      .on(EventTypes.SUGGESTED_SEARCH_RESULTS_RENDERED, (_, eventData) => {
        Algolia.setLocalStorageItems({ queryID: eventData?.queryID });
      });

    const productListerElement = document.querySelector('[data-algolia-queryid]');
    if (productListerElement) {
      const observer = new MutationObserver((mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'attributes') {
            Algolia.setLocalStorageItems();
          }
        }
      });
      observer.observe(productListerElement, {
        attributeFilter: ['data-algolia-queryid', 'data-algolia-index-name'],
      });
    }
  }

  initialize() {
    const { algoliaAppId: appId, algoliaInsightsApiKey: apiKey, consentCookieValue } = AppSettings;

    if (!appId || !apiKey) return;

    const cookieWallEnabled = Boolean(AppSettings?.cookieDialog?.enableWall);
    if (!consentCookieValue.match(/111/) && cookieWallEnabled) {
      Algolia.optOut();
    } else {
      aa('init', { appId, apiKey, userToken: Algolia.rsu });
      initialized = true;
      Algolia.setLocalStorageItems();
    }
  }

  static setLocalStorageItems({ queryID, objectID, index } = {}) {
    if (!hasLocalStorage()) return;
    if (queryID || Algolia.queryID) {
      localStorage.setItem('algoliaQueryID', queryID || Algolia.queryID);
    }
    if (objectID) {
      localStorage.setItem('algoliaObjectID', objectID);
    }
    if (index || Algolia.index) {
      localStorage.setItem('algoliaIndexName', index || Algolia.index);
    }
  }

  /**
   * Emits convertedObjectIDs event
   * CHECKOUT_convertedObjectIDs
   */
  static emitConfirmationPageEvents() {
    const { objectIDs } = getConfirmationPageData('algolia');

    const aaEventData = {
      userToken: Algolia.rsu,
      index: AppSettings.algoliaProductPrimaryIndexName,
      eventName: 'CHECKOUT_convertedObjectIDs',
      objectIDs,
    };

    if (initialized && Algolia.isValid(aaEventData)) {
      aa('convertedObjectIDs', aaEventData);

      // Remove the Algolia items from localStorage
      if (!hasLocalStorage()) return;
      localStorage.removeItem('algoliaIndexName');
      localStorage.removeItem('algoliaObjectID');
      localStorage.removeItem('algoliaObjectIDs');
    }
  }

  static optOut() {
    aa('userHasOptedOut', true);
    aa('useCookie', false);
  }

  // Handles current product style variant click on PDP
  static handleProductDetailsColorSelectorClick(_, { code } = {}) {
    if (!hasLocalStorage()) return;

    let objectIDs = [];
    try {
      objectIDs = JSON.parse(localStorage.getItem('algoliaObjectIDs') || '[]');
    } catch (e) {}
    const { algoliaObjectId } = objectIDs?.find(e => e?.code === code) || {};

    if (algoliaObjectId) {
      // Set localStorage item with the current product style variant algoliaObjectId
      localStorage.setItem('algoliaObjectID', algoliaObjectId);
    } else {
      // Remove the localStorage item if the product style variant code is not in the objectIDs array
      localStorage.removeItem('algoliaObjectID');
    }
  }

  static handleProductClick(eventData) {
    const { productTileType } = eventData;
    const mappedContextName = productTileTypeMapping?.[productTileType];

    if (!mappedContextName && hasLocalStorage()) {
      // Product was clicked from a non Algolia powered component, such as a PDP recommendations carousel
      localStorage.removeItem('algoliaObjectID');
      localStorage.removeItem('algoliaObjectIDs');
      return;
    }

    let variantTiles = document
      .querySelector(`[data-slug="${eventData?.click?.products[0]?.materialNumber}"]`)
      ?.querySelector('.productTile__style-variants-scroll-content');

    if (!variantTiles) {
      variantTiles = document.querySelector(
        `[data-algolia-objectid="${eventData?.click?.products[0]?.algoliaObjectId}"]`
      )?.parentElement;
    }

    let variantObjectIDs = [];
    try {
      variantObjectIDs = [...(variantTiles?.children || [])]
        .map(e => e?.dataset?.product && JSON.parse(e.dataset.product))
        .filter(Boolean)
        .map(({ code, algoliaObjectId }) => ({ code, algoliaObjectId }));
    } catch (e) {}

    if (eventData?.click?.products[0]?.objectID && hasLocalStorage()) {
      localStorage.setItem('algoliaObjectID', eventData.click.products[0].objectID);
    }
    if (variantObjectIDs.length && hasLocalStorage()) {
      localStorage.setItem('algoliaObjectIDs', JSON.stringify(variantObjectIDs));
    }

    const methodPrefix = Algolia.queryID ? '' : 'SOLR_';
    const methodName = Algolia.queryID ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs';
    const eventName = `${mappedContextName}_${methodPrefix}${methodName}`;

    Algolia[methodName](eventData, eventName);
  }

  /**
   * PLP_clickedObjectIDsAfterSearch
   * SUGGESTION_clickedObjectIDsAfterSearch (queryID present)
   * PRP_clickedObjectIDsAfterSearch (queryID present)
   */
  static clickedObjectIDsAfterSearch(eventData, eventName) {
    const {
      queryID,
      click: { products },
    } = eventData;

    const { objectIDs, positions } = products.reduce(
      (acc, product) => {
        if (product.objectID) {
          acc.objectIDs = [...acc.objectIDs, product.objectID];
          acc.positions = [...acc.positions, product.position];
        }
        return acc;
      },
      { objectIDs: [], positions: [] }
    );

    const aaEventData = {
      userToken: Algolia.rsu,
      index: Algolia.index,
      eventName,
      queryID: queryID || Algolia.queryID,
      objectIDs: objectIDs.length ? objectIDs : undefined,
      positions,
    };

    if (initialized && Algolia.isValid(aaEventData)) {
      aa('clickedObjectIDsAfterSearch', aaEventData);

      Algolia.setLocalStorageItems({ queryID, index: Algolia.index });
    }
  }

  /**
   * PLP_SOLR_clickedObjectIDs
   * SUGGESTION_clickedObjectIDs
   * PRP_SOLR_clickedObjectIDs
   */
  static clickedObjectIDs(eventData, eventName) {
    const {
      queryID,
      click: { products },
    } = eventData;

    const objectIDs = products.map(product => product.objectID);

    const aaEventData = {
      userToken: Algolia.rsu,
      index: AppSettings.algoliaProductPrimaryIndexName,
      eventName,
      objectIDs,
    };

    if (initialized && Algolia.isValid(aaEventData)) {
      aa('clickedObjectIDs', aaEventData);

      Algolia.setLocalStorageItems({ queryID, index: AppSettings.algoliaProductPrimaryIndexName });
    }
  }

  /**
   * PRP_clickedFilters
   * PLP_clickedFilters
   */
  static clickedFilters(eventData) {
    const { indexName: index, queryID, filter } = eventData;
    const { pageType } = AppSettings;

    const filters = filter.map(_filter => _filter.values.map(value => `${_filter.name}:${value}`));

    const aaEventData = {
      userToken: Algolia.rsu,
      index,
      eventName: `${pageType === 'SEARCHRESULTS' ? 'PRP' : 'PLP'}_clickedFilters`,
      filters,
      queryID,
    };

    if (initialized && Algolia.isValid(aaEventData)) {
      aa('clickedFilters', aaEventData);
    }
  }

  /**
   * Spec says: "A filter has been applied without clicking it (e.g via url parameter)"
   * This implies a direct page visit to a page with a filter applied.
   * How to check this?
   * - There is no document.referrer or the referrer is not the current page *and* has no Solr query
   *   - No referrer means a fresh page visit
   *   - Referrer is not the current page and has no Solr query means
   *     user might come from homepage clicking a button with pre-filtered URL
   * - The current URL contains a Solr query or has /fit/ in the pathname
   * - The event should not come from after plpLoadPageRendered as
   *   this implies the page has been updated by a $.ajax call
   *
   * PRP_viewedFilters
   * PLP_viewedFilters
   */
  static viewedFilters() {
    if (!initialized || !Algolia.index) {
      return;
    }
    const pageURL = new URL(document.location.href);

    if (document.referrer) {
      const referrerURL = new URL(document.referrer);
      if (
        referrerURL.toString() === pageURL.toString() ||
        referrerURL.search.match(/\?q=::/)?.length
      ) {
        return;
      }
    }

    const isTrackable = this.hasSolrQuery || this.isFitFilterSeoPage;
    if (!isTrackable || (isTrackable && plpLoadPageRendered)) {
      return;
    }

    let eventName;
    switch (AppSettings.pageType) {
      case 'SEARCHRESULTS':
        eventName = 'PRP_viewedFilters';
        break;
      case 'CATEGORY':
        eventName = 'PLP_viewedFilters';
        break;
    }

    const aaEventData = {
      userToken: Algolia.rsu,
      index: Algolia.index,
      eventName,
      filters: [
        Algolia.categoryName && `category:${encodeURIComponent(Algolia.categoryName)}`,
        ...Algolia.parseFilters(pageURL),
      ]
        .filter(Boolean)
        .slice(0, 10),
    };

    if (Algolia.isValid(aaEventData)) {
      aa('viewedFilters', aaEventData);
    }
  }

  static get hasSolrQuery() {
    return !!new URL(document.location.href).search?.match(/\?q=.*?::/)?.length;
  }

  static get isFitFilterSeoPage() {
    return new URL(document.location.href)?.pathname?.includes('/fit/');
  }

  static parseFilters(pageURL) {
    let filterAsString = [];
    if (this.hasSolrQuery) {
      const solrQuery = pageURL.searchParams.get('q');
      let filters = solrQuery?.split('::')[1]?.split(':') || [];
      while (filters.length > 0) {
        filterAsString.push(
          `${encodeURIComponent(filters.shift())}:${encodeURIComponent(filters.shift())}`
        );
      }
    }

    if (this.isFitFilterSeoPage) {
      const fitFilterValue = pageURL.pathname.split('/fit/')?.[1];
      if (fitFilterValue) {
        filterAsString = [...filterAsString, `fit:${encodeURIComponent(fitFilterValue)}`];
      }
    }
    return filterAsString;
  }

  /**
   * Via non-click action?! How to keep state?
   * URL_viewedObjectIDs
   * Missing: index and objectID
   * @unused at the moment
   */
  static viewedObjectIDs(eventData) {
    if (!initialized) return;

    const { objectIDs } = eventData;

    const aaEventData = {
      userToken: Algolia.rsu,
      index: Algolia.index,
      eventName: 'URL_viewedObjectIDs',
      objectIDs, // max 20 according to Algolia
    };

    if (Algolia.isValid(aaEventData)) {
      aa('viewedObjectIDs', aaEventData);
    }
  }

  static convertedObjectIDsAfterSearch() {
    if (!initialized || !hasLocalStorage()) return;

    const baseEventName = 'convertedObjectIDsAfterSearch';
    const queryID = Algolia.queryID || localStorage.getItem('algoliaQueryID');
    const index = Algolia.index || localStorage.getItem('algoliaIndexName');
    let objectID = localStorage.getItem('algoliaObjectID');

    if (!queryID || !objectID || !index) {
      console.log(queryID, objectID, index);
      return;
    }

    let eventName;
    switch (AppSettings.pageType) {
      case 'SEARCHRESULTS':
        eventName = `PRP_addToBasket_${baseEventName}`;
        break;
      case 'CATEGORY':
        eventName = `PLP_addToBasket_${baseEventName}`;
        break;
      case 'PRODUCT':
        eventName = `PDP_addToBasket_${baseEventName}`;
        break;
    }

    const aaEventData = {
      userToken: Algolia.rsu,
      index,
      eventName,
      queryID,
      objectIDs: [objectID],
    };

    if (Algolia.isValid(aaEventData)) {
      aa('convertedObjectIDsAfterSearch', aaEventData);
    }
  }
}

export default Algolia;
