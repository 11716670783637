/* globals AppSettings */
/**
 * Restricts the input of characters in form elements to latin characters only.
 * Shows a warning below the input box if a restricted character is typed.
 * Also removes restricted characters from input. By default all fields are restricted to LATIN input.
 *
 * test with: 中国辽宁省大连市中山区 or 漢字/汉字
 *
 * @author Tim van Oostrom - timvanoostrom@gmail.com
 * @author Meinaart van Straalen - meinaart@kayenta.nl
 * @author Vincent Bruijn - <vebruijn@gmail.com>
 *
 * http://www.rikai.com/library/kanjitables/kanji_codes.unicode.shtml
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import Mappings from './Mappings';
import restrictionNoticeTemplate from './templates/restrictionNotice.html';
import defineProperties from 'components/utils/defineProperties';
import 'components/domutils/Element.jQuery';
import 'jquery.extendPrototype';

const Logger = createLogger('InputRestriction');

// field names that should *not* be input restricted ever
const globalExcludes = [
  '.storeFinder-search-input',
  '.storeFinder-directions-input',
  '.react-input',
];

function InputRestriction() {}

$.extendPrototype(InputRestriction, {
  warningMessageHideable: true,
  warningMessageVisible: false,

  /**
   * Initialize warning element lazily,
   * calls event binder
   */
  initialize() {
    Logger.time('initialize', this);

    defineProperties(this, {
      $warningMessage: { lazy: this.renderWarningMessage.bind(this) },
    });

    this.bindEvents();

    Logger.timeEnd('initialize', this);
  },

  /**
   * Binds listeners to input text elements though body
   */
  bindEvents() {
    const mappings = this.getCountryBasedMapping();
    let names;
    let selector;
    let cssSelector = '';

    for (let i = 0; i < mappings.length; i++) {
      selector = [];
      names = mappings[i].name;

      // generate css selector based on input name
      for (let j = 0; j < names.length; j++) {
        selector.push(this.generateSelector(names[j]));
      }

      cssSelector = selector.join(',');

      document.body.jq
        .on('blur.textRestriction', cssSelector, this.handleInputEvent.bind(this, mappings[i]))
        .on('focus.textRestriction', cssSelector, this.cacheInitialValue);

      const elements = Array.from(document.querySelectorAll(cssSelector))
        .reverse()
        .filter(el => !el.closest('#react-root'));

      elements.map(element => {
        if (element.value === '') {
          return;
        }
        this.cacheInitialValue.call(element);
        const formApi = element?.form?.jq?.data('api');
        if (formApi && formApi.hasErrors()) {
          this.handleInputEvent.call(this, mappings[i], { currentTarget: element });
        }
        this.warningMessageVisible = false;
      });
    }
  },

  /**
   * Validates the value of input against potential restriction
   * @param  {String}  name   Name of the input field
   * @param  {String}  string Value of the input field
   * @return {Boolean}        Validity of the value for this input
   */
  isValueRestrictedByName(name, string) {
    const restrictionRegexp = this.getRestrictionsForName(name);
    let isValueValid = true;

    if (typeof string !== 'string' || !restrictionRegexp) {
      return isValueValid;
    }

    isValueValid = this.isCharacterInAllowedSet(string, restrictionRegexp);

    return isValueValid;
  },

  /**
   * Matches the supplied string against the unicode range regexp
   * @param  {String}  string       The string to be tested
   * @param  {RegExp}  regexp       The regexp to be matched against
   * @return {Boolean}
   */
  isCharacterInAllowedSet(string, regexp) {
    return string ? regexp.test(string) : true;
  },

  /**
   * Appends error message to document,
   * hides warning message when mouse is moved out of element
   * @return {jQuery} jQuery object representing the message HTML
   */
  renderWarningMessage() {
    const $warningMessage = $(restrictionNoticeTemplate).appendTo(document.body);

    $warningMessage
      .on(
        'mouseenter',
        function () {
          this.warningMessageHideable = false;
        }.bind(this)
      )
      .on(
        'mouseleave',
        function () {
          $warningMessage.hide();
          this.warningMessageVisible = false;
          this.warningMessageHideable = true;
        }.bind(this)
      );

    return $warningMessage;
  },

  /**
   * Get the input / regexp mapping for the
   * current country
   * @return {Object} @see Mappings.js
   */
  getCountryBasedMapping() {
    let key = AppSettings.country;

    if (AppSettings.country === 'JP') {
      key += AppSettings.inputRestrictionsActiveOption;
    }

    return Mappings[key] !== undefined ? Mappings[key] : Mappings.default;
  },

  /**
   * Check input / regexp mapping for this country
   * to see if the supplied name has restrictions
   * @param  {String} name Name of input field
   * @return {RegExp}      Regular expression the input's value should be checked against
   */
  getRestrictionsForName(name) {
    const mappings = this.getCountryBasedMapping();
    let restrictionRegexp;

    for (let i = 0; i < mappings.length; i++) {
      if ($.inArray(name, mappings[i].name) !== -1) {
        restrictionRegexp = mappings[i].regexp;
      }
    }

    return restrictionRegexp;
  },

  /**
   * The wild card '*' as present in Mappings.js is used
   * to generate a selector for _all_ input fieds.
   *
   * @param  {String} name Name of the input field
   * @return {String}      CSS selector
   */
  generateSelector(name) {
    let selector =
      name === '*' ? ['input[type="email"]', 'input[type="text"]'] : [`input[name="${name}"]`];
    selector = selector.map(function (el) {
      return `${el}:not(${globalExcludes.join('):not(')})`;
    });

    return selector.join(',');
  },

  /**
   * Stores initial value on the focused input
   */
  cacheInitialValue() {
    this.jq.data('initialValue', this.jq.val());
  },

  /**
   * Event listener for events bind in bindEvents
   * @param {Object} mapping The mapping object for the current country
   * @param {object} event jQuery event object
   */
  handleInputEvent(mapping, event) {
    const input = event.currentTarget;
    input.value = input.value.trim();
    const { value } = input;
    const { regexp } = mapping;
    const message = mapping.message || window.labels.forms.messageCheckInput;
    const isEmail = input.type === 'email';

    const valueInAllowedSet = this.isCharacterInAllowedSet(value, regexp);

    Logger.debug(`TextInputRestriction ${value} ${valueInAllowedSet} ${regexp.toString()}`);
    const $input = input.jq;

    if (this.warningMessageVisible && valueInAllowedSet) {
      this.$warningMessage.hide();
      this.warningMessageHideable = true;
    }

    if (value) {
      if (!valueInAllowedSet) {
        input.value = $input.data('initialValue');

        this.showMessage(input, message);

        document.jq.one(
          'vmousedown',
          function () {
            if (this.warningMessageHideable && this.warningMessageVisible) {
              this.$warningMessage.hide();
            }
          }.bind(this)
        );
      } else {
        if (!isEmail) {
          $input
            .data({
              initialValue: value,
              invalid: false,
            })
            .closest('.formInputGroup, label')
            .removeClass('invalid')
            .addClass('valid');
        }
      }
    }
  },

  showMessage(input, message) {
    const $input = input.jq;
    const top = $input.offset().top + $input.outerHeight(true) + (input.name === 'terms' ? 22 : 13);

    this.$warningMessage
      .show()
      .css({
        left: $input.offset().left,
        top,
      })
      .html(message);

    this.warningMessageVisible = true;
    $input.parents('.js-addressInputGroup, label').addClass('invalid').removeClass('valid');
  },
});

export default new InputRestriction();
