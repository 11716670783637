/**
 * Lazy load the sprite image of the payment methods in the footer.
 * @see gstar/scss/specifics/paymentMethods/_paymentMethods-link.scss
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import IntersectionUtil from '../utils/IntersectionUtil';

export default {
  lazyLoad: () => {
    const icons = document.querySelectorAll('.js-paymentMethod');
    const options = {
      root: null,
      rootMargin: '100px 0px',
    };

    if (icons.length) {
      IntersectionUtil.observe(icons, options).forEach(iconPromise =>
        iconPromise.then(data => {
          const { intersectingElements, observer } = data;
          intersectingElements.forEach(element => {
            element.classList.remove('is-lazy');

            observer.disconnect();
          });
        })
      );
    }
  },
};
