/* global AppSettings */
/**
 * Mappings between country, input field names, regular expressions and messages.
 * This mapping is used to restrict the clients to use certain character ranges.
 *
 * @author  Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import createLogger from 'components/logger/Logger';
import Utf8Blocks from './Utf8Blocks';

const Logger = createLogger('Mappings');
  /**
   * Return a RegExp object containing a specific regexp
   * @return {RegExp}
   */
  function createRegExp() {
    let regexp = '';

    if (arguments.length === 0) {
      Logger.warn('Invalid arguments supplied for createRegExp.');
      return;
    }

    for (let i = 0; i < arguments.length; i++) {
      regexp += arguments[i];
    }

    return new RegExp(`^[${regexp}]+$`);
  }

  const Mappings = {
    // China
    CN: [
      {
        name: ['city', 'street', 'province'],
        regexp: createRegExp(
          Utf8Blocks.LATIN_NUMBERS,
          Utf8Blocks.LATIN_PUNCTUATION,
          Utf8Blocks.WHITESPACE,
          Utf8Blocks.CJK_UNIFIED,
          Utf8Blocks.CJK_PUNCTUATION
        ),
        message: window.labels.forms.messageChineseOnly,
      },
      {
        name: ['email', 'phone', 'tel'],
        regexp: createRegExp(
          Utf8Blocks.LATIN_NUMBERS,
          Utf8Blocks.LATIN,
          Utf8Blocks.LATIN_PUNCTUATION,
          Utf8Blocks.WHITESPACE
        ),
        message: window.labels.forms.messageLatinOnly,
      },
    ],

    // Japan option 2 see: (https://g-star.atlassian.net/wiki/spaces/ROC/pages/122159317/Sagawa+-+Japanese+carrier+and+local+warehouse)
    JP2: [
      {
        name: ['postcode', 'phone'],
        regexp: createRegExp(
          Utf8Blocks.LATIN_NUMBERS,
          Utf8Blocks.LATIN_PUNCTUATION,
          Utf8Blocks.WHITESPACE
        ),
        message: window.labels.forms.messageLatinOnly,
      },
      {
        name: ['houseNumber', 'street'],
        regexp: createRegExp(
          Utf8Blocks.CJK_PUNCTUATION,
          Utf8Blocks.CJK_UNIFIED,
          Utf8Blocks.CJK_UNIFIED_EXTENSION_A,
          Utf8Blocks.HIRAGANA,
          Utf8Blocks.KATAKANA,
          Utf8Blocks.HALFWIDTH_FULLWIDTH,
          Utf8Blocks.WHITESPACE
        ),
        message: window.labels.forms.messageJapaneseOnly,
      },
      {
        name: ['province', 'city'],
        regexp: createRegExp(
          Utf8Blocks.CJK_PUNCTUATION,
          Utf8Blocks.CJK_UNIFIED,
          Utf8Blocks.CJK_UNIFIED_EXTENSION_A,
          Utf8Blocks.HIRAGANA,
          Utf8Blocks.KATAKANA
        ),
        message: window.labels.forms.messageJapaneseOnly,
      },
      {
        name: ['building', 'company', 'firstName', 'lastName'],
        regexp: createRegExp(
          Utf8Blocks.CJK_PUNCTUATION,
          Utf8Blocks.CJK_UNIFIED,
          Utf8Blocks.CJK_UNIFIED_EXTENSION_A,
          Utf8Blocks.HIRAGANA,
          Utf8Blocks.KATAKANA,
          Utf8Blocks.HALFWIDTH_FULLWIDTH
        ),
        message: window.labels.forms.messageJapaneseOnly,
      },
    ],

    // Japan option 3 (see: https://g-star.atlassian.net/wiki/spaces/ROC/pages/122159317/Sagawa+-+Japanese+carrier+and+local+warehouse)
    JP3: [
      {
        name: ['postcode', 'phone'],
        regexp: createRegExp(
          Utf8Blocks.LATIN_NUMBERS,
          Utf8Blocks.LATIN_PUNCTUATION,
          Utf8Blocks.WHITESPACE
        ),
        message: window.labels.forms.messageLatinOnly,
      },
      {
        name: [
          'houseNumber',
          'street',
          'province',
          'city',
          'building',
          'company',
          'firstName',
          'lastName',
        ],
        regexp: createRegExp(
          Utf8Blocks.CJK_PUNCTUATION,
          Utf8Blocks.CJK_UNIFIED,
          Utf8Blocks.CJK_UNIFIED_EXTENSION_A,
          Utf8Blocks.HIRAGANA,
          Utf8Blocks.KATAKANA,
          Utf8Blocks.HALFWIDTH_FULLWIDTH,
          Utf8Blocks.LATIN,
          Utf8Blocks.LATIN_NUMBERS,
          Utf8Blocks.LATIN_PUNCTUATION,
          Utf8Blocks.WHITESPACE
        ),
        message: '',
      },
    ],
    KR: [
      {
        name: [
          'houseNumber',
          'street',
          'province',
          'building',
          'company',
          'firstName',
          'lastName',
          'country',
          'postcode',
          'question',
        ],
        regexp: createRegExp(
          Utf8Blocks.LATIN_NUMBERS,
          Utf8Blocks.LATIN,
          Utf8Blocks.LATIN_PUNCTUATION,
          Utf8Blocks.WHITESPACE,
          Utf8Blocks.KOREAN_HANGUL
        ),
        message: window.labels.forms.messageEnglishOrKorean,
      },
    ],
    default: [
      {
        name: ['*'],
        regexp: createRegExp(
          Utf8Blocks.LATIN_NUMBERS,
          Utf8Blocks.LATIN,
          Utf8Blocks.LATIN_PUNCTUATION,
          Utf8Blocks.WHITESPACE
        ),
        message: window.labels.forms.messageLatinOnly,
      },
    ],
  };

  // set killswitch.allow.custom.input.restrictions.{countryCode} to false
  // in Global Configuration in HMC
  // to remove country specific restrictions
  // e.g. killswitch.allow.custom.input.restrictions.jp
  const countryCode = AppSettings.country.toUpperCase();
  if (!!Mappings[countryCode] && AppSettings.allowCustomInputRestrictions === false) {
    delete Mappings[countryCode];
  }

export default Mappings;
