/**
 * Basic accordion, used in:
 * footer, sitemap, faq
 *
 * @author  Tamara Bazko
 */
import createLogger from 'components/logger/Logger';
import $ from 'jquery';
import Factory from 'components/utils/Factory';
import matchBreakpoint from 'components/utils/matchBreakpoint';
import 'jquery.resizeEvents';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('BasicAccordion');

const $window = window.jq;

function BasicAccordion($element, settings) {
  Logger.info('Created');

  this.settings = $.extend(true, {}, this.settings, settings);
  this.$accordion = $element;
  this.$toggle = $(this.settings.toggleSelector);
  this.$content = $(this.settings.contentSelector);
  this.maxBreakPoint = this.settings.maxBreakPoint;
  this.duration = this.settings.duration;

  this.bindEvents();

  if (this.maxBreakPoint) {
    this.onResize();
    this.onResizeEnd();
  }
}

$.extend(BasicAccordion.prototype, {
  $accordion: undefined,
  $toggle: undefined,
  $content: undefined,
  duration: undefined,
  maxBreakPoint: undefined,

  settings: {
    maxBreakPoint: undefined,
    duration: 250,
    contentSelector: '.js-basicAccordion-content',
    toggleSelector: '.js-basicAccordion-toggle',
  },

  bindEvents() {
    this.$accordion
      .off('vclick.basicAccordion')
      .on('vclick.basicAccordion', this.settings.toggleSelector, this.toggleContent.bind(this));
  },

  unbindEvents() {
    this.$accordion.off('vclick.basicAccordion');
    this.$content.attr('style', '');
  },

  onResize() {
    $window.on('resizeEnd', this.onResizeEnd.bind(this));
  },

  onResizeEnd() {
    if (matchBreakpoint(this.maxBreakPoint)) {
      this.unbindEvents();
    } else {
      this.bindEvents();
    }
  },

  toggleContent(event) {
    const $currentToggle = $(event.target);
    const hasContent = $currentToggle.next(this.$content).children().length;

    event.preventDefault();
    event.stopPropagation();

    if (hasContent) {
      $currentToggle.toggleClass('is-active').next(this.$content).slideToggle(this.duration);
    }
  },
});

export default Factory.create(BasicAccordion);
