import createLogger from '../logger/Logger';

const Logger = createLogger('getCssBreakpointConfig');
let cssStyleDeclaration = null;

export default function getCssBreakpointConfig() {
  Logger.time('getCssBreakpointConfig', this);

  if (cssStyleDeclaration === null) {
    cssStyleDeclaration = window.getComputedStyle(document.body, ':after');
  }

  const content = cssStyleDeclaration.getPropertyValue('content').replace(/"|'| /g, '');

  Logger.timeEnd('getCssBreakpointConfig', this);

  return content;
}
