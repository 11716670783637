import Storage from 'components/utils/storage/Storage';
import device from '../utils/device';

class ExponeaPushNotifications {
  constructor() {
    this.cookie = null;
    this.subscriberKey = null;
  }

  static async registerConsentEvent(isSubscribed) {
    if (this.cookie || this.subscriberKey) {
      const action = isSubscribed ? 'accept' : 'reject';
      const message = 'Web Push consent ' + action + 'ed from: ' + window.location.href;
      const data = JSON.stringify({
        customerIds: {
          cookie: this.cookie,
          subscriberKey: this.subscriberKey,
        },
        message,
        action,
      });

      try {
        await fetch(AppSettings.endpoints.exponeaPushEventConsent, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: data,
          keepalive: true,
        });
      } catch (error) {
        console.error('Error storing exponea opt-in selection: ', error);
      }
    }
  }

  static async showPushNotificationPrompt() {
    exponea.notifications.isAvailable(async browserSupported => {
      if (!browserSupported) return;
      this.cookie = exponea.configuration?.customer?.cookie;
      this.subscriberKey = AppState.userMetaData.customerId
        ? AppState.userMetaData.customerId
        : null;
      const url = AppSettings.endpoints.exponeaCustomerAttributes;
      const body = JSON.stringify({
        customerIds: {
          cookie: this.cookie,
          subscriberKey: this.subscriberKey,
        },
        attributes: [
          {
            type: 'consent',
            category: 'webpush',
          },
        ],
      });

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        });
        const data = await response.json();
        const localPushNotificationFlag = Storage.getItem('g-star_pushNotifications_disabled');

        if (
          data.success === true &&
          data.results[0].value === false &&
          localPushNotificationFlag !== true
        ) {
          const permission = Notification.permission;
          if (permission === 'granted') {
            ExponeaPushNotifications.agreeNotifications(permission);
            return;
          } else if (permission === 'denied') {
            return;
          }
          setTimeout(() => {
            const exponeaEntranceModal = document.querySelector('.exponea-entrance-modal');
            const exponeaExitModal = document.querySelector('.exponea-exit-modal');

            if (exponeaEntranceModal || exponeaExitModal) {
              ExponeaPushNotifications.onNodeRemove(
                exponeaEntranceModal ? exponeaEntranceModal : exponeaExitModal,
                ExponeaPushNotifications.showRequestNotificationPopUp
              );
              return;
            }

            ExponeaPushNotifications.showRequestNotificationPopUp();
          }, AppSettings.exponeaPushNotificationDelay * 1000);
        } else if (data.success === false) {
          console.error('Error when checking if the user is subscribed: ', error);
        }
      } catch (error) {
        console.error('Error when checking if the user is subscribed: ', error);
      }
    });
  }

  static handlePermission(permission) {
    if (permission === 'default') return;
    exponea.notifications.subscribe(
      status => {
        if (status === 'default' || status === 'error') return;
        const isSubscribed = status === 'subscribed';
        ExponeaPushNotifications.registerConsentEvent(isSubscribed);
        sessionStorage.setItem(
          'endShowingRequestPushNotifications',
          AppSettings.exponeaPushNotificationRequestPerSession
        );
      },
      { push_notifications: true }
    );
  }

  static agreeNotifications() {
    if (device.browserName === 'Safari') {
      Notification.requestPermission().then(permission => {
        ExponeaPushNotifications.handlePermission(permission);
      });
    } else {
      Notification.requestPermission(permission => {
        ExponeaPushNotifications.handlePermission(permission);
      });
    }
  }

  static rejectNotifications() {
    Storage.setItem('g-star_pushNotifications_disabled', true);
    ExponeaPushNotifications.registerConsentEvent(false);
  }

  static showRequestNotificationPopUp() {
    const popUp = document.querySelector('.requestPushNotifications');
    const agreeButton = document.querySelector('.requestPushNotifications__agreeButton');
    const rejectButton = document.querySelector('.requestPushNotifications__rejectButton');
    const closeButton = document.querySelector('.requestPushNotifications__closeButton');
    if (!popUp || !agreeButton || !rejectButton || !closeButton) return;

    closeButton.addEventListener('click', () => {
      popUp.classList.remove('showNotification');
      ExponeaPushNotifications.pushToDataLayer('close');
    });

    agreeButton.addEventListener('click', () => {
      popUp.classList.remove('showNotification');
      ExponeaPushNotifications.agreeNotifications();
      ExponeaPushNotifications.pushToDataLayer('submit');
    });

    rejectButton.addEventListener('click', () => {
      popUp.classList.remove('showNotification');
      ExponeaPushNotifications.rejectNotifications();
      ExponeaPushNotifications.pushToDataLayer('close');
    });

    popUp.classList.add('showNotification');
    ExponeaPushNotifications.pushToDataLayer('open');
  }

  static onNodeRemove(element, callback) {
    new MutationObserver((mutations, observer) => {
      if (!document.body.contains(element)) {
        observer.disconnect();
        callback();
      }
    }).observe(document.body, { childList: true, subtree: true });
  }

  static pushToDataLayer(action, event = 'push_notification') {
    window.dataLayer = window.dataLayer || [];
    window?.dataLayer.push({
      events: {
        category: event,
        action: action,
      },
      event,
    });
  }

  initialize() {
    const notificationIsSupported = !!(
      window.Notification ||
      window?.win?.webkitNotifications ||
      navigator.mozNotification
    );

    if (
      AppSettings.exponeaPushNotificationEnabled === 'true' &&
      !AppState.assistedSale &&
      window.exponea &&
      !AppState.isMobile &&
      !AppState.isTablet &&
      notificationIsSupported
    ) {
      if (device.browserName === 'Safari') {
        const browserVersion = Number(device.browserVersion.split('.').slice(0, 2).join('.'));
        if (browserVersion < 16 || browserVersion === 16.2) return;
      }
      window.addEventListener('pageViewsComplete', event => {
        if (event.detail.pageViewCount >= AppSettings.exponeaPushNotificationPageViewNumber) {
          if (!sessionStorage.getItem('endShowingRequestPushNotifications')) {
            sessionStorage.setItem(
              'endShowingRequestPushNotifications',
              event.detail.pageViewCount + AppSettings.exponeaPushNotificationRequestPerSession
            );
          }
          if (
            event.detail.pageViewCount <
            +sessionStorage.getItem('endShowingRequestPushNotifications')
          ) {
            ExponeaPushNotifications.showPushNotificationPrompt();
          }
        }
      });
    }
  }
}

export default ExponeaPushNotifications;
