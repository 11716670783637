import HelpButton from './helpButton';
import InvitationStartChatButton from './invitationStartChatButton';

const LivechatButtons = {
  initialize() {
    HelpButton.initialize();
    InvitationStartChatButton.initialize();
  }
}

export default LivechatButtons;
