/* global AppSettings */
/**
 * This fixes an annoying bug where it would sometimes become
 * impossible for the user to scroll the sidenavigation (it would lock in place).
 *
 * This only occurs on touch devices.
 */
import EventTypes from '../EventTypes';
import device from '../utils/device';
import 'jquery.scrollEvents';
import '../domutils/Element.jQuery';

const ScrollFix = function () {
  const eventNameSpace = '.sideNavigationScrollFix';
  let initialized = false;
  let scrollContainer;
  let scrollUpdating = false;
  let sideNavigation;
  let sideNavigationVisible = false;
  let wasVisible;

  this.initialize = function () {
    if (!initialized && device.hasTouch) {
      sideNavigation = document.querySelector('.js-sideNav');
      if (!sideNavigation) {
        return;
      }

      bindEvents();
      initialized = true;
    }
  };

  function bindEvents() {
    document.jq
      .on(EventTypes.SIDENAV_HIDE, sideNavStateUpdate)
      .on(EventTypes.SIDENAV_SHOW, onSideNavShow);
    sideNavigation.jq.on(EventTypes.ACCORDION_TOGGLE, scrollFix);
  }

  function onSideNavShow(e) {
    sideNavStateUpdate(e);
    setScrollContainer();
    scrollContainer.jq.scrollTop(1);
    scrollContainer.jq.on('touchend.Fix touchcancel.Fix scrollEnd.Fix', scrollFix);
  }

  function scrollFix() {
    const scrollContainerHeight = scrollContainer.jq.height() + AppSettings.topNavigationHeight * 2;
    const scrollAtBottom = scrollContainer.scrollHeight - scrollContainerHeight;

    if (scrollContainer.scrollTop <= 0) {
      scrollContainer.jq.scrollTop(1);
    } else if (scrollAtBottom > 1 && scrollContainer.scrollTop === scrollAtBottom) {
      scrollContainer.jq.scrollTop(scrollAtBottom - 1);
    }
  }

  function setScrollContainer() {
    if (!scrollContainer) {
      scrollContainer = sideNavigation.querySelector('.js-sideNav-scrollContainer');
    }
  }

  function sideNavStateUpdate(event) {
    sideNavigationVisible = event.type === EventTypes.SIDENAV_SHOW;
    update();
  }

  function onContainerScroll(event) {
    event.stopPropagation();
  }

  function update() {
    if (sideNavigationVisible !== wasVisible) {
      setScrollContainer();

      if (sideNavigationVisible) {
        scrollContainer.addEventListener('scroll', onContainerScroll);

        window.jq.on(`scrollUpdate${eventNameSpace}`, function () {
          if (!scrollUpdating) {
            scrollUpdating = true;
            setTimeout(() => {
              scrollUpdating = false;
            }, (1000 / 60) * 10);
          }
        });
      } else {
        window.jq.off(eventNameSpace);
        scrollContainer.removeEventListener('scroll', onContainerScroll);
      }
    }

    wasVisible = sideNavigationVisible;
  }
};

export default new ScrollFix();
