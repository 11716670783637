import Mustache from 'mustache';
export default {
  initialize({ asmInstoreLocale }) {
    const domTemplate = document.getElementById('stopAssistedSaleElement');
    if (!domTemplate) {
      return;
    }

    const href = `/${asmInstoreLocale}/${domTemplate.dataset.basehref}`;
    domTemplate.insertAdjacentHTML('afterend', Mustache.render(domTemplate.innerHTML, { href }));
  },
};
