/**
 * Creates sticky events.
 *
 * What are sticky events?
 * Sticky events are events that remember that they are triggered.
 * A listener that is added after the event has been triggered will be called immediately.
 *
 * A native example of this would be $(document).ready.
 *
 * With this module you can listen to events like you are used to with jQuery.
 * But if you want to trigger a stickyEvent you should use `.stickyTrigger` instead of `.trigger`.
 *
 * That's all!
 *
 * Example:
 * ```
 * $(document).trigger('stickyEvent'); // this will do nothing since the listener is added after the trigger
 * $(document).stickyTrigger('stickyEvent'); // this will trigger the listener below
 *
 * $(document).on('stickyEvent', function() {
 *   console.log('stickyEvent has triggered');
 * });
 * ```
 *
 * @author Meinaart van Straalen
 */

import $ from 'jquery';
  /**
   * Check if `this` contains specified property
   * @param  {String}  name Name of property
   * @return {Boolean}      Does `this` have property with specified name
   */
  function hasOwn(name) {
    if (!this) {
      throw new TypeError('stickyTrigger.hasOwn called on non-object');
    }
    return Object.prototype.hasOwnProperty.call(this, name);
  }

  /**
   * Similar to jQuery's .trigger
   * @param  {jQuery.Event|String} event jQuery event object or eventType as string
   * @param  {Object} data  Data related to event
   */
  $.fn.stickyTrigger = function stickyTrigger(event, data) {
    const type = hasOwn.call(event, 'type') ? event.type : event;

    if (!$.event.special[type]) {
      const dataKey = $.camelCase(`stickyEvent-${type}`);

      // Create special event so that when event listeners are bound after event trigger they are executed with
      // the already executed event.
      $.event.special[type] = {
        /**
         * Each time an event handler is added to an element through an API such as .on(), jQuery calls this hook.
         * https://learn.jquery.com/events/event-extensions/#add-function-handleobj
         *
         * @param {Object} handleObj Object related to an event listener
         */
        add(handleObj) {
          const cached = $.data(this)[dataKey];
          if (cached) {
            for (const target in cached) {
              const cache = cached[target];
              handleObj.handler.call(this, cache.event, cache.data);
            }
          }
        },

        /**
         * Called when the .trigger() or .triggerHandler() methods are used to trigger this sticky event.
         *
         * https://learn.jquery.com/events/event-extensions/#trigger-function-event-jquery-event-data-object
         *
         * @param  {jQuery.Event} event
         * @param  {Object=}      data
         * @return {Boolean}                return false to stop the trigger
         */
        trigger(event, data) {
          const cached = { event, data };

          // eslint-disable-next-line consistent-this
          let el = this;
          while (el) {
            const elData = $.data(el);
            elData[dataKey] = elData[dataKey] || {};

            // Remember last event per target
            elData[dataKey][event.target] = cached;

            // Make sure event bubbling works
            if (
              event.bubbles === false ||
              el === document ||
              el === window ||
              !(el instanceof Element)
            ) {
              el = false;
            } else if (el === document.documentElement) {
              el = document;
            } else {
              el = el.parentElement;
            }
          }

          return true;
        },
      };
    }

    return this.trigger(event, data);
  };

