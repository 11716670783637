/**
 * Get the summary basket object
 * @param {Object} basketTotal Basket totals
 * @param {Boolean} includeBasketId Condition to include or not the Id
 * @returns {Object} Returns formatted basket summary object
 */
export const getBasketSummary = (
  { id, subtotal, subtotalIncludingTax, total, quantity } = {},
  includeBasketId
) => ({
  ...(includeBasketId ? { id } : {}),
  ...(subtotal ? { subtotal } : {}),
  ...(subtotalIncludingTax ? { subtotalIncludingTax } : {}),
  ...(total ? { total } : {}),
  quantity,
});

/**
 * Get the basket item object
 * @param {Object} basketItem
 * @param {Object} basketSummaryPerItem
 * @returns {Object} Returns formatted basket item object
 */
export const getBasketItem = (basketItem, basketSummaryPerItem) => {
  const {
    sku,
    name,
    categories,
    manufacturer,
    color,
    size,
    price,
    originalPrice,
    productId,
  } = basketItem.product;

  const { quantity, subtotal, subtotalIncludingTax } = basketItem;

  return {
    basket: basketSummaryPerItem,
    product: {
      sku,
      name,
      categories,
      price,
      originalPrice,
      manufacturer,
      color,
      size,
      productId,
    },
    quantity,
    ...(subtotal ? { subtotal } : {}),
    ...(subtotalIncludingTax ? { subtotalIncludingTax } : {}),
  };
};

/**
 * Get product data
 * @param {Object} productData
 * @returns {Object} Returns product data object structured for Qubit needs
 */
export const getProductData = productData => {
  const {
    materialNumber,
    name,
    mainColor,
    size = '',
    fromPrice,
    basePrice,
    finalPrice,
    currency,
    staticCategoryPath,
    ecomm_prodid = '',
  } = productData;

  const category = staticCategoryPath ? staticCategoryPath.replace('/', ' > ') : '';

  return {
    sku: ecomm_prodid,
    productId: materialNumber,
    name,
    color: mainColor,
    size,
    price: {
      currency,
      value: finalPrice,
    },
    originalPrice: {
      currency,
      value: fromPrice || basePrice,
    },
    categories: [category],
    manufacturer: 'Manufacturer', // TODO to be populated later
    stock: productData?.availability === 'No stock available' ? 0 : 1,
  };
};
