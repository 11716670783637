/* global AppSettings */
/**
 * Cookie wall dialog
 *
 * cookie.wall.feature.switch.enable
 * cookie.settings.feature.switch.enable
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import $ from 'jquery';
import createLogger from 'components/logger/Logger';
import AnalyticsEventTypes from 'components/analytics/AnalyticsEventTypes';
import Dialog from 'components/dialogs/dialog';
import DialogTypes from 'components/dialogs/Types';
import EventTypes from 'components/EventTypes';
import StorageKeys from 'components/utils/storage/StorageKeys';
import SubscriptionDialog from 'components/dialogs/dialog--subscriptionFormCTA';

const Logger = createLogger('dialog--cookie');
const labels = JSON.parse(document.getElementById('labels-cookie')?.textContent || null) || {};

/**
 * COokie COnsent wall or
 * COnsent COokie wall
 * The cookie name is hence `coco`.
 */
const COOKIE_NAME = StorageKeys.COOKIE_CONSENT_WALL;

/**
 * Time To Live for the consent cookie
 */
const COOKIE_TTL = 1 * 365 * 24 * 60 * 60 * 1e3;

/**
 * Consent variants
 * Use bitwise OR to combine variants
 */
const ConsentVariants = {
  NECESSARY: 1,
  FUNCTIONAL: 2,
  ANALYTICS: 4,
  ADS: 8,
  SOCIAL: 16,
};

/**
 * Amount of consent groups.
 */
const consentVariantsLength = Object.keys(ConsentVariants).length;

/**
 * RegExp to validate cookie value
 */
const CookieRegExp = new RegExp(`[0-1]{${consentVariantsLength}}`);

/**
 * Switch labels with fallback to English
 */
const switchState = {
  ON: labels.cookieSettingOn || 'On',
  OFF: labels.cookieSettingOff || 'Off',
};

const CONSENT_ALL = (
  ConsentVariants.NECESSARY |
  ConsentVariants.FUNCTIONAL |
  ConsentVariants.ANALYTICS |
  ConsentVariants.ADS |
  ConsentVariants.SOCIAL
).toString(2);

/**
 * Default consent cookie meta data
 */
const META_DATA = {
  expires: new Date(Date.now() + COOKIE_TTL),
  path: '/',
  secure: true,
};

export default class CookieDialog {
  constructor(options) {
    Logger.time('initialize', this);

    const hasValidConsentCookie = CookieRegExp.test($.cookie(COOKIE_NAME));
    if (hasValidConsentCookie) {
      return;
    }

    const templateContainer = document.getElementById(options.id);
    this.cookieWallTemplate = templateContainer.textContent;

    if (!this.cookieWallTemplate) {
      return;
    }
    this.show(options);

    Logger.timeEnd('initialize', this);
  }

  show(options) {
    let className = `dialog--default dialog--cookie dialog--cookieWall js-dialog--cookie dialog--created-at-${Date.now()}`;
    if (AppSettings.cookieDialog.enableSettings) {
      className += ' dialog--cookie__hasSettings';
    }

    document.body.classList.toggle('blur-cookiewall-bg', true);

    Dialog.show({
      className,
      contents: this.cookieWallTemplate,
      cssDependencies: [`${AppSettings.assetPath}css/dialog--cookie`],
      id: `${options.id}--${Date.now()}`,
      preventBackgroundScroll: false,
      type: DialogTypes.COOKIE,
      vAlignBottom: true,
      vAlignMiddleOnSmallScreen: false,
      closeDialogButtonOnly: true,
      isFullScreenOnMobile: false,
    }).then(dialogInstance => {
      this.dialogInstance = dialogInstance;
      this.dialog = this.dialogInstance.$dialog[0];
      this.cookieWall =
        this.dialog.querySelector('.cookie-dialog--wall') ||
        this.dialog.querySelector('.cookiev2-dialog--wall') ||
        this.dialog.querySelector('.cookie-v3-dialog--wall');
      this.bindEvents();

      // Check if cookie wall V2/V3 is enabled and add corresponding classes
      const dialogContentWrapper = document.querySelector('.dialog-contentWrapper');
      const dialogContent = document.querySelector('.dialog-content');
      Array.from(dialogContent.children).forEach(child => {
        if (child.classList.contains('cookiev2-dialog')) {
          dialogContentWrapper.classList.add('dialog-contentWrapper--cookiev2');
          dialogContent.classList.add('dialog-content--cookiev2');
        } else if (child.classList.contains('cookie-v3-dialog')) {
          const acceptButtonV3 = this.dialog.querySelector('.cookie-accept.cookie-button-v3');
          dialogContentWrapper.classList.add('dialog-content-wrapper--cookie-v3');
          dialogContent.classList.add('dialog-content--cookie-v3');
          this.dialog.classList.add('dialog--cookie-wall-v3');
          setTimeout(() => {acceptButtonV3.focus()}, 100)
        } else if (child.classList.contains('cookie-dialog')) {
          dialogContent.classList.add('dialog-content--cookiev1');
        }
      });
    });
  }

  bindEvents() {
    const container = this.dialog.querySelector('.js-dialog-content');

    container.jq
      .on('click', '.js-cookieSettings', event => this.toggleSettings(event))
      .on('click', '.js-cookieAccept', event => {
        event.preventDefault();
        const { action } = event.currentTarget.dataset;

        if (action === 'change' || action === 'accept') {
          this.acceptAll();
          document.jq.one(AnalyticsEventTypes.COOKIE_CLICK, () => {
            this.smartBannerHandler();
            this.exponeaHandler();
            this.qubitHandler();
          });
        } else if (action === 'reject') {
          this.refuseOptional();
        } else {
          return;
        }

        SubscriptionDialog.triggerWhenReady();

        document.jq.trigger(AnalyticsEventTypes.COOKIE_CLICK, { action });
      })
      .on('change', '.js-onoff-switch', event => this.handleSwitchChange(event))
      .on('click', '.js-cookieDone', event => {
        this.updateSettings(event);
        SubscriptionDialog.triggerWhenReady();

        const { action } = event.currentTarget.dataset;
        document.jq.trigger(AnalyticsEventTypes.COOKIE_CLICK, { action });
      });

    container.addEventListener('keydown', this.handleKeydown);
  }

  /**
   * Show the cookie settings subwindow
   * @param {jQuery.event} event jQuery Event object
   */
  toggleSettings(event) {
    event.preventDefault();
    const bufferedSettings = document.getElementById('cookie-dialog__settings');
    if (!bufferedSettings) {
      return;
    }
    const template = bufferedSettings.textContent;
    this.cookieWall.parentNode.removeChild(this.cookieWall);
    this.dialog.classList.remove('dialog--cookieWall');
    this.dialog.classList.remove('dialog--vAlign-bottom');
    this.dialog.classList.add('dialog--cookieSettings');
    this.dialog.classList.add('dialog--vAlign-center');
    this.dialog.querySelector('.js-dialog-content').insertAdjacentHTML('beforeend', template);

    // reposition the dialog
    this.dialogInstance.positionDialogContent(this.dialog);

    // focus the dialog
    document.jq.trigger(EventTypes.DIALOG_SET_FOCUS, this.dialog);
  }

  /**
   * Accept all cookie groups in one go
   * @param {jQuery.Event} event jQuery Event object
   */
  acceptAll() {
    const switches = this.dialog.querySelectorAll('.js-onoff-switch');
    switches.forEach(element => {
      element.checked = true;
    });

    $.cookie(COOKIE_NAME, CONSENT_ALL, META_DATA);
    if (AppSettings.analyticsBlockDetectionEnabled) {
      this.setAbdctCookie(false);
    }

    this.delayedClose();
  }

  /**
   * Refuse all optional cookies (FUNCTIONAL was merged with NECESSARY)
   * @param {jQuery.Event} event jQuery Event object
   */
  refuseOptional() {
    $.cookie(
      COOKIE_NAME,
      this.leftPad((ConsentVariants.NECESSARY | ConsentVariants.FUNCTIONAL).toString(2)),
      META_DATA
    );
    this.delayedClose();
  }

  delayedClose() {
    document.documentElement.classList.remove('has-cookiewall');
    window.setTimeout(() => {
      this.dialog.querySelector('.js-closeDialog').jq.click();
      document.body.classList.toggle('blur-cookiewall-bg', false);
    }, 400);
  }

  /**
   * Switch the label of the switch. Basically switch between 'On' and 'Off'.
   *
   * @param {jQuery.Event} event The jQuery Event object
   */
  handleSwitchChange(event) {
    const textContainer = event.target.jq
      .parents('.cookie-dialog__details')
      .find('.js-switch-text');
    if (textContainer) {
      const switchText = event.target.checked ? switchState.ON : switchState.OFF;
      textContainer.text(switchText);
    }
  }

  handleKeydown(event) {
    if (event.keyCode === 32 && event?.target?.classList?.contains('onoff-switch__label')) {
      event.preventDefault();
      const switchElement = event?.target?.parentElement?.querySelector('.js-onoff-switch');
      if (switchElement) {
        switchElement.checked = !switchElement.checked;
      }
    }
  }

  /**
   * Update the 'coco' consent cookie value
   * @param {jQuery.Event} event jQuery Event object
   */
  updateSettings(event) {
    event.preventDefault();
    const switches = this.dialog.querySelectorAll('.js-onoff-switch');
    const consentValue = [...switches]
      .reduce((acc, input) => {
        if (input.checked) {
          return acc | input.value;
        }
        return acc;
      }, ConsentVariants.FUNCTIONAL)
      .toString(2);

    const cookieValue = this.leftPad(consentValue);
    $.cookie(COOKIE_NAME, cookieValue, META_DATA);

    if (AppSettings.analyticsBlockDetectionEnabled) {
      const isAnalyticsAllowed = (parseInt(consentValue, 2) >> 2) & (1 === 1);
      this.setAbdctCookie(!isAnalyticsAllowed);
    }

    if (event.target.classList.contains('js-cookieDone')) {
      this.delayedClose();
    }
  }

  /**
   * Pad `101` into `0101` or `11` into `0011`
   * @param {string} value The string to get padded
   */
  leftPad(value) {
    const initialValue = '0'.repeat(consentVariantsLength).split('');
    const padded = value
      .split('')
      .reverse()
      .reduce((acc, el, idx) => {
        acc[idx] = el;
        return acc;
      }, initialValue);
    return padded.reverse().join('');
  }

  isApplicableConsentLevel() {
    const { consentCookieValue } = AppSettings;

    return (
      consentCookieValue === '' || consentCookieValue === '00001' || consentCookieValue === '00011'
    );
  }

  smartBannerHandler() {
    if (this.isApplicableConsentLevel()) {
      return;
    }

    window.loadSmartBanner && window.loadSmartBanner();
  }

  /**
   * CDP-37
   * Initialize Exponea when proper consent is given by consumer
   */
  exponeaHandler() {
    const { consentCookieValue } = AppSettings;
    if (consentCookieValue === '11111') {
      window.initializeExponea && window.initializeExponea();
      if (window.webxpClient && window.webxpClient.src) {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = window.webxpClient.src.replace('/script/', '/script-async/');
        document.body.insertAdjacentElement('beforeend', s);
      }
    }
  }

  qubitHandler() {
    window.qubitIntegration?.loadQubitScript?.();
  }

  setAbdctCookie(value) {
    $.cookie(StorageKeys.COOKIE_ABDCT, value, { path: '/' });
  }
}
