/**
 * Wrapper around `Object.defineProperties` which allows less verbose syntax.
 *
 * By default functions are added as getter, other values are set as value:
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperties
 *
 * It's also possible to add lazy getters and lazy jQuery selectors.
 *
 * Usage:
 * ```
 * defineProperties(this, {
 *   name: 'Meinaart van Straalen',
 *   // This function is executed everytime `this.occupation` is accessed
 *   occupation: function() {
 *     return 'Front end developer';
 *   },
 *   // This function is executed first time `this.lazyGetter` is executed
 *   // value is then remembered and on consecutive calls the value is returned.
 *   lazyGetter: {lazy: function() {
 *     return 'Very lazy';
 *   }},
 *   $lazyJQuery: {selector: '.js-element'},
 *   $lazyJQueryChild: {selector: '.js-child', container: $container}
 *   nativeValue: {
 *     configurable: true
 *     enumerable: true
 *     value: 'Native',
 *     writable: true
 *   },
 *   nativeGetterSetter: {
 *     get: function() {
 *       return this.__native;
 *     },
 *     set: function(value) {
 *       this.__native = value;
 *       this.update();
 *     }
 *   }
 * })
 * ```
 *
 * @author  Meinaart van Straalen
 */
import defineLazyProperty from './defineLazyProperty';
import defineLazyJquerySelector from './defineLazyJquerySelector';

  /**
   * Check if specified object has a valid format for `Object.defineProperties`
   * @param  {Object}  object Object to check
   * @return {Boolean}
   */
  function isDefineObject(object) {
    return typeof object === 'object' && (object.value !== undefined || object.get !== undefined);
  }

  /**
   * Add properties to scope, functions become getters, values become values, see full documentation on top of file.
   *
   * @param {Object} scope     Object to add properties to
   * @param {Object} properties Object with properties
   * @return {Object} Decorated object
   */
export default function defineProperties(scope, properties) {
    const props = {};
    let hasProps = false;
    for (const key in properties) {
      const value = properties[key];
      if (typeof value === 'function') {
        props[key] = {
          configurable: true,
          get: value.bind(scope),
        };
        hasProps = true;
      } else if (isDefineObject(value)) {
        props[key] = value;
        hasProps = true;
      } else if (typeof value === 'object') {
        if (value.lazy) {
          defineLazyProperty(scope, key, value.lazy);
        } else if (value.selector) {
          defineLazyJquerySelector(scope, key, value.selector, value.container, value.overwrite);
        }
      }
    }

    if (hasProps) {
      Object.defineProperties(scope, props);
    }
    return scope;
}
