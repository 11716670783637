/**
 * Import gsap dependencies from campaign.g-star.com
 *
 * window.GSRD.gsap(['gsap', 'ScrollToPlugin']);
 * import gsapLoader from 'requireGsap';
 * gsapLoader('gsap', 'CSSPlugin').then(() => {})
 *
 * @author Vincent Bruijn <vincent-bruijn@g-star.com>
 */
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';

import createLogger from '../app/components/logger/Logger';
import loadAsset from './loadAsset';

const Logger = createLogger('gsapImporter');
// const prefix = ['https://', 'campaign.g-star.com', ':8443', '/vendor/gsap'].join('');
const prefix = ['https://', 'campaign.g-star.com', '/vendor/gsap'].join('');

/**
 * Create full URL for requested module
 * @param {object} gsapAssets Collection of available assets of gsap
 * @param {string} gsapModule Name of gsap module
 * @param {string} version Version of gsap
 */
function createModuleUrl(gsapAssets, gsapModule, version) {
  return `${prefix}/${version}/${gsapAssets[version][gsapModule]}.min.js`;
}

// given list of all existing Gsap modules and plugins
const gsapAssets = {
  '2.0.2': {
    'jquery.gsap': 'jquery.gsap',
    TimelineLite: 'TimelineLite',
    TimelineMax: 'TimelineMax',
    TweenLite: 'TweenLite',
    TweenMax: 'TweenMax',
    AttrPlugin: 'plugins/AttrPlugin',
    CSSRulePlugin: 'plugins/CSSRulePlugin',
    DrawSVGPlugin: 'plugins/DrawSVGPlugin',
    ModifiersPlugin: 'plugins/ModifiersPlugin',
    PhysicsPropsPlugin: 'plugins/PhysicsPropsPlugin',
    RoundPropsPlugin: 'plugins/RoundPropsPlugin',
    TextPlugin: 'plugins/TextPlugin',
    BezierPlugin: 'plugins/BezierPlugin',
    ColorPropsPlugin: 'plugins/ColorPropsPlugin',
    EaselPlugin: 'plugins/EaselPlugin',
    MorphSVGPlugin: 'plugins/MorphSVGPlugin',
    PixiPlugin: 'plugins/PixiPlugin',
    ScrambleTextPlugin: 'plugins/ScrambleTextPlugin',
    ThrowPropsPlugin: 'plugins/ThrowPropsPlugin',
    CSSPlugin: 'plugins/CSSPlugin',
    DirectionalRotationPlugin: 'plugins/DirectionalRotationPlugin',
    EndArrayPlugin: 'plugins/EndArrayPlugin',
    Physics2DPlugin: 'plugins/Physics2DPlugin',
    RaphaelPlugin: 'plugins/RaphaelPlugin',
    ScrollToPlugin: 'plugins/ScrollToPlugin',
    CustomBounce: 'easing/CustomBounce',
    CustomEase: 'easing/CustomEase',
    CustomWiggle: 'easing/CustomWiggle',
    EasePack: 'easing/EasePack',
    Draggable: 'utils/Draggable',
    GSDevTools: 'utils/GSDevTools',
    SplitText: 'utils/SplitText',
  },
  '3.2.4': {
    CSSRulePlugin: 'CSSRulePlugin',
    CustomBounce: 'CustomBounce',
    CustomEase: 'CustomEase',
    CustomWiggle: 'CustomWiggle',
    Draggable: 'Draggable',
    DrawSVGPlugin: 'DrawSVGPlugin',
    EasePack: 'EasePack',
    EaselPlugin: 'EaselPlugin',
    GSDevTools: 'GSDevTools',
    InertiaPlugin: 'InertiaPlugin',
    MorphSVGPlugin: 'MorphSVGPlugin',
    MotionPathHelper: 'MotionPathHelper',
    MotionPathPlugin: 'MotionPathPlugin',
    Physics2DPlugin: 'Physics2DPlugin',
    PhysicsPropsPlugin: 'PhysicsPropsPlugin',
    PixiPlugin: 'PixiPlugin',
    ScrambleTextPlugin: 'ScrambleTextPlugin',
    ScrollToPlugin: 'ScrollToPlugin',
    SplitText: 'SplitText',
    TextPlugin: 'TextPlugin',
    gsap: 'gsap',
  },
  '3.7.1': {
    CSSRulePlugin: 'CSSRulePlugin',
    CustomBounce: 'CustomBounce',
    CustomEase: 'CustomEase',
    CustomWiggle: 'CustomWiggle',
    Draggable: 'Draggable',
    DrawSVGPlugin: 'DrawSVGPlugin',
    EasePack: 'EasePack',
    EaselPlugin: 'EaselPlugin',
    Flip: 'Flip',
    GSDevTools: 'GSDevTools',
    InertiaPlugin: 'InertiaPlugin',
    MorphSVGPlugin: 'MorphSVGPlugin',
    MotionPathHelper: 'MotionPathHelper',
    MotionPathPlugin: 'MotionPathPlugin',
    Physics2DPlugin: 'Physics2DPlugin',
    PhysicsPropsPlugin: 'PhysicsPropsPlugin',
    PixiPlugin: 'PixiPlugin',
    ScrambleTextPlugin: 'ScrambleTextPlugin',
    ScrollToPlugin: 'ScrollToPlugin',
    ScrollTrigger: 'ScrollTrigger',
    SplitText: 'SplitText',
    TextPlugin: 'TextPlugin',
    gsap: 'gsap',
  },
};

// runtime cache
const activeRequests = {
  // e.g.
  // CSSRulePlugin: Promise{<pending>}
};

window.GSRD = window.GSRD || {};

/**
 * Require Gsap function
 * @param {string|string[]} requestedGsapModules Array of gsap modules to be required
 * @param {string} version either 2.0.2, 3.2.4 or 3.7.1
 * @returns {Promise}
 */
async function gsapLoader(requestedGsapModules = [], version = '2.0.2') {
  if (typeof requestedGsapModules === 'string') {
    // eslint-disable-next-line no-param-reassign
    requestedGsapModules = [requestedGsapModules];
  }
  // force a 3.x.x version when first requested module is named 'gsap' as v2.x.x does not support that
  if (requestedGsapModules[0] === 'gsap') {
    version = '3.7.1';
  }

  if (!gsapAssets[version]) {
    return Promise.reject(`Unknown version: ${version} (should be one of 2.0.2, 3.2.4 or 3.7.1)`);
  }

  const importPromises = requestedGsapModules.map(requestedGsapModule => {
    // module download already active or done, return cached import promise
    if (activeRequests[version] && activeRequests[version][requestedGsapModule]) {
      return activeRequests[version][requestedGsapModule];
    }

    // module does not exist
    if (!gsapAssets[version][requestedGsapModule]) {
      return Promise.reject(`Unknown gsap module: ${version}/${requestedGsapModule}`);
    }

    // create full URL and initiate import
    const fullUrl = createModuleUrl(gsapAssets, requestedGsapModule, version);
    const importPromise = loadAsset(fullUrl);

    // cache import promise
    if (!activeRequests[version]) {
      activeRequests[version] = {};
    }
    activeRequests[version][requestedGsapModule] = importPromise;
    return importPromise;
  });

  if (importPromises.length === 1) {
    return importPromises.pop();
  }

  return new Promise((resolve, reject) => {
    Promise.all(importPromises)
      .then(results => {
        const unsuccessfulDownloads = results.filter(el => el instanceof Error);
        if (unsuccessfulDownloads.length) {
          return reject(false);
        }
        resolve(true);
      })
      .catch(reason => Logger.error(`Unable to download gsap dependencies: ${reason.message}`));
  });
}

// Make gsap initializer globally accessible
// for example for campaign code
window.GSRD.gsap = gsapLoader;

export default gsapLoader;
