/* global AppSettings, AppState */
/**
 * Clear basket cache, basically forcing a reload from server when
 * the foldout basket module is initialized.
 *
 * @author Meinaart van Straalen
 */
import storage from 'components/utils/storage/Storage';
import StorageKeys from 'components/utils/storage/StorageKeys';
import 'components/domutils/Element.jQuery';

const localePrefix = `${AppSettings.country.toLowerCase()}_`;
const timeoutSuffix = '_TO';

const basketCacheClearer = {
  clear() {
    storage.removeItem(StorageKeys.BASKET);
  },
};

if (AppState.headerShoppingBagEnabled === false || AppState.refreshBasketFromServer === true) {
  // Clear cached fold out basket so it will be
  // retrieved from the server on next initialized basket load.
  basketCacheClearer.clear();
}

const logoutLink = document.querySelector('a[href$="/logout"]');
// eslint-disable-next-line no-unused-expressions
logoutLink &&
  logoutLink.jq.one('click', function () {
    basketCacheClearer.clear();

    // clear locale specific privacy sensitive data
    storage.removeItem(localePrefix + StorageKeys.BAG_COUNT);
    storage.removeItem(localePrefix + StorageKeys.BAG_COUNT + timeoutSuffix);
    storage.removeItem(localePrefix + StorageKeys.RECENTLY_VIEWED_PRODUCTS);

    // clear wishlist cache
    storage.removeItem(StorageKeys.WISHLIST_ENTRIES);

    // clear privacy sensitive data
    storage.removeItem(StorageKeys.RECENTLY_VIEWED_PRODUCTS_TIMESTAMP);
    storage.removeItem(StorageKeys.PRODUCT_LIST);
    storage.removeItem(StorageKeys.PRODUCT_LIST + timeoutSuffix);
    storage.removeItem(StorageKeys.PRODUCT_SIZE_TRANSFER_LENGTH);
    storage.removeItem(StorageKeys.PRODUCT_SIZE_TRANSFER_SIZE);
    storage.removeItem(StorageKeys.PRODUCT_SIZE_TRANSFER_WAISTLENGTHSELECTION);

    // clear form specific privacy sensitive data, set in the Checkout V2 React app
    storage.removeItem(StorageKeys.__REACT__HOME_DELIVERY_FORM);
    storage.removeItem(StorageKeys.__REACT__PICKUP_DELIVERY_FORM);
    storage.removeItem(StorageKeys.__REACT__LOGIN_FORM);
    storage.removeItem(StorageKeys.__REACT__GUEST_FORM);
    storage.removeItem(StorageKeys.__REACT__PAYMENT_FORM);
  });

export default basketCacheClearer;
