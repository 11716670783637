/* global AppSettings */
/**
 * SideNavigation handling.
 *
 * Handles:
 * - Blocks scrolling of main content while sidenav is open
 * - Toggles visibility of the side navigation
 * - Make sure the sidenavigation closes when you click on the dimmed area
 **/
import $ from 'jquery';
import createLogger from '../logger/Logger';
import EventTypes from '../EventTypes';
import device from '../utils/device';
import matchBreakpoint from '../utils/matchBreakpoint';
import onWindowLoaded from '../utils/onWindowLoaded';
import keyboardInput from '../utils/keyboardInput';
import 'jquery.pageshowEvents';
import 'jquery.resizeEvents';
import 'jquery.bindTransitionEnd';
import 'jquery.scrollEvents';
import '../domutils/Element.jQuery';

const Logger = createLogger('Toggle');

function SideNavigationToggle() {
  const animationDuration = 275; // ms
  const breakpointNextToContent = AppSettings.sideNavigationBreakpointNextToContent;
  let containerTop;
  let footerHeight;
  const hasMarginBug =
    (device.osName === device.IOS && device.osVersion < 8) || device.osName === device.ANDROID; // See RES-499
  let lastScrollingPositionY = 0;
  let listeningForScroll = false;
  let openedByDefault;
  let sideNavigationVisible = false;
  let stateEventsEnabled = false;
  const triggerCheckboxSelector = '.js-topNavigation-sideNavTrigger-checkbox';
  let updateScrollId;
  let isNextToContent = false;
  const _this = this;

  let footer;
  let mainContainer;
  let triggerCheckbox;
  let sideNavigation;
  let scrollContainer;

  let $trigger;
  let $sideNavigation;

  let tiggerAnimationProgressInterval;

  /* ** PUBLIC METHODS ** */
  this.initialize = () => {
    Logger.time('initialize', this);
    footer = document.querySelector('.js-footer') || document.createElement('div');
    mainContainer = document.querySelector('.js-mainContainer');
    sideNavigation = document.querySelector('.js-sideNav') || document.createElement('div');
    scrollContainer = document.querySelector('.js-sideNav-scrollContainer');
    triggerCheckbox =
      document.querySelector(triggerCheckboxSelector) || $('<input type="checkbox"/>');

    $sideNavigation = sideNavigation.jq;
    $trigger = $('.js-topNavigation-sideNavTrigger');

    openedByDefault = document.documentElement.classList.contains(
      'has-open-sideNavigationByDefault'
    );
    isNextToContent = matchBreakpoint(breakpointNextToContent);
    sideNavigationVisible = openedByDefault && isNextToContent;

    bindEvents();
    Logger.timeEnd('initialize', this);

    // Read stuff from DOM when window has loaded
    onWindowLoaded(initialize, _this);
  };

  /* ** PRIVATE METHODS ** */
  function bindEvents() {
    // Set initial state before adding other listeners
    triggerCheckbox.checked = sideNavigationVisible;
    sideNavigation.setAttribute('aria-expanded', sideNavigationVisible);

    if (device.osName === device.IOS) {
      window.jq.on(EventTypes.PAGESHOW_FROM_CACHE, hide.bind(_this, false));
    }

    $trigger.on('vclick', setSideNavVisibilityState).on(
      'keyup',
      keyboardInput.handleKeys.bind(this, 'enter', event => {
        toggleVisibility(event);
      })
    );
    window.jq
      .on('resizeWidthUpdate', onResize)
      .on('resizeStart', onResizeStart)
      .on('resizeEnd', onResizeEnd);

    // Does not work when bound directly on $triggerCheckbox
    document.body.jq.on('change', triggerCheckboxSelector, update);

    // If clicking outside of the scrollContainer the sidenavigation should be closed.

    if (scrollContainer && device.hasTouch) {
      scrollContainer.jq.on('vclick', hideWhenClickedOutside);
      document.body.jq.on('vclick', hideWhenClickedOutside);
    } else {
      document.body.jq.on('click', hideWhenClickedOutside);
      sideNavigation.jq.on('click', hideWhenClickedOutside);
    }

    if (!AppSettings.isEndlessAisleSession) {
      const links = $('.js-sideNav-link');
      links.on('click vclick', onLinkClick);

      if (device.osName === device.IOS) {
        links.on('touchend', event => {
          event.stopPropagation();
        });
      }
    }

    document.jq.on(EventTypes.SIDENAV_HIDE_REQUEST, hide);

    // Enable state events after 2 frames
    setTimeout(() => {
      stateEventsEnabled = true;
    }, (1000 / 60) * 2);
  }

  /**
   * Get height of the footer
   */
  function getFooterHeight() {
    if (footerHeight === undefined) {
      footerHeight = footer.scrollHeight;
    }

    return footerHeight;
  }

  /**
   * Collapse sideNavigation
   * @param {Boolean=} [enableAnimations=true] Animate hiding?
   */
  function hide(enableAnimations) {
    if (triggerCheckbox.checked) {
      if (enableAnimations === false) {
        document.documentElement.classList.remove('has-open-sideNavigation');
        resetAnimation();
      }

      triggerCheckbox.checked = false;
      triggerCheckbox.jq.trigger('change');
    }
  }

  /**
   * Hide when user clicks outside of side navigation.
   * @param  {object} e jQuery event
   */
  function hideWhenClickedOutside(event) {
    if (event.currentTarget === event.target && !isNextToContent) {
      hide();
      event.stopPropagation();
    }
  }

  /**
   * Initialize stuff that needs DOM access
   */
  function initialize() {
    Logger.time('initialize DOM');
    update();
    updateScrollListener();
    Logger.timeEnd('initialize DOM');
  }

  /**
   * Event listener for when a link in sideNav is clicked
   */
  function onLinkClick(event) {
    event.stopPropagation();

    if (!isNextToContent) {
      // Closing sidenav before leaving page, so that in cached page sidenav stays closed
      if (device.osName === device.IOS) {
        hide(false);
        document.location.assign(event.target.href || event.currentTarget.href);
      } else {
        requestAnimationFrame(hide);
      }
    }
  }

  /**
   * Event listener for `resizeWidthEnd` event.
   * Clears top CSS setting when sideNavigation is displayed next to content.
   */
  function onResize() {
    const wasNextToContent = isNextToContent;
    isNextToContent = matchBreakpoint(breakpointNextToContent);

    if (isNextToContent !== wasNextToContent) {
      if (isNextToContent) {
        mainContainer.style.top = '';

        if (footer) {
          footer.style.top = '';
        }

        triggerCheckbox.checked = sideNavigationVisible;
      } else if (document.documentElement.classList.contains('has-open-sideNavigation')) {
        hide();
      } else {
        triggerCheckbox.checked = false;
      }

      updateScrollListener();
    }
  }

  function onResizeStart() {
    sideNavigation.classList.add('is-resizing');
  }

  function onResizeEnd() {
    sideNavigation.classList.remove('is-resizing');
  }

  /**
   * Reset animation
   */
  function resetAnimation() {
    document.documentElement.classList.add('reset-sideNavigation-animation');

    setTimeout(() => {
      document.documentElement.classList.remove('reset-sideNavigation-animation');
    }, (1000 / 60) * 16);
  }

  /**
   * Remember whether user has closed the sideNavigation.
   */
  function setSideNavVisibilityState(event) {
    const labelTarget = event.currentTarget.htmlFor;
    const target = document.getElementById(`${labelTarget}`);
    const targetControls = target.getAttribute('aria-controls');
    const sideNav = document.getElementById(`${targetControls}`);

    try {
      if (isNextToContent && sessionStorage) {
        if (
          document.documentElement.classList.contains('has-open-sideNavigation') ||
          document.documentElement.classList.contains('has-open-sideNavigationByDefault')
        ) {
          sessionStorage.setItem('hasClosedSideNavigation', 'true');
          sideNav.setAttribute('aria-expanded', false);
        } else {
          sessionStorage.removeItem('hasClosedSideNavigation');
          sideNav.setAttribute('aria-expanded', true);
        }
      }
    } catch (event) {}
  }

  /**
   * Toggle visibility of side navigation
   * @param  {mixed} value Can be either boolean with specific value or jQuery event (toggles visibility).
   */
  function toggleVisibility(value) {
    let newValue;

    if (typeof value === 'boolean') {
      newValue = value;
    } else {
      newValue = !sideNavigationVisible;

      if (typeof value === 'object') {
        value.preventDefault();
      }
    }

    if (newValue !== sideNavigationVisible) {
      sideNavigationVisible = newValue;
      updateState();
    }
  }

  /**
   * Dispatched animation complete event on transition end event.
   * It will also be dispatched after timeout, it will only be dispatched once though.
   *
   * @param  {String} additionalEvent Optional argument of additional event to dispatch.
   */
  function triggerAnimationComplete(additionalEvent) {
    if (stateEventsEnabled) {
      const triggerEvent = function triggerEvent() {
        if (additionalEvent) {
          $sideNavigation.trigger(additionalEvent);
        }

        clearInterval(tiggerAnimationProgressInterval);
        $sideNavigation.trigger(EventTypes.SIDENAV_ANIMATION_COMPLETE);
      };

      $sideNavigation.bindTransitionEnd(triggerEvent, animationDuration);
    }
  }

  /**
   * Update visibility state based on checkbox value
   */
  function update() {
    toggleVisibility(triggerCheckbox.checked);
    sideNavigation.setAttribute('aria-expanded', triggerCheckbox.checked);

    if (openedByDefault && !sideNavigationVisible && matchBreakpoint(breakpointNextToContent)) {
      document.documentElement.classList.toggle(
        'has-open-sideNavigationByDefault',
        sideNavigationVisible
      );
    }
  }

  /**
   * Updates value of lastScrollingPositionY when sideNavigation is not visible.
   * @param {Boolean} immediate If true position is updated immediately
   */
  function updateScroll(immediate) {
    if (immediate) {
      updateScrollPosition();
    } else {
      cancelAnimationFrame(updateScrollId);
      updateScrollId = requestAnimationFrame(updateScrollPosition);
    }
  }

  /**
   * Updates value of lastScrollingPositionY when sideNavigation is not visible.
   */
  function updateScrollPosition() {
    if (!sideNavigationVisible) {
      lastScrollingPositionY = window.pageYOffset;

      if (containerTop === undefined) {
        containerTop = mainContainer.offsetTop || 0;
      }
    }
  }

  /**
   * Binds or unbinds window scroll event based on whether sideNavigation is visible
   */
  function updateScrollListener() {
    const shouldListen = !sideNavigationVisible && !isNextToContent;

    if (shouldListen && !listeningForScroll) {
      listeningForScroll = true;
      window.jq.on('scrollUpdate.sideNavigationToggle', updateScroll);
    } else if (!shouldListen) {
      window.jq.off('scrollUpdate.sideNavigationToggle');
      listeningForScroll = false;
    }
  }

  /**
   * Disable scrolling of main content when sidenavigation is open.
   *
   * When sidenavigation is closed again the page scrolls to it's last known scroll position.
   */
  function updateState() {
    updateScrollListener();

    if (stateEventsEnabled) {
      $sideNavigation.trigger(EventTypes.SIDENAV_ANIMATION_START);

      tiggerAnimationProgressInterval = setInterval(() => {
        $sideNavigation.trigger(EventTypes.SIDENAV_ANIMATION_PROGRESS);
      }, 40);
    }

    _this.mainContainerHeight = document.body.clientHeight;

    requestAnimationFrame(function updateStateWrite() {
      Logger.time('updateState', this);
      document.documentElement.classList.toggle('has-open-sideNavigation', sideNavigationVisible);

      if (sideNavigationVisible) {
        if (!isNextToContent) {
          let offsetY;

          if (lastScrollingPositionY !== 0) {
            if (containerTop) {
              offsetY = containerTop - lastScrollingPositionY;
            } else {
              offsetY = -lastScrollingPositionY;
            }

            mainContainer.style.top = `${offsetY}px`;
            if (footer) {
              footer.style.top = `${Math.round(
                offsetY + _this.mainContainerHeight - getFooterHeight()
              )}px`;
            }
          } else if (footer) {
            footer.style.top = `${Math.round(_this.mainContainerHeight - getFooterHeight())}px`;
          }
          $sideNavigation.trigger(EventTypes.TOPNAV_SCROLL_PAUSE_REQUEST);
        }

        if (stateEventsEnabled) {
          $sideNavigation.trigger(EventTypes.SIDENAV_SHOW);
        }

        triggerAnimationComplete();
        Logger.timeEnd('updateState', this);
      } else {
        if (!isNextToContent) {
          mainContainer.style.top = '';
          if (footer) {
            footer.style.top = '';
          }

          if (hasMarginBug) {
            mainContainer.style.overflow = 'hidden';
            setTimeout(() => {
              mainContainer.style.overflow = '';
            }, 0);
          }
        }

        const triggerEvents = () => {
          _this.mainContainerHeight = document.documentElement.scrollHeight;

          if (stateEventsEnabled) {
            $sideNavigation.trigger(EventTypes.SIDENAV_HIDE);
          }
          triggerAnimationComplete(EventTypes.TOPNAV_SCROLL_RESUME_REQUEST);

          Logger.timeEnd('updateState', this);
        };

        if (isNextToContent) {
          triggerEvents();
        } else {
          window.scrollTo(window.pageXOffset, lastScrollingPositionY);
          triggerEvents();
        }
      }
    });
  }
}

export default new SideNavigationToggle();
