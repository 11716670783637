/* global AppSettings */
/**
 * Animate scrolling
 *
 * @author Meinaart van Straalen
 */
import { gsap, ScrollToPlugin } from 'gsap/all';
import EventTypes from '../EventTypes';
import device from './device';
import '../domutils/Element.jQuery';

gsap.registerPlugin(ScrollToPlugin);

const $document = document.jq;

/**
 * Animate scroll to specified position
 * @param {object} targetPosition Target (y-coordinate) position.
 * @param {number=}  [duration=140]             How long it should take to scroll from the top of the page till the bottom (in milliseconds).
 * @param {boolean=} [forceGlobalScroll=false]  When true the scrollTo function will scroll the document even if there are dialogs
 * @param {boolean=} [ignoreDistance=false]     When true duration is calculated based on scroll distance
 * @param {boolean=} [compensateTopNavVisibility=false] When true the targetPosition is compensated for whether the topnav is visible or not
 */
export default function (
  targetPosition,
  duration,
  forceGlobalScroll,
  ignoreDistance,
  compensateTopNavVisibility
) {
  duration = !isNaN(duration) || duration < 0 ? duration : 140;
  let scrollContainer = window;

  if (!forceGlobalScroll) {
    const $visibleDialog = document.querySelectorAll('.js-dialog').jq.filter(':visible');

    if ($visibleDialog.length) {
      scrollContainer = $visibleDialog.get(0);
    }
  }

  // Compensate targetPosition for situation where top nav could become hidden, or when topnav is always visible
  if (compensateTopNavVisibility && targetPosition >= AppSettings.topNavigationHeight) {
    // Returns number or undefined if top nav scroll is not enabled
    const topNavOffset = $document.triggerHandler(EventTypes.TOPNAV_HIDE_OFFSET_REQUEST);

    // Check if topNav will be visible after scroll;
    // if so subtract the height of the topnav from target scroll position
    if (
      !topNavOffset ||
      targetPosition < topNavOffset ||
      (topNavOffset && targetPosition < window.pageYOffset)
    ) {
      targetPosition -= AppSettings.topNavigationHeight;
    }
  }

  // Determine duration based on distance that should be scrolled
  if (!ignoreDistance) {
    duration = (Math.abs(targetPosition - window.pageYOffset) / window.innerHeight) * duration;
  }

  gsap.killTweensOf(scrollContainer.jq);
  gsap.to(scrollContainer.jq, {
    duration: duration / 1000,
    scrollTo: {
      y: targetPosition,

      // Prevent iOS bug: https://greensock.com/forums/topic/15108-ios-10-scrolltoplugin/
      autoKill: device.osName !== device.IOS,
      onComplete() {
        window.jq.trigger('scrollUpdate').trigger('scrollEnd');
      },
    },
  });
}
