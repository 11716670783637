/* globals AppSettings */
/**
 * This module takes care of dispatching a SESSION_UPDATE_REQUIRED event every
 * AppSettings.sessionRefreshInterval seconds.
 *
 * Other modules listen to this and fetch the basket from the server to update the session.
 *
 * This is handled in this separate module because of some specific behavior of timers when a
 * computer is put in standby and/or hibernation.
 *
 * This module should make sure that timers are actually resumed when a computer is woken from sleep state.
 *
 * @author Meinaart van Straalen
 */
import createLogger from 'components/logger/Logger';
import EventTypes from 'components/EventTypes';
import requestIdleCallback from '../utils/requestIdleCallback';
import 'components/domutils/Element.jQuery';

const Logger = createLogger('SessionRefresher');
// Returns Unix timestamp in seconds
function getTime() {
  return Math.round(Date.now() / 1000);
}

let sessionRefreshInterval = false;

function SessionRefresher() {
  Logger.info('Created');
  let sessionTime = getTime();
  let intervalId;
  let initialized = false;

  // Check expiration of session time every .. seconds
  const checkInterval = 60; // seconds

  // Check session for stalenesss
  function checkSessionTimeout() {
    const currentTime = getTime();
    const age = currentTime - sessionTime;

    if (age >= sessionRefreshInterval - (checkInterval * 2)) {
      document.jq.trigger(EventTypes.SESSION_UPDATE_REQUIRED);
      sessionTime = getTime();
    }
  }

  /**
   * Restart interval, should be called when there was interaction with the server
   */
  this.reset = function () {
    sessionTime = getTime();
    this.start();
  };

  /**
   * (re)Starts the check timer
   */
  this.start = function () {
    if (intervalId !== undefined) {
      clearInterval(intervalId);
      checkSessionTimeout();
    }

    if (sessionRefreshInterval !== false) {
      // Check every 60 seconds if the session is older than AppSettings.sessionRefreshInterval
      // this is done because hibernating a computer for example breaks timers with
      // a specific interval (otherwise we could just use AppSettings.sessionRefreshInterval directly)
      intervalId = window.setInterval(checkSessionTimeout, checkInterval * 1000);
    }
  };

  this.initialize = function () {
    if (initialized) {
      return;
    }
    this.start();

    // Restart check timer when user focuses on window
    window.jq.on('focus', this.start.bind(this));

    // Automatically reset timer on server interaction (specific events)
    const events = [
      EventTypes.CART_ADD_ITEM_SUCCESS,
      EventTypes.CART_ADD_ITEM_FAIL,
      EventTypes.CART_VIEW_UPDATE_COMPLETE,
      EventTypes.API_ERROR,
      EventTypes.API_FAIL,
      EventTypes.CART_REMOVE_ITEM_SUCCESS,
      EventTypes.CART_REMOVE_ITEM_FAIL,
      EventTypes.STOCK_NOTIFICATION_LOADED,
    ];

    document.jq.on(events.join(' '), this.reset.bind(this));

    initialized = true;
  };
}

const sessionRefresher = new SessionRefresher();

const idleCallback = () => {
  if (!isNaN(AppSettings.sessionRefreshInterval)) {
    sessionRefreshInterval = AppSettings.sessionRefreshInterval;
    sessionRefresher.initialize();
  }
};

requestIdleCallback(idleCallback);

export default sessionRefresher;
