
import Storage from '../utils/storage/Storage';
import StorageKeys from '../utils/storage/StorageKeys';
import EventTypes from '../EventTypes';

/**
 * Session Page class
 * tracks the number of pages viewed in a single user session
 */
class SessionPageViews {

  /**
   * Constructs the object.
   */
  constructor() {
    this.pageViewCount = Storage.getItem(StorageKeys.PAGE_VIEWS) || 0;
  }

  /**
   * Increases the page view count by 1
   */
  pageShow() {
    this.setPageViewCount(this.pageViewCount + 1);
  }

  /**
   * Returns page view count
   * @returns {number}
   */
  getPageViewCount() {
    return this.pageViewCount;
  }

  /**
   * Sets page view count
   * @param value
   */
  setPageViewCount(value) {
    this.pageViewCount = value;
    Storage.setItem(StorageKeys.PAGE_VIEWS, this.pageViewCount);
    var ev = new CustomEvent(EventTypes.PAGE_VIEWS_COMPLETE, {
      detail: {
        pageViewCount: this.pageViewCount
      }
    });
    window.dispatchEvent(ev);
  }
}

export default new SessionPageViews();