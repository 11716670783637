/* global AppSettings */

/**
 * For stateless login pages information within the loginForm should be
 * prefilled onError. we use the ?error=true of the query to assume there was
 * an error on the login wall.
 */
import $ from 'jquery';

const LOGIN_WALL_ENDPOINT = `/${AppSettings.locale}/api/v1/loginwall`;

function hasLoginWallError() {
  return window.location.href.includes('error=true');
}

/**
 * Returns an object with the error message and email addres used as input
 */
function getLoginWallError() {
  return $.ajax({
    url: LOGIN_WALL_ENDPOINT,
    cache: false,
    dataType: 'json',
    headers: {
      'X-Original-Referer': document.referrer,
    },
  });
}

export default {
  initialize() {
    if (!AppSettings.statelessPage) {
      return;
    }

    if (!hasLoginWallError()) {
      return;
    }

    getLoginWallError().then(({ message, email }) => {
      if (!message && !email) {
        return;
      }

      const usernameInput = document.querySelector('.js-loginform-username');
      if (usernameInput) {
        usernameInput.value = email;
      }
      if (message) {
        const messageElement = document.querySelector('.js-loginform-message');
        if (messageElement) {
          messageElement.innerHTML = message;
        }
      }
    });
  },
};
